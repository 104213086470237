import React, { Component } from 'react';
import { api } from '../../../services/api';
import moment from 'moment';
import { ApiPath } from '../../../common/ApiPath';
import { ResponseCode } from '../../../common/ResponseCode';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { browserHistory } from '../../../browser-history';
import { AppConst } from '../../../common/AppConst';
import SecurityService from '../../../services/security_service';
import {
  Form,
  Steps,
  Button,
  Row,
  Col,
  Input,
  DatePicker,
  Checkbox,
  Upload,
  Spin,
  notification,
  Tooltip,
  Modal,
  Tabs,
} from 'antd';
import { OrganizerValidationConst } from '../../../common/validation-const/organizer_validation_const';
import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import SelectionMessagePackageComponent from './SelectionMessagePackage';
import UploadImageModal from '../../../components/ModalUploadImageResizer';
import UploadVideoModal from '../../../components/UploadOneVideoModal';
import './index.css';
import { getFileName } from '../../../utils';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Step } = Steps;
const { TextArea } = Input;
const acceptVideo = ['video/mp4'];
const acceptImage = ['image/png', 'image/jpg', 'image/jpeg'];
const steps = [
  {
    title: 'アカウント設定',
    content: 'First-content',
  },
  {
    title: '会社情報設定',
    content: 'Second-content',
  },
  {
    title: 'チャネル設定',
    content: 'Thirst-content',
  },
  {
    title: 'メッセージ設定',
    content: 'Last-content',
  },
];

class OrganizerDetail extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      organizer: null,
      current: null,
      visible: false,
      isEdit: false,
      isUploadVideo: false,
      isUploadImage: false,
      isUploadText: false,
      isVisibleModalUploadImage: false,
      message_photo_url: '',
      message_photo_size: 'giga',
      message_photo_target_url: '',
      msg_video_url: '',
      message_package_id: '',
      message_package_name: '',
      isVisibleModalUploadVideo: false,
    };
  }

  componentDidMount() {
    this.getOrganizerInfo(this.props.match.params.id);
  }

  getOrganizerInfo(organizerId) {
    api.get(this.getOrganizerDetailPath(organizerId)).then(
      (response) => {
        if (response.status === ResponseCode.OK && response.data.meta.success) {
          const organizer = response.data.data;
          const msg_video_url = organizer?.tks_msg_video_url;
          const message_photo_url = organizer?.tks_msg_photo_url;
          const message_package_id = organizer?.message_package_id;
          const message_package_name = organizer?.message_package_name;
          const message_photo_size = organizer?.tks_msg_photo_size ? organizer.tks_msg_photo_size : 'giga';
          const message_photo_target_url = organizer?.tks_msg_photo_target_url;
          this.setState({
            current: 0,
            organizer,
            msg_video_url,
            message_photo_url,
            loading: false,
            message_package_id,
            message_package_name,
            message_photo_size,
            message_photo_target_url,
          });
        } else {
          notification.error({ message: response?.data?.meta?.error_data?.error_msg, description: '' });
          this.setState({ loading: false });
          // browserHistory.push(AppConst.ERROR_404_PATH);
          // window.location = AppConst.ERROR_404_PATH;
        }
      },
      (error) => {
        notification.error({ message: AppConst.INTERNAL_SERVER_ERROR, description: error });
        this.setState({ loading: false });
        // browserHistory.push(AppConst.ERROR_404_PATH);
        // window.location = AppConst.ERROR_404_PATH;
      }
    );
  }

  getOrganizerDetailPath = (organizerId) => {
    const localOrganizerId = localStorage.getItem('organizerId');
    if (localOrganizerId) {
      return ApiPath.ORGANIZER_DETAIL_PATH + localOrganizerId;
    }
    return ApiPath.ADMIN_ORGANIZER_DETAIL_PATH + organizerId;
  };

  getApiPath = (organizerId) => {
    const current = this.state.current;
    const localOrganizerId = localStorage.getItem('organizerId');
    switch (current) {
      case 0:
        if (localOrganizerId) {
          return ApiPath.ORGANIZER_UPDATE_ACCOUNT_PATH.replace(AppConst.ORGANIZER_ID, localOrganizerId);
        }
        return ApiPath.ADMIN_ORGANIZER_UPDATE_ACCOUNT_PATH.replace(AppConst.ORGANIZER_ID, organizerId);
      case 1:
        if (localOrganizerId) {
          return ApiPath.ORGANIZER_UPDATE_COMPANY_PATH.replace(AppConst.ORGANIZER_ID, localOrganizerId);
        }
        return ApiPath.ADMIN_ORGANIZER_UPDATE_COMPANY_PATH.replace(AppConst.ORGANIZER_ID, organizerId);
      case 2:
        if (localOrganizerId) {
          return ApiPath.ORGANIZER_UPDATE_CHANNEL_PATH.replace(AppConst.ORGANIZER_ID, localOrganizerId);
        }
        return ApiPath.ADMIN_ORGANIZER_UPDATE_CHANNEL_PATH.replace(AppConst.ORGANIZER_ID, organizerId);
      case 3:
        if (localOrganizerId) {
          return ApiPath.ORGANIZER_UPDATE_MESSAGE_PATH.replace(AppConst.ORGANIZER_ID, localOrganizerId);
        }
        return ApiPath.ADMIN_ORGANIZER_UPDATE_MESSAGE_PATH.replace(AppConst.ORGANIZER_ID, organizerId);
      default:
        break;
    }
  };

  getFileExt = (type) => {
    return type.split('/')[1];
  };

  uploadToS3 = (file, fileType) => {
    const params = {
      'content-type': file.type,
      'file-ext': this.getFileExt(file.type),
      type: fileType,
    };
    const localOrganizerId = localStorage.getItem('organizerId');
    let path_api = ApiPath.ADMIN_ORGANIZER_UPLOAD_S3_PATH.replace(AppConst.ORGANIZER_ID, this.state.organizer.id);
    if (localOrganizerId) {
      path_api = ApiPath.ORGANIZER_UPLOAD_S3_PATH.replace(AppConst.ORGANIZER_ID, localOrganizerId);
    }
    return api.get(path_api, params).then(
      (response) => {
        const options = {
          params: {
            Key: file.name,
            ContentType: file.type,
          },
          headers: {
            'Content-Type': file.type,
          },
        };
        if (response.status === ResponseCode.OK && response.data.meta.success) {
          return api.putToS3(response.data.data.second, file, options).then(
            (responseS3) => {
              if (response.status === ResponseCode.OK) {
                return response.data.data.first;
              } else {
                return false;
              }
            },
            (error) => {
              return false;
            }
          );
        } else {
          return false;
        }
      },
      (error) => {
        return false;
      }
    );
  };

  processUploadS3 = async (values) => {
    const promiseList = [];
    const fieldList = [];
    if (values.image && values.message_photo_url?.file?.originFileObj instanceof File) {
      promiseList.push(
        this.uploadToS3(values.message_photo_url?.file?.originFileObj, AppConst.S3_TYPE_UPLOAD.THANKS_IMAGE)
      );
      fieldList.push('message_photo_url');
    }
    if (values.video && values.message_video_url?.file?.originFileObj instanceof File) {
      promiseList.push(
        this.uploadToS3(values.message_video_url?.file?.originFileObj, AppConst.S3_TYPE_UPLOAD.THANKS_VIDEO)
      );
      fieldList.push('message_video_url');
    }

    if (values.video && values.message_video_thumbnail_url?.file?.originFileObj instanceof File) {
      promiseList.push(
        this.uploadToS3(
          values.message_video_thumbnail_url?.file?.originFileObj,
          AppConst.S3_TYPE_UPLOAD.THANKS_VIDEO_THUMBNAIL
        )
      );
      fieldList.push('message_video_thumbnail_url');
    }

    await Promise.all(promiseList)
      .then((results) => {
        fieldList.forEach((item, index) => {
          values[item] = results[index];
        });
      })
      .catch((reason) => {
        this.setState({ isLoading: false });
      });
  };

  onFinish = () => {
    const { current, organizer } = this.state;
    const { t } = this.props;

    this.formRef.current
      .validateFields()
      .then(async (values) => {
        if (current === 3 && !values.text && !values.image && !values.video) {
          notification.error({
            message:
              this.props.t('organizer_detail.detail_title.message_content') + this.props.t('common.field_required'),
            description: '',
          });
          return;
        }
        this.setState({ loading: true });
        if (current === 0) {
          values.expired_date = moment(values.expired_date).format('yyyy-MM-DD');
        }
        if (current === 2) {
          values.channel_access_token = values.channel_access_token || organizer.channel_access_token;
          values.channel_secret = values.channel_secret || organizer.channel_secret;
        }
        if (current === 3) {
          // if (values.image || values.video) {
          //   await this.processUploadS3(values);
          // }
          values.message_text = !values.text ? '' : values.message_text.trim();
          values.message_photo_url = !values.image ? '' : values.message_photo_url;
          values.message_photo_size = this.state.message_photo_size;
          values.message_photo_target_url = this.state.message_photo_target_url;
          values.message_video_url = !values.video ? '' : values.message_video_url;
          values.message_package_id = this.state.message_package_id;
          values.message_package_name = this.state.message_package_name;
        }
        api.put(this.getApiPath(organizer.id), values).then(
          (response) => {
            if (response.status === ResponseCode.OK && response.data.meta.success) {
              if (current === 2) {
                organizer.channel_access_token = values.channel_access_token;
                organizer.channel_secret = values.channel_secret;
                this.formRef.current.setFieldsValue({ channel_webhook_url: response?.data?.data });
              }
              notification.success({ message: t('message.MSG_03'), description: '' });
              this.setState({ isEdit: false, loading: false, organizer });
            } else {
              notification.error({ message: response?.data?.meta?.error_data?.error_msg, description: '' });
              this.setState({ loading: false });
              return;
            }
          },
          (error) => {
            notification.error({ message: AppConst.INTERNAL_SERVER_ERROR, description: error });
            this.setState({ loading: false });
            return;
          }
        );
      })
      .catch((errorInfo) => {
        console.log('errorInfo', errorInfo);
      });
  };

  handleChangeTextCheckbox = (event) => {
    const checked = event.target.checked;
    if (!checked) {
      this.formRef.current.setFieldsValue({ message_text: null });
    }
    this.setState({ isUploadText: checked });
  };

  handleChangeVideoCheckbox = (event) => {
    const checked = event.target.checked;
    if (!checked) {
      this.formRef.current.setFieldsValue({
        message_video_name: null,
        message_video_url: null,
        message_video_thumbnail_name: null,
        message_video_thumbnail_url: null,
      });
    }
    this.setState({ isUploadVideo: checked });
  };

  handleChangeImageCheckbox = (event) => {
    const checked = event.target.checked;
    if (!checked) {
      this.formRef.current.setFieldsValue({ message_photo_name: null, message_photo_url: null });
      this.setState({
        message_photo_url: '',
        message_photo_size: 'giga',
        message_photo_target_url: '',
      });
    }
    this.setState({ isUploadImage: checked });
  };

  beforeUploadVideo = (file) => {
    const fileSize = file.size / 1024 / 1024;
    if (!acceptVideo.includes(file.type) || fileSize >= 10) {
      notification.error({ message: this.props.t('common.video_upload_invalid'), description: '' });
      return;
    }
    this.formRef.current.setFieldsValue({ message_video_name: file.name });
  };

  beforeUploadImage = (file) => {
    const fileSize = file.size / 1024 / 1024;
    if (!acceptImage.includes(file.type) || fileSize >= 10) {
      notification.error({ message: this.props.t('common.image_upload_invalid'), description: '' });
      return;
    }
    this.formRef.current.setFieldsValue({ message_photo_name: file.name });
  };

  beforeUploadVideoThumbnail = (file) => {
    const fileSize = file.size / 1024 / 1024;
    if (!acceptImage.includes(file.type) || fileSize >= 10) {
      notification.error({ message: this.props.t('common.image_upload_invalid'), description: '' });
      return;
    }
    this.formRef.current.setFieldsValue({ message_video_thumbnail_name: file.name });
  };

  disabledDate = (current) => {
    return current && current < moment().startOf('days');
  };

  formatInput = (event) => {
    const target = event.target;
    this.formRef.current.setFieldsValue({ [target?.id]: target?.value.trim() });
  };

  formatInputNumber = (event) => {
    const target = event.target;
    const regexNumber = /^[0-9]+$/;
    if (regexNumber.test(target.value)) {
      this.formRef.current.setFieldsValue({ [target?.id]: target?.value.trim() });
    } else {
      this.formRef.current.setFieldsValue({ [target?.id]: target?.value.replace(/[^0-9]/g, '') });
    }
  };

  onChangeMessagePackage = (values) => {
    const { id, name } = values;
    this.setState({
      message_package_id: id,
      message_package_name: name,
    });
  };

  toggleModalUploadImage = () => {
    this.setState({
      isVisibleModalUploadImage: !this.state.isVisibleModalUploadImage,
    });
  };

  toggleModalUploadVideo = () => {
    this.setState({
      isVisibleModalUploadVideo: !this.state.isVisibleModalUploadVideo,
    });
  };

  handleChangeUploadImageModal = (values) => {
    this.formRef.current.setFieldsValue({ message_photo_url: values.imageUrl });
    this.formRef.current.setFieldsValue({ message_photo_url_name: getFileName(values.imageUrl) });
    this.setState({
      message_photo_url: values.imageUrl,
      message_photo_size: values.imageSize,
      message_photo_target_url: values.link,
    });
  };

  handleChangeUploadVideo = (values) => {
    this.formRef.current.setFieldsValue({
      message_video_name: getFileName(values.videoURL),
      message_video_url: values.videoURL,
      message_video_thumbnail_name: getFileName(values.thumbnailURL),
      message_video_thumbnail_url: values.thumbnailURL,
    });
  };

  getContentOfSteps = () => {
    const {
      isEdit,
      current,
      organizer,
      message_photo_url,
      msg_video_url,
      isUploadImage,
      isUploadVideo,
      isUploadText,
    } = this.state;
    const { t } = this.props;
    const { TabPane } = Tabs;

    switch (current) {
      case 0:
        return (
          <div>
            <Row className="row-content">
              <Col span={2}></Col>
              <Col className="row-title-content" span={4}>
                <span className="label-style-common">{t('organizer_detail.detail_title.account')}</span>
              </Col>
              <Col span={14}>
                <Input defaultValue={organizer?.account} disabled={true} />
              </Col>
            </Row>
            <Row>
              <Col span={2}></Col>
              <Col className="row-title-content" span={4}>
                <span className="label-style-common">{t('organizer_detail.detail_title.password')}</span>
              </Col>
              <Col span={14}>
                <Form.Item name="password" initialValue="">
                  <Input.Password onBlur={this.formatInput} disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={2}></Col>
              <Col className="row-title-content" span={4}>
                <span className="label-style-common">{t('organizer_detail.detail_title.email')}</span>
              </Col>
              <Col span={14}>
                <Form.Item
                  name="email"
                  initialValue={organizer?.email}
                  rules={[{ required: true, message: t('organizer_update.create_form.email.validation.required') }]}
                >
                  <Input onBlur={this.formatInput} disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={2}></Col>
              <Col className="row-title-content" span={4}>
                <span className="label-style-common">{t('organizer_detail.detail_title.expired_date')}</span>
              </Col>
              <Col span={14}>
                <Form.Item
                  name="expired_date"
                  initialValue={
                    organizer?.expired_date
                      ? moment(organizer?.expired_date)
                      : moment(organizer?.expired_date).format('YYYY-MM-DD')
                  }
                >
                  <DatePicker
                    disabledDate={this.disabledDate}
                    className="due-date"
                    disabled={!isEdit || organizer.role !== 'ADMIN'}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        );
      case 1:
        return (
          <div>
            <Row>
              <Col span={2}></Col>
              <Col className="row-title-content" span={4}>
                <span className="label-style-common">{t('organizer_detail.detail_title.company_name')}</span>
              </Col>
              <Col span={14}>
                <Form.Item
                  name="company_name"
                  initialValue={organizer?.company_name}
                  rules={[
                    { required: true, message: t('organizer_update.create_form.company_name.validation.required') },
                  ]}
                >
                  <Input maxLength={255} onBlur={this.formatInput} disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={2}></Col>
              <Col className="row-title-content" span={4}>
                <span className="label-style-common">{t('organizer_detail.detail_title.email')}</span>
              </Col>
              <Col span={14}>
                <Form.Item
                  name="company_email"
                  initialValue={organizer?.company_email}
                  rules={[
                    {
                      required: true,
                      message: t('organizer_update.create_form.person_in_charge_email.validation.required'),
                    },
                    (form) => ({
                      validator(rule, value) {
                        if (!value || value.match(OrganizerValidationConst.EMAIL_REGEX)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(t('organizer_create.create_form.email.validation.match'));
                      },
                    }),
                  ]}
                >
                  <Input maxLength={255} onBlur={this.formatInput} disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={2}></Col>
              <Col className="row-title-content" span={4}>
                <span className="label-style-common">{t('organizer_detail.detail_title.company_address')}</span>
              </Col>
              <Col span={14}>
                <Form.Item
                  name="company_address"
                  initialValue={organizer?.company_address}
                  rules={[
                    { required: true, message: t('organizer_update.create_form.company_address.validation.required') },
                  ]}
                >
                  <Input maxLength={255} onBlur={this.formatInput} disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={2}></Col>
              <Col className="row-title-content" span={4}>
                <span className="label-style-common">{t('organizer_detail.detail_title.company_phone_no')}</span>
              </Col>
              <Col span={14}>
                <Form.Item
                  name="company_phone_no"
                  initialValue={organizer?.company_phone_no}
                  rules={[
                    {
                      required: true,
                      message: t('organizer_update.create_form.company_phone_number.validation.required'),
                    },
                  ]}
                >
                  <Input
                    onChange={this.formatInputNumber}
                    onBlur={this.formatInputNumber}
                    maxLength={20}
                    disabled={!isEdit}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={2}></Col>
              <Col className="row-title-content" span={4}>
                <span className="label-style-common">{t('organizer_detail.detail_title.company_postal_code')}</span>
              </Col>
              <Col span={14}>
                <Form.Item
                  name="company_postal_code"
                  initialValue={organizer?.company_postal_code}
                  rules={[
                    {
                      required: true,
                      message: t('organizer_update.create_form.company_postal_code.validation.required'),
                    },
                  ]}
                >
                  <Input
                    onChange={this.formatInputNumber}
                    onBlur={this.formatInputNumber}
                    maxLength={20}
                    disabled={!isEdit}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={2}></Col>
              <Col className="row-title-content" span={4}>
                <span className="label-style-common">{t('organizer_detail.detail_title.person_in_charge_name')}</span>
              </Col>
              <Col span={14}>
                <Form.Item
                  name="person_in_charge_name"
                  initialValue={organizer?.person_in_charge_name}
                  rules={[
                    {
                      required: true,
                      message: t('organizer_update.create_form.person_in_charge_name.validation.required'),
                    },
                  ]}
                >
                  <Input maxLength={255} onBlur={this.formatInput} disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>
          </div>
        );
      case 2:
        return (
          <div>
            <Row>
              <Col span={2}></Col>
              <Col className="row-title-content" span={5}>
                <span className="label-style-common">{t('organizer_detail.detail_title.line_user_id')}</span>
              </Col>
              <Col span={14}>
                <Form.Item
                  name="line_user_id"
                  initialValue={organizer?.line_user_id}
                  rules={[
                    { required: true, message: t('organizer_update.create_form.line_user_id.validation.required') },
                  ]}
                >
                  <Input maxLength={64} onBlur={this.formatInput} disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={2}></Col>
              <Col className="row-title-content" span={5}>
                <span className="label-style-common">{t('organizer_detail.detail_title.channel_access_token')}</span>
              </Col>
              <Col span={14}>
                <Form.Item
                  name="channel_access_token"
                  initialValue={organizer?.channel_access_token ? '登録済' : '登録未'}
                  rules={[
                    {
                      required: !organizer?.channel_access_token,
                      message: t('organizer_update.create_form.channel_access_token.validation.required'),
                    },
                  ]}
                >
                  <Input maxLength={255} onBlur={this.formatInput} disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={2}></Col>
              <Col className="row-title-content" span={5}>
                <span className="label-style-common">{t('organizer_detail.detail_title.channel_id')}</span>
              </Col>
              <Col span={14}>
                <Form.Item
                  name="channel_id"
                  initialValue={organizer?.channel_id}
                  rules={[
                    { required: true, message: t('organizer_update.create_form.channel_id.validation.required') },
                  ]}
                >
                  <Input maxLength={255} onBlur={this.formatInput} disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={2}></Col>
              <Col className="row-title-content" span={5}>
                <span className="label-style-common">{t('organizer_detail.detail_title.channel_secret')}</span>
              </Col>
              <Col span={14}>
                <Form.Item
                  name="channel_secret"
                  initialValue={organizer?.channel_secret ? '登録済' : '登録未'}
                  rules={[
                    {
                      required: !organizer?.channel_secret,
                      message: t('organizer_update.create_form.channel_secret.validation.required'),
                    },
                  ]}
                >
                  <Input maxLength={255} onBlur={this.formatInput} disabled={!isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={2}></Col>
              <Col className="row-title-content" span={5}>
                <span className="label-style-common">{t('organizer_detail.detail_title.channel_webhook_url')}</span>
              </Col>
              <Col span={14}>
                <Form.Item name="channel_webhook_url" initialValue={organizer?.channel_webhook_url}>
                  <Input onBlur={this.formatInput} disabled={true} />
                </Form.Item>
              </Col>
            </Row>
          </div>
        );
      case 3:
        return (
          <Tabs defaultActiveKey="0">
            <TabPane tab="メッセージ" key="0">
              <Row>
                <Col span={2}></Col>
                <Col className="row-title-content" span={4}></Col>
                <Col span={14}>
                  <div className="thanks-message-announce">＊お礼とセミナー開催を告知するメッセージ</div>
                </Col>
              </Row>
              <Row className="row-content">
                <Col span={2}></Col>
                <Col className="row-title-content" span={4}>
                  <span className="label-style-common">{t('organizer_detail.detail_title.message_type')}</span>
                </Col>
                <Col span={14}>
                  <div>{t('organizer_detail.detail_title.thank_message')}</div>
                </Col>
              </Row>
              <Row>
                <Col span={2}></Col>
                <Col className="row-title-content" span={4}>
                  <span className="label-style-common">{t('organizer_detail.detail_title.message_content')}</span>
                </Col>
                <Col span={14}>
                  <Row>
                    <Col span={4}>
                      <Form.Item name="text" initialValue={organizer?.tks_msg_text} valuePropName="checked">
                        <Checkbox onChange={this.handleChangeTextCheckbox} disabled={!isEdit}>
                          {t('organizer_detail.detail_title.message_text')}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item name="image" initialValue={organizer?.tks_msg_photo_url} valuePropName="checked">
                        <Checkbox onChange={this.handleChangeImageCheckbox} disabled={!isEdit}>
                          {t('organizer_detail.detail_title.message_image')}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item name="video" initialValue={organizer?.tks_msg_video_url} valuePropName="checked">
                        <Checkbox onChange={this.handleChangeVideoCheckbox} disabled={!isEdit}>
                          {t('organizer_detail.detail_title.message_video')}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Tooltip placement="right" title={t('organizer_detail.detail_title.popup')}>
                        <Button type="link" icon={<QuestionCircleOutlined />} onClick={this.showModal}></Button>
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="message_text"
                        rules={[
                          { required: isUploadText, message: t('common.message_text') + t('common.field_required') },
                        ]}
                        initialValue={organizer?.tks_msg_text}
                      >
                        <TextArea maxLength={2000} rows={4} disabled={!(isUploadText && isEdit)} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="step1-image-title" span={19}>
                      <Form.Item
                        name="message_photo_url_name"
                        initialValue={getFileName(organizer?.tks_msg_photo_url)}
                        rules={[
                          { required: isUploadImage, message: t('common.image_upload') + t('common.field_required') },
                        ]}
                      >
                        <Input placeholder={t('common.image_upload')} disabled={true} />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item name="message_photo_url" initialValue={organizer?.tks_msg_photo_url}>
                        {/*<Upload
                          className="full-width"
                          name="file"
                          accept=".png,.jpeg,.jpg"
                          beforeUpload={this.beforeUploadImage}
                          showUploadList={false}
                        >
                          
                        </Upload>*/}
                        <Button
                          className="full-width step1-photo button button-active"
                          disabled={!(isUploadImage && isEdit)}
                          onClick={this.toggleModalUploadImage}
                        >
                          {t('seminar_detail.step1.upload')}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="step1-image-title" span={19}>
                      <Form.Item
                        name="message_video_name"
                        initialValue={getFileName(msg_video_url)}
                        rules={[
                          { required: isUploadVideo, message: t('common.video_upload') + t('common.field_required') },
                        ]}
                      >
                        <Input placeholder={t('common.video_upload')} disabled={true} />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item name="message_video_url" initialValue={msg_video_url}>
                        <Button
                          className="full-width step1-photo button button-active"
                          disabled={!(isUploadVideo && isEdit)}
                          onClick={this.toggleModalUploadVideo}
                        >
                          {t('seminar_detail.step1.upload')}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="step1-image-title" span={19}>
                      <Form.Item
                        name="message_video_thumbnail_name"
                        initialValue={getFileName(organizer?.tks_msg_video_thumbnail_url)}
                        rules={[
                          {
                            message: t('common.video_thumbnail_upload') + t('common.field_required'),
                          },
                        ]}
                      >
                        <Input placeholder={t('common.video_thumbnail_upload')} disabled={true} />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        name="message_video_thumbnail_url"
                        initialValue={organizer?.tks_msg_video_thumbnail_url}
                      >
                        <Button
                          className="full-width step1-photo button button-active"
                          disabled={!(isUploadVideo && isEdit)}
                          onClick={this.toggleModalUploadVideo}
                        >
                          {t('seminar_detail.step1.upload')}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="メッセージパッケージ" key="1">
              <SelectionMessagePackageComponent
                onChangeMessagePackage={this.onChangeMessagePackage}
                values={{
                  id: this.state.message_package_id,
                  name: this.state.message_package_name,
                }}
                isEdit={isEdit}
                height={300}
              />
            </TabPane>
          </Tabs>
        );
      default:
        break;
    }
  };

  next = () => {
    const current = this.state.current + 1;
    if (current === 3) {
      const channel_access_token = this.state.organizer?.channel_access_token ? '登録済' : '登録未';
      const channel_secret = this.state.organizer?.channel_secret ? '登録済' : '登録未';
      this.formRef.current.setFieldsValue({ channel_access_token, channel_secret });
    }
    this.setState({ current, isEdit: false });
  };

  prev = () => {
    const current = this.state.current - 1;
    if (current === 1) {
      const channel_access_token = this.state.organizer?.channel_access_token ? '登録済' : '登録未';
      const channel_secret = this.state.organizer?.channel_secret ? '登録済' : '登録未';
      this.formRef.current.setFieldsValue({ channel_access_token, channel_secret });
    }
    this.setState({ current, isEdit: false });
  };

  edit = () => {
    const isUploadText = this.formRef.current.getFieldValue('text') ? true : false;
    const isUploadVideo = this.formRef.current.getFieldValue('video') ? true : false;
    const isUploadImage = this.formRef.current.getFieldValue('image') ? true : false;
    if (this.state.current === 2) {
      this.formRef.current.setFieldsValue({ channel_access_token: '', channel_secret: '' });
    }
    this.setState({ isEdit: true, isUploadImage, isUploadText, isUploadVideo });
  };

  save = () => {
    this.onFinish();
  };

  cancel = () => {
    const backToList = SecurityService.isAdmin()
      ? AppConst.ROUTE.ORGANIZER_MANAGEMENTS
      : AppConst.ROUTE.SEMINAR_MANAGEMENTS;
    browserHistory.push(backToList);
    window.location = backToList;
  };

  onChangeStep = (current) => {
    this.setState({ current, isEdit: false });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleModalCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { t } = this.props;
    const { organizer, current, isEdit, loading } = this.state;
    if (!organizer) return <div></div>;
    return (
      <Spin indicator={antIcon} spinning={loading}>
        <div className="container">
          <h2 className="text-center title">{t('organizer_detail.page_title')}</h2>
          <hr></hr>
          <>
            <Steps current={current} onChange={this.onChangeStep}>
              {steps.map((item, index) => (
                <Step status={current === index ? 'process' : 'wait'} key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">
              <Form ref={this.formRef} onFinish={this.onFinish}>
                {this.getContentOfSteps()}
              </Form>
            </div>
            <div className="steps-action">
              {current !== 0 && (
                <Button className="button-style-common" style={{ margin: '0 8px' }} onClick={this.prev}>
                  {t('organizer_detail.prev_btn_label')}
                </Button>
              )}
              {current < steps.length - 1 && (
                <Button className="style-button button-style-common" onClick={this.next}>
                  {t('organizer_detail.next_btn_label')}
                </Button>
              )}
              {isEdit && (
                <Button className="style-button button-style-common" onClick={this.save}>
                  {t('organizer_detail.save_btn_label')}
                </Button>
              )}
              {!isEdit && (
                <Button className="style-button button-style-common" onClick={this.edit}>
                  {t('organizer_detail.edit_btn_label')}
                </Button>
              )}
              <Button className="button-style-common" onClick={this.cancel}>
                {t('organizer_detail.go_to_list_btn_label')}
              </Button>
            </div>
            <Modal
              width="40%"
              title={<div className="text-center">{t('organizer_detail.detail_title.modal_title')}</div>}
              visible={this.state.visible}
              onCancel={this.handleModalCancel}
              footer={null}
            >
              <p>●テキストメッセージのみの場合</p>
              <img width="95%" src={require('../../../resources/assets/image1.png')} />
              <p style={{ marginTop: '30px' }}>●画像メッセージのみの場合</p>
              <img width="95%" src={require('../../../resources/assets/image2.png')} />
              <p style={{ marginTop: '30px' }}>●テキストメッセージと画像メッセージの場合</p>
              <img width="95%" src={require('../../../resources/assets/image3.png')} />
              <p style={{ marginTop: '30px' }}>●動画メッセージの場合</p>
              <p>LINEシステムでは動画メッセージが区別して送ります。</p>
              <p>動画メッセージとテキストメッセージまたは画像メッセージを送れば</p>
              <p>二つメッセージの以下のようなメッセージを送ります。</p>
              <img width="60%" src={require('../../../resources/assets/image4.png')} />
            </Modal>
            <UploadImageModal
              isVisible={this.state.isVisibleModalUploadImage}
              toggleModal={this.toggleModalUploadImage}
              handleChangeModal={this.handleChangeUploadImageModal}
              imageURLValue={this.state.message_photo_url}
              imageSizeValue={this.state.message_photo_size}
              imageLinkValue={this.state.message_photo_target_url}
              organizerIdAdmin={this.state.organizer.id}
            />
            <UploadVideoModal
              isModalVisible={this.state.isVisibleModalUploadVideo}
              toggleModal={this.toggleModalUploadVideo}
              handleChangeModal={this.handleChangeUploadVideo}
              videoURLValue={this.formRef.current?.getFieldValue('message_video_url')}
              thumbnailURLValue={this.formRef.current?.getFieldValue('message_video_thumbnail_url')}
              organizerIdAdmin={this.state.organizer.id}
            />
          </>
        </div>
      </Spin>
    );
  }
}

const mstp = (state) => ({
  loginReducer: state.loginReducer,
});
const mdtp = (dispatch) => ({});

export default connect(mstp, mdtp)(withTranslation()(OrganizerDetail));
