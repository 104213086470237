import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Tabs} from "antd";
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {api} from "../../services/api";
import './styles.css';
import { Header } from "antd/lib/layout/layout";

const {TabPane} = Tabs;

class ShorternUrl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            targetUrl: null,
            remaining: 5,
        }
        this.code = this.props.match.params.code;
    }

    updateQueryStringParameter(uri, key, value) {
        if (!uri) return uri;
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
            return uri.replace(re, '$1' + key + "=" + value + '$2');
        }
        else {
            return uri + separator + key + "=" + value;
        }
    }

    getTargetUrl(code) {
        api.get("/shorten-url/full-url?shortenCode=" + this.code).then((response) => {
            const result = response.data.data;
            if (result) {
                document.location.href = this.updateQueryStringParameter(result, "rf", this.code);
            }
        }).catch((error) => {
        });
    }

    componentDidMount() {
        this.getTargetUrl(this.code);
    }

    render() {
        return (
            <div className="shorten-url">
                <Header>
                    <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <img alt="logo" src={require('../../resources/assets/logo-line-webinar.png')} width="24" height="35" />
                    &nbsp;&nbsp;
                    <span style={{ fontSize: '25px' }}>L EVER</span>
                    </div>
                </Header>
                <div className="shorten-url__container">
                    <h2 className="text">リダイレクト中。。。</h2>
                </div>
            </div>
        );
    }
}


class EditorPreview extends Component {
    render() {
        return (
            <div className="editor-preview">
                <div dangerouslySetInnerHTML={{__html: this.props.data}}/>
            </div>
        );
    }
}

EditorPreview.defaultProps = {
    data: ''
};

EditorPreview.propTypes = {
    data: PropTypes.string
};


const mstp = (state) => ({});
const mdtp = (dispatch) => ({});

export default connect(mstp, mdtp)(withTranslation()(ShorternUrl));
