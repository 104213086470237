import React, { useState, useEffect, Fragment } from 'react';
import { Modal, Divider, Menu, Layout, Button, Table, Tag, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { FolderOpenOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import _ from 'lodash';
import { actions } from '../../pages/ActionManagement/actions';
import { ellipsisText } from '../../utils';

const { Item } = Menu;
const { Content, Sider } = Layout;

function ActionSelectionModal({
  isModalVisible,
  toggleModal,
  indexField,
  actionFolders,
  actions,
  getListAction,
  getActionFolder,
  formikProps: { setFieldValue, values },
  getFilterTags,
  filterActions,
}) {
  const { t } = useTranslation();
  const [searchAction, setSearchAction] = useState();
  const [initialValues, setInitialValues] = useState({
    id: '',
    name: '',
  });

  useEffect(() => {
    getActionFolder();
  }, []);

  useEffect(() => {
    const { actions } = values;

    if (actions[indexField]?.response_action_id && actions[indexField]?.response_action_name) {
      setInitialValues({
        id: actions[indexField].response_action_id,
        name: actions[indexField].response_action_name,
      });
    }
  }, [values.actions[indexField]?.response_action_id, isModalVisible]);

  useEffect(() => {
    if (actionFolders && actionFolders?.length) {
      getListAction(actionFolders[0]?.id);
    }
  }, [actionFolders]);

  const setActionValue = (indexField, id, name) => {
    setFieldValue(`actions.${indexField}.response_action_id`, id);
    setFieldValue(`actions.${indexField}.response_action_name`, name);
  };

  const onSearchAction = (e) => {
    const { value } = e.target;
    getFilterTags(value.trim());
    setSearchAction(value.trim());
  };

  const handleSubmit = () => {
    const { id, name } = initialValues;
    setActionValue(indexField, id, name);
    toggleModal();
  };

  const rowSelection = {
    renderCell: (checked, record) => {
      const { id } = initialValues;
      return (
        <Radio
          value={record.actionID}
          onChange={() => {
            setInitialValues({
              id: record.actionID,
              name: record.name,
            });
          }}
          checked={record.actionID === id}
        />
      );
    },
  };

  const columns = [
    {
      title: t('action_management.label_column_action_name'),
      dataIndex: 'name',
      key: 'name',
      render: (text) => <a>{text}</a>,
      width: '20%',
    },
    {
      title: t('action_management.label_column_action'),
      dataIndex: 'actions',
      key: 'actions',
      width: '80%',
      align: 'left',
      className: 'action-management__content__table__header-actions',
      render: (actions, { name, people }) => (
        <ul>
          {actions?.map((item, index) => (
            <Fragment key={index}>
              {item.type === 'MESSAGE' && (
                <li key={index}>
                  「
                  {`${item?.text ? ellipsisText(item.text) : ''}」${!!item?.image_url ? '「画像」' : ''}${
                    item?.video_url ? '「動画」' : ''
                  }`}
                  を送信
                </li>
              )}
              {item.type === 'TAG_ADD' && !!item?.tag_name?.length && (
                <li key={index}>{`タグ「${ellipsisText(item.tag_name.join('・'))}」追加`}</li>
              )}
              {item.type === 'TAG_REMOVE' && !!item?.tag_name?.length && (
                <li key={index}>{`タグ「${ellipsisText(item.tag_name.join('・'))}」を外す`}</li>
              )}
              {item.type === 'MESSAGE_PACKAGE' && !!item?.msg_package_name && (
                <li key={index}>{`パッケージ「${ellipsisText(item.msg_package_name)}」を開始`}</li>
              )}
            </Fragment>
          ))}
        </ul>
      ),
    },
  ];

  const data = (searchAction ? filterActions : actions)?.map((action, index) => {
    return {
      key: index,
      actionID: action.id,
      name: action.name,
      actions: action.actions,
    };
  });

  return (
    <Modal
      title={t('action_management.modal_action_title')}
      visible={isModalVisible}
      onCancel={toggleModal}
      closable={false}
      width={860}
      footer={null}
      className="modal-action modal-update"
    >
      <>
        <div className="modal-update-action">
          <label className="modal-update-action__label title-content">
            {t('action_management.modal_action_label_action')}
          </label>
          <div className="modal-update-action__input">
            {!!initialValues.name && (
              <Tag closable onClose={() => {
                setActionValue(indexField, '', '');
                setInitialValues({
                  id: '',
                  name: '',
                })
              }} key={indexField} visible={true}>
                {initialValues.name}
              </Tag>
            )}
            <input
              placeholder={t('action_management.modal_action_placeholder_search_action')}
              onChange={onSearchAction}
            />
          </div>
        </div>
        <Layout className="site-layout-background" style={{ padding: '24px 0' }}>
          <Sider className="site-layout-background" width={searchAction ? 0 : 200}>
            <Menu mode="inline" defaultSelectedKeys={['0']}>
              {actionFolders?.map((folder, index) => (
                <Item
                  key={index}
                  icon={<FolderOpenOutlined />}
                  onClick={({ key }) => getListAction(actionFolders[key]?.id)}
                  className="action-management__sider__list__item"
                >
                  {folder.name}
                </Item>
              ))}
            </Menu>
          </Sider>
          <Content>
            <Table
              rowSelection={{
                type: 'radio',
                ...rowSelection,
              }}
              columns={columns}
              dataSource={data}
              showHeader={false}
              scroll={{
                y: 500,
              }}
              pagination={false}
            />
          </Content>
        </Layout>
        <Divider />
        <div className="modal-update-tags__button">
          <Button className="button button-solid dusty-gray" onClick={toggleModal}>
            {t('common.button_close')}
          </Button>
          <Button className="button button-active" onClick={handleSubmit}>
            {t('common.button_save')}
          </Button>
        </div>
      </>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  actionFolders: state.actionManagementReducer.actionFolders,
  actions: state.actionManagementReducer.actions,
  filterActions: state.actionManagementReducer.filterActions,
});

const mapDispatchToProps = (dispatch) => ({
  getActionFolder: () => dispatch(actions.getListActionFolderStart()),
  getListAction: (folderId) => dispatch(actions.getListActionStart(folderId)),
  getFilterTags: (keyword) => dispatch(actions.getFilterActionStart(keyword)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionSelectionModal);
