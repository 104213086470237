export const ActionTypes = {
  GET_LIST_CONVERSION_FOLDER_START: 'GET_LIST_CONVERSION_FOLDER_START',
  GET_LIST_CONVERSION_FOLDER_SUCCESS: 'GET_LIST_CONVERSION_FOLDER_SUCCESS',
  GET_LIST_CONVERSION_FOLDER_ERROR: 'GET_LIST_CONVERSION_FOLDER_ERROR',
  GET_LIST_CONVERSION_START: 'GET_LIST_CONVERSION_START',
  GET_LIST_CONVERSION_SUCCESS: 'GET_LIST_CONVERSION_SUCCESS',
  GET_LIST_CONVERSION_ERROR: 'GET_LIST_CONVERSION_ERROR',
  GET_FILTER_CONVERSION_START: 'GET_FILTER_CONVERSION_START',
  GET_FILTER_CONVERSION_SUCCESS: 'GET_FILTER_CONVERSION_SUCCESS',
  GET_FILTER_CONVERSION_ERROR: 'GET_FILTER_CONVERSION_ERROR',
  PUT_FOLDER_CONVERSION_START: 'PUT_FOLDER_CONVERSION_START',
  PUT_FOLDER_CONVERSION_SUCCESS: 'PUT_FOLDER_CONVERSION_SUCCESS',
  PUT_FOLDER_CONVERSION_ERROR: 'PUT_FOLDER_CONVERSION_ERROR',
  POST_FOLDER_CONVERSION_START: 'POST_FOLDER_CONVERSION_START',
  POST_FOLDER_CONVERSION_SUCCESS: 'POST_FOLDER_CONVERSION_SUCCESS',
  POST_FOLDER_CONVERSION_ERROR: 'POST_FOLDER_CONVERSION_ERROR',
  POST_NEW_CONVERSION_START: 'POST_NEW_CONVERSION_START',
  POST_NEW_CONVERSION_SUCCESS: 'POST_NEW_CONVERSION_SUCCESS',
  POST_NEW_CONVERSION_ERROR: 'POST_NEW_CONVERSION_ERROR',
  PUT_CONVERSION_DETAIL_START: 'PUT_CONVERSION_DETAIL_START',
  PUT_CONVERSION_DETAIL_SUCCESS: 'PUT_CONVERSION_DETAIL_SUCCESS',
  PUT_CONVERSION_DETAIL_ERROR: 'PUT_CONVERSION_DETAIL_ERROR',
  GET_CONVERSION_DETAIL_START: 'GET_CONVERSION_DETAIL_START',
  GET_CONVERSION_DETAIL_SUCCESS: 'GET_CONVERSION_DETAIL_SUCCESS',
  GET_CONVERSION_DETAIL_ERROR: 'GET_CONVERSION_DETAIL_ERROR',
  DELETE_A_CONVERSION_START: 'DELETE_A_CONVERSION_START',
  DELETE_A_CONVERSION_SUCCESS: 'DELETE_A_CONVERSION_SUCCESS',
  DELETE_A_CONVERSION_ERROR: 'DELETE_A_CONVERSION_ERROR',
  DELETE_CONVERSION_FOLDER_START: 'DELETE_CONVERSION_FOLDER_START',
  DELETE_CONVERSION_FOLDER_SUCCESS: 'DELETE_CONVERSION_FOLDER_SUCCESS',
  DELETE_CONVERSION_FOLDER_ERROR: 'DELETE_CONVERSION_FOLDER_ERROR',
  POST_SCRIPT_URL_START: 'POST_SCRIPT_URL_START',
  POST_SCRIPT_URL_SUCCESS: 'POST_SCRIPT_URL_SUCCESS',
  POST_SCRIPT_URL_ERROR: 'POST_SCRIPT_URL_ERROR',
  RESET_TYPE_OF_ACTION: 'RESET_TYPE_OF_ACTION',
  RESET_ERROR_MESSAGE: 'RESET_ERROR_MESSAGE',
};

export const actions = {
  getListConversionFolderStart: function () {
    return {
      type: ActionTypes.GET_LIST_CONVERSION_FOLDER_START,
    };
  },
  getListConversionFolderSuccess: function (data) {
    return {
      type: ActionTypes.GET_LIST_CONVERSION_FOLDER_SUCCESS,
      payload: data,
    };
  },
  getListConversionFolderError: function (error) {
    return {
      type: ActionTypes.GET_LIST_CONVERSION_FOLDER_ERROR,
      payload: error,
    };
  },
  getListConversionStart: function (params) {
    return {
      type: ActionTypes.GET_LIST_CONVERSION_START,
      params,
    };
  },
  getListConversionSuccess: function (data) {
    return {
      type: ActionTypes.GET_LIST_CONVERSION_SUCCESS,
      payload: data,
    };
  },
  getListConversionError: function (error) {
    return {
      type: ActionTypes.GET_LIST_CONVERSION_ERROR,
      payload: error,
    };
  },
  getFilterConversionStart: function (params) {
    return {
      type: ActionTypes.GET_FILTER_CONVERSION_START,
      params,
    };
  },
  getFilterConversionSuccess: function (data) {
    return {
      type: ActionTypes.GET_FILTER_CONVERSION_SUCCESS,
      payload: data,
    };
  },
  getFilterConversionError: function (error) {
    return {
      type: ActionTypes.GET_FILTER_CONVERSION_ERROR,
      payload: error,
    };
  },
  putFolderConversionStart: function (params) {
    return {
      type: ActionTypes.PUT_FOLDER_CONVERSION_START,
      params,
    };
  },
  putFolderConversionSuccess: function (data) {
    return {
      type: ActionTypes.PUT_FOLDER_CONVERSION_SUCCESS,
      payload: data,
    };
  },
  putFolderConversionError: function (error) {
    return {
      type: ActionTypes.PUT_FOLDER_CONVERSION_ERROR,
      payload: error,
    };
  },
  postFolderConversionStart: function (params) {
    return {
      type: ActionTypes.POST_FOLDER_CONVERSION_START,
      params,
    };
  },
  postFolderConversionSuccess: function (data) {
    return {
      type: ActionTypes.POST_FOLDER_CONVERSION_SUCCESS,
      payload: data,
    };
  },
  postFolderConversionError: function (error) {
    return {
      type: ActionTypes.POST_FOLDER_CONVERSION_ERROR,
      payload: error,
    };
  },
  postNewConversionStart: function (params) {
    return {
      type: ActionTypes.POST_NEW_CONVERSION_START,
      params,
    };
  },
  postNewConversionSuccess: function (data) {
    return {
      type: ActionTypes.POST_NEW_CONVERSION_SUCCESS,
      payload: data,
    };
  },
  postNewConversionError: function (error) {
    return {
      type: ActionTypes.POST_NEW_CONVERSION_ERROR,
      payload: error,
    };
  },
  putConversionDetailStart: function (params) {
    return {
      type: ActionTypes.PUT_CONVERSION_DETAIL_START,
      params,
    };
  },
  putConversionDetailSuccess: function (data) {
    return {
      type: ActionTypes.PUT_CONVERSION_DETAIL_SUCCESS,
      payload: data,
    };
  },
  putConversionDetailError: function (error) {
    return {
      type: ActionTypes.PUT_CONVERSION_DETAIL_ERROR,
      payload: error,
    };
  },
  getConversionDetailStart: function (params) {
    return {
      type: ActionTypes.GET_CONVERSION_DETAIL_START,
      params,
    };
  },
  getConversionDetailSuccess: function (data) {
    return {
      type: ActionTypes.GET_CONVERSION_DETAIL_SUCCESS,
      payload: data,
    };
  },
  getConversionDetailError: function (error) {
    return {
      type: ActionTypes.GET_CONVERSION_DETAIL_ERROR,
      payload: error,
    };
  },
  deleteAConversionStart: function (params) {
    return {
      type: ActionTypes.DELETE_A_CONVERSION_START,
      params,
    };
  },
  deleteAConversionSuccess: function (data) {
    return {
      type: ActionTypes.DELETE_A_CONVERSION_SUCCESS,
      payload: data,
    };
  },
  deleteAConversionError: function (error) {
    return {
      type: ActionTypes.DELETE_A_CONVERSION_ERROR,
      payload: error,
    };
  },
  deleteConversionFolderStart: function (params) {
    return {
      type: ActionTypes.DELETE_CONVERSION_FOLDER_START,
      params,
    };
  },
  deleteConversionFolderSuccess: function (data) {
    return {
      type: ActionTypes.DELETE_CONVERSION_FOLDER_SUCCESS,
      payload: data,
    };
  },
  deleteConversionFolderError: function (error) {
    return {
      type: ActionTypes.DELETE_CONVERSION_FOLDER_ERROR,
      payload: error,
    };
  },
  postFolderConversionError: function (error) {
    return {
      type: ActionTypes.POST_FOLDER_CONVERSION_ERROR,
      payload: error,
    };
  },
  postScriptURLStart: function () {
    return {
      type: ActionTypes.POST_SCRIPT_URL_START,
    };
  },
  postScriptURLSuccess: function (data) {
    return {
      type: ActionTypes.POST_SCRIPT_URL_SUCCESS,
      payload: data,
    };
  },
  postScriptURLError: function (error) {
    return {
      type: ActionTypes.POST_SCRIPT_URL_ERROR,
      payload: error,
    };
  },
};
