import React, { Fragment, useState, useMemo, useEffect } from 'react';
import { Button, Divider, Tag, Table, Row, Col, notification } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import ModalEditUser from '../ModalEditUser';
import ModalUpdateTag from '../ModalUpdateTags';
import {
  FORMAT_TIME_DAY,
  FORMAT_TIME_TIME,
  FORMAT_TIME_DAY_HOUR,
  convertSeminarStatus,
} from '../../../common/AppConst';
import { actions, ActionTypes } from './actions';
import { actions as appActions } from '../../Home/actions';
import { actions as tagActions, ActionTypes as TagActionTypes } from '../../TagManagement/actions';

function UserPage({
  getUserDetail,
  userDetail,
  updateUserDetail,
  typeOfAction,
  resetType,
  getListTag,
  getListTagFolder,
  tagFolders,
  tags,
  putTagInformation,
  getFilterTags,
  filterTags,
  typeOfTagAction,
  error,
}) {
  const [isOpeningModalEditUser, setIsOpeningModalEditUser] = useState(false);
  const [isOpeningModalUpdateTags, setIsOpeningModalUpdateTags] = useState(false);
  const { t } = useTranslation();
  const { userID } = useParams();

  const columns = [
    {
      title: t('list_user.seminar_name'),
      dataIndex: 'seminarName',
      key: 'seminarName',
      width: '20%',
    },
    {
      title: t('list_user.seminar_description'),
      dataIndex: 'seminarDescription',
      key: 'seminarDescription',
      ellipsis: true,
      width: '20%',
    },
    {
      title: t('list_user.seminar_status'),
      dataIndex: 'seminarStatus',
      key: 'seminarStatus',
      render: (status, record) => <Fragment>{convertSeminarStatus(status)}</Fragment>,
      align: 'center',
      width: '20%',
    },
    {
      title: t('list_user.seminar_time'),
      dataIndex: 'seminarTime',
      key: 'seminarTime',
      align: 'center',
      render: (data) => (
        <div>
          {moment(data.day).format(FORMAT_TIME_DAY)} {moment(data.time1).format(FORMAT_TIME_TIME)}{' '}
          {moment(data.time2).format(FORMAT_TIME_TIME)} {moment(data.time3).format(FORMAT_TIME_TIME)}
        </div>
      ),
      width: '30%',
    },
  ];

  const dataSource = userDetail?.seminars_attendance?.map((seminar, index) => ({
    key: index,
    seminarName: seminar.latest_attendance_seminar_name,
    seminarDescription: seminar.description,
    seminarStatus: seminar.status,
    seminarTime: {
      day: seminar.latest_attendance_seminar_day,
      time1: seminar.latest_attendance_seminar_time1,
      time2: seminar.latest_attendance_seminar_time2,
      time3: seminar.latest_attendance_seminar_time3,
    },
  }));

  const initialUserValue = {
    username: userDetail?.name,
    dateOfBirth: userDetail?.date_of_birth,
    gender: userDetail?.gender,
    email: userDetail?.email,
    phoneNumber: userDetail?.phone_no,
    address: userDetail?.address,
  };

  const toggleModalEditUser = () => setIsOpeningModalEditUser(!isOpeningModalEditUser);
  const toggleModalUpdateTags = () => setIsOpeningModalUpdateTags(!isOpeningModalUpdateTags);
  const memoizedModalEditUser = useMemo(
    () => (
      <ModalEditUser
        userID={userID}
        initialUserValue={initialUserValue}
        isVisible={isOpeningModalEditUser}
        toggleModal={toggleModalEditUser}
        updateUserDetail={updateUserDetail}
      />
    ),
    [isOpeningModalEditUser]
  );
  const memoizedModalUpdateTags = useMemo(
    () => (
      <ModalUpdateTag
        initialTags={userDetail?.tag}
        isVisible={isOpeningModalUpdateTags}
        toggleModal={toggleModalUpdateTags}
        tagFolders={tagFolders}
        getListTag={getListTag}
        tags={tags}
        putTagInformation={putTagInformation}
        getFilterTags={getFilterTags}
        filterTags={filterTags}
      />
    ),
    [isOpeningModalUpdateTags, tagFolders, tags, filterTags]
  );

  useEffect(() => {
    getUserDetail(userID);
    getListTagFolder();
  }, []);

  useEffect(() => {
    if (typeOfAction === ActionTypes.PUT_USER_INFORMATION_SUCCESS) {
      toggleModalEditUser();
      getUserDetail(userID);
      resetType();
    } else if (typeOfTagAction === TagActionTypes.PUT_TAG_INFORMATION_SUCCESS) {
      toggleModalUpdateTags();
      getUserDetail(userID);
      resetType();
    }
  }, [typeOfAction, typeOfTagAction]);

  useEffect(() => {
    if (error) {
      notification.error({ message: error.error_msg });
    }
  }, [error]);

  return (
    <div className="user-details">
      <div className="ant-col-22 ant-col-offset-1">
        <h2 className="title">{t('list_user.title')}</h2>
        <Divider />
        <h4 className="title-content">{t('list_user.heading_seminar')}</h4>
        <Divider />
        <Row gutter={16} className="section-seminar">
          <Col className="gutter-row label-row section-seminar__label" span={4}>
            <span className="title-content">{t('list_user.label_seminar_id')}</span>
          </Col>
          <Col className="gutter-row section-seminar__content" span={20}>
            {userDetail?.line_user_id}
          </Col>
          <Col className="gutter-row label-row section-seminar__label" span={4}>
            <span className="title-content">{t('list_user.label_seminar_name')}</span>
          </Col>
          <Col className="gutter-row section-seminar__content" span={20}>
            {userDetail?.display_name}
          </Col>
          <Col className="gutter-row label-row section-seminar__label" span={4}>
            <span className="title-content">{t('list_user.label_status')}</span>
          </Col>
          <Col className="gutter-row section-seminar__content" span={20}>
            {userDetail?.status_message}
          </Col>
          <Col className="gutter-row label-row section-seminar__label" span={4}>
            <span className="title-content">{t('list_user.label_avatar')}</span>
          </Col>
          <Col className="gutter-row section-seminar__content" span={20}>
            {userDetail?.avatar && <img src={userDetail.avatar} alt="avatar user" />}
          </Col>
          <Col className="gutter-row label-row section-seminar__label" span={4}>
            <span className="title-content">{t('list_user.label_date')}</span>
          </Col>
          <Col className="gutter-row section-seminar__content" span={20}>
            {userDetail?.register_at ? `${moment(userDetail.register_at).format(FORMAT_TIME_DAY_HOUR)}時` : ''}
          </Col>
        </Row>
        <div className="block-heading">
          <span className="title-content">{t('list_user.heading_user')}</span>
          <Button type="text" className="block-heading__button" onClick={toggleModalEditUser}>
            {t('common.edit')}
          </Button>
        </div>
        <Divider />
        <Row gutter={16} className="section-user">
          <Col className="gutter-row label-row section-user__label" span={4}>
            <span className="title-content">{t('list_user.label_username')}</span>
          </Col>
          <Col className="gutter-row section-user__content" span={20}>
            {userDetail?.name}
          </Col>
          <Col className="gutter-row label-row section-user__label" span={4}>
            <span className="title-content">{t('list_user.label_user_date_of_bird')}</span>
          </Col>
          <Col className="gutter-row section-user__content" span={20}>
            {moment(userDetail?.date_of_birth).format(FORMAT_TIME_DAY)}
          </Col>
          <Col className="gutter-row label-row section-user__label" span={4}>
            <span className="title-content">{t('list_user.label_user_gender')}</span>
          </Col>
          <Col className="gutter-row section-user__content" span={20}>
            {userDetail?.gender
              ? userDetail.gender === 'male'
                ? t('common.gender_male')
                : t('common.gender_female')
              : ''}
          </Col>
          <Col className="gutter-row label-row section-user__label" span={4}>
            <span className="title-content">{t('list_user.label_user_email')}</span>
          </Col>
          <Col className="gutter-row section-user__content" span={20}>
            {userDetail?.email}
          </Col>
          <Col className="gutter-row label-row section-user__label" span={4}>
            <span className="title-content">{t('list_user.label_user_phone_number')}</span>
          </Col>
          <Col className="gutter-row section-user__content" span={20}>
            {userDetail?.phone_no}
          </Col>
          <Col className="gutter-row label-row section-user__label" span={4}>
            <span className="title-content">{t('list_user.label_user_address')}</span>
          </Col>
          <Col className="gutter-row section-user__content" span={20}>
            {userDetail?.address}
          </Col>
        </Row>
        <div className="block-heading">
          <span className="title-content">{t('list_user.heading_tag')}</span>
          <Button type="text" className="block-heading__button" onClick={toggleModalUpdateTags}>
            {t('common.edit')}
          </Button>
        </div>
        <Divider />
        <div className="list-tag">
          {userDetail?.tag ? userDetail.tag?.map((tag, index) => <Tag key={index}>{tag}</Tag>) : ''}
        </div>
        <div className="block-heading">
          <span className="title-content">{t('list_user.heading_related_seminar')}</span>
        </div>
        <Divider />
        <Table rowKey="key" bordered dataSource={dataSource} columns={columns} pagination={{ pageSize: 3 }} />
      </div>
      {memoizedModalEditUser}
      {memoizedModalUpdateTags}
    </div>
  );
}

const mapStateToProps = (state) => ({
  userDetail: state.userDetailReducer.user,
  typeOfAction: state.userDetailReducer.type,
  typeOfTagAction: state.tagManagementReducer.type,
  tagFolders: state.tagManagementReducer.tagFolders,
  tags: state.tagManagementReducer.tags,
  filterTags: state.tagManagementReducer.filterTags,
  error: state.userDetailReducer.error,
});

const mapDispatchToProps = (dispatch) => ({
  getUserDetail: (userId) => dispatch(actions.getUserDetailStart(userId)),
  updateUserDetail: (payload) => dispatch(actions.putUserInformationStart(payload)),
  getListTag: (folderId) => dispatch(tagActions.getListTagStart(folderId)),
  getListTagFolder: () => dispatch(tagActions.getListTagFolderStart()),
  putTagInformation: (payload) => dispatch(tagActions.putTagInformationStart(payload)),
  getFilterTags: (keyword) => dispatch(tagActions.getFilterTagStart(keyword)),
  resetType: () => dispatch(appActions.resetTypeOfAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPage);
