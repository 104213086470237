import { api } from './api';

export const getListConversionAPI = (folderId) => {
  return api.getMethod(`/organizer/conversions/${folderId}/conversions`);
};

export const getListConversionFolderAPI = () => {
  return api.getMethod(`/organizer/conversions/list`);
};

export const getFilterConversionAPI = (keyword) => {
  return api.getMethod(`organizer/conversions/filter`, { keyword });
};

export const putConversionFolderAPI = ({ folderID, folderName }) => {
  return api.putMethod(`/organizer/conversion/folders/${folderID}`, { name: folderName });
};

export const postConversionFolderAPI = (name) => {
  return api.postMethod(`/organizer/conversion/folders`, name);
};

export const postNewConversionAPI = (body) => {
  return api.postMethod('/organizer/conversions/create-conversion', body);
};

export const putConversionDetailAPI = ({ conversionID, body }) => {
  return api.putMethod(`/organizer/conversions/${conversionID}/update-conversion`, body);
};

export const getConversionDetailAPI = (conversionID) => {
  return api.getMethod(`/organizer/conversions/${conversionID}/detail`);
};

export const deleteConversionAPI = (conversionID) => {
  return api.deleteMethod(`/organizer/conversions/${conversionID}/delete`);
};

export const deleteConversionFolderAPI = (folderID) => {
  return api.deleteMethod(`/organizer/conversion/folders/${folderID}`);
};

export const postScriptURLAPI = () => {
  return api.postMethod(`/organizer/conversions/create-script`);
};
