import { api } from './api';
import { HTTP_RESPONSE } from '../common/ResponseCode';
import { getFileExtension } from '../utils';

export const getURLS3API = ({ organizerID, file, type, fileName, isAdmin = false }) => {
  return api.getMethod(
    `/${isAdmin ? 'admin/organizers' : 'organizer'}/${organizerID}/pre-sign-url?content-type=${
      file.type
    }&file-ext=${getFileExtension(file.type)}&type=${type}&file-name=${fileName}`
  );
};

export const uploadFileAPI = ({ organizerID, file, type, fileName, isAdmin }) => {
  return getURLS3API({ file, organizerID, type, fileName, isAdmin }).then(
    (response) => {
      const options = {
        headers: {
          'Content-Type': file.type,
        },
      };
      if (response.meta.success) {
        return api.putToS3(response.data.second, file, options).then(
          (responseS3) => {
            if (responseS3.status === HTTP_RESPONSE.OK) {
              return {
                status: true,
                data: {
                  file_url: response.data.first,
                  file,
                },
              };
            } else {
              console.log('error', responseS3);
            }
          },
          (error) => console.log('error put file to s3', error)
        );
      } else {
        return {
          ...response,
        };
      }
    },
    (error) => {
      console.log('error', error);
    }
  );
};
