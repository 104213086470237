import React, { Component } from 'react';
import { Avatar, Button, Col, Comment, Input, Layout, List, Row, Spin, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { SendOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player';
import io from 'socket.io-client';
import ReactGA from 'react-ga';
import { api } from '../../services/api';
import { ResponseCode } from '../../common/ResponseCode';
import { AppConst } from '../../common/AppConst';
import IconMute from '../../resources/assets/IconMute';
import './index.css';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Search } = Input;
const { Header, Content } = Layout;
const EventGA = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

const CommentList = ({ comments }) => (
  <List
    className="presenting-comment-list"
    dataSource={comments}
    header={`コメント (${comments.length})`}
    itemLayout="horizontal"
    renderItem={(props) => <Comment {...props} />}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value, isEnded, commentable }) => (
  <>
    <Search
      disabled={isEnded || isEnded === undefined || !commentable}
      className="presenting-send-comment-button"
      value={value}
      onSearch={onSubmit}
      loading={submitting}
      onChange={onChange}
      enterButton={<SendOutlined />}
    />
  </>
);

export default class PresentingPage extends Component {
  intervalID = 0;
  waitingTimeoutId = 0;

  constructor(props) {
    super(props);
    this.state = {
      presentDetail: null,
      currentUser: null,
      comments: [],
      submitting: false,
      value: '',
      displayConversionBtn: false,
      muted: true,
      loading: true,
      enabled_comment: false,
    };
    this.socket = io(process.env.REACT_APP_SOCKET_URL);
    const url = new URL(window.location.href);
    this.seminarId = this.props.match.params.seminarId;
    this.token = url.searchParams.get('token');
    this.messagesEnd = React.createRef();
  }

  componentDidMount() {
    const that = this;
    this.scrollToBottom();
    this.screenResize();
    this.socket.on('chat_room', function (data) {
      let comments = that.state.comments;
      comments.push({
        author: data.sender_name,
        avatar: data.sender_image,
        content: data.content,
        time: parseInt(data.sent_time),
        datetime: that.formatCommentTime(data.sent_time),
      });
      comments = comments.sort((a, b) => (a.time > b.time ? 1 : -1));
      that.setState({ comments: comments });
    });
    this.socket.on('info', function (data) {
      that.setState({ currentUser: data });
    });
    this.socket.on('old_messages', function (data) {
      let comments = data.map((msg) => {
        return {
          author: msg.sender_name,
          avatar: msg.sender_image,
          content: msg.content,
          time: parseInt(msg.sent_time),
          datetime: that.formatCommentTime(msg.sent_time),
        };
      });
      comments = comments.sort((a, b) => (a.time > b.time ? 1 : -1));
      that.setState({ comments: comments });
    });
    this.socket.on('seminar-setting-change', function (data) {
      that.setState({
        // header: data.header,
        // footer: data.footer,
        enabled_comment: data.enabled_comment,
      });
    });
    this.getPresentingDetail();
    this.loadSeminarSetting();
  }

  loadSeminarSetting = () => {
    api.get('/presenting-page/' + this.props.match.params.seminarId + '/settings').then((response) => {
      this.setState({
        header: response.data.data?.header,
        footer: response.data.data?.footer,
        enabled_comment: response.data.data?.enabled_comment,
      });
    });
  };

  componentDidUpdate() {
    this.scrollToBottom();
    window.addEventListener('resize', this.screenResize);
    return () => window.removeEventListener('resize', this.screenResize);
  }

  screenResize = () => {
    const width = window.innerWidth;
    this.resizeCommentList();
    if (width <= AppConst.WINDOW_INNER_WIDTH) {
      this.setState({ isMobile: true });
      return;
    }
    this.setState({ isMobile: false });
  };

  resizeCommentList = () => {
    var video = document.querySelector('#video > video');
    let elementBottom = document.querySelector(
      '.presenting-comment-list > .ant-spin-nested-loading > .ant-spin-container'
    );
    if (elementBottom) {
      elementBottom.setAttribute('style', 'height: ' + (video?.clientHeight - 90) + 'px');
    }
  };

  scrollToBottom = () => {
    let elementBottom = document.querySelector(
      '.presenting-comment-list > .ant-spin-nested-loading > .ant-spin-container'
    );
    if (elementBottom) {
      elementBottom.scrollTop = elementBottom.scrollHeight;
    }
  };

  getPresentingDetail = () => {
    const seminarId = this.props.match.params.seminarId;
    const url = new URL(window.location.href);
    const token = url.searchParams.get('token');
    this.setState({ displayConversionBtn: false });
    this.loadPresentingDetail(seminarId, token);
    clearInterval(this.intervalID);
  };

  loadPresentingDetail(seminarId, token) {
    api.get('/presenting-page/' + seminarId, { token: token }).then(
      (response) => {
        const detail = response?.data?.data;
        if (response.status === ResponseCode.OK && response?.data?.meta?.success && detail) {
          this.socket.emit('join', { presentDetail: detail, token: token });
          this.processGoogleAnalytics(detail);
          const isEnded = detail.isEnded;
          if (isEnded === false) {
            this.processVideo(detail);
            this.setState({ isEnded, loading: false, presentDetail: detail });
            return;
          }
          this.processVideoWaiting(detail);
          this.setState({ isEnded, loading: false, presentDetail: detail });
          return;
        }
        this.setState({ loading: false });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  }

  processGoogleAnalytics = (detail) => {
    if (detail?.google_analytics) {
      ReactGA.initialize(detail.google_analytics);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    if (detail?.vp_header_meta) {
      document.getElementsByTagName('head')[0].innerHTML += detail.vp_header_meta;
    }
    if (detail?.vp_body_meta) {
      var meta = document.createElement('meta');
      meta.innerHTML = detail.vp_body_meta.trim();
      if (meta.firstChild) {
        document.getElementsByTagName('body')[0].appendChild(meta.firstChild);
      }
    }
  };

  processVideo = (detail) => {
    setTimeout(() => {
      var video = document.querySelector('#video > video');
      const currentTime = Math.ceil((new Date().getTime() - new Date(detail.present_time).getTime()) / 1000);
      video.currentTime = currentTime;
      function onSeeking() {
        const time = Math.ceil((new Date().getTime() - new Date(detail.present_time).getTime()) / 1000);
        var delta = video.currentTime - time;
        if (Math.abs(delta) > 0.01 || time - video.currentTime >= 10) {
          video.currentTime = time;
        }
      }
      video.addEventListener('seeking', onSeeking);
      this.setState({ loading: false });
    }, 500);
    if (
      detail?.conversion_btn_time_display &&
      detail?.videoDuration &&
      detail?.videoDuration - detail?.conversion_btn_time_display * 60 >= 0
    ) {
      const currentTime = Math.ceil(new Date().getTime() - new Date(detail.present_time).getTime());
      const timeDisplay = (detail?.videoDuration - detail?.conversion_btn_time_display * 60) * 1000 - currentTime;
      setTimeout(
        () => {
          this.setState({ displayConversionBtn: true });
        },
        timeDisplay >= 0 ? timeDisplay : 0
      );
    }
    setTimeout(() => {
      this.resizeCommentList();
    }, 100);
    this.intervalID = setInterval(() => {
      this.seekingToCurrent();
    }, 10000);
  };

  processVideoWaiting = (detail) => {
    if (!detail?.next_present_time) {
      clearTimeout(this.waitingTimeoutId);
      clearInterval(this.intervalID);
      return;
    }
    const countDown = detail?.next_present_time - moment().valueOf();
    this.waitingTimeoutId = setTimeout(() => {
      this.getPresentingDetail();
    }, countDown);
  };

  seekingToCurrent = () => {
    const { presentDetail } = this.state;
    var video = document.querySelector('#video > video');
    if (!video) {
      return;
    }
    const currentTime = Math.ceil((new Date().getTime() - new Date(presentDetail?.present_time).getTime()) / 1000);
    if (currentTime > presentDetail?.videoDuration) {
      this.getPresentingDetail();
    }
    if (currentTime <= presentDetail?.videoDuration && currentTime - video.currentTime >= 10) {
      video.currentTime = currentTime;
    }
  };

  formatCommentTime(seconds) {
    let str = moment.utc(parseInt(seconds) * 1000).format('HH:mm:ss');
    if (str.startsWith('00:')) return str.substring(3);
    return str;
  }

  toggleSound = () => {
    const video = document.querySelector('#video > video');
    video.muted = !video.muted;
  };

  handleSubmit = () => {
    EventGA('CHAT_BOX', this.state.value, this.state.currentUser?.name);

    if (!this.state.enabled_comment) return;

    if (!this.state.value) {
      return;
    }

    this.setState({
      submitting: true,
    });
    this.socket.emit('chat', {
      content: this.state.value,
      time: parseInt(document.querySelector('#video > video').currentTime),
    });
    this.setState({ submitting: false, value: '' });
  };

  handleChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  playMedia = (event) => {
    const { presentDetail } = this.state;
    const video = document.querySelector('#video > video');
    const currentTime = Math.ceil((new Date().getTime() - new Date(presentDetail.present_time).getTime()) / 1000);
    video.currentTime = currentTime;
  };

  onMuted = () => {
    const video = document.querySelector('#video > video');
    video.muted = !video.muted;
    this.setState({ muted: false });
  };

  goToConversionPage = () => {
    const seminarId = this.props.match.params.seminarId;
    let url = this.state.presentDetail?.conversion_btn_url;
    if (!url.includes('http')) {
      url = 'http://' + url;
    }

    this.setState({ loading: true });
    api.put(`/presenting-page/${seminarId}/conversion?token=${this.token}`, {}).then(
      (response) => {
        if (response.status === ResponseCode.OK && response.data.meta.success) {
          this.setState({ loading: false });
          return;
        }
        notification.error({ message: response?.data?.meta?.error_data?.error_msg, description: '' });
        this.setState({ loading: false });
      },
      (error) => {
        notification.error({ message: AppConst.INTERNAL_SERVER_ERROR, description: '' });
        this.setState({ loading: false });
      }
    );

    EventGA('CONVERSION', 'Conversion button clicked');
    window.open(url, '_blank');
  };

  getPresentTime = () => {
    const { presentDetail } = this.state;
    const date = moment().format('YYYY.MM.DD');
    const presentTime = [];
    if (presentDetail?.present_time_1) {
      presentTime.push(moment(presentDetail?.present_time_1).format('HH:mm'));
    }
    if (presentDetail?.present_time_2) {
      presentTime.push(moment(presentDetail?.present_time_2).format('HH:mm'));
    }
    if (presentDetail?.present_time_3) {
      presentTime.push(moment(presentDetail?.present_time_3).format('HH:mm'));
    }
    return date + ' ' + presentTime.join(' ');
  };

  renderScreenPresenting = () => {
    const { presentDetail, isEnded, muted, isMobile } = this.state;
    if (isEnded || isEnded === undefined) {
      return (
        <div className="presenting-screen-end">
          {presentDetail ? (
            <div className="text-center">
              <p className="presenting-screen-end-title">{presentDetail?.title}</p>
              <p className="presenting-screen-end-presenter-title">開催者</p>
              <p className="presenting-screen-end-presenter">{presentDetail?.presenter}</p>
              <p className="presenting-screen-end-present-time-title">開催時間</p>
              <p className="presenting-screen-end-present-time">{this.getPresentTime()}</p>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      );
    }

    return (
      <div className="video-container">
        <ReactPlayer
          id="video"
          url={isEnded || presentDetail?.video_url}
          loop={false}
          muted={true}
          playing={true}
          playsinline={true}
          volume={1}
          width="100%"
          height="100%"
          onPlay={this.playMedia}
          controls={false}
        />
        {muted && (
          <div>
            <Button
              className="volume-button"
              type="link"
              onClick={this.onMuted}
              icon={<IconMute width={isMobile ? 24 : 50} height="auto" />}
            ></Button>
          </div>
        )}
      </div>
    );
  };

  renderCommentChat = () => {
    const { comments, submitting, value, isMobile, isEnded } = this.state;
    return (
      <Col span={24} className="presenter-chat-container">
        <Row className="presenter-chat">
          <Col span={24}>
            <div ref={this.messagesEnd}>{<CommentList comments={comments} />}</div>
          </Col>
          <Col span={24}>
            <Comment
              avatar={
                <Avatar shape="square" src={this.state.currentUser?.pictureUrl} alt={this.state.currentUser?.name} />
              }
              content={
                <Editor
                  onChange={this.handleChange}
                  onSubmit={this.handleSubmit}
                  submitting={submitting}
                  value={value}
                  isEnded={isEnded}
                  commentable={this.state.enabled_comment}
                />
              }
            />
          </Col>
        </Row>
      </Col>
    );
  };

  render() {
    const { loading, presentDetail, isMobile, isEnded, displayConversionBtn, enabled_comment } = this.state;
    return (
      <div className="presenting-page">
        <Spin indicator={antIcon} spinning={loading}>
          <Layout className="ant-col-22 ant-col-offset-1">
            <div className="present-header" dangerouslySetInnerHTML={{ __html: this.state.header }}></div>
            <div className="presenting-content" style={{ height: 'unset' }}>
              <Row>
                <Col span={24}>{this.renderScreenPresenting()}</Col>
                {enabled_comment && this.renderCommentChat()}
              </Row>
              <Row align="bottom">
                <Col className="presenting-apply-col" span={24}>
                  {displayConversionBtn && (
                    <Button
                      className="presenting-apply-button style-button"
                      onClick={this.goToConversionPage}
                      disabled={isEnded || isEnded === undefined}
                    >
                      {presentDetail?.conversion_btn_name}
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
            <div className="present-footer" dangerouslySetInnerHTML={{ __html: this.state.footer }}></div>
            {this.state.footer && (
              <Row align="bottom">
                <Col className="presenting-apply-col" span={24}>
                  {displayConversionBtn && (
                    <Button
                      className="presenting-apply-button style-button"
                      onClick={this.goToConversionPage}
                      disabled={isEnded || isEnded === undefined}
                    >
                      {presentDetail?.conversion_btn_name}
                    </Button>
                  )}
                </Col>
              </Row>
            )}
          </Layout>
        </Spin>
      </div>
    );
  }
}
