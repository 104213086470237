import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Divider, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { actions, ActionTypes } from '../actions';

function CreateMessagePackagePage({ createMessagePackage, messagePackage, type }) {
  const { t } = useTranslation();
  let history = useHistory();

  const handleCreateMessagePackage = (type) => () => {
    if (type === 'RELATIVE') {
      createMessagePackage({
        type: 'RELATIVE',
      });
    } else {
      createMessagePackage({
        type: 'ABSOLUTE',
      });
    }
  };

  useEffect(() => {
    switch (type) {
      case ActionTypes.POST_NEW_MESSAGE_PACKAGE_SUCCESS:
        history.push(`/organizer/message-packages/edit/${messagePackage.id}`);
        return;
      default:
        return;
    }
  }, [type]);

  return (
    <div className="create-message-package">
      <div className="ant-col-22 ant-col-offset-1">
        <h2 className="create-message-package__title title">{t('message_package.title_create_message_package')}</h2>
        <Divider />
        <Row gutter={16} justify="center">
          <Col className="gutter-row" span={8}>
            <div className="create-message-package__box box-left">
              <div className="create-message-package__box__title">{t('message_package.column_relative_title')}</div>
              <div>
                {t('message_package.column_relative_description')}
                <span className="create-message-package__box__text-bold">{t('message_package.text_bold')}</span>
              </div>
              <ul>
                <li>{t('message_package.column_relative_line_1')}</li>
                <li>{t('message_package.column_relative_line_2')}</li>
              </ul>
              <Button
                className="create-message-package__box__button button-left"
                onClick={handleCreateMessagePackage('RELATIVE')}
              >
                {t('message_package.button_relative')}
              </Button>
            </div>
          </Col>
          <Col className="gutter-row" span={8}>
            <div className="create-message-package__box box-right">
              <div className="create-message-package__box__title">{t('message_package.column_absolute_title')}</div>
              <div>{t('message_package.column_absolute_description')}</div>
              <div className="create-message-package__box__text-bold">{t('message_package.text_bold')}</div>
              <ul>
                <li>{t('message_package.column_absolute_line_1')}</li>
                <li>{t('message_package.column_absolute_line_2')}</li>
              </ul>
              <Button
                className="create-message-package__box__button button-right"
                onClick={handleCreateMessagePackage('ABSOLUTE')}
              >
                {t('message_package.button_absolute')}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  type: state.messagePackageReducer.type,
  messagePackage: state.messagePackageReducer.messagePackage,
});

const mapDispatchToProps = (dispatch) => ({
  createMessagePackage: (messageType) => dispatch(actions.postNewMessagePackageStart(messageType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateMessagePackagePage);
