import LoginPage from './pages/Login';
import OrganizerDetailPage from './pages/OrganizerManagement/OrganizerDetail';
import OrganizerListPage from './pages/OrganizerManagement/OrganizerList';
import CreateOrganizerPage from './pages/OrganizerManagement/CreateOrganizer';
import SeminarListPage from './pages/Organizer/Seminar/SeminarList';
import LandingPageSetting from './pages/OrganizerManagement/LandingPageSetting';
import SeminarDetailPage from './pages/Organizer/Seminar/SeminarDetail';
import LandingPagePage from './pages/LandingPage';
import SecurityService from './services/security_service';
import PresentingPage from './pages/PresentingPage';
import AdminDetailPage from './pages/OrganizerManagement/AdminDetail';
import ListUser from './pages/ListUser';
import UserDetails from './pages/ListUser/User';
import RichMenuManagement from './pages/RichMenuManagement';
import CreateRichMenuPage from './pages/RichMenuManagement/CreateRichMenu';
import EditRichMenuPage from './pages/RichMenuManagement/EditRichMenu';
import TagManagementPage from './pages/TagManagement';
import TagFormPage from './pages/TagManagement/TagForm';
import ActionManagementPage from './pages/ActionManagement';
import ActionFormPage from './pages/ActionManagement/ActionForm';
import SeminarSettingPage from './pages/SeminarSetting';
import ConversionPage from './pages/Conversion';
import ConversionFormPage from './pages/Conversion/Form';
import PersonalTalkPage from './pages/PersonalTalk';
import TalkListPage from './pages/TalkList';
import ShorternUrlPage from './pages/ShorternUrl';
import MessagePackagePage from './pages/MessagePackage';
import CreateMessagePackagePage from './pages/MessagePackage/Create';
import EditMessagePackagePage from './pages/MessagePackage/Create/Form';
import CreateMessagePage from './pages/MessagePackage/Create/Message';

const routes = [
  {
    name: 'LoginPage',
    path: '/login',
    pathActive: '/login',
    component: LoginPage,
    exact: true,
  },
  {
    name: 'CreateOrganizerPage',
    path: '/admin/organizers/new',
    pathActive: '/admin/organizers/new',
    component: CreateOrganizerPage,
    exact: true,
    roles: [SecurityService.ROLES.ROLE_ADMIN],
  },
  {
    name: 'OrganizerListPage',
    path: '/admin/organizers',
    pathActive: '/admin/organizers',
    component: OrganizerListPage,
    exact: true,
    roles: [SecurityService.ROLES.ROLE_ADMIN],
  },
  // {
  //   name: "EditOrganizerPage",
  //   path: "/admin/organizer/:id/edit",
  //   pathActive: "/admin/organizer/",
  //   component: EditOrganizerPage,
  //   exact: true,
  //   roles: [SecurityService.ROLES.ROLE_ADMIN]
  // },
  {
    name: 'OrganizerDetailPage',
    path: '/admin/organizer/:id',
    pathActive: '/admin/organizer/',
    component: OrganizerDetailPage,
    exact: true,
    roles: [SecurityService.ROLES.ROLE_ADMIN],
  },
  {
    name: 'SeminarListPage',
    path: '/organizer/seminars',
    pathActive: '/organizer/seminars',
    component: SeminarListPage,
    exact: true,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
  },
  {
    name: 'SeminarCreationPage',
    path: '/organizer/seminars/new',
    pathActive: '/organizer/seminars/new',
    component: SeminarDetailPage,
    exact: true,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
  },
  {
    name: 'SeminarDetailPage',
    path: '/organizer/seminar/:id',
    pathActive: '/organizer/seminar/',
    component: SeminarDetailPage,
    exact: true,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
  },
  {
    name: 'LandingPageSetting',
    path: '/organizer/settings/landing-page',
    pathActive: '/organizer/settings/landing-page',
    component: LandingPageSetting,
    exact: true,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
  },
  {
    name: 'OrganizerDetailPage',
    path: '/organizer/setting/:id',
    pathActive: '/organizer/setting/',
    component: OrganizerDetailPage,
    exact: true,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
  },
  {
    name: 'AdminDetailPage',
    path: '/admin/setting',
    pathActive: '/admin/setting',
    component: AdminDetailPage,
    exact: true,
    roles: [SecurityService.ROLES.ROLE_ADMIN],
  },
  {
    name: 'LandingPagePage',
    path: '/landing-page/:organizer_id',
    pathActive: '/landing-page/',
    component: LandingPagePage,
    exact: true,
  },
  {
    name: 'PresentingPage',
    path: '/live/:seminarId',
    pathActive: '/live/',
    component: PresentingPage,
    exact: true,
  },
  {
    name: 'ListUser',
    path: '/organizer/users',
    component: ListUser,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
    exact: true,
  },
  {
    name: 'ListUser',
    path: '/organizer/users/tag=:tag',
    component: ListUser,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
    exact: true,
  },
  {
    name: 'User',
    path: '/organizer/users/:userID',
    component: UserDetails,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
    exact: true,
  },
  {
    name: 'RichMenuManagement',
    path: '/organizer/rich-menu',
    component: RichMenuManagement,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
    exact: true,
  },
  {
    name: 'Create Rich Menu',
    path: '/organizer/rich-menu/create',
    component: CreateRichMenuPage,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
    exact: true,
  },
  {
    name: 'Edit Rich Menu',
    path: '/organizer/rich-menu/edit/:menuID',
    component: EditRichMenuPage,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
    exact: true,
  },
  {
    name: 'Tag Management',
    path: '/organizer/tags',
    component: TagManagementPage,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
    exact: true,
  },
  {
    name: 'Create Tag',
    path: '/organizer/tags/create',
    component: TagFormPage,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
    exact: true,
  },
  {
    name: 'Edit Tag',
    path: '/organizer/tags/:tagID',
    component: TagFormPage,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
    exact: true,
  },
  {
    name: 'Action Management',
    path: '/organizer/actions',
    component: ActionManagementPage,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
    exact: true,
  },
  {
    name: 'Create Action',
    path: '/organizer/actions/create',
    component: ActionFormPage,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
    exact: true,
  },
  {
    name: 'Edit Action',
    path: '/organizer/actions/:actionID',
    component: ActionFormPage,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
    exact: true,
  },
  {
    name: 'Seminar Setting',
    path: '/organizer/seminar-settings',
    component: SeminarSettingPage,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
    exact: true,
  },
  {
    name: 'Conversion Management',
    path: '/organizer/conversions',
    component: ConversionPage,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
    exact: true,
  },
  {
    name: 'Personal Talk',
    path: '/organizer/personal-talk',
    component: PersonalTalkPage,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
    exact: true,
  },
  {
    name: 'Create Conversion',
    path: '/organizer/conversions/create',
    component: ConversionFormPage,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
    exact: true,
  },
  {
    name: 'Edit Conversion',
    path: '/organizer/conversions/edit/:conversionID',
    component: ConversionFormPage,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
    exact: true,
  },
  {
    name: 'ShortenUrl',
    path: '/s/:code',
    pathActive: '/s/:code',
    component: ShorternUrlPage,
    exact: true,
  },
  {
    name: 'Message Package',
    path: '/organizer/message-packages',
    component: MessagePackagePage,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
    exact: true,
  },
  {
    name: 'Create Message Package',
    path: '/organizer/message-packages/create',
    component: CreateMessagePackagePage,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
    exact: true,
  },
  {
    name: 'Edit Message Package',
    path: '/organizer/message-packages/edit/:messagePackageID',
    component: EditMessagePackagePage,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
    exact: true,
  },
  {
    name: 'Create Message',
    path: '/organizer/message-packages/message/:messagePackageID/create',
    component: CreateMessagePage,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
    exact: true,
  },
  {
    name: 'Edit Message',
    path: '/organizer/message-packages/message/:messagePackageID/edit/:messageID',
    component: CreateMessagePage,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
    exact: true,
  },
  {
    name: 'Talk List',
    path: '/organizer/talk-list',
    component: TalkListPage,
    roles: [SecurityService.ROLES.ROLE_ORGANIZER],
    exact: true,
  },
];

export default routes;
