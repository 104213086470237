export const ActionTypes = {
  GET_LIST_TAG_FOLDER_START: 'GET_LIST_TAG_FOLDER_START',
  GET_LIST_TAG_FOLDER_SUCCESS: 'GET_LIST_TAG_FOLDER_SUCCESS',
  GET_LIST_TAG_FOLDER_ERROR: 'GET_LIST_TAG_FOLDER_ERROR',
  GET_LIST_TAG_START: 'GET_LIST_TAG_START',
  GET_LIST_TAG_SUCCESS: 'GET_LIST_TAG_SUCCESS',
  GET_LIST_TAG_ERROR: 'GET_LIST_TAG_ERROR',
  PUT_TAG_INFORMATION_START: 'PUT_TAG_INFORMATION_START',
  PUT_TAG_INFORMATION_SUCCESS: 'PUT_TAG_INFORMATION_SUCCESS',
  PUT_TAG_INFORMATION_ERROR: 'PUT_TAG_INFORMATION_ERROR',
  GET_FILTER_TAG_START: 'GET_FILTER_TAG_START',
  GET_FILTER_TAG_SUCCESS: 'GET_FILTER_TAG_SUCCESS',
  GET_FILTER_TAG_ERROR: 'GET_FILTER_TAG_ERROR',
  PUT_FOLDER_TAG_START: 'PUT_FOLDER_TAG_START',
  PUT_FOLDER_TAG_SUCCESS: 'PUT_FOLDER_TAG_SUCCESS',
  PUT_FOLDER_TAG_ERROR: 'PUT_FOLDER_TAG_ERROR',
  POST_FOLDER_TAG_START: 'POST_FOLDER_TAG_START',
  POST_FOLDER_TAG_SUCCESS: 'POST_FOLDER_TAG_SUCCESS',
  POST_FOLDER_TAG_ERROR: 'POST_FOLDER_TAG_ERROR',
  POST_NEW_TAG_START: 'POST_NEW_TAG_START',
  POST_NEW_TAG_SUCCESS: 'POST_NEW_TAG_SUCCESS',
  POST_NEW_TAG_ERROR: 'POST_NEW_TAG_ERROR',
  PUT_TAG_DETAIL_START: 'PUT_TAG_DETAIL_START',
  PUT_TAG_DETAIL_SUCCESS: 'PUT_TAG_DETAIL_SUCCESS',
  PUT_TAG_DETAIL_ERROR: 'PUT_TAG_DETAIL_ERROR',
  GET_TAG_DETAIL_START: 'GET_TAG_DETAIL_START',
  GET_TAG_DETAIL_SUCCESS: 'GET_TAG_DETAIL_SUCCESS',
  GET_TAG_DETAIL_ERROR: 'GET_TAG_DETAIL_ERROR',
  DELETE_A_TAG_START: 'DELETE_A_TAG_START',
  DELETE_A_TAG_SUCCESS: 'DELETE_A_TAG_SUCCESS',
  DELETE_A_TAG_ERROR: 'DELETE_A_TAG_ERROR',
  DELETE_TAG_FOLDER_START: 'DELETE_TAG_FOLDER_START',
  DELETE_TAG_FOLDER_SUCCESS: 'DELETE_TAG_FOLDER_SUCCESS',
  DELETE_TAG_FOLDER_ERROR: 'DELETE_TAG_FOLDER_ERROR',
  RESET_TYPE_OF_ACTION: 'RESET_TYPE_OF_ACTION',
  RESET_ERROR_MESSAGE: 'RESET_ERROR_MESSAGE',
};

export const actions = {
  getListTagFolderStart: function () {
    return {
      type: ActionTypes.GET_LIST_TAG_FOLDER_START,
    };
  },
  getListTagFolderSuccess: function (data) {
    return {
      type: ActionTypes.GET_LIST_TAG_FOLDER_SUCCESS,
      payload: data,
    };
  },
  getListTagFolderError: function (error) {
    return {
      type: ActionTypes.GET_LIST_TAG_FOLDER_ERROR,
      payload: error,
    };
  },
  getListTagStart: function (params) {
    return {
      type: ActionTypes.GET_LIST_TAG_START,
      params,
    };
  },
  getListTagSuccess: function (data) {
    return {
      type: ActionTypes.GET_LIST_TAG_SUCCESS,
      payload: data,
    };
  },
  getListTagError: function (error) {
    return {
      type: ActionTypes.GET_LIST_TAG_ERROR,
      payload: error,
    };
  },
  putTagInformationStart: function (params) {
    return {
      type: ActionTypes.PUT_TAG_INFORMATION_START,
      params,
    };
  },
  putTagInformationSuccess: function (data) {
    return {
      type: ActionTypes.PUT_TAG_INFORMATION_SUCCESS,
      payload: data,
    };
  },
  putTagInformationError: function (error) {
    return {
      type: ActionTypes.PUT_TAG_INFORMATION_ERROR,
      payload: error,
    };
  },
  getFilterTagStart: function (params) {
    return {
      type: ActionTypes.GET_FILTER_TAG_START,
      params,
    };
  },
  getFilterTagSuccess: function (data) {
    return {
      type: ActionTypes.GET_FILTER_TAG_SUCCESS,
      payload: data,
    };
  },
  getFilterTagError: function (error) {
    return {
      type: ActionTypes.GET_FILTER_TAG_ERROR,
      payload: error,
    };
  },
  putFolderTagStart: function (params) {
    return {
      type: ActionTypes.PUT_FOLDER_TAG_START,
      params,
    };
  },
  putFolderTagSuccess: function (data) {
    return {
      type: ActionTypes.PUT_FOLDER_TAG_SUCCESS,
      payload: data,
    };
  },
  putFolderTagError: function (error) {
    return {
      type: ActionTypes.PUT_FOLDER_TAG_ERROR,
      payload: error,
    };
  },
  postFolderTagStart: function (params) {
    return {
      type: ActionTypes.POST_FOLDER_TAG_START,
      params,
    };
  },
  postFolderTagSuccess: function (data) {
    return {
      type: ActionTypes.POST_FOLDER_TAG_SUCCESS,
      payload: data,
    };
  },
  postFolderTagError: function (error) {
    return {
      type: ActionTypes.POST_FOLDER_TAG_ERROR,
      payload: error,
    };
  },
  postNewTagStart: function (params) {
    return {
      type: ActionTypes.POST_NEW_TAG_START,
      params,
    };
  },
  postNewTagSuccess: function (data) {
    return {
      type: ActionTypes.POST_NEW_TAG_SUCCESS,
      payload: data,
    };
  },
  postNewTagError: function (error) {
    return {
      type: ActionTypes.POST_NEW_TAG_ERROR,
      payload: error,
    };
  },
  putTagDetailStart: function (params) {
    return {
      type: ActionTypes.PUT_TAG_DETAIL_START,
      params,
    };
  },
  putTagDetailSuccess: function (data) {
    return {
      type: ActionTypes.PUT_TAG_DETAIL_SUCCESS,
      payload: data,
    };
  },
  putTagDetailError: function (error) {
    return {
      type: ActionTypes.PUT_TAG_DETAIL_ERROR,
      payload: error,
    };
  },
  getTagDetailStart: function (params) {
    return {
      type: ActionTypes.GET_TAG_DETAIL_START,
      params,
    };
  },
  getTagDetailSuccess: function (data) {
    return {
      type: ActionTypes.GET_TAG_DETAIL_SUCCESS,
      payload: data,
    };
  },
  getTagDetailError: function (error) {
    return {
      type: ActionTypes.GET_TAG_DETAIL_ERROR,
      payload: error,
    };
  },
  deleteATagStart: function (params) {
    return {
      type: ActionTypes.DELETE_A_TAG_START,
      params,
    };
  },
  deleteATagSuccess: function (data) {
    return {
      type: ActionTypes.DELETE_A_TAG_SUCCESS,
      payload: data,
    };
  },
  deleteATagError: function (error) {
    return {
      type: ActionTypes.DELETE_A_TAG_ERROR,
      payload: error,
    };
  },
  deleteTagFolderStart: function (params) {
    return {
      type: ActionTypes.DELETE_TAG_FOLDER_START,
      params,
    };
  },
  deleteTagFolderSuccess: function (data) {
    return {
      type: ActionTypes.DELETE_TAG_FOLDER_SUCCESS,
      payload: data,
    };
  },
  deleteTagFolderError: function (error) {
    return {
      type: ActionTypes.DELETE_TAG_FOLDER_ERROR,
      payload: error,
    };
  },
};
