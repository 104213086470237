import React, { useState, useEffect, useMemo, useCallback, Fragment } from 'react';
import { connect } from 'react-redux';
import { Layout, Menu, Divider, Table, Space, Button, notification, Input, Popconfirm, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { FolderOpenOutlined, SearchOutlined, PlusCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';
import { actions, ActionTypes } from './actions';
import { FORMAT_TIME_DAY } from '../../common/AppConst';
import TagModal from './Modal';
import { actions as appActions } from '../../pages/Home/actions';
import ExecuteActionModal from './ExcuteActionModal';
import { ellipsisText } from '../../utils';

const { Item } = Menu;
const { Content, Sider } = Layout;

function ActionManagementPage({
  getActionFolder,
  actionFolders,
  getListAction,
  actions,
  putFolderAction,
  error,
  type,
  createFolderAction,
  resetType,
  filterActions,
  getFilterActions,
  deleteAAction,
  deleteActionFolder,
  resetErrorMessage,
}) {
  const { t } = useTranslation();
  const [isCreateTagModalVisible, setIsCreateTagModalVisible] = useState(false);
  const [isUpdateTagModalVisible, setIsUpdateTagModalVisible] = useState(false);
  const [isExecuteActionModalVisible, setIsExecuteActionModalVisible] = useState(false);
  const [folderAction, setFolderAction] = useState({});
  const [searchAction, setSearchAction] = useState('');
  const [actionID, setActionID] = useState('');
  const [activeItem, setActiveItem] = useState(0);

  useEffect(() => {
    getActionFolder();
  }, []);

  useEffect(() => {
    if (error) {
      notification.error({ message: error.error_msg });
      resetErrorMessage();
    }
  }, [error]);

  useEffect(() => {
    switch (type) {
      case ActionTypes.GET_LIST_ACTION_FOLDER_SUCCESS:
        if (actionFolders?.length) {
          getListAction(actionFolders[activeItem]?.id);
          setFolderAction(actionFolders[activeItem]);
        }
        resetType();
        return;
      case ActionTypes.PUT_FOLDER_ACTION_SUCCESS:
        getListAction(folderAction.id);
        getActionFolder();
        toggleUpdateActionModal();
        resetType();
        return;
      case ActionTypes.POST_FOLDER_ACTION_SUCCESS:
        getActionFolder();
        toggleCreateActionModal();
        resetType();
        return;
      case ActionTypes.DELETE_A_ACTION_SUCCESS:
        notification.success({ message: t('message.MSG_04') });
        getListAction(folderAction.id);
        if (searchAction) {
          getFilterActions(searchAction);
        }
        resetType();
        return;
      case ActionTypes.POST_EXECUTE_ACTION_SUCCESS:
        toggleExecuteActionModal();
        notification.success({ message: t('message.MSG_11') });
        resetType();
        return;
      case ActionTypes.DELETE_ACTION_FOLDER_SUCCESS:
        notification.success({ message: t('message.MSG_04') });
        getActionFolder();
        resetType();
        return;
      default:
        resetType();
        return;
    }
  }, [type]);

  const toggleCreateActionModal = useCallback(
    () => setIsCreateTagModalVisible(!isCreateTagModalVisible),
    [isCreateTagModalVisible]
  );
  const toggleUpdateActionModal = useCallback(
    () => setIsUpdateTagModalVisible(!isUpdateTagModalVisible),
    [isUpdateTagModalVisible]
  );

  const toggleExecuteActionModal = useCallback(() => {
    setIsExecuteActionModalVisible(!isExecuteActionModalVisible);
  }, [isExecuteActionModalVisible]);

  const getListTagByFolder = ({ key }) => {
    getListAction(actionFolders[key]?.id);
    setFolderAction(actionFolders[key]);
    setActiveItem(key);
  };

  const editFolderTag = (folder) => (e) => {
    e.stopPropagation();
    toggleUpdateActionModal();
    setFolderAction(folder);
  };

  const deleteFolderTag = (e) => {
    e.stopPropagation();
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchAction(value.trim());
    getFilterActions(value.trim());
  };

  const columns = [
    {
      title: t('action_management.label_column_action_name'),
      dataIndex: 'name',
      key: 'name',
      render: (text) => <a>{text}</a>,
      width: '25%',
      ellipsis: true,
    },
    {
      title: t('action_management.label_column_action'),
      dataIndex: 'actions',
      key: 'actions',
      width: '25%',
      align: 'left',
      className: 'action-management__content__table__header-actions',
      render: (actions) => (
        <ul>
          {actions?.map((item, index) => (
            <Fragment key={index}>
              {item.type === 'MESSAGE' && (
                <li key={index}>
                  「
                  {`${item?.text ? ellipsisText(item.text) : ''}」${!!item?.image_url ? '「画像」' : ''}${
                    item?.video_url ? '「動画」' : ''
                  }`}
                  を送信
                </li>
              )}
              {item.type === 'TAG_ADD' && !!item?.tag_name?.length && (
                <li key={index}>{`タグ「${ellipsisText(item.tag_name.join('・'))}」追加`}</li>
              )}
              {item.type === 'TAG_REMOVE' && !!item?.tag_name?.length && (
                <li key={index}>{`タグ「${ellipsisText(item.tag_name.join('・'))}」を外す`}</li>
              )}
              {item.type === 'MESSAGE_PACKAGE' && !!item?.msg_package_name && (
                <li key={index}>{`パッケージ「${ellipsisText(item.msg_package_name)}」を開始`}</li>
              )}
            </Fragment>
          ))}
        </ul>
      ),
    },
    {
      title: t('action_management.label_column_created_date'),
      dataIndex: 'created_date',
      key: 'created_date',
      width: '25%',
      align: 'center',
    },
    {
      title: '',
      key: 'action',
      render: (text, { actionID }) => (
        <Space size="middle" wrap>
          <Button
            type="text"
            className="button button-solid"
            onClick={() => {
              toggleExecuteActionModal();
              setActionID(actionID);
            }}
          >
            {t('common.button_run')}
          </Button>
          <Button type="text" className="button button-solid" href={`/organizer/actions/${actionID}`}>
            {t('common.edit')}
          </Button>
          <Popconfirm
            placement="topRight"
            title={t('common.confirm_delete_message')}
            onConfirm={() => deleteAAction(actionID)}
            okText={t('common.yes')}
            cancelText={t('common.no')}
          >
            <Button type="text" className="button button-inactive">
              {t('common.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
      width: '25%',
      align: 'right',
    },
  ];

  const data = (searchAction ? filterActions : actions)?.map((action, index) => {
    return {
      key: index,
      actionID: action.id,
      name: action.name,
      actions: action.actions,
      created_date: moment(action.created_at).format(FORMAT_TIME_DAY),
    };
  });

  const memoizedCreateTagModal = useMemo(
    () => (
      <TagModal
        isModalVisible={isCreateTagModalVisible}
        toggleModal={toggleCreateActionModal}
        t={t}
        modalTitle={t('tag_management.modal_title_create_tag')}
        createFolderAction={createFolderAction}
        isCreateFolder={true}
      />
    ),
    [isCreateTagModalVisible]
  );

  const memoizedUpdateTagModal = useMemo(
    () => (
      <TagModal
        isModalVisible={isUpdateTagModalVisible}
        toggleModal={toggleUpdateActionModal}
        t={t}
        modalTitle={t('tag_management.modal_title_update_tag')}
        updateFolderAction={putFolderAction}
        folderAction={folderAction}
      />
    ),
    [isUpdateTagModalVisible]
  );

  const memoizedExecuteActionModal = useMemo(
    () => (
      <ExecuteActionModal
        actionID={actionID}
        isModalVisible={isExecuteActionModalVisible}
        toggleModal={toggleExecuteActionModal}
      />
    ),
    [isExecuteActionModalVisible]
  );

  return (
    <div className="action-management">
      <div className="ant-col-22 ant-col-offset-1">
        <h2 className="action-management__title title">{t('action_management.title')}</h2>
        <Divider />
        <Layout>
          <Sider width={searchAction ? 0 : 400} className="site-layout-background action-management__sider">
            <Button className="button button-active" onClick={toggleCreateActionModal} icon={<PlusCircleOutlined />}>
              {t('tag_management.button_new_folder')}
            </Button>
            <Menu
              className="action-management__sider__list"
              mode="inline"
              defaultSelectedKeys={[`${activeItem}`]}
              style={{ borderRight: 0 }}
            >
              {actionFolders?.map((folder, index) => (
                <Item
                  key={index}
                  icon={<FolderOpenOutlined />}
                  onClick={getListTagByFolder}
                  className="action-management__sider__list__item"
                >
                  <Tooltip title={folder.name}>
                    <span className="folder-name">{folder.name}</span>
                  </Tooltip>
                  <Space size="middle">
                    <Button type="text" className="button button-solid" onClick={editFolderTag(folder)}>
                      {t('common.edit')}
                    </Button>
                    <Popconfirm
                      placement="topRight"
                      title={t('common.confirm_delete_message')}
                      onConfirm={() => deleteActionFolder(folder.id)}
                      okText={t('common.yes')}
                      cancelText={t('common.no')}
                    >
                      <Button type="text" className="button button-inactive" onClick={deleteFolderTag}>
                        {t('common.delete')}
                      </Button>
                    </Popconfirm>
                  </Space>
                </Item>
              ))}
            </Menu>
          </Sider>
          <Content
            className="site-layout-background action-management__content"
            style={{
              padding: 24,
              margin: 0,
            }}
          >
            <div className="action-management__content__action">
              <Button className="button button-active" href="/organizer/actions/create" icon={<PlusCircleOutlined />}>
                {t('action_management.button_add_new_action')}
              </Button>
              <Input
                className="action-management__content__action__input"
                placeholder={t('action_management.placeholder_search')}
                suffix={<SearchOutlined />}
                onChange={handleSearch}
              />
            </div>
            <Table
              tableLayout="fixed"
              columns={columns}
              dataSource={data}
              pagination={{ pageSize: 10, showSizeChanger: false }}
              className="action-management__content__table"
            />
          </Content>
        </Layout>
      </div>
      {memoizedCreateTagModal}
      {memoizedUpdateTagModal}
      {isExecuteActionModalVisible && memoizedExecuteActionModal}
    </div>
  );
}

const mapStateToProps = (state) => ({
  actionFolders: state.actionManagementReducer.actionFolders,
  actions: state.actionManagementReducer.actions,
  type: state.actionManagementReducer.type,
  error: state.actionManagementReducer.error,
  filterActions: state.actionManagementReducer.filterActions,
});

const mapDispatchToProps = (dispatch) => ({
  getActionFolder: () => dispatch(actions.getListActionFolderStart()),
  getListAction: (folderID) => dispatch(actions.getListActionStart(folderID)),
  putFolderAction: (payload) => dispatch(actions.putFolderActionStart(payload)),
  createFolderAction: (payload) => dispatch(actions.postFolderActionStart(payload)),
  getFilterActions: (keyword) => dispatch(actions.getFilterActionStart(keyword)),
  deleteAAction: (actionID) => dispatch(actions.deleteAActionStart(actionID)),
  deleteActionFolder: (folderID) => dispatch(actions.deleteActionFolderStart(folderID)),
  resetType: () => dispatch(appActions.resetTypeOfAction()),
  resetErrorMessage: () => dispatch(appActions.resetErrorMessage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionManagementPage);
