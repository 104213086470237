import { put, takeLatest } from 'redux-saga/effects';
import { actions, ActionTypes } from './actions';
import {
  getListActionAPI,
  getListActionFolderAPI,
  putTagInfoAPI,
  getFilterActionsAPI,
  putFolderActionAPI,
  postFolderActionAPI,
  postNewActionAPI,
  putActionDetailAPI,
  getActionDetailAPI,
  deleteActionAPI,
  postExecuteActionAPI,
  deleteActionFolderAPI,
} from '../../services/ActionRequest';
import { RESPONSE_STATUS } from '../../common/ResponseCode';
import { actions as appActions } from '../Home/actions';

function* getListActionFolder() {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield getListActionFolderAPI();
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getListActionFolderSuccess(response.data));
    } else {
      yield put(actions.getListActionFolderError(response));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.getListActionFolderError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* getListAction({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield getListActionAPI(params);
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getListActionSuccess(response?.data));
    } else {
      yield put(actions.getListActionError(response));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.getListActionError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* getFilterAction({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield getFilterActionsAPI(params);
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getFilterActionSuccess(response?.data));
    } else {
      yield put(actions.getFilterActionError(response));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.getFilterActionError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* putFolderAction({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield putFolderActionAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.putFolderActionSuccess(response));
    } else {
      yield put(actions.putFolderActionError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.putFolderActionError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* postFolderAction({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield postFolderActionAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.postFolderActionSuccess(response));
    } else {
      yield put(actions.postFolderActionError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.postFolderActionError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* postNewAction({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield postNewActionAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.postNewActionSuccess(response));
    } else {
      yield put(actions.postNewActionError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.postNewActionError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* putActionDetail({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield putActionDetailAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.putActionDetailSuccess(response));
    } else {
      yield put(actions.putActionDetailError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.putActionDetailError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* getActionDetail({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield getActionDetailAPI(params);
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getActionDetailSuccess(response?.data));
    } else {
      yield put(actions.getActionDetailError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.getActionDetailError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* deleteAAction({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield deleteActionAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.deleteAActionSuccess(response?.data));
    } else {
      yield put(actions.deleteAActionError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.deleteAActionError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* postExecuteAction({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield postExecuteActionAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.postExecuteActionSuccess(response));
    } else {
      yield put(actions.postExecuteActionError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.postExecuteActionError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* deleteActionFolder({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield deleteActionFolderAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.deleteActionFolderSuccess(response?.data));
    } else {
      yield put(actions.deleteActionFolderError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.deleteActionFolderError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

export function* watchActionManagement() {
  yield takeLatest(ActionTypes.GET_LIST_ACTION_FOLDER_START, getListActionFolder);
  yield takeLatest(ActionTypes.GET_LIST_ACTION_START, getListAction);
  yield takeLatest(ActionTypes.GET_FILTER_ACTION_START, getFilterAction);
  yield takeLatest(ActionTypes.PUT_FOLDER_ACTION_START, putFolderAction);
  yield takeLatest(ActionTypes.POST_FOLDER_ACTION_START, postFolderAction);
  yield takeLatest(ActionTypes.POST_NEW_ACTION_START, postNewAction);
  yield takeLatest(ActionTypes.POST_EXECUTE_ACTION_START, postExecuteAction);
  yield takeLatest(ActionTypes.PUT_ACTION_DETAIL_START, putActionDetail);
  yield takeLatest(ActionTypes.GET_ACTION_DETAIL_START, getActionDetail);
  yield takeLatest(ActionTypes.DELETE_A_ACTION_START, deleteAAction);
  yield takeLatest(ActionTypes.DELETE_ACTION_FOLDER_START, deleteActionFolder);
}
