import { ActionTypes } from './actions';

const DEFAULT_STATE = {
  file: {},
  thumbnailURL: {},
  image: {},
  type: '',
  error: '',
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.PUSH_FILE_TO_S3_STORE_SUCCESS:
      return {
        ...state,
        file: action.payload,
        type: action.type,
      };
    case ActionTypes.PUSH_FILE_TO_S3_STORE_ERROR:
      return {
        ...state,
        error: action.payload,
        type: action.type,
      };
    case ActionTypes.PUSH_THUMBNAIL_IMAGE_TO_S3_SUCCESS:
      return {
        ...state,
        thumbnailURL: action.payload,
        type: action.type,
      };
    case ActionTypes.PUSH_THUMBNAIL_IMAGE_TO_S3_ERROR:
      return {
        ...state,
        error: action.payload,
        type: action.type,
      };
    case ActionTypes.PUSH_IMAGE_TO_S3_SUCCESS:
      return {
        ...state,
        image: action.payload,
        type: action.type,
      };
    case ActionTypes.PUSH_IMAGE_TO_S3_ERROR:
      return {
        ...state,
        error: action.payload,
        type: action.type,
      };
    case ActionTypes.RESET_TYPE_OF_ACTION:
      return {
        ...state,
        type: '',
      };
    default:
      return {
        ...state,
      };
  }
};
