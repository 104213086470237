export class OrganizerValidationConst {
  static ACCOUNT_MIN = 4;
  static ACCOUNT_MAX = 50;
  static PWD_MIN = 8;
  static PWD_MAX = 20;
  static EMAIL_REGEX =
    "^[\\w!#$%&'*+/=?`{|}~^-]+(?:\\.[\\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$";
  static PHONE_REGEX = /^[\s()+-]*([0-9][\s()+-]*){6,20}$/;
  static PHONE_NUMBER_REGEX = /^[0-9]{6,20}$/;
}
