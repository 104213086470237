import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button, Divider, Row, Col, Space, Tooltip, TimePicker, notification, Input } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { Formik, Field } from 'formik';
import moment from 'moment';
import _ from 'lodash';
import * as Yup from 'yup';
import InputComponent from '../../../../components/Input';
import TextareaComponent from '../../../../components/Textarea';
import TagSelectionModal from '../TagModal';
import { actions, ActionTypes } from '../../actions';
import { actions as appActions } from '../../../Home/actions';
import UploadVideoModal from '../../../../components/UploadOneVideoModal';
import ModalUploadImage from '../../../../components/ModalUploadImageResizer';
import ErrorMessage from '../../../../components/MessageError';
import { getFileName, validationInput } from '../../../../utils';
import { REGEX_NOT_NUMBER } from '../../../../common/AppConst';
import MessageContentComponent from '../../../../components/MessageContent';

function MessagePage({
  createNewMessage,
  type,
  error,
  getMessagePackageDetail,
  messagePackage,
  resetType,
  getMessageDetail,
  messageDetail,
  updateMessageDetail,
}) {
  const { t } = useTranslation();
  const { messageID, messagePackageID } = useParams();
  let history = useHistory();
  const isRelativeMessagePackage = messagePackage?.type === 'RELATIVE';
  const [initialValues, setInitialValues] = useState({
    time_to_send: moment().startOf('days'),
    day_diff: 0,
    hour_diff: '',
    time_relative: '0:00',
    tag: '',
    text: '',
    image_url: '',
    image_size: 'giga',
    image_target_url: '',
    video_url: '',
    video_thumbnail_url: '',
    message_type_text: false,
    message_type_image: false,
    message_type_video: false,
    tag_id: '',
    tag_condition: 'ACCEPT',
    tag_name: '',
    listCheckbox: '',
  });
  const [isVisibleModalUploadImage, setIsVisibleModalUploadImage] = useState(false);
  const [isVisibleModalUploadVideo, setIsVisibleModalUploadVideo] = useState(false);
  const [isVisibleActionModal, setIsVisibleActionModal] = useState(false);
  const [isVisibleMessageContentModal, setIsVisibleMessageContentModal] = useState(false);
  const [indexField, setIndexField] = useState(0);

  const validationSchema = Yup.object().shape({
    day_diff: Yup.number().required(t('message.MSG_02', { fieldName: t('common.text_day') })),
    time_relative: Yup.string()
      .nullable()
      .when('message_package_type', {
        is: (type) => type === 'RELATIVE',
        then: Yup.string()
          .nullable()
          .required(t('message.MSG_02', { fieldName: t('common.text_time') })),
      }),
    time_to_send: Yup.string()
      .nullable()
      .when('message_package_type', {
        is: (type) => type === 'ABSOLUTE',
        then: Yup.string()
          .nullable()
          .required(t('message.MSG_02', { fieldName: t('common.text_time') })),
      }),
    listCheckbox: Yup.string().when(['message_type_text', 'message_type_image', 'message_type_video'], {
      is: (message_type_text, message_type_image, message_type_video) =>
        !message_type_text && !message_type_image && !message_type_video,
      then: Yup.string().required(t('common.message_content_required')),
      otherwise: Yup.string().notRequired(),
    }),
    text: Yup.string()
      .nullable()
      .trim()
      .when('message_type_text', {
        is: (checked) => checked,
        then: Yup.string()
          .nullable()
          .trim()
          .required(t('message.MSG_02', { fieldName: 'テキスト' })),
      }),
    image_url: Yup.string()
      .nullable()
      .when('message_type_image', {
        is: (checked) => checked,
        then: Yup.string()
          .nullable()
          .required(t('message.MSG_02', { fieldName: '画像' })),
      }),
    video_url: Yup.string()
      .nullable()
      .when('message_type_video', {
        is: (checked) => checked,
        then: Yup.string()
          .nullable()
          .required(t('message.MSG_02', { fieldName: '動画' })),
      }),
    video_thumbnail_url: Yup.string()
      .nullable()
      .when(['message_type_video', 'video_url'], {
        is: (checked, video_url) => checked && video_url,
        then: Yup.string()
          .nullable()
          .required(t('message.MSG_02', { fieldName: '動画' })),
      }),
  });

  useEffect(() => {
    getMessagePackageDetail(messagePackageID);
    if (messageID) {
      getMessageDetail({ messagePackageID, messageID });
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(messagePackage)) {
      setInitialValues({
        ...initialValues,
        message_package_type: messagePackage.type,
      });
    }
  }, [messagePackage]);

  useEffect(() => {
    if (!_.isEmpty(messageDetail)) {
      const { messageData, hour_diff, time_to_send, minute_diff } = messageDetail;

      setInitialValues({
        ...initialValues,
        ...messageDetail,
        message_type_text: !!messageData.text,
        message_type_image: !!messageData.imageUrl,
        message_type_video: !!messageData.videoUrl,
        text: messageData.text,
        image_url: messageData.imageUrl ? messageData.imageUrl : '',
        image_size: messageData.imageSize ? messageData.imageSize : 'giga',
        image_target_url: messageData.imageTargetUrl ? messageData.imageTargetUrl : '',
        video_url: messageData.videoUrl ? messageData.videoUrl : '',
        video_thumbnail_url: messageData.videoImageThumbnail ? messageData.videoImageThumbnail : '',
        hour_diff: hour_diff,
        time_relative: `${hour_diff}:${minute_diff}`,
        time_to_send: time_to_send,
        minute_diff: minute_diff ? minute_diff : '',
      });
    }
  }, [messageDetail]);

  useEffect(() => {
    switch (type) {
      case ActionTypes.POST_NEW_MESSAGE_SUCCESS:
        history.push(`/organizer/message-packages/edit/${messagePackageID}`);
        notification.success({ message: t('message.MSG_01') });
        resetType();
        return;
      case ActionTypes.PUT_MESSAGE_DETAIL_SUCCESS:
        history.push(`/organizer/message-packages/edit/${messagePackageID}`);
        notification.success({ message: t('message.MSG_03') });
        resetType();
      default:
        return;
    }
  }, [type]);

  const handleInputChange = (setFieldValue, fieldName, inputType) => (e) => {
    const { value } = e.target;
    if (inputType === 'input' || inputType === 'radio') {
      setFieldValue(fieldName, value);
    } else if (inputType === 'number') {
      const newValue = validationInput(value, REGEX_NOT_NUMBER);
      setFieldValue(fieldName, newValue);
    } else {
      setFieldValue(fieldName, e);
    }
  };

  const handleChangeUploadImageModal = (index, setFieldValue) => (data) => {
    const { imageUrl, imageSize, link } = data;
    if (imageUrl) {
      setFieldValue('image_url', imageUrl);
    }
    if (imageSize) {
      setFieldValue('image_size', imageSize);
    }
    if (link) {
      setFieldValue('image_target_url', link);
    }
  };

  const handleFormSubmit = (data) => {
    if (!messageID) {
      createNewMessage({
        ...data,
        day_diff: Number.parseInt(data.day_diff),
        hour_diff: isRelativeMessagePackage ? moment(data.time_relative, 'HH:mm').get('hour') : 0,
        minute_diff: isRelativeMessagePackage ? moment(data.time_relative, 'HH:mm').get('minute') : 0,
        time_to_send: !isRelativeMessagePackage ? Number.parseInt(moment(data.time_to_send).format('x')) : '',
        message_package_id: messagePackage.id,
        type: messagePackage.type,
      });
    } else {
      updateMessageDetail({
        id: messageID,
        body: {
          ...data,
          day_diff: Number.parseInt(data.day_diff),
          hour_diff: isRelativeMessagePackage ? moment(data.time_relative, 'HH:mm').get('hour') : 0,
          minute_diff: isRelativeMessagePackage ? moment(data.time_relative, 'HH:mm').get('minute') : 0,
          time_to_send: !isRelativeMessagePackage ? Number.parseInt(moment(data.time_to_send).format('x')) : '',
          message_package_id: messagePackage.id,
          type: messagePackage.type,
        },
      });
    }
  };

  const toggleModalUploadImage = () => setIsVisibleModalUploadImage(!isVisibleModalUploadImage);
  const toggleModalUploadVideo = () => setIsVisibleModalUploadVideo(!isVisibleModalUploadVideo);
  const toggleMessageContentModal = () => setIsVisibleMessageContentModal(!isVisibleMessageContentModal);

  const toggleActionModal = useCallback(() => {
    setIsVisibleActionModal(!isVisibleActionModal);
  }, [isVisibleActionModal]);

  useEffect(() => {
    if (error) {
      notification.error({ message: error.error_msg });
    }
  }, [error]);

  const handleChangeUploadVideo = (setFieldValue) => (values) => {
    const { videoURL, thumbnailURL } = values;
    setFieldValue('video_url', videoURL);
    setFieldValue('video_thumbnail_url', thumbnailURL);
  };

  return (
    <div className="message">
      <div className="ant-col-22 ant-col-offset-1">
        <h2 className="message__title title">{t('message_package.title_edit_message_package')}</h2>
        <span className="message__title-right">{t('message_package.message_page_label_name')}:</span>
        <span className="message__title-right">{`「${messagePackage?.name}」`}</span>
        <Divider />
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleFormSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, submitForm }) => (
            <Fragment>
              <Row gutter={16}>
                <Col className="gutter-row" span={6}>
                  <h4 className="message__subtitle">{t('message_package.message_page_label_delivery_time')}</h4>
                  <div className="message__description title-content">
                    {isRelativeMessagePackage
                      ? t('message_package.message_page_time_specification')
                      : t('message_package.message_page_time_designation')}
                  </div>
                </Col>
                <Col className="gutter-row message__column-time" span={6}>
                  {isRelativeMessagePackage ? (
                    <Fragment>
                      <Field
                        name="day_diff"
                        wrapperClass="input-day"
                        placeholder={t('common.text_day')}
                        inputLength={2}
                        value={values.day_diff}
                        onChange={handleInputChange(setFieldValue, 'day_diff', 'number')}
                        component={InputComponent}
                      />
                      <div className="input-time-send">
                        日と
                        <Field
                          name="time_relative"
                          format="HH:mm"
                          placeholder={t('common.text_time')}
                          inputReadOnly
                          value={values.time_relative ? moment(`${values.time_relative}`, 'HH:mm') : null}
                          onChange={(date) => {
                            setFieldValue('time_relative', moment(date).format('HH:mm'));
                          }}
                          component={TimePicker}
                        />
                        時間後
                        <ErrorMessage name="time_relative" />
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Field
                        name="day_diff"
                        wrapperClass="input-day"
                        placeholder={t('common.text_day')}
                        inputLength={2}
                        value={values.day_diff}
                        onChange={handleInputChange(setFieldValue, 'day_diff', 'number')}
                        component={InputComponent}
                      />
                      <div className="input-time-send">
                        日後
                        <Field
                          name="time_to_send"
                          format="HH:mm"
                          placeholder={t('common.text_time')}
                          inputReadOnly
                          value={values.time_to_send ? moment(values.time_to_send) : null}
                          onChange={(date) => setFieldValue('time_to_send', date)}
                          component={TimePicker}
                        />
                        時に
                        <ErrorMessage name="time_to_send" />
                      </div>
                    </Fragment>
                  )}
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-row" span={6}>
                  <div className="message__description title-content">
                    {t('message_package.message_page_label_filter')}
                  </div>
                </Col>
                <Col className="gutter-row" span={6}>
                  <Button type="text" className="button button-active" onClick={toggleActionModal}>
                    {t('message_package.button_configuration')}
                  </Button>
                  <TagSelectionModal
                    formikValues={values}
                    formikSetFieldValue={setFieldValue}
                    isModalVisible={isVisibleActionModal}
                    toggleModal={toggleActionModal}
                  />
                  <div>
                    {values.tag_name
                      ? values.tag_condition === 'ACCEPT'
                        ? `${values.tag_name}${t('message_package.option_accept_tag')}`
                        : `${values.tag_name}${t('message_package.option_ignore_tag')}`
                      : ''}
                  </div>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-row" span={6}>
                  <h4 className="message__subtitle">{t('common.message_text')}</h4>
                </Col>
                <Col className="gutter-row" span={8} className="message__content">
                  <div className="message__content__checkbox">
                    <Field
                      id="message_type_text"
                      name="message_type_text"
                      fieldname="message_type_text"
                      type="checkbox"
                      checked={values.message_type_text}
                      onChange={() => {
                        setFieldValue('message_type_text', !values.message_type_text);
                        setFieldValue('text', '');
                      }}
                    />
                    <label className="message__content__label" htmlFor="message_type_text">
                      {t('tag_management.label_text')}
                    </label>
                    <Field
                      id="message_type_image"
                      name="message_type_image"
                      fieldname="message_type_image"
                      type="checkbox"
                      checked={values.message_type_image}
                      onChange={() => {
                        setFieldValue('message_type_image', !values.message_type_image);
                        setTimeout(() => {
                          setFieldValue('image_url', '');
                          setFieldValue('image_size', 'giga');
                          setFieldValue('image_target_url', '');
                        }, 0);
                      }}
                    />
                    <label className="message__content__label" htmlFor="message_type_image">
                      {t('tag_management.label_image')}
                    </label>
                    <Field
                      id="message_type_video"
                      name="message_type_video"
                      fieldname="message_type_video"
                      type="checkbox"
                      checked={values.message_type_video}
                      onChange={() => {
                        setFieldValue('message_type_video', !values.message_type_video);
                        setTimeout(() => {
                          setFieldValue('video_url', '');
                          setFieldValue('video_thumbnail_url', '');
                        }, 0);
                      }}
                    />
                    <label className="message__content__label" htmlFor="message_type_video">
                      {t('tag_management.label_video')}
                    </label>
                    <Tooltip title={t('section_message.tooltip_text')}>
                      <Button onClick={toggleMessageContentModal}>{<QuestionCircleOutlined />}</Button>
                    </Tooltip>
                  </div>
                  <Field name="listCheckbox" className="hidden" component={Input} />
                  <ErrorMessage name="listCheckbox" />
                  <Field
                    name="text"
                    fieldName="text"
                    className="message__content__textarea"
                    placeholder={t('tag_management.label_text')}
                    value={values.text}
                    onChange={handleInputChange(setFieldValue, 'text', 'input')}
                    rows={4}
                    inputLength={2000}
                    disabled={!values.message_type_text}
                    component={TextareaComponent}
                  />
                  <ErrorMessage name="text" />
                  <div className="message__content-require">
                    <div>{t('tag_management.section_send_message_require_1')}</div>
                    <div>{t('tag_management.section_send_message_require_2', { number: 2000 })}</div>
                  </div>
                  <div className="message__content__upload">
                    <div className="message__content__upload__input">
                      <Field
                        name="image_url"
                        placeholder={t('common.image_upload')}
                        value={getFileName(values.image_url)}
                        disabled
                        component={InputComponent}
                      />
                      <Button
                        disabled={!values.message_type_image}
                        onClick={toggleModalUploadImage}
                        className="button button-active"
                      >
                        {t('tag_management.button_upload')}
                      </Button>
                    </div>
                    <div className="message__content__upload__input">
                      <Field
                        name="video_url"
                        placeholder={t('common.video_upload')}
                        value={getFileName(values.video_url)}
                        disabled
                        component={InputComponent}
                      />
                      <Button
                        disabled={!values.message_type_video}
                        onClick={toggleModalUploadVideo}
                        className="button button-active"
                      >
                        {t('tag_management.button_upload')}
                      </Button>
                    </div>
                    <div className="message__content__upload__input">
                      <Field
                        name="video_thumbnail_url"
                        placeholder={t('common.video_thumbnail_upload')}
                        value={getFileName(values.video_thumbnail_url)}
                        disabled
                        component={InputComponent}
                      />
                      <Button
                        disabled={!values.message_type_video || !values.video_url}
                        onClick={toggleModalUploadVideo}
                        className="button button-active"
                      >
                        {t('tag_management.button_upload')}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
              <Divider />
              <Space size="middle" className="message__action">
                <Button
                  type="text"
                  className="button button-delete"
                  href={`/organizer/message-packages/edit/${messagePackageID}`}
                >
                  {t('common.button_cancel')}
                </Button>
                <Button type="text" className="button button-active" onClick={submitForm}>
                  {t('common.button_save')}
                </Button>
              </Space>
              <ModalUploadImage
                isVisible={isVisibleModalUploadImage}
                toggleModal={toggleModalUploadImage}
                fieldNameImageURL={'image_url'}
                handleChangeModal={handleChangeUploadImageModal(indexField, setFieldValue)}
                imageURLValue={values.image_url}
                imageSizeValue={values.image_size}
                imageLinkValue={values.image_target_url}
                setFieldValue={setFieldValue}
              />
              <UploadVideoModal
                isModalVisible={isVisibleModalUploadVideo}
                toggleModal={toggleModalUploadVideo}
                handleChangeModal={handleChangeUploadVideo(setFieldValue)}
                videoURLValue={values.video_url}
                thumbnailURLValue={values.video_thumbnail_url}
              />
              <MessageContentComponent
                isModalVisible={isVisibleMessageContentModal}
                toggleModal={toggleMessageContentModal}
              />
            </Fragment>
          )}
        </Formik>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  type: state.messagePackageReducer.type,
  error: state.messagePackageReducer.error,
  messagePackage: state.messagePackageReducer.messagePackage,
  messageDetail: state.messagePackageReducer.messageDetail,
});

const mapDispatchToProps = (dispatch) => ({
  createNewMessage: (payload) => dispatch(actions.postNewMessageStart(payload)),
  getMessagePackageDetail: (messagePackageID) => dispatch(actions.getMessagePackageDetailStart(messagePackageID)),
  getMessageDetail: (payload) => dispatch(actions.getMessageDetailStart(payload)),
  updateMessageDetail: (payload) => dispatch(actions.putMessageDetailStart(payload)),
  resetType: () => dispatch(appActions.resetTypeOfAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessagePage);
