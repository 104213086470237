export const ActionTypes = {
  GET_USER_DETAIL_START: 'GET_USER_DETAIL_START',
  GET_USER_DETAIL_SUCCESS: 'GET_USER_DETAIL_SUCCESS',
  GET_USER_DETAIL_ERROR: 'GET_USER_DETAIL_ERROR',
  PUT_USER_INFORMATION_START: 'PUT_USER_INFORMATION_START',
  PUT_USER_INFORMATION_SUCCESS: 'PUT_USER_INFORMATION_SUCCESS',
  PUT_USER_INFORMATION_ERROR: 'PUT_USER_INFORMATION_ERROR',
  RESET_TYPE_OF_ACTION: 'RESET_TYPE_OF_ACTION',
};

export const actions = {
  getUserDetailStart: function (params) {
    return {
      type: ActionTypes.GET_USER_DETAIL_START,
      params,
    };
  },
  getUserDetailSuccess: function (data) {
    return {
      type: ActionTypes.GET_USER_DETAIL_SUCCESS,
      payload: data,
    };
  },
  getUserDetailError: function (error) {
    return {
      type: ActionTypes.GET_USER_DETAIL_ERROR,
      payload: error,
    };
  },
  putUserInformationStart: function (params) {
    return {
      type: ActionTypes.PUT_USER_INFORMATION_START,
      params,
    };
  },
  putUserInformationSuccess: function (data) {
    return {
      type: ActionTypes.PUT_USER_INFORMATION_SUCCESS,
      payload: data,
    };
  },
  putUserInformationError: function (error) {
    return {
      type: ActionTypes.PUT_USER_INFORMATION_ERROR,
      payload: error,
    };
  },
};
