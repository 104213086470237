import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Divider, Row, Col, Space, Button, Tooltip, TimePicker, notification, Input, Collapse } from 'antd';
import { QuestionCircleOutlined, PlusCircleOutlined, RightCircleOutlined, DownCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Formik, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import SelectComponent from '../../../components/Select';
import InputComponent from '../../../components/Input';
import { actions, ActionTypes } from '../actions';
import { getTagFolders, getTypeTimeSendMessageTag } from '../../../common/AppConst';
import TextareaComponent from '../../../components/Textarea';
import RadioComponent from '../../../components/Radio';
import ModalUploadImage from '../../../components/ModalUploadImageResizer';
import { actions as appActions } from '../../Home/actions';
import SelectionMessagePackageComponent from '../../../components/SelectionMessagePackage';
import UploadVideoModal from '../../../components/UploadVideoModal';
import ErrorMessage from '../../../components/MessageError';
import HeaderComponent from '../../../components/Header';
import { getFileName, handleScrollToFirstError } from '../../../utils';
import MessageContentComponent from '../../../components/MessageContent';

const { Panel } = Collapse;

const TimePickerComponent = ({ type, onChange, setFieldValue, t, index, values }) => {
  return (
    <Fragment>
      {type === 'RELATIVE' && (
        <Fragment>
          <Field
            name="time_diff"
            placeholder={t('tag_management.placeholder_hour_minute')}
            value={values.time_diff ? moment(values.time_diff, 'HH:mm') : null}
            onChange={onChange(setFieldValue, `actions.${index}.time_diff`, 'date')}
            format="HH:mm"
            inputReadOnly
            component={TimePicker}
          />{' '}
          {t('tag_management.text_after')}
        </Fragment>
      )}
      {type === 'ABSOLUTE' && (
        <Field
          name="time_to_send"
          placeholder={t('tag_management.placeholder_hour_minute')}
          onChange={onChange(setFieldValue, `actions.${index}.time_to_send`)}
          defaultValue={values.time_to_send ? moment(values.time_to_send) : null}
          format="HH:mm"
          inputReadOnly
          component={TimePicker}
        />
      )}
    </Fragment>
  );
};

function CreateTagPage({
  getListTagFolders,
  tagFolders,
  createNewTag,
  getTagDetail,
  tagDetail,
  editTagDetail,
  resetType,
  type,
  error,
  resetErrorMessage,
}) {
  const { t } = useTranslation();
  const [isVisibleModalUploadImage, setIsVisibleModalUploadImage] = useState(false);
  const [isVisibleModalUploadVideo, setIsVisibleModalUploadVideo] = useState(false);
  const [isVisibleMessageContentModal, setIsVisibleMessageContentModal] = useState(false);
  const [indexField, setIndexField] = useState('');
  const [initialValues, setInitialValues] = useState({
    name: '',
    folder: null,
    package_id: '',
    actions: [],
  });
  const history = useHistory();
  const { tagID } = useParams();

  const validationSchema = Yup.object().shape({
    folder: Yup.string()
      .nullable()
      .required(t('message.MSG_02', { fieldName: 'フォルダ' })),
    name: Yup.string()
      .trim()
      .required(t('message.MSG_02', { fieldName: 'タグ名' })),
    actions: Yup.array().of(
      Yup.object().shape({
        listCheckbox: Yup.string().when(['type', 'message_type_text', 'message_type_image', 'message_type_video'], {
          is: (type, message_type_text, message_type_image, message_type_video) =>
            type === 'MESSAGE' && !message_type_text && !message_type_image && !message_type_video,
          then: Yup.string().required(t('common.message_content_required')),
          otherwise: Yup.string().notRequired(),
        }),
        text: Yup.string()
          .nullable()
          .trim()
          .when('message_type_text', {
            is: (checked) => checked,
            then: Yup.string()
              .nullable()
              .trim()
              .required(t('message.MSG_02', { fieldName: 'テキスト' })),
          }),
        image_url: Yup.string()
          .nullable()
          .when('message_type_image', {
            is: (checked) => checked,
            then: Yup.string()
              .nullable()
              .required(t('message.MSG_02', { fieldName: t('tag_management.label_image') })),
          }),
        video_url: Yup.string()
          .nullable()
          .when('message_type_video', {
            is: (checked) => checked,
            then: Yup.string()
              .nullable()
              .required(t('message.MSG_02', { fieldName: t('tag_management.label_video') })),
          }),
        video_thumbnail_url: Yup.string()
          .nullable()
          .when(['message_type_video', 'video_url'], {
            is: (checked, video_url) => checked && video_url,
            then: Yup.string()
              .nullable()
              .required(t('message.MSG_02', { fieldName: t('modal_upload_video.label_thumbnail') })),
          }),
        time_diff: Yup.string()
          .nullable()
          .when('sending_time_type', {
            is: (value) => value === 'RELATIVE',
            then: Yup.string()
              .nullable()
              .required(t('message.MSG_02', { fieldName: '送信を遅らせる' })),
          }),
        time_to_send: Yup.string()
          .nullable()
          .when('sending_time_type', {
            is: (value) => value === 'ABSOLUTE',
            then: Yup.string()
              .nullable()
              .required(t('message.MSG_02', { fieldName: '時刻を指定する' })),
          }),
        msg_package_id: Yup.string()
          .nullable()
          .when(['type'], {
            is: (value) => value === 'MESSAGE_PACKAGE',
            then: Yup.string()
              .nullable()
              .required(t('message.MSG_02', { fieldName: 'パッケージ' })),
          }),
      })
    ),
  });

  const handleInputChange = (setFieldValue, fieldName, inputType) => (value) => {
    if (inputType === 'input' || inputType === 'radio') {
      setFieldValue(fieldName, value.target.value);
    } else {
      setFieldValue(fieldName, value);
    }
  };

  const createNewMessage = (setFieldValue, values) => () => {
    setFieldValue('actions', [
      ...values.actions,
      {
        listCheckbox: '',
        message_type_text: false,
        message_type_image: false,
        message_type_video: false,
        name: '',
        text: '',
        image_url: '',
        image_url_temp: '',
        image_size: 'giga',
        image_target_url: '',
        video_url: '',
        video_url_temp: '',
        video_thumbnail_url: '',
        video_thumbnail_url_temp: '',
        sending_time_type: 'UNDEFINED',
        time_diff: '',
        time_to_send: '',
        type: 'MESSAGE',
      },
    ]);
  };

  const createNewPackage = (setFieldValue, values) => () => {
    const isExistPackage = values.actions.find((action) => action.type === 'MESSAGE_PACKAGE');
    if (!isExistPackage) {
      setFieldValue('actions', [
        ...values.actions,
        {
          msg_package_id: '',
          type: 'MESSAGE_PACKAGE',
        },
      ]);
    } else {
      return;
    }
  };

  const handleChangeUploadImageModal = (index, setFieldValue) => (data) => {
    const { imageUrl, imageSize, link } = data;
    if (imageUrl) {
      setFieldValue(`actions.${index}.image_url`, imageUrl);
    }
    if (imageSize) {
      setFieldValue(`actions.${index}.image_size`, imageSize);
    }
    if (link) {
      setFieldValue(`actions.${index}.image_target_url`, link);
    }
  };

  const handleSubmit = (data) => {
    const tag = {
      ...data,
      folder_id: data.folder,
      name: data.name?.trim(),
      actions: data.actions.map((action) => ({
        ...action,
        time_to_send:
          action.sending_time_type === 'ABSOLUTE' ? Number.parseInt(moment(action.time_to_send).format('x')) : null,
        text: action.text?.trim(),
        time_diff:
          action.sending_time_type === 'RELATIVE'
            ? moment(action.time_diff).diff(moment().startOf('day'), 'minutes')
            : null,
      })),
    };

    if (!tagID) {
      createNewTag(tag);
    } else {
      editTagDetail({
        tagID,
        body: tag,
      });
    }
  };

  useEffect(() => {
    getListTagFolders();
    if (tagID) {
      getTagDetail(tagID);
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(tagDetail)) {
      const actionTime = tagDetail.actions?.length
        ? tagDetail.actions.map((action) => ({
            ...action,
            listCheckbox: '',
            time_diff:
              action.sending_time_type === 'RELATIVE'
                ? moment().startOf('day').add(action.time_diff, 'minutes').format('HH:mm')
                : null,
            time_to_send: action.sending_time_type === 'ABSOLUTE' ? action.time_to_send : null,
            message_type_text: !!action.text,
            message_type_image: !!action.image_url,
            message_type_video: !!action.video_thumbnail_url || !!action.video_url,
            image_url_temp: action.image_url,
            video_url_temp: action.video_url,
            video_thumbnail_url_temp: action.video_thumbnail_url,
            image_size: action.image_size ? action.image_size : 'giga',
          }))
        : [];

      setInitialValues({
        ...initialValues,
        folder: tagDetail?.folder_id,
        name: tagDetail?.name,
        actions: actionTime,
      });
    }
  }, [tagDetail]);

  useEffect(() => {
    if (type === ActionTypes.POST_NEW_TAG_SUCCESS) {
      history.push('/organizer/tags');
      notification.success({ message: t('message.MSG_01') });
      resetType();
    } else if (type === ActionTypes.PUT_TAG_DETAIL_SUCCESS) {
      history.push('/organizer/tags');
      notification.success({ message: t('message.MSG_03') });
      resetType();
    }
  }, [type]);

  useEffect(() => {
    if (error) {
      notification.error({ message: error.error_msg });
      resetErrorMessage();
    }
  }, [error]);

  const toggleModalUploadImage = () => setIsVisibleModalUploadImage(!isVisibleModalUploadImage);
  const toggleModalUploadVideo = () => setIsVisibleModalUploadVideo(!isVisibleModalUploadVideo);
  const toggleMessageContentModal = () => setIsVisibleMessageContentModal(!isVisibleMessageContentModal);

  return (
    <div className="create-tag">
      <div className="ant-col-22 ant-col-offset-1">
        <h2 className="create-tag__title title">
          {!tagID ? t('tag_management.create_tag_title') : t('tag_management.update_tag_title')}
        </h2>
        <Divider />
        <h4 className="create-tag__subtitle">{t('tag_management.label_setting_information')}</h4>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ submitForm, values, setFieldValue, isSubmitting }) => {
            handleScrollToFirstError(isSubmitting, '.message-error');

            return (
              <form onSubmit={submitForm}>
                <Row gutter={16} className="create-tag__folder-tag">
                  <Col className="gutter-row" span={6}>
                    <div className="title-content">{t('tag_management.label_folder')}</div>
                  </Col>
                  <Col className="gutter-row" span={18}>
                    <Field
                      name="folder"
                      placeholder={t('tag_management.placeholder_select_folder')}
                      value={values.folder}
                      options={getTagFolders(tagFolders)}
                      onChange={handleInputChange(setFieldValue, 'folder')}
                      component={SelectComponent}
                    />
                  </Col>
                </Row>
                <Row gutter={16} className="create-tag__name">
                  <Col className="gutter-row" span={6}>
                    <div className="title-content">{t('tag_management.label_tag_name')}</div>
                  </Col>
                  <Col className="gutter-row" span={18}>
                    <Field name="name" inputLength={30} component={InputComponent} />
                  </Col>
                </Row>
                <div className="create-tag__action">
                  <Space size="middle" align="center">
                    <Button
                      type="text"
                      className="button button-active"
                      onClick={createNewMessage(setFieldValue, values)}
                      icon={<PlusCircleOutlined />}
                    >
                      {t('tag_management.button_send_message')}
                    </Button>
                    <Button
                      type="text"
                      className="button button-active"
                      onClick={createNewPackage(setFieldValue, values)}
                      disabled={values.actions?.findIndex((action) => action.type === 'MESSAGE_PACKAGE') !== -1}
                      icon={<PlusCircleOutlined />}
                    >
                      {t('tag_management.button_package_operation')}
                    </Button>
                  </Space>
                </div>
                <FieldArray
                  name="actions"
                  render={({ remove }) =>
                    !!values.actions.length &&
                    values.actions.map((action, index) => (
                      <Collapse
                        key={index}
                        className="collapse-custom"
                        defaultActiveKey={[index]}
                        expandIcon={({ isActive }) => (!isActive ? <RightCircleOutlined /> : <DownCircleOutlined />)}
                      >
                        {action.type === 'MESSAGE' && (
                          <Panel
                            header={
                              <HeaderComponent
                                index={index}
                                title={t('tag_management.button_send_message')}
                                buttonAction={() => remove(index)}
                              />
                            }
                            key={index}
                          >
                            <div key={index} className="create-tag__section-send-message">
                              <Row gutter={16} className="create-tag__section-send-message__folder">
                                <Col className="gutter-row" span={6}>
                                  <div className="title-content">{t('tag_management.label_message_content')}</div>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                  <div className="create-tag__section-send-message__folder__checkbox">
                                    <Field
                                      name={`actions.${index}.message_type_text`}
                                      fieldname={`actions.${index}.message_type_text`}
                                      type="checkbox"
                                      checked={action.message_type_text}
                                      onChange={() => {
                                        setFieldValue(`actions.${index}.message_type_text`, !action.message_type_text);
                                        setFieldValue(`actions.${index}.text`, '');
                                      }}
                                    />
                                    <label
                                      className="edit-tag__section-send-message__folder__checkbox__label"
                                      htmlFor={`actions.${index}.message_type_text`}
                                    >
                                      {t('tag_management.label_text')}
                                    </label>
                                    <Field
                                      name={`actions.${index}.message_type_image`}
                                      fieldname={`actions.${index}.message_type_image`}
                                      type="checkbox"
                                      checked={action.message_type_image}
                                      onChange={() => {
                                        setFieldValue(
                                          `actions.${index}.message_type_image`,
                                          !action.message_type_image
                                        );
                                        setTimeout(() => {
                                          setFieldValue(`actions.${index}.image_url`, '');
                                          setFieldValue(`actions.${index}.image_size`, 'giga');
                                          setFieldValue(`actions.${index}.image_target_url`, '');
                                        }, 0);
                                      }}
                                    />
                                    <label
                                      className="edit-tag__section-send-message__folder__checkbox__label"
                                      htmlFor={`actions.${index}.message_type_image`}
                                    >
                                      {t('tag_management.label_image')}
                                    </label>
                                    <Field
                                      name={`actions.${index}.message_type_video`}
                                      fieldname={`actions.${index}.message_type_video`}
                                      type="checkbox"
                                      checked={action.message_type_video}
                                      onChange={() => {
                                        setFieldValue(
                                          `actions.${index}.message_type_video`,
                                          !action.message_type_video
                                        );
                                        setTimeout(() => {
                                          setFieldValue(`actions.${index}.video_url`, '');
                                          setFieldValue(`actions.${index}.video_url_temp`, '');
                                          setFieldValue(`actions.${index}.video_thumbnail_url`, '');
                                          setFieldValue(`actions.${index}.video_thumbnail_url_temp`, '');
                                        }, 0);
                                      }}
                                    />
                                    <label
                                      className="edit-tag__section-send-message__folder__checkbox__label"
                                      htmlFor={`actions.${index}.message_type_video`}
                                    >
                                      {t('tag_management.label_video')}
                                    </label>
                                    <Tooltip title={t('section_message.tooltip_text')}>
                                      <Button onClick={toggleMessageContentModal}>{<QuestionCircleOutlined />}</Button>
                                    </Tooltip>
                                  </div>
                                  <Field name="listCheckbox" className="hidden" component={Input} />
                                  <ErrorMessage name={`actions.${index}.listCheckbox`} />
                                  <Field
                                    name={`actions.${index}.text`}
                                    fieldName={`actions.${index}.text`}
                                    placeholder={t('tag_management.label_text')}
                                    value={action.text}
                                    onChange={handleInputChange(setFieldValue, `actions.${index}.text`, 'input')}
                                    rows={4}
                                    disabled={!action.message_type_text}
                                    className="create-tag__section-send-message__folder__text"
                                    maxLength={2000}
                                    component={TextareaComponent}
                                  />
                                  <ErrorMessage name={`actions.${index}.text`} />
                                  <div className="create-tag__section-send-message__folder__message-require">
                                    <div>{t('tag_management.section_send_message_require_1')}</div>
                                    <div>{t('tag_management.section_send_message_require_2', { number: 2000 })}</div>
                                  </div>
                                  <div className="create-tag__section-send-message__folder__upload">
                                    <Space size="middle">
                                      <Field
                                        name={`actions.${index}.image_url`}
                                        placeholder={t('common.image_upload')}
                                        value={getFileName(action.image_url)}
                                        disabled
                                        component={InputComponent}
                                      />
                                      <Button
                                        disabled={!action.message_type_image}
                                        className="button button-active"
                                        onClick={() => {
                                          toggleModalUploadImage();
                                          setIndexField(index);
                                        }}
                                      >
                                        {t('tag_management.button_upload')}
                                      </Button>
                                    </Space>
                                    <ErrorMessage name={`actions.${index}.image_url`} />
                                    <Space size="middle">
                                      <Field
                                        name={`actions.${index}.video_url`}
                                        placeholder={t('common.video_upload')}
                                        value={getFileName(action.video_url)}
                                        disabled
                                        component={InputComponent}
                                      />
                                      <Button
                                        disabled={!action.message_type_video}
                                        className="button button-active"
                                        onClick={() => {
                                          toggleModalUploadVideo();
                                          setIndexField(index);
                                        }}
                                      >
                                        {t('tag_management.button_upload')}
                                      </Button>
                                    </Space>
                                    <ErrorMessage name={`actions.${index}.video_url`} />
                                    <Space size="middle">
                                      <Field
                                        name={`actions.${index}.video_thumbnail_url`}
                                        placeholder={t('common.video_thumbnail_upload')}
                                        value={getFileName(action.video_thumbnail_url)}
                                        disabled
                                        component={InputComponent}
                                      />
                                      <Button
                                        disabled={!action.message_type_video || !action.video_url}
                                        className="button button-active"
                                        onClick={() => {
                                          toggleModalUploadVideo();
                                          setIndexField(index);
                                        }}
                                      >
                                        {t('tag_management.button_upload')}
                                      </Button>
                                    </Space>
                                    <ErrorMessage name={`actions.${index}.video_thumbnail_url`} />
                                  </div>
                                </Col>
                              </Row>
                              <Row gutter={16} className="create-tag__section-send-message__folder">
                                <Col className="gutter-row" span={6}>
                                  <div className="title-content">{t('tag_management.label_time')}</div>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                  <Field
                                    name={`actions.${index}.sending_time_type`}
                                    fieldName={`actions.${index}.sending_time_type`}
                                    value={action.sending_time_type}
                                    onChange={handleInputChange(
                                      setFieldValue,
                                      `actions.${index}.sending_time_type`,
                                      'radio'
                                    )}
                                    options={getTypeTimeSendMessageTag(t)}
                                    component={RadioComponent}
                                  />
                                  {
                                    <TimePickerComponent
                                      type={action.sending_time_type}
                                      onChange={handleInputChange}
                                      setFieldValue={setFieldValue}
                                      t={t}
                                      index={index}
                                      values={action}
                                    />
                                  }
                                  <ErrorMessage name={`actions.${index}.time_diff`} />
                                  <ErrorMessage name={`actions.${index}.time_to_send`} />
                                </Col>
                              </Row>
                            </div>
                          </Panel>
                        )}
                        {action.type === 'MESSAGE_PACKAGE' && (
                          <Panel
                            header={
                              <HeaderComponent
                                index={index}
                                title={t('tag_management.button_package_operation')}
                                buttonAction={() => remove(index)}
                              />
                            }
                            key={index}
                          >
                            <div className="create-tag__section-package-operation">
                              <Row gutter={16} className="create-tag__section-package-operation__package">
                                <Col className="gutter-row" span={6}>
                                  <div className="title-content">{t('tag_management.label_package_selection')}</div>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                  <SelectionMessagePackageComponent
                                    formikValues={values}
                                    setFieldValue={setFieldValue}
                                    indexField={index}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </Panel>
                        )}
                      </Collapse>
                    ))
                  }
                />
                <Divider />
                <Space size="middle" align="center">
                  <Button type="text" className="button button-delete" href="/organizer/tags">
                    {t('common.button_cancel')}
                  </Button>
                  <Button type="text" className="button button-active" onClick={submitForm}>
                    {t('common.button_save')}
                  </Button>
                </Space>
                <ModalUploadImage
                  isVisible={isVisibleModalUploadImage}
                  formikValues={values}
                  toggleModal={toggleModalUploadImage}
                  fieldNameImageURL={`actions.${indexField}.image_url`}
                  handleChangeModal={handleChangeUploadImageModal(indexField, setFieldValue)}
                  imageURLValue={values.actions[indexField]?.image_url}
                  imageSizeValue={values.actions[indexField]?.image_size}
                  imageLinkValue={values.actions[indexField]?.image_target_url}
                  indexField={indexField}
                  setFieldValue={setFieldValue}
                />
                <UploadVideoModal
                  isModalVisible={isVisibleModalUploadVideo}
                  toggleModal={toggleModalUploadVideo}
                  fieldNameVideoURL={`actions.${indexField}.video_url`}
                  fieldNameThumbnailURL={`actions.${indexField}.video_thumbnail_url`}
                  setFieldValue={setFieldValue}
                  indexField={indexField}
                  formikValues={values}
                />
                <MessageContentComponent
                  isModalVisible={isVisibleMessageContentModal}
                  toggleModal={toggleMessageContentModal}
                />
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  tagFolders: state.tagManagementReducer.tagFolders,
  tagDetail: state.tagManagementReducer.tagDetail,
  error: state.tagManagementReducer.error,
  type: state.tagManagementReducer.type,
});

const mapDispatchToProps = (dispatch) => ({
  getListTagFolders: () => dispatch(actions.getListTagFolderStart()),
  createNewTag: (body) => dispatch(actions.postNewTagStart(body)),
  editTagDetail: (payload) => dispatch(actions.putTagDetailStart(payload)),
  getTagDetail: (payload) => dispatch(actions.getTagDetailStart(payload)),
  resetType: () => dispatch(appActions.resetTypeOfAction()),
  resetErrorMessage: () => dispatch(appActions.resetErrorMessage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTagPage);
