import React from 'react';
import './index.css';
import { Button, Form, Steps, Spin, notification, Modal, Progress } from 'antd';
import io from 'socket.io-client';

import { api } from '../../../../services/api';
import { ApiPath } from '../../../../common/ApiPath';
import { ResponseCode } from '../../../../common/ResponseCode';
import { browserHistory } from '../../../../browser-history';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { AppConst } from '../../../../common/AppConst';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import { getFileNameWithoutExtension, UUID } from '../../../../utils';
import SecurityService from '../../../../services/security_service';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Step } = Steps;
const steps = [
  {
    id: 0,
    title: '主催者',
    content: 'First-content',
  },
  {
    id: 1,
    title: '開催日時',
    content: 'Second-content',
  },
  {
    id: 2,
    title: '開始時',
    content: 'Four-content',
  },
  {
    id: 3,
    title: 'メッセージ設定',
    content: 'Thirst-content',
  },
  {
    id: 4,
    title: '開始後',
    content: 'Five-content',
  },
  {
    id: 5,
    title: 'Metaデータ',
    content: 'Last-content',
  },
];

export default class SeminarDetail extends React.Component {
  formRef = React.createRef();
  constructor(pros) {
    super(pros);
    this.state = this.defaultState;
    this.socket = io(process.env.REACT_APP_SOCKET_URL);
  }

  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  defaultState = {
    seminarId: 0,
    loading: true,
    seminar: null,
    current: null,
    eventDay: 1,
    timeToSend: null,
    repetitionDay: 1,
    isEdit: true,
    isEditMessage: false,
    messageType: null,
    detailMessage: {},
    seminarMessageId: null,
    isUploadVideo: true,
    somethingchanged: false,
    progressPercent: 0,
    formID: UUID(),
  };

  componentDidMount() {
    let seminarId = this.props.match.params.id;
    const { formID } = this.state;
    if (seminarId) {
      this.getSeminarInfo(seminarId, 0, false);
    } else {
      // seminarId = localStorage.getItem("seminarId");
      this.setState({ loading: false, isEdit: true, current: 0 });
    }
    this.socket.emit('auth', { token: SecurityService.getToken() });
    this.socket.on('auth', (response) => {
      if (response) {
        this.socket.on('upload--video-seminar-progress', (event) => {
          if (event.form_id !== formID) {
            return;
          }

          if (event.progress % 10 === 0) {
            this.setState((prevState) => ({
              progressPercent: prevState.progressPercent + 1,
            }));
          }
        });
      }
    });
  }

  getSeminarInfo(seminarId, current, isEdit) {
    api.get('organizer/seminars/' + seminarId).then(
      (response) => {
        if (response.status === ResponseCode.OK && response.data.meta.success) {
          const seminar = response.data.data;
          const eventDay = seminar?.present_days ? 1 : 2;
          const repetitionDay = seminar?.present_repetition_days || 1;
          this.setState({
            current,
            seminar,
            loading: false,
            seminarId,
            isEdit,
            repetitionDay,
            eventDay,
            isEditMessage: false,
          });
          return;
        }
        notification.error({ message: response?.data?.meta?.error_data?.error_msg, description: '' });
        this.setState({ current, seminar: {}, loading: false, seminarId, isEdit });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  }

  onChangeEventDay = (value) => {
    this.setState({ eventDay: value });
  };

  onChangeTimeToSend = (value) => {
    this.setState({ timeToSend: value });
  };

  onChangeMessageType = (value) => {
    this.setState({ messageType: value });
  };

  onchangeRepetitionDay = (value) => {
    this.setState({ repetitionDay: value });
  };

  addNewMessage = () => {
    this.formRef.current.resetFields();
    this.setState({
      isEditMessage: true,
      isEdit: true,
      messageType: null,
      timeToSend: null,
      detailMessage: {},
      seminarMessageId: null,
    });
  };

  changeDetailMessage = (record) => {
    api.get(`organizer/seminars/${this.state.seminarId}/seminar_message/${record?.id}`).then(
      (response) => {
        if (response.status === ResponseCode.OK && response.data.meta.success) {
          this.formRef.current.resetFields();
          const detailMessage = response.data.data;
          let messageType = record?.message_type;
          let timeToSend = record?.sending_time_type;
          if (
            messageType === AppConst.MESSAGE_TYPE_CLICKED ||
            messageType === AppConst.MESSAGE_TYPE_NOT_CLICK ||
            messageType === AppConst.MESSAGE_TYPE_NOT_ACCESS ||
            messageType === AppConst.MESSAGE_TYPE_ALL
          ) {
            messageType = AppConst.MESSAGE_TYPE_TARGET;
            timeToSend = AppConst.SENDING_TIME_TYPE.RELATIVE;
          }

          if (messageType === AppConst.MESSAGE_TYPE_BEGIN) {
            timeToSend = AppConst.UNDEFINED;
            detailMessage.sending_time_type = AppConst.UNDEFINED;
          }

          this.setState({
            isEditMessage: true,
            detailMessage,
            seminarMessageId: record?.id,
            messageType,
            timeToSend,
            isEdit: false,
          });
          return;
        }
        notification.error({ message: response?.data?.meta?.error_data?.error_msg, description: '' });
        this.setState({ loading: false });
      },
      (error) => {
        notification.error({ message: AppConst.INTERNAL_SERVER_ERROR, description: '' });
        this.setState({ loading: false });
      }
    );
  };

  deleteMessage = (seminarMessageId) => {
    api.delete(`/organizer/seminars/${this.state.seminarId}/delete/seminar_message/${seminarMessageId}`).then(
      (response) => {
        if (response.status === ResponseCode.OK && response.data.meta.success) {
          const seminarMessage = this.state.seminar?.seminar_messages.filter((item) => item.id !== seminarMessageId);
          const seminar = this.state.seminar;
          seminar.seminar_messages = seminarMessage;
          this.setState({ seminar });
          return;
        }
        notification.error({ message: response?.data?.meta?.error_data?.error_msg, description: '' });
        this.setState({ loading: false });
      },
      (error) => {
        notification.error({ message: AppConst.INTERNAL_SERVER_ERROR, description: '' });
        this.setState({ loading: false });
      }
    );
  };

  somethingChanged = () => {
    this.setState({ somethingChanged: true });
  };

  getContentOfSteps = () => {
    const { isEdit, current, seminar, timeToSend, eventDay, detailMessage, repetitionDay, isEditMessage, messageType } =
      this.state;
    const { t } = this.props;
    switch (current) {
      case 0:
        return (
          <Step1 form={this.formRef} seminar={seminar} isEdit={isEdit} t={t} somethingChanged={this.somethingChanged} />
        );
      case 1:
        return (
          <Step2
            form={this.formRef}
            seminar={seminar}
            isEdit={isEdit}
            t={t}
            eventDay={eventDay}
            onChangeEventDay={this.onChangeEventDay}
            repetitionDay={repetitionDay}
            onchangeRepetitionDay={this.onchangeRepetitionDay}
            somethingChanged={this.somethingChanged}
          />
        );
      case 2:
        return (
          <Step3
            form={this.formRef}
            seminar={seminar}
            isEdit={isEdit}
            t={t}
            handleUploadVideo={this.handleUploadVideo}
            somethingChanged={this.somethingChanged}
            getSeminarInfo={this.getSeminarInfo}
          />
        );
      case 3:
        return (
          <Step4
            form={this.formRef}
            seminarMessages={seminar?.seminar_messages}
            isEdit={isEdit}
            t={t}
            isEditMessage={isEditMessage}
            addNewMessage={this.addNewMessage}
            timeToSend={timeToSend}
            onChangeTimeToSend={this.onChangeTimeToSend}
            detailMessage={detailMessage}
            changeDetailMessage={this.changeDetailMessage}
            onChangeMessageType={this.onChangeMessageType}
            messageType={messageType}
            deleteMessage={this.deleteMessage}
            somethingChanged={this.somethingChanged}
          />
        );
      case 4:
        return (
          <Step5 form={this.formRef} seminar={seminar} isEdit={isEdit} t={t} somethingChanged={this.somethingChanged} />
        );
      case 5:
        return (
          <Step6 form={this.formRef} seminar={seminar} isEdit={isEdit} t={t} somethingChanged={this.somethingChanged} />
        );
      default:
        break;
    }
  };

  onFinish = () => {
    const { current, seminarId } = this.state;
    return this.formRef.current
      .validateFields()
      .then(async (values) => {
        // if (current === 0) {
        //   if (!values.isUploadPresenterProfileImage && !values.isUploadPresenterProfileVideo && !values.isUploadPresenterProfileText) {
        //     notification.error({message: this.props.t('seminar_detail.step1.presenter_profile_text') + this.props.t('common.field_required'), description: "" });
        //     return;
        //   }
        //   if (!values.isUploadDescriptionImage && !values.isUploadDescriptionVideo && !values.isUploadDescriptionText) {
        //     notification.error({message: this.props.t('seminar_detail.step1.description') + this.props.t('common.field_required'), description: "" });
        //     return;
        //   }
        // }
        if (current === 3) {
          if (!values.isUploadMessageText && !values.isUploadMessageImage && !values.isUploadMessageVideo) {
            notification.error({
              message: this.props.t('seminar_detail.step3.field2') + this.props.t('common.field_required'),
              description: '',
            });
            return;
          }
        }

        this.setState({ loading: true });
        if (!seminarId) {
          return api.post(ApiPath.SEMINAR_CREATION_PATH, {}).then(
            async (response) => {
              if (response.status !== ResponseCode.OK || !response.data.meta.success) {
                notification.error({ message: response?.data?.meta?.error_data?.error_msg, description: '' });
                this.setState({ loading: false });
                return;
              }
              const id = response.data.data.id;
              // localStorage.setItem("seminarId", id);
              return this.updateSeminar(id, values);
            },
            (error) => {
              notification.error({ message: AppConst.INTERNAL_SERVER_ERROR, description: '' });
              this.setState({ loading: false });
              return;
            }
          );
        } else {
          return this.updateSeminar(seminarId, values);
        }
      })
      .catch((errorInfo) => {
        this.setState({ loading: false });
        return;
      });
  };

  updateSeminar = async (seminarId, values) => {
    const { current, isEditMessage, seminarMessageId, formID } = this.state;
    const { t } = this.props;

    if (current === 0 || current === 3) {
      if (
        values.isUploadPresenterProfileImage ||
        values.isUploadPresenterProfileVideo ||
        values.isUploadDescriptionImage ||
        values.isUploadDescriptionVideo ||
        values.isUploadMessageImage ||
        values.isUploadMessageVideo ||
        values.photo ||
        values.presenter_photo
      ) {
        await this.processUploadS3(values, seminarId);
      }
    }
    if (current === 1) {
      if (values.present_day) {
        values.present_day = moment(values.present_day).startOf('day').valueOf();
      }
      if (values.present_time_1) {
        values.present_time_1 = moment(values.present_time_1).seconds(0).millisecond(0).valueOf();
      }
      if (values.present_time_2) {
        values.present_time_2 = moment(values.present_time_2).seconds(0).millisecond(0).valueOf();
      }
      if (values.present_time_3) {
        values.present_time_3 = moment(values.present_time_3).seconds(0).millisecond(0).valueOf();
      }
    }
    if (current === 3) {
      if (values.time_to_send) {
        values.time_to_send = moment(values.time_to_send).seconds(0).millisecond(0).valueOf();
      }
      if (values.message_type === AppConst.MESSAGE_TYPE_TARGET) {
        values.message_type = values.message_type_last;
      }
      values.sending_time_type = AppConst.SENDING_TIME_TYPE.RELATIVE;

      return api.post(this.getApiSeminarMessagePath(seminarId, isEditMessage, seminarMessageId), values).then(
        (responseSeminarMessage) => {
          if (responseSeminarMessage.status !== ResponseCode.OK || !responseSeminarMessage.data.meta.success) {
            notification.error({ message: responseSeminarMessage?.data?.meta?.error_data?.error_msg, description: '' });
            this.setState({ loading: false });
            return;
          }
          return api.get('organizer/seminars/' + seminarId).then(
            (response) => {
              if (response.status === ResponseCode.OK && response.data.meta.success) {
                const isEdit = this.props.match.params.id ? false : true;
                this.setState({ seminar: response.data.data, loading: false, seminarId, isEditMessage: false, isEdit });
                return true;
              }
              notification.error({ message: response?.data?.meta?.error_data?.error_msg, description: '' });
              this.setState({ loading: false });
            },
            (error) => {
              notification.error({ message: AppConst.INTERNAL_SERVER_ERROR, description: '' });
              this.setState({ loading: false });
            }
          );
        },
        (error) => {
          notification.error({ message: AppConst.INTERNAL_SERVER_ERROR, description: '' });
          this.setState({ loading: false });
          return;
        }
      );
    }
    if (current === 2) {
      let config = {};

      let newValues = new FormData();
      newValues.append('conversionBtnName', values.conversion_btn_name);
      newValues.append('conversionBtnTimeDisplay', values.conversion_btn_time_display);
      newValues.append('conversionBtnUrl', values.conversion_btn_url);
      newValues.append('formId', formID);
      const isUploadFile = 'File' in window && values.video_url instanceof File;
      if (isUploadFile) {
        config = {
          onUploadProgress: (progressEvent) => {
            let percentCompleted = ((progressEvent.loaded * 100) / progressEvent.total / 10) * 9;
            this.setState({
              progressPercent: percentCompleted,
            });
          },
        };
        newValues.append('videoFile', values.video_url);
      } else {
        newValues.append('videoUrl', values.video_url);
      }

      return api
        .postMultiplePart(this.getApiPath(seminarId), newValues, config)
        .then(
          (response) => {
            if (response.meta.success) {
              const isEdit = this.props.match.params.id ? false : true;
              if (!isEdit) {
                notification.success({ message: t('message.MSG_03'), description: response?.data?.message });
              } else {
                notification.success({ message: t('message.MSG_01'), description: response?.data?.message });
              }
              this.showModal(values);
              this.setState({ loading: false, isEdit, seminarId, somethingChanged: false, progressPercent: 0 });
              this.getSeminarInfo(seminarId, 2, isEdit);
              return true;
            }
            notification.error({
              message: response?.data?.meta?.error_data?.error_msg || response?.meta?.error_data?.error_msg,
              description: '',
            });
            this.setState({ loading: false, progressPercent: 0 });
          },
          (error) => {
            notification.error({ message: AppConst.INTERNAL_SERVER_ERROR, description: '' });
            this.setState({ loading: false, progressPercent: 0 });
            return;
          }
        )
        .catch((error) => console.log(error));
    }

    return api.put(this.getApiPath(seminarId), values).then(
      (response) => {
        if (response.status === ResponseCode.OK && response.data.meta.success) {
          const isEdit = this.props.match.params.id ? false : true;
          if (!isEdit) {
            notification.success({ message: t('message.MSG_03'), description: response?.data?.message });
          } else {
            notification.success({ message: t('message.MSG_01'), description: response?.data?.message });
          }
          this.showModal(values);
          this.setState({ loading: false, isEdit, seminarId, somethingChanged: false });
          if (current === 2) {
            this.getSeminarInfo(seminarId, 2, isEdit);
          }
          return true;
        }
        console.log('error');
        notification.error({
          message: response?.data?.meta?.error_data?.error_msg,
          description: '',
        });
        this.setState({ loading: false });
      },
      (error) => {
        notification.error({ message: AppConst.INTERNAL_SERVER_ERROR, description: '' });
        this.setState({ loading: false });
        return;
      }
    );
  };

  showModal = (values) => {
    const { current, seminar } = this.state;
    if (current === 1) {
      if (
        values.present_time_1 !== seminar?.present_time_1 ||
        (values.present_time_2 && values.present_time_2 !== seminar?.present_time_2 && seminar?.present_time_2) ||
        (values.present_time_3 && values.present_time_3 !== seminar?.present_time_3 && seminar?.present_time_3)
      ) {
        Modal.success({
          className: 'step2-change-time',
          title: 'お知らせ',
          content: `セミナー開催時間が変更されましたので、メッセージ設定では送信時点を修正してください。`,
          maskClosable: true,
        });
      }
    }
    if (current === 2) {
      if (seminar?.video_url) {
        Modal.success({
          className: 'step2-change-time',
          title: 'お知らせ',
          content: `セミナー動画が変更されましたので、メッセージ設定では送信時点を修正してください。`,
          maskClosable: true,
        });
      }
    }
  };

  getApiPath = (seminarId) => {
    const current = this.state.current;
    switch (current) {
      case 0:
        return ApiPath.SEMINAR_UPDATE_PRESENTER_PATH.replace(AppConst.SEMINAR_ID, seminarId);
      case 1:
        return ApiPath.SEMINAR_UPDATE_PRESENT_TIME_PATH.replace(AppConst.SEMINAR_ID, seminarId);
      case 2:
        return ApiPath.SEMINAR_UPDATE_PRESENT_START_PATH.replace(AppConst.SEMINAR_ID, seminarId);
      case 3:
        return ApiPath.SEMINAR_UPDATE_SEMENAR_MESSAGE_PATH.replace(AppConst.SEMINAR_ID, seminarId);
      case 4:
        return ApiPath.SEMINAR_UPDATE_PRESENT_END_PATH.replace(AppConst.SEMINAR_ID, seminarId);
      case 5:
        return ApiPath.SEMINAR_UPDATE_META_PATH.replace(AppConst.SEMINAR_ID, seminarId);
      default:
        break;
    }
  };

  getApiSeminarMessagePath = (seminarId, isEditMessage, seminarMessageId) => {
    if (isEditMessage && !seminarMessageId) {
      return ApiPath.SEMINAR_CREATE_SEMENAR_MESSAGE_PATH.replace(AppConst.SEMINAR_ID, seminarId);
    }
    const path = ApiPath.SEMINAR_UPDATE_SEMENAR_MESSAGE_PATH.replace(AppConst.SEMINAR_ID, seminarId);
    return path.replace(AppConst.SEMINAR_MESSAGE_ID, seminarMessageId);
  };

  getFileExt = (type) => {
    return type.split('/')[1];
  };

  getUploadPath = (seminarId) => {
    if (this.state.current === 3) {
      return ApiPath.SEMINAR_MESSAGE_UPLOAD_S3_PATH;
    }
    return ApiPath.SEMINAR_UPLOAD_S3_PATH.replace(AppConst.SEMINAR_ID, seminarId);
  };

  uploadToS3 = (seminarId, file, type) => {
    const params = {
      'content-type': file.type,
      'file-ext': this.getFileExt(file.type),
      type: type,
      seminarId,
      ['file-name']: getFileNameWithoutExtension(file.name),
    };
    return api.get(this.getUploadPath(seminarId), params).then(
      (response) => {
        const options = {
          headers: {
            'Content-Type': file.type,
          },
        };
        if (response.status === ResponseCode.OK && response.data.meta.success) {
          return api.putToS3(response.data.data.second, file, options).then(
            (responseS3) => {
              if (responseS3.status === ResponseCode.OK) {
                return response.data.data.first;
              } else {
                notification.error({ message: 'Upload media failed.', description: '' });
                return false;
              }
            },
            (error) => {
              notification.error({ message: 'Upload media failed.', description: error.message });
              return false;
            }
          );
        } else {
          notification.error({ message: 'Get media url failed.', description: '' });
          return false;
        }
      },
      (error) => {
        notification.error({ message: AppConst.INTERNAL_SERVER_ERROR, description: error.message });
        return false;
      }
    );
  };

  processUploadS3 = async (values, seminarId) => {
    const promiseList = [];
    const fieldList = [];
    if (
      values.isUploadPresenterProfileImage &&
      values.presenter_profile_photo_url?.file?.originFileObj instanceof File
    ) {
      promiseList.push(
        this.uploadToS3(
          seminarId,
          values.presenter_profile_photo_url.file.originFileObj,
          AppConst.S3_TYPE_UPLOAD.PRESENTER_PROFILE_PHOTO
        )
      );
      fieldList.push('presenter_profile_photo_url');
    }
    if (
      values.isUploadPresenterProfileVideo &&
      values.presenter_profile_video_url?.file?.originFileObj instanceof File
    ) {
      promiseList.push(
        this.uploadToS3(
          seminarId,
          values.presenter_profile_video_url.file.originFileObj,
          AppConst.S3_TYPE_UPLOAD.PRESENTER_PROFIlE_VIDEO
        )
      );
      fieldList.push('presenter_profile_video_url');
    }
    if (
      values.isUploadPresenterProfileVideo &&
      values.presenter_profile_thumbnail_url?.file?.originFileObj instanceof File
    ) {
      promiseList.push(
        this.uploadToS3(
          seminarId,
          values.presenter_profile_thumbnail_url.file.originFileObj,
          AppConst.S3_TYPE_UPLOAD.PRESENTER_PROFIlE_VIDEO_THUMBNAIL
        )
      );
      fieldList.push('presenter_profile_thumbnail_url');
    }
    if (values.isUploadDescriptionImage && values.description_photo_url?.file?.originFileObj instanceof File) {
      promiseList.push(
        this.uploadToS3(
          seminarId,
          values.description_photo_url.file.originFileObj,
          AppConst.S3_TYPE_UPLOAD.SEMINAR_DESCRIPTION_PHOTO
        )
      );
      fieldList.push('description_photo_url');
    }
    if (values.isUploadDescriptionVideo && values.description_video_url?.file?.originFileObj instanceof File) {
      promiseList.push(
        this.uploadToS3(
          seminarId,
          values.description_video_url.file.originFileObj,
          AppConst.S3_TYPE_UPLOAD.SEMINAR_DESCRIPTION_VIDEO
        )
      );
      fieldList.push('description_video_url');
    }
    if (values.isUploadDescriptionVideo && values.description_thumbnail_url?.file?.originFileObj instanceof File) {
      promiseList.push(
        this.uploadToS3(
          seminarId,
          values.description_thumbnail_url.file.originFileObj,
          AppConst.S3_TYPE_UPLOAD.SEMINAR_DESCRIPTION_VIDEO_THUMBNAIL
        )
      );
      fieldList.push('description_thumbnail_url');
    }
    if (values.photo && values.photo?.file?.originFileObj instanceof File) {
      promiseList.push(
        this.uploadToS3(seminarId, values.photo.file.originFileObj, AppConst.S3_TYPE_UPLOAD.SEMINAR_PHOTO)
      );
      fieldList.push('photo');
    }
    if (values.presenter_photo && values.presenter_photo?.file?.originFileObj instanceof File) {
      promiseList.push(
        this.uploadToS3(
          seminarId,
          values.presenter_photo.file.originFileObj,
          AppConst.S3_TYPE_UPLOAD.PRESENTER_PROFILE_PHOTO
        )
      );
      fieldList.push('presenter_photo');
    }

    if (values.isUploadMessageImage && values.message_data_photo?.file?.originFileObj instanceof File) {
      promiseList.push(
        this.uploadToS3(
          seminarId,
          values.message_data_photo.file.originFileObj,
          AppConst.S3_TYPE_UPLOAD.SEMINAR_MSG_RESOURCES
        )
      );
      fieldList.push('message_data_photo');
    }

    if (values.isUploadMessageVideo && values.message_data_video?.file?.originFileObj instanceof File) {
      promiseList.push(
        this.uploadToS3(
          seminarId,
          values.message_data_video.file.originFileObj,
          AppConst.S3_TYPE_UPLOAD.SEMINAR_MSG_RESOURCES
        )
      );
      fieldList.push('message_data_video');
    }

    if (values.isUploadMessageVideo && values.message_data_video_thumbnail?.file?.originFileObj instanceof File) {
      promiseList.push(
        this.uploadToS3(
          seminarId,
          values.message_data_video_thumbnail.file.originFileObj,
          AppConst.S3_TYPE_UPLOAD.SEMINAR_MSG_RESOURCES
        )
      );
      fieldList.push('message_data_video_thumbnail');
    }

    await Promise.all(promiseList)
      .then((results) => {
        fieldList.forEach((item, index) => {
          values[item] = results[index];
        });
      })
      .catch((reason) => {
        this.setState({ isLoading: false });
      });
  };

  changeStep = (current, isEdit) => {
    if (!this.state.seminarId) {
      this.setState({ current, loading: false, isEdit, isEditMessage: false, somethingChanged: false });
      return;
    }
    this.setState({ loading: true, somethingChanged: false });
    this.getSeminarInfo(this.state.seminarId, current, isEdit);
  };

  confirmNextStep = async (current, isEdit) => {
    const result = await this.onFinish();
    if (!result) {
      return;
    }
    this.changeStep(current, isEdit);
  };

  onChangeStep = (current) => {
    const isEdit = this.props.match.params.id ? false : true;
    const editState = this.state.isEdit;

    if ((this.state.current !== 3 || this.state.isEditMessage) && editState && this.state.somethingChanged) {
      const modal = Modal.confirm({
        title: '保存しましょうか。',
        content: '',
        maskClosable: true,
        onOk: () => this.confirmNextStep(current, isEdit),
        okText: 'はい',
        cancelButtonProps: {
          onClick: () => {
            modal.destroy();
            this.changeStep(current, isEdit);
          },
        },
        onCancel: () => {},
      });
      return;
    }
    this.changeStep(current, isEdit);
  };

  next = () => {
    const current = this.state.current + 1;
    this.onChangeStep(current);
  };

  prev = () => {
    const { isEditMessage } = this.state;
    const current = this.state.current - 1;
    if (isEditMessage) {
      this.onChangeStep(this.state.current);
      return;
    }
    this.onChangeStep(current);
  };

  edit = () => {
    this.setState({ isEdit: true });
  };

  save = () => {
    this.onFinish();
  };

  cancel = () => {
    // localStorage.removeItem("seminarId");
    browserHistory.push(AppConst.ROUTE.SEMINAR_MANAGEMENTS);
    window.location = AppConst.ROUTE.SEMINAR_MANAGEMENTS;
  };

  handleUploadVideo = (isUploadVideo) => {
    this.setState({ isUploadVideo });
  };

  render() {
    const { seminar, current, isEdit, loading, isEditMessage, isUploadVideo, progressPercent } = this.state;
    const { t } = this.props;
    const seminarId = this.props.match.params.id;
    return (
      <Spin
        indicator={antIcon}
        spinning={loading}
        indicator={!!progressPercent && <Progress type="circle" percent={Math.floor(progressPercent)} />}
      >
        <div className="container">
          <h2 className="text-center title">{t('seminar_detail.page_title')}</h2>
          <hr></hr>
          <>
            <Steps current={current} onChange={this.onChangeStep}>
              {steps.map((item, index) => (
                <Step status={current === index ? 'process' : 'wait'} key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">
              <Form ref={this.formRef} onFinish={this.onFinish}>
                {this.getContentOfSteps()}
              </Form>
            </div>
            <div className="steps-action">
              <Button
                className="button-style-common"
                style={{ margin: '0 8px' }}
                onClick={this.prev}
                disabled={current === 0}
              >
                {t('organizer_detail.prev_btn_label')}
              </Button>
              {current < steps.length - 1 && (
                <Button className="style-button button-style-common" onClick={this.next}>
                  {t('organizer_detail.next_btn_label')}
                </Button>
              )}
              {/* {current === steps.length - 1 && (
                <Button className="style-button button-style-common" onClick={this.cancel}>
                  {t('organizer_detail.done_btn_label')}
                </Button>
              )} */}
              {(isEdit || !seminarId) && (isEditMessage || current !== 3) && (
                <Button
                  className="style-button button-style-common"
                  onClick={this.save}
                  disabled={current === 2 && !isUploadVideo}
                >
                  {t('organizer_detail.save_btn_label')}
                </Button>
              )}
              {seminar && !isEdit && seminarId && (isEditMessage || current !== 3) && (
                <Button className="style-button button-style-common" onClick={this.edit}>
                  {t('organizer_detail.edit_btn_label')}
                </Button>
              )}
              <Button className="button-style-common" onClick={this.cancel}>
                {t('organizer_detail.go_to_list_btn_label')}
              </Button>
            </div>
          </>
        </div>
      </Spin>
    );
  }
}
