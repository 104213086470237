import { put, takeLatest } from 'redux-saga/effects';
import { actions, ActionTypes } from './actions';
import { getUserDetailAPI, putUserInformationAPI } from '../../../services/UserRequest';
import { RESPONSE_STATUS } from '../../../common/ResponseCode';
import { actions as appActions } from '../../Home/actions';

function* getUserDetail({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield getUserDetailAPI(params);
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getUserDetailSuccess(response?.data));
    } else {
      yield put(actions.getUserDetailError(response));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.getUserDetailError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* putUserInformation({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield putUserInformationAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.putUserInformationSuccess(response));
    } else {
      yield put(actions.putUserInformationError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.putUserInformationError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

export function* watchUserDetail() {
  yield takeLatest(ActionTypes.GET_USER_DETAIL_START, getUserDetail);
  yield takeLatest(ActionTypes.PUT_USER_INFORMATION_START, putUserInformation);
}
