import React, { Fragment } from 'react';

function Input({ field, form: { touched, errors }, wrapperClass, labelTitle, required, inputLength, icon, ...props }) {
  return (
    <div className={`input ${wrapperClass}`}>
      {labelTitle && (
        <label htmlFor={field.name}>
          {labelTitle} {required && <span className="required">*</span>}
        </label>
      )}
      {props.maxLength ? (
        <div className="input__max--length">
          <input {...field} {...props} />
          <span>{`${field.value.length}/${props.maxLength}`}</span>
        </div>
      ) : (
        <Fragment>
          {icon}
          <input maxLength={inputLength} id={field.name} {...field} {...props} />
        </Fragment>
      )}
      {errors[field.name] && touched[field.name] && <span className="message message-error">{errors[field.name]}</span>}
    </div>
  );
}

export default Input;
