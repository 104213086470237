import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <path
        d="M16.135 2.898h-8.06l-.325-.627a1.865 1.865 0 00-1.703-1.104H1.865C.835 1.167 0 2 0 3.03v12.156c0 .91.737 1.646 1.646 1.646h14.708c.909 0 1.646-.737 1.646-1.646V4.763c0-1.03-.835-1.865-1.865-1.865zM6.042 3.04l.045.094.326.627c.322.62.963 1.01 1.662 1.01h8.052v1.41H1.873V3.04h4.169z"
        fill="#BCBCBC"
      />
    </svg>
  );
}

export default SvgComponent;
