import React from 'react';
import './index.css';
import { browserHistory } from '../../../../browser-history';
import { AppConst } from '../../../../common/AppConst';
import { ApiPath } from '../../../../common/ApiPath';
import { Table, Button, Space, Input, Row, notification, Tooltip } from 'antd';
import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined';
import { api } from '../../../../services/api';
import { ResponseCode } from '../../../../common/ResponseCode';
import Popconfirm from 'antd/es/popconfirm';
import * as moment from 'moment';
import { PlusCircleOutlined } from '@ant-design/icons';

export default class SeminarList extends React.Component {
  constructor(pros) {
    super(pros);
    this.state = this.defaultState;
  }

  defaultState = {
    keyword: '',
    seminars: [],
    filteredInfo: null,
    sortedInfo: null,
    page: 1,
    perPage: 10,
    total: 0,
    orderBy: null,
    orderDirection: null,
  };

  componentDidMount() {
    const { keyword, page, perPage, orderBy, orderDirection } = this.state;
    this.search(keyword, page, perPage, orderBy, orderDirection);
  }

  handleChange = (pagination, filters, sorter) => {
    const keyword = this.state.keyword;
    const page = pagination.current;
    const perPage = pagination.pageSize;
    const orderBy = sorter.field;
    const orderDirection = this.getOrderDirection(sorter.order);

    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
      keyword: this.state.keyword,
      page: pagination.current,
      perPage: pagination.pageSize,
      orderBy: orderBy,
      orderDirection: orderDirection,
    });

    this.search(keyword, page, perPage, orderBy, orderDirection);
  };

  search = (keyword, page, perPage, orderBy, orderDirection) => {
    const params = { keyword, perPage, page, orderBy, orderDirection };
    this.setState({
      loading: true,
    });
    api.get('/organizer/seminars/filter', params).then(
      (response) => {
        if (response.status === ResponseCode.OK) {
          let seminars = response.data?.data?.elements;
          this.setState({
            loading: false,
            keyword: '',
            seminars: seminars,
            total: response.data?.data?.paging?.total,
          });
        } else {
          this.setState({ seminars: [], loading: false });
        }
      },
      (error) => {
        this.setState({ seminars: [], loading: false });
      }
    );
  };

  onChangeKeyword = (event) => {
    const newKeyword = event.target.value;
    const newPage = 1;
    this.search(newKeyword, newPage, this.perPage);
    this.setState({ keyword: newKeyword, page: newPage });
  };

  deleteSeminar = (seminarId) => () => {
    api.delete(ApiPath.DELETE_SEMINAR_PATH.replace(AppConst.SEMINAR_ID, seminarId)).then(
      (response) => {
        if (response.status === ResponseCode.OK) {
          const seminars = this.state.seminars.filter((item) => item.id !== seminarId);
          this.setState({
            loading: false,
            keyword: '',
            seminars,
            total: seminars.length - 1,
          });
        } else {
          this.setState({ loading: false });
        }
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  getOrderDirection(orderAlias) {
    switch (orderAlias) {
      case 'descend':
        return 'DESC';
      default:
        return 'ASC';
    }
  }

  addNew = () => {
    localStorage.removeItem('seminarId');
    browserHistory.push(AppConst.ROUTE.SEMINAR_CREATION);
    window.location = AppConst.ROUTE.SEMINAR_CREATION;
  };

  detailOrganizer = (organizerId) => {
    browserHistory.push(AppConst.ROUTE.SEMINAR_DETAIL + organizerId);
    window.location = AppConst.ROUTE.SEMINAR_DETAIL + organizerId;
  };

  updateStatus = (seminarId, status) => () => {
    const { t } = this.props;
    api.put('/organizer/seminars/' + seminarId + '/update-status' + '?status=' + status).then((response) => {
      if (response.status === ResponseCode.OK && response.data.success) {
        notification.success({ message: t('message.MSG_03'), description: '' });
        this.setState({
          seminars: this.state.seminars.map((item) => {
            if (item.id === seminarId) {
              item.status = status;
            }
            return item;
          }),
        });
        return;
      }
      notification.error({ message: response?.data?.meta?.error_data?.error_msg, description: '' });
    });
  };

  getPresentDays = (text, record) => {
    return text
      ? moment(text).format('yyyy年MM月DD')
      : record?.present_after_days === 0
      ? '当日'
      : record?.present_after_days
      ? record?.present_after_days + '日後'
      : '-';
  };

  getStatus = (text) => {
    return text === AppConst.SEMINAR_STATUS.PUBLISH
      ? '公演'
      : !text || text === AppConst.SEMINAR_STATUS.INIT
      ? '初期化'
      : '停止';
  };

  getPresentTime = (record) => {
    let displayText = moment(record.present_time_1).format('HH:mm');
    if (record.present_time_2) displayText += ', ' + moment(record.present_time_2).format(' HH:mm');
    if (record.present_time_3) displayText += ', ' + moment(record.present_time_3).format('HH:mm');
    return displayText === 'Invalid date' ? '-' : displayText;
  };

  render() {
    let { sortedInfo, page, perPage } = this.state;
    const { t } = this.props;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'No',
        dataIndex: 'id',
        key: 'id',
        width: '5%',
        render: (text, record, index) => <div>{(page - 1) * perPage + (index + 1)}</div>,
        ellipsis: true,
        align: 'left',
      },
      {
        title: 'タイトル',
        dataIndex: 'title',
        key: 'title',
        width: '15%',
        sortOrder: sortedInfo.columnKey === 'title' && sortedInfo.order,
        render: (text, record) => (
          <div className="step4-tooltip">
            <Tooltip color="#50d890" overlayClassName="tooltip-message-content" placement="bottomLeft" title={text}>
              <div className="tooltip-message-content">{text || '-'}</div>
            </Tooltip>
          </div>
        ),
        ellipsis: true,
        align: 'left',
      },
      {
        title: '主催者',
        dataIndex: 'presenter',
        key: 'presenter',
        width: '10%',
        sortOrder: sortedInfo.columnKey === 'presenter' && sortedInfo.order,
        render: (text, record) => (
          <div className="step4-tooltip">
            <Tooltip color="#50d890" overlayClassName="tooltip-message-content" placement="bottomLeft" title={text}>
              <div className="tooltip-message-content">{text || '-'}</div>
            </Tooltip>
          </div>
        ),
        ellipsis: true,
        align: 'left',
      },

      {
        title: '開催日',
        dataIndex: 'present_days',
        key: 'present_days',
        sortOrder: sortedInfo.columnKey === 'present_days' && sortedInfo.order,
        ellipsis: true,
        width: '10%',
        render: (text, record) => (
          <div>
            <Tooltip
              color="#50d890"
              overlayClassName="tooltip-message-content"
              className="step4-tooltip"
              placement="bottomLeft"
              title={this.getPresentDays(text, record)}
            >
              {this.getPresentDays(text, record)}
            </Tooltip>
          </div>
        ),
        align: 'left',
      },
      {
        title: '開催期間',
        dataIndex: 'presentRepetitionDays',
        key: 'present_repetition_days',
        ellipsis: true,
        width: '10%',
        render: (text, record) => (
          <div>
            <Tooltip
              color="#50d890"
              overlayClassName="tooltip-message-content"
              className="step4-tooltip"
              placement="bottomLeft"
              title={record.present_repetition_days}
            >
              {record.present_repetition_days || '-'}
            </Tooltip>
          </div>
        ),
        align: 'left',
      },
      {
        title: '開催時間',
        dataIndex: 'present_time_1',
        key: 'present_time_1',
        width: '10%',
        ellipsis: true,
        render: (text, record) => (
          <div className="step4-tooltip">
            <Tooltip
              color="#50d890"
              overlayClassName="tooltip-message-content"
              placement="bottomLeft"
              title={this.getPresentTime(record)}
            >
              <div className="tooltip-message-content">{this.getPresentTime(record)}</div>
            </Tooltip>
          </div>
        ),
        align: 'left',
      },
      {
        title: '説明',
        dataIndex: 'description',
        key: 'description',
        width: '12%',
        render: (text, record) => (
          <div className="step4-tooltip">
            <Tooltip color="#50d890" overlayClassName="tooltip-message-content" placement="bottomLeft" title={text}>
              <div className="tooltip-message-content">{text || '-'}</div>
            </Tooltip>
          </div>
        ),
        ellipsis: true,
        align: 'left',
      },
      {
        title: '様態',
        dataIndex: 'status',
        key: 'status',
        width: '8%',
        render: (text, record) => (
          <div>
            <Tooltip
              color="#50d890"
              overlayClassName="tooltip-message-content"
              className="step4-tooltip"
              placement="bottomLeft"
              title={this.getStatus(text)}
            >
              {this.getStatus(text)}
            </Tooltip>
          </div>
        ),
        ellipsis: true,
        align: 'left',
      },
      {
        title: '',
        key: 'action',
        width: '20%',
        align: 'center',
        render: (text, record) => (
          <div size="middle" align="center">
            <Button type="text" className="organizer-detail" onClick={() => this.detailOrganizer(record.id)}>
              {t('seminar_detail.step3.edit')}
            </Button>
            {record?.status === AppConst.SEMINAR_STATUS.PUBLISH ? (
              <Button
                type="text"
                className="organizer-stop"
                onClick={this.updateStatus(record.id, AppConst.SEMINAR_STATUS.UNPUBLISH)}
              >
                停止
              </Button>
            ) : (
              <Button
                type="text"
                className="organizer-stop"
                onClick={this.updateStatus(record.id, AppConst.SEMINAR_STATUS.PUBLISH)}
              >
                公演
              </Button>
            )}
            <Popconfirm
              placement="topRight"
              title={t('common.confirm_delete_message')}
              onConfirm={this.deleteSeminar(record.id)}
              okText={t('common.yes')}
              cancelText={t('common.no')}
            >
              <Button className="organizer-delete" type="text">
                削除
              </Button>
            </Popconfirm>
            <Button
              type="text"
              className="button button-solid"
              target="_blank"
              disabled={!record.presenting_access_url}
              href={`${record.presenting_access_url}`}
            >
              {t('seminar_list.button_go_to_present')}
            </Button>
          </div>
        ),
      },
    ];

    return (
      <div className="account-management ant-col-22 ant-col-offset-1">
        <>
          <Row>
            <div className="account-management-title">{t('seminar_list.page_title')}</div>
          </Row>
          <Row>
            <div className="search-bar">
              <Input
                className="search-input"
                placeholder={t('seminar_list.search')}
                size="large"
                onChange={this.onChangeKeyword}
                suffix={<SearchOutlined />}
              />
              <Button onClick={this.addNew} className="create-seminar-button" type="text" icon={<PlusCircleOutlined />}>
                {t('seminar_list.create_btn_label')}
              </Button>
            </div>
          </Row>
          <Table
            columns={columns}
            dataSource={this.state.seminars}
            onChange={this.handleChange}
            bordered
            loading={this.state.loading}
            rowKey="id"
            pagination={{ defaultPageSize: this.state.perPage, current: this.state.page, total: this.state.total }}
          />
        </>
      </div>
    );
  }
}
