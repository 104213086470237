export const ActionTypes = {
  GET_RICH_MENU_DETAIL_START: 'GET_RICH_MENU_DETAIL_START',
  GET_RICH_MENU_DETAIL_SUCCESS: 'GET_RICH_MENU_DETAIL_SUCCESS',
  GET_RICH_MENU_DETAIL_ERROR: 'GET_RICH_MENU_DETAIL_ERROR',
  GET_LIST_RICH_MENU_START: 'GET_LIST_RICH_MENU_START',
  GET_LIST_RICH_MENU_SUCCESS: 'GET_LIST_RICH_MENU_SUCCESS',
  GET_LIST_RICH_MENU_ERROR: 'GET_LIST_RICH_MENU_ERROR',
  PUSH_FILE_TO_S3_START: 'PUSH_FILE_TO_S3_START',
  PUSH_FILE_TO_S3_SUCCESS: 'PUSH_FILE_TO_S3_SUCCESS',
  PUSH_FILE_TO_S3_ERROR: 'PUSH_FILE_TO_S3_ERROR',
  GET_URL_UPLOAD_TO_S3_START: 'GET_URL_UPLOAD_TO_S3_START',
  GET_URL_UPLOAD_TO_S3_SUCCESS: 'GET_URL_UPLOAD_TO_S3_SUCCESS',
  GET_URL_UPLOAD_TO_S3_ERROR: 'GET_URL_UPLOAD_TO_S3_ERROR',
  PUT_RICH_MENU_DETAIL_START: 'PUT_RICH_MENU_DETAIL_START',
  PUT_RICH_MENU_DETAIL_SUCCESS: 'PUT_RICH_MENU_DETAIL_SUCCESS',
  PUT_RICH_MENU_DETAIL_ERROR: 'PUT_RICH_MENU_DETAIL_ERROR',
  POST_RICH_MENU_DETAIL_START: 'POST_RICH_MENU_DETAIL_START',
  POST_RICH_MENU_DETAIL_SUCCESS: 'POST_RICH_MENU_DETAIL_SUCCESS',
  POST_RICH_MENU_DETAIL_ERROR: 'POST_RICH_MENU_DETAIL_ERROR',
  DELETE_RICH_MENU_DETAIL_START: 'DELETE_RICH_MENU_DETAIL_START',
  DELETE_RICH_MENU_DETAIL_SUCCESS: 'DELETE_RICH_MENU_DETAIL_SUCCESS',
  DELETE_RICH_MENU_DETAIL_ERROR: 'DELETE_RICH_MENU_DETAIL_ERROR',
  GET_ACTIVE_RICH_MENU_START: 'GET_ACTIVE_RICH_MENU_START',
  GET_ACTIVE_RICH_MENU_SUCCESS: 'GET_ACTIVE_RICH_MENU_SUCCESS',
  GET_ACTIVE_RICH_MENU_ERROR: 'GET_ACTIVE_RICH_MENU_ERROR',
  RESET_TYPE_OF_ACTION: 'RESET_TYPE_OF_ACTION',
};

export const actions = {
  getRichMenuDetailStart: function (params) {
    return {
      type: ActionTypes.GET_RICH_MENU_DETAIL_START,
      params,
    };
  },
  getRichMenuDetailSuccess: function (data) {
    return {
      type: ActionTypes.GET_RICH_MENU_DETAIL_SUCCESS,
      payload: data,
    };
  },
  getRichMenuDetailError: function (error) {
    return {
      type: ActionTypes.GET_RICH_MENU_DETAIL_ERROR,
      payload: error,
    };
  },
  getListRichMenuStart: function (params) {
    return {
      type: ActionTypes.GET_LIST_RICH_MENU_START,
      params,
    };
  },
  getListRichMenuSuccess: function (data) {
    return {
      type: ActionTypes.GET_LIST_RICH_MENU_SUCCESS,
      payload: data,
    };
  },
  getListRichMenuError: function (error) {
    return {
      type: ActionTypes.GET_LIST_RICH_MENU_ERROR,
      payload: error,
    };
  },
  pushFileToS3Start: function (params) {
    return {
      type: ActionTypes.PUSH_FILE_TO_S3_START,
      params,
    };
  },
  pushFileToS3Success: function (data) {
    return {
      type: ActionTypes.PUSH_FILE_TO_S3_SUCCESS,
      payload: data,
    };
  },
  pushFileToS3Error: function (error) {
    return {
      type: ActionTypes.PUSH_FILE_TO_S3_ERROR,
      payload: error,
    };
  },
  getURLUploadToS3Start: function (params) {
    return {
      type: ActionTypes.GET_URL_UPLOAD_TO_S3_START,
      params,
    };
  },
  getURLUploadToS3Success: function (data) {
    return {
      type: ActionTypes.GET_URL_UPLOAD_TO_S3_SUCCESS,
      payload: data,
    };
  },
  getURLUploadToS3Error: function (error) {
    return {
      type: ActionTypes.GET_URL_UPLOAD_TO_S3_ERROR,
      payload: error,
    };
  },
  putRichMenuDetailStart: function (params) {
    return {
      type: ActionTypes.PUT_RICH_MENU_DETAIL_START,
      params,
    };
  },
  putRichMenuDetailSuccess: function (data) {
    return {
      type: ActionTypes.PUT_RICH_MENU_DETAIL_SUCCESS,
      payload: data,
    };
  },
  putRichMenuDetailError: function (error) {
    return {
      type: ActionTypes.PUT_RICH_MENU_DETAIL_ERROR,
      payload: error,
    };
  },
  postRichMenuDetailStart: function (params) {
    return {
      type: ActionTypes.POST_RICH_MENU_DETAIL_START,
      params,
    };
  },
  postRichMenuDetailSuccess: function (data) {
    return {
      type: ActionTypes.POST_RICH_MENU_DETAIL_SUCCESS,
      payload: data,
    };
  },
  postRichMenuDetailError: function (error) {
    return {
      type: ActionTypes.POST_RICH_MENU_DETAIL_ERROR,
      payload: error,
    };
  },
  deleteRichMenuDetailStart: function (params) {
    return {
      type: ActionTypes.DELETE_RICH_MENU_DETAIL_START,
      params,
    };
  },
  deleteRichMenuDetailSuccess: function (data) {
    return {
      type: ActionTypes.DELETE_RICH_MENU_DETAIL_SUCCESS,
      payload: data,
    };
  },
  deleteRichMenuDetailError: function (error) {
    return {
      type: ActionTypes.DELETE_RICH_MENU_DETAIL_ERROR,
      payload: error,
    };
  },
  getActiveRichMenuStart: function () {
    console.log('dss');
    return {
      type: ActionTypes.GET_ACTIVE_RICH_MENU_START,
    };
  },
  getActiveRichMenuSuccess: function (data) {
    return {
      type: ActionTypes.GET_ACTIVE_RICH_MENU_SUCCESS,
      payload: data,
    };
  },
  getActiveRichMenuError: function (error) {
    return {
      type: ActionTypes.GET_ACTIVE_RICH_MENU_ERROR,
      payload: error,
    };
  },
};
