import { all } from 'redux-saga/effects';

import { watchHome } from '../pages/Home/saga';
import { watchLogin } from '../pages/Login/saga';
import { watchListUser } from '../pages/ListUser/saga';
import { watchUserDetail } from '../pages/ListUser/User/saga';
import { watchTagManagement } from '../pages/TagManagement/saga';
import { watchRichMenuManagement } from '../pages/RichMenuManagement/saga';
import { watchActionManagement } from '../pages/ActionManagement/saga';
import { watchConversionManagement } from '../pages/Conversion/saga';
import { watchMessagePackage } from '../pages/MessagePackage/saga';
import { UploadFileWatch } from '../components/UploadVideoModal/saga';

function* rootSaga() {
  yield all([
    watchHome(),
    watchLogin(),
    watchListUser(),
    watchUserDetail(),
    watchTagManagement(),
    watchRichMenuManagement(),
    watchActionManagement(),
    watchConversionManagement(),
    watchMessagePackage(),
    UploadFileWatch(),
  ]);
}
export default rootSaga;
