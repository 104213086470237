import React, { Fragment, useEffect, useState, useMemo, useCallback } from 'react';
import { Collapse, Input, Button } from 'antd';
import { Field, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import SelectComponent from '../Select';
import { getActionOptions, getListTemplate } from '../../common/AppConst';
import ActionModal from '../SelectionAction';
import { convertTemplateToLayout } from '../../utils';
import MessageError from '../MessageError';

const { Panel } = Collapse;

function SelectAction({ type, onChange, toggleModal, index, t, formikProps, setIndexItem }) {
  if (type === 'TEXT')
    return (
      <div className="rich-menu-item__text">
        <Field
          name={`actions.${index}.data`}
          onChange={onChange}
          showCount
          maxLength={50}
          defaultValue={formikProps.values.actions[index]?.data}
          component={Input.TextArea}
        />
        <div className="rich-menu-item__text__select-action">
          <Button
            type="text"
            className="button button-active"
            onClick={() => {
              toggleModal();
              setIndexItem(index);
            }}
          >
            {t('common.button_choose')}
          </Button>
          <Field
            name={`actions.${index}.response_action_name`}
            disabled
            value={formikProps.values.actions[index]?.response_action_name}
            component={Input}
          />
        </div>
      </div>
    );
  if (type === 'LINK')
    return (
      <div className="rich-menu-item__link">
        <Field
          name={`actions.${index}.data`}
          onChange={onChange}
          value={formikProps.values.actions[index]?.data}
          component={Input}
        />
      </div>
    );
  return null;
}

export default function RichMenuItem({ template, getMenuItem, props }) {
  const { t } = useTranslation();
  const [isOpeningActionModal, setIsOpeningActionModal] = useState(false);
  const [indexItem, setIndexItem] = useState();

  const getActionValues = (item, index, setFieldValue) => (e) => {
    const { value } = e.target;
    setFieldValue(`actions.${index}.data`, value);
  };

  const removeItem = (list, length) => {
    for (let i = 0; i < list.length; i++) {
      if (i + 1 >= length) {
        console.log('remove i', i);
        list.splice(i, 1);
      }
    }
  };

  useEffect(() => {
    const { values, setFieldValue } = props;
    const { image_width, image_height } = values;

    const actions = template.map((item, index) => ({
      ...values.actions[index],
      coordinates_x: convertTemplateToLayout(values.template, image_width, image_height, index).x,
      coordinates_y: convertTemplateToLayout(values.template, image_width, image_height, index).y,
      area_width: convertTemplateToLayout(values.template, image_width, image_height, index).width,
      area_height: convertTemplateToLayout(values.template, image_width, image_height, index).height,
      name: item,
      data: values.actions[index]?.data ? values.actions[index].data.trim() : '',
      response_action_id: values.actions[index]?.response_action_id ? values.actions[index].response_action_id : '',
      response_action_name: values.actions[index]?.response_action_name
        ? values.actions[index].response_action_name
        : '',
      type: values.actions[index]?.type ? values.actions[index].type : 'NONE',
    }));

    if (actions.length >= template.length) {
      actions.length = template.length;
    }

    setFieldValue('actions', actions);

    // removeItem(values.actions, template.length);
  }, [template, props.values.image_height]);

  const toggleModal = useCallback(() => setIsOpeningActionModal(!isOpeningActionModal), [isOpeningActionModal]);

  const handlePanelChange = (item, index, setFieldValue) => (value) => {
    setFieldValue(`actions.${index}.type`, value);
  };

  const memoizedActionModal = useMemo(
    () => (
      <ActionModal
        formikProps={props}
        indexField={indexItem}
        isModalVisible={isOpeningActionModal}
        toggleModal={toggleModal}
      />
    ),
    [isOpeningActionModal]
  );

  return (
    <Fragment>
      <Collapse
        defaultActiveKey={['0']}
        expandIcon={({ isActive }) => (isActive ? <MinusCircleOutlined /> : <PlusCircleOutlined />)}
        className="rich-menu-item"
      >
        {template?.map((item, index) => (
          <Panel header={item} key={index}>
            <Field
              name={`actions.${index}.type`}
              value={props.values?.actions[index]?.type ? props.values.actions[index].type : 'NONE'}
              defaultValue="NONE"
              onChange={handlePanelChange(item, index, props.setFieldValue)}
              options={getActionOptions(t)}
              labelInput={t('create_rich_menu.select_label_input')}
              component={SelectComponent}
            />
            <Field
              name="actionValues"
              type={props.values.actions[index]?.type}
              t={t}
              toggleModal={toggleModal}
              onChange={getActionValues(item, index, props.setFieldValue)}
              index={index}
              formikProps={props}
              component={SelectAction}
              setIndexItem={setIndexItem}
            />
            <MessageError name={`actions.${index}.data`} />
            <MessageError name={`actions.${index}.response_action_name`} />
          </Panel>
        ))}
        {isOpeningActionModal && memoizedActionModal}
      </Collapse>
    </Fragment>
  );
}
