import React, { Fragment } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

function HeaderComponent({ index, title, buttonAction }) {
  const { t } = useTranslation();

  return (
    <div className="header-component">
      <div className="title-content">
        {`${index + 1}. `} {title}
      </div>
      <Button
        type="text"
        className="button button-delete"
        onClick={(e) => {
          buttonAction();
          e.stopPropagation();
        }}
      >
        {t('common.delete')}
      </Button>
    </div>
  );
}

export default HeaderComponent;
