import React, { Fragment, useEffect, useState } from 'react';
import { Modal, Button, Table, Tag, Input, Divider } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { actions as userActions } from '../../ListUser/actions';
import { actions } from '../../ActionManagement/actions';
import { convertSeminarStatus } from '../../../common/AppConst';

const ExecuteActionModal = ({ isModalVisible, toggleModal, actionID, listUser, getListUser, executeAction, type }) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [initialValues, setInitialValues] = useState({
    actionID: '',
    lineUserIDs: [],
  });

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      actionID,
    });
  }, [actionID]);

  useEffect(() => {
    getListUser({ keyword: searchText.trim(), status: '' });
  }, [searchText]);

  const handleSubmit = () => {
    const { actionID, lineUserIDs } = initialValues;
    executeAction({
      action_id: actionID,
      line_user_ids: lineUserIDs,
    });
  };

  const columns = [
    {
      title: t('action_management.modal_execute_column_name'),
      dataIndex: 'name',
      render: (text) => <a>{text}</a>,
      ellipsis: true,
      width: '40%',
    },
    {
      title: t('action_management.modal_execute_column_tag'),
      dataIndex: 'tag',
      render: (tags) =>
        tags?.map((tag, index) =>
          tag ? (
            <Tag key={index} color="geekblue">
              {tag}
            </Tag>
          ) : (
            ''
          )
        ),
      width: '60%',
    },
  ];

  const data = listUser?.data?.elements?.map((user, index) => ({
    key: index,
    name: user.display_name,
    tag: user.tag,
    lineUserIDs: user.line_user_id,
  }));

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const lineUserIDs = selectedRows.map((item) => item.lineUserIDs);
      setInitialValues({ ...initialValues, lineUserIDs });
    },
  };

  return (
    <Modal
      title={t('action_management.modal_execute_title')}
      visible={isModalVisible}
      onCancel={toggleModal}
      className="execute-action-modal"
      width={700}
      closable={false}
      footer={null}
    >
      <Input
        prefix={<SearchOutlined />}
        onChange={(e) => setSearchText(e.target.value.trim())}
        placeholder={t('action_management.modal_execute_placeholder')}
      />
      <Table
        rowSelection={
          isModalVisible && {
            type: 'checkbox',
            ...rowSelection,
          }
        }
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{
          y: 800,
        }}
      />
      <Divider />
      <div className="execute-action-modal__action">
        <Button type="text" className="button button-inactive" onClick={toggleModal}>
          {t('common.button_cancel')}
        </Button>
        <Button
          type="text"
          className="button button-active"
          onClick={handleSubmit}
          disabled={!initialValues.lineUserIDs.length}
        >
          {t('common.button_run')}
        </Button>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  listUser: state.listUserReducer.listUser,
  type: state.actionManagementReducer.type,
});

const mapDispatchToProps = (dispatch) => ({
  getListUser: (params) => dispatch(userActions.getListUserStart(params)),
  executeAction: (payload) => dispatch(actions.postExecuteActionStart(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExecuteActionModal);
