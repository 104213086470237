import { ActionTypes } from './actions';

const DEFAULT_STATE = {
  listUser: [],
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_LIST_USER:
      return {
        ...state,
        listUser: action.params,
      };
    case ActionTypes.GET_LIST_USER_SUCCESS:
      return {
        ...state,
        listUser: action.payload,
      };
    case ActionTypes.GET_LIST_USER_ERROR:
      return {
        ...state,
        listUser: action.payload,
      };
    case ActionTypes.PUT_USER_STATUS:
      return {
        ...state,
        statusUpdate: action.params,
      };
    case ActionTypes.PUT_USER_STATUS_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.PUT_USER_STATUS_ERROR:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.RESET_TYPE_OF_ACTION:
      return {
        ...state,
        type: '',
      };
    default:
      return state;
  }
};
