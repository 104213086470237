import React, { useState, useEffect, Fragment, useMemo, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { Layout, Menu, Divider, Table, Tag, Space, Button, notification, Input, Popconfirm, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { FolderOpenOutlined, PlusCircleOutlined, SearchOutlined, SnippetsOutlined } from '@ant-design/icons';
import moment from 'moment';
import { actions, ActionTypes } from './actions';
import { FORMAT_TIME_DAY } from '../../common/AppConst';
import ConversionModal from './Modal';
import { actions as appActions } from '../../pages/Home/actions';

const { Item } = Menu;
const { Content, Sider } = Layout;

function ConversionManagementPage({
  getConversionFolder,
  conversionFolders,
  getListConversion,
  conversions,
  updateConversionFolder,
  error,
  type,
  createConversionFolder,
  resetType,
  filterConversions,
  getFilterConversions,
  deleteAConversion,
  deleteConversionFolder,
  resetErrorMessage,
}) {
  const { t } = useTranslation();
  const [isCreateConversionModalVisible, setIsCreateConversionModalVisible] = useState(false);
  const [isUpdateConversionModalVisible, setIsUpdateConversionModalVisible] = useState(false);
  const [conversionFolder, setConversionFolder] = useState({});
  const [searchConversion, setSearchConversion] = useState('');
  const [activeItem, setActiveItem] = useState(0);

  useEffect(() => {
    getConversionFolder();
  }, []);

  useEffect(() => {
    if (error) {
      notification.error({ message: error.error_msg });
      resetErrorMessage();
    }
  }, [error]);

  useEffect(() => {
    switch (type) {
      case ActionTypes.GET_LIST_CONVERSION_FOLDER_SUCCESS:
        if (conversionFolders?.length) {
          getListConversion(conversionFolders[activeItem]?.id);
          setConversionFolder(conversionFolders[activeItem]);
        }
        resetType();
        return;
      case ActionTypes.PUT_FOLDER_CONVERSION_SUCCESS:
        getListConversion(conversionFolder.id);
        getConversionFolder();
        toggleUpdateConversionModal();
        resetType();
        return;
      case ActionTypes.POST_FOLDER_CONVERSION_SUCCESS:
        getConversionFolder();
        toggleCreateConversionModal();
        resetType();
        return;
      case ActionTypes.DELETE_A_CONVERSION_SUCCESS:
        getListConversion(conversionFolder.id);
        notification.success({ message: t('message.MSG_04') });
        if (searchConversion) {
          getFilterConversions(searchConversion);
        }
        resetType();
        return;
      case ActionTypes.DELETE_CONVERSION_FOLDER_SUCCESS:
        getConversionFolder();
        resetType();
        notification.success({ message: t('message.MSG_04') });
        return;
      default:
        resetType();
        return;
    }
  }, [type]);

  const toggleCreateConversionModal = useCallback(
    () => setIsCreateConversionModalVisible(!isCreateConversionModalVisible),
    [isCreateConversionModalVisible]
  );
  const toggleUpdateConversionModal = useCallback(
    () => setIsUpdateConversionModalVisible(!isUpdateConversionModalVisible),
    [isUpdateConversionModalVisible]
  );

  const copyScriptURL = (value) => {
    navigator.clipboard.writeText(value);
    notification.success({ message: t('message.MSG_20') });
  };

  const getListConversionByFolder = ({ key }) => {
    getListConversion(conversionFolders[key]?.id);
    setConversionFolder(conversionFolders[key]);
    setActiveItem(key);
  };

  const editFolder = (folder) => (e) => {
    e.stopPropagation();
    toggleUpdateConversionModal();
    setConversionFolder(folder);
  };

  const deleteFolder = (e) => {
    e.stopPropagation();
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchConversion(value.trim());
    getFilterConversions(value.trim());
  };

  const buildScript = (scriptUrl) => {
    if (!scriptUrl) return '';
    return "<script src='" + scriptUrl + "'></script>";
  };

  const columns = [
    {
      title: t('conversion_management.column_conversion_name'),
      dataIndex: 'name',
      key: 'name',
      render: (text, { name, scriptURL }) => (
        <Fragment>
          <div>{name}</div>
          <Input.Search value={buildScript(scriptURL)} enterButton={<SnippetsOutlined />} onSearch={copyScriptURL} />
        </Fragment>
      ),
      width: '25%',
    },
    {
      title: t('conversion_management.column_action'),
      dataIndex: 'actionName',
      key: 'actionName',
      width: '25%',
      align: 'center',
    },
    {
      title: t('conversion_management.column_number_of_people'),
      dataIndex: 'people',
      key: 'people',
      width: '25%',
      render: (people) => <span>{`${people}${t('tag_management.text_people')}`}</span>,
    },
    {
      title: t('conversion_management.column_created_date'),
      dataIndex: 'createdDate',
      key: 'createdDate',
      width: '25%',
      align: 'center',
    },
    {
      title: '',
      key: 'action',
      render: (text, { conversionID }) => (
        <Space size="middle">
          <Button type="text" className="button button-solid" href={`/organizer/conversions/edit/${conversionID}`}>
            {t('common.edit')}
          </Button>
          <Popconfirm
            placement="topRight"
            title={t('common.confirm_delete_message')}
            onConfirm={() => deleteAConversion(conversionID)}
            okText={t('common.yes')}
            cancelText={t('common.no')}
          >
            <Button type="text" className="button button-inactive">
              {t('common.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
      width: '25%',
      align: 'right',
    },
  ];

  const data = (searchConversion ? filterConversions : conversions)?.map((tag, index) => ({
    key: index,
    conversionID: tag.id,
    name: tag.name,
    scriptURL: tag.scriptUrl,
    actionName: tag.action_name,
    people: tag.total_subscribers,
    createdDate: moment(tag.created_at).format(FORMAT_TIME_DAY),
  }));

  const memoizedCreateConversionModal = useMemo(
    () => (
      <ConversionModal
        isModalVisible={isCreateConversionModalVisible}
        toggleModal={toggleCreateConversionModal}
        t={t}
        modalTitle={t('tag_management.modal_title_create_tag')}
        createConversionFolder={createConversionFolder}
        isCreateFolder={true}
      />
    ),
    [isCreateConversionModalVisible]
  );

  const memoizedUpdateConversionModal = useMemo(
    () => (
      <ConversionModal
        isModalVisible={isUpdateConversionModalVisible}
        toggleModal={toggleUpdateConversionModal}
        t={t}
        modalTitle={t('tag_management.modal_title_update_tag')}
        updateConversionFolder={updateConversionFolder}
        conversionFolder={conversionFolder}
      />
    ),
    [isUpdateConversionModalVisible]
  );

  return (
    <div className="conversion">
      <div className="ant-col-22 ant-col-offset-1">
        <h2 className="conversion__title title">{t('conversion_management.title')}</h2>
        <Divider />
        <Layout>
          <Sider width={searchConversion ? 0 : 400} className="site-layout-background conversion__sider">
            <Button
              className="button button-active"
              onClick={toggleCreateConversionModal}
              icon={<PlusCircleOutlined />}
            >
              {t('tag_management.button_new_folder')}
            </Button>
            <Menu
              className="conversion__sider__list"
              mode="inline"
              defaultSelectedKeys={[`${activeItem}`]}
              style={{ borderRight: 0 }}
            >
              {conversionFolders?.map((folder, index) => (
                <Item
                  key={index}
                  icon={<FolderOpenOutlined />}
                  onClick={getListConversionByFolder}
                  className="conversion__sider__list__item"
                >
                  <Tooltip title={folder.name}>
                    <span className="folder-name">{folder.name}</span>
                  </Tooltip>
                  <Space size="middle">
                    <Button type="text" className="button button-solid" onClick={editFolder(folder)}>
                      {t('common.edit')}
                    </Button>
                    <Popconfirm
                      placement="topRight"
                      title={t('common.confirm_delete_message')}
                      onConfirm={() => deleteConversionFolder(folder?.id)}
                      okText={t('common.yes')}
                      cancelText={t('common.no')}
                    >
                      <Button type="text" className="button button-inactive" onClick={deleteFolder}>
                        {t('common.delete')}
                      </Button>
                    </Popconfirm>
                  </Space>
                </Item>
              ))}
            </Menu>
          </Sider>
          <Content
            className="site-layout-background conversion__content"
            style={{
              padding: 24,
              margin: 0,
            }}
          >
            <div className="conversion__content__action">
              <Button
                className="button button-active"
                href="/organizer/conversions/create"
                icon={<PlusCircleOutlined />}
              >
                {t('conversion_management.button_new_conversion')}
              </Button>
              <Input
                className="conversion__content__action__input"
                suffix={<SearchOutlined />}
                onChange={handleSearch}
                placeholder={t('conversion_management.placeholder_input_search')}
              />
            </div>
            <Table
              columns={columns}
              dataSource={data}
              pagination={{ pageSize: 10, showSizeChanger: false }}
              className="conversion__content__table"
            />
          </Content>
        </Layout>
      </div>
      {memoizedCreateConversionModal}
      {memoizedUpdateConversionModal}
    </div>
  );
}

const mapStateToProps = (state) => ({
  conversionFolders: state.conversionManagementReducer.conversionFolders?.folders,
  conversions: state.conversionManagementReducer.conversions,
  type: state.conversionManagementReducer.type,
  error: state.conversionManagementReducer.error,
  filterConversions: state.conversionManagementReducer.filterConversions,
});

const mapDispatchToProps = (dispatch) => ({
  getConversionFolder: () => dispatch(actions.getListConversionFolderStart()),
  getListConversion: (folderId) => dispatch(actions.getListConversionStart(folderId)),
  updateConversionFolder: (payload) => dispatch(actions.putFolderConversionStart(payload)),
  createConversionFolder: (payload) => dispatch(actions.postFolderConversionStart(payload)),
  getFilterConversions: (keyword) => dispatch(actions.getFilterConversionStart(keyword)),
  deleteAConversion: (conversionID) => dispatch(actions.deleteAConversionStart(conversionID)),
  deleteConversionFolder: (folderID) => dispatch(actions.deleteConversionFolderStart(folderID)),
  resetType: () => dispatch(appActions.resetTypeOfAction()),
  resetErrorMessage: () => dispatch(appActions.resetErrorMessage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConversionManagementPage);
