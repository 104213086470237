import moment from 'moment';
import template1 from '../resources/assets/template-rich-menu-1.png';
import template2 from '../resources/assets/template-rich-menu-2.png';
import template3 from '../resources/assets/template-rich-menu-3.png';
import template4 from '../resources/assets/template-rich-menu-4.png';
import template5 from '../resources/assets/template-rich-menu-5.png';
import template6 from '../resources/assets/template-rich-menu-6.png';
import template7 from '../resources/assets/template-rich-menu-7.png';
import template8 from '../resources/assets/template-rich-menu-8.png';
import template9 from '../resources/assets/template-rich-menu-9.png';
import template10 from '../resources/assets/template-rich-menu-10.png';
import template11 from '../resources/assets/template-rich-menu-11.png';
import template12 from '../resources/assets/template-rich-menu-12.png';
import { FORMAT_TIME_DAY, REGEX_NUMBER } from '../common/AppConst';
import DEFAULT_AVATAR from '../resources/assets/default-avatar.png';

export function avatar(avatarPath) {
  if (avatarPath == null) return DEFAULT_AVATAR;
  if (avatarPath.trim().length === 0) return DEFAULT_AVATAR;
  return avatarPath;
}
export function getImageSize(element) {
  return {
    width: element?.naturalWidth,
    height: element?.naturalHeight,
  };
}

export function getRichMenuTemplate(index) {
  const listRichMenuTemplate = [
    template1,
    template2,
    template3,
    template4,
    template5,
    template6,
    template7,
    template8,
    template9,
    template10,
    template11,
    template12,
  ];
  return listRichMenuTemplate[index];
}

export function validateSizeImageBeforeUpload(file, width, height) {
  const _URL = window.URL || window.webkitURL;
  let img;
  if (file) {
    img = new Image();
    const objectUrl = _URL.createObjectURL(file);
    return new Promise((resolve, reject) => {
      img.onload = () => resolve({ width: img.width, height: img.height });
    });
  }
}

/**
 *
 * @param {width} width of rectangle
 * @param {height} height of rectangle
 * @param {row} row of layout
 * @param {column} column of layout
 * @param  {...block} block [optional] number of block
 */
const getPositionImage = (width, height, row, column, template) => {
  let arr = [];
  const widthOneBlock = width / column;
  const heightOneBlock = height / row;

  if (!template) {
    for (let i = 0; i < row; i++) {
      for (let j = 0; j < column; j++) {
        arr.push(
          new Object({
            x: widthOneBlock * j,
            y: heightOneBlock * i,
            width: widthOneBlock,
            height: heightOneBlock,
          })
        );
      }
    }
  } else {
    if (template === 3) {
      arr.push({
        x: 0,
        y: 0,
        width: widthOneBlock * 3,
        height: heightOneBlock,
      });
      arr.push({
        x: 0,
        y: heightOneBlock,
        width: widthOneBlock,
        height: heightOneBlock,
      });
      arr.push({
        x: widthOneBlock,
        y: heightOneBlock,
        width: widthOneBlock,
        height: heightOneBlock,
      });
      arr.push({
        x: widthOneBlock * 2,
        y: heightOneBlock,
        width: widthOneBlock,
        height: heightOneBlock,
      });
    }

    if (template === 4) {
      arr.push({
        x: 0,
        y: 0,
        width: widthOneBlock * 2,
        height: heightOneBlock * 2,
      });
      arr.push({
        x: widthOneBlock * 2,
        y: 0,
        width: widthOneBlock,
        height: heightOneBlock,
      });
      arr.push({
        x: widthOneBlock * 2,
        y: heightOneBlock,
        width: widthOneBlock,
        height: heightOneBlock,
      });
    }

    if (template === 10) {
      arr.push({
        x: 0,
        y: 0,
        width: widthOneBlock * 2,
        height: heightOneBlock,
      });
      arr.push({
        x: widthOneBlock * 2,
        y: 0,
        width: widthOneBlock,
        height: heightOneBlock,
      });
    }

    if (template === 11) {
      arr.push({
        x: 0,
        y: 0,
        width: widthOneBlock,
        height: heightOneBlock,
      });
      arr.push({
        x: widthOneBlock,
        y: 0,
        width: widthOneBlock * 2,
        height: heightOneBlock,
      });
    }
  }
  return arr;
};

export const convertTemplateToLayout = (template, width, height, index) => {
  switch (template) {
    case 0:
      return getPositionImage(width, height, 2, 3)[index];
    case 1:
      return getPositionImage(width, height, 2, 2)[index];
    case 2:
      return getPositionImage(width, height, 2, 3, 3)[index];
    case 3:
      return getPositionImage(width, height, 2, 3, 4)[index];
    case 4:
      return getPositionImage(width, height, 2, 1)[index];
    case 5:
      return getPositionImage(width, height, 1, 2)[index];
    case 6:
      return getPositionImage(width, height, 1, 1)[index];
    case 7:
      return getPositionImage(width, height, 1, 3)[index];
    case 8:
      return getPositionImage(width, height, 1, 2)[index];
    case 9:
      return getPositionImage(width, height, 1, 3, 10)[index];
    case 10:
      return getPositionImage(width, height, 1, 3, 11)[index];
    case 11:
      return getPositionImage(width, height, 1, 1)[index];
    default:
      return;
  }
};

export function preventPressSpace(event) {
  if (event.which === 32) {
    return event.preventDefault();
  }
}

export function removeItemOnce(arr, value) {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

export const getFileExtension = (fileType) => {
  if (fileType.includes('/')) {
    return fileType.split('/')[1];
  }
  return fileType;
};

export const checkDimension = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.originFileObj);
    reader.onload = async (e) => resolve(await addImageProcess(e.target.result));
    reader.onerror = reject;
  });
};

export const addImageProcess = (src) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = src;
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = reject;
  });
};

export const mergeDateAndTimeToTimestamp = (day, time) => {
  if (!day && !time) {
    return '';
  } else if (day && !time) {
    return Number.parseInt(moment(day).format('x'));
  } else if (!day && time) {
    return Number.parseInt(moment(time).format('x'));
  }
  const getDay = moment(day).format(FORMAT_TIME_DAY);
  const getTime = moment(time).format('HH:mm:ss');
  const timestamp = moment(getDay + ' ' + getTime).format('x');
  return Number.parseInt(timestamp);
};

export function getFileName(fileUrl) {
  const newArray = fileUrl && fileUrl.split('/');
  return fileUrl && decodeURIComponent(newArray[newArray.length - 1]);
}

export const onlyPressNumber = (e) => {
  if (!/[0-9]/.test(e.key)) {
    e.preventDefault();
  }
};

export const validationInput = (value, regex) => {
  return value.replace(regex, '');
};

export const ellipsisText = (text) => {
  let str = text
    .split('')
    .filter((t, index) => index < 45)
    .join('');
  return text.length >= 45 ? str.concat('...') : str;
};

export const getFileNameWithoutExtension = (filename) => {
  return filename.split('.').slice(0, -1).join('.');
};

const random = Math.random;

export function UUID() {
  var nbr,
    randStr = '';
  do {
    randStr += (nbr = random()).toString(16).substr(3, 6);
  } while (randStr.length < 30);
  return (
    randStr.substr(0, 8) +
    '-' +
    randStr.substr(8, 4) +
    '-4' +
    randStr.substr(12, 3) +
    '-' +
    (((nbr * 4) | 0) + 8).toString(16) + // [89ab]
    randStr.substr(15, 3) +
    '-' +
    randStr.substr(18, 12)
  );
}

export function handleScrollToFirstError(isSubmitting, selector = '.message-error') {
  const nodeError = document.querySelector(selector);

  if (isSubmitting && nodeError) {
    nodeError.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
