import React, { Component } from "react";
import { api } from "../../../services/api";
import { ApiPath } from "../../../common/ApiPath";
import { ResponseCode } from "../../../common/ResponseCode";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { browserHistory } from "../../../browser-history";
import { AppConst } from "../../../common/AppConst";
import { Form, Button, Row, Col, Input, Spin, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import "./index.css";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
class AdminDetail extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isEdit: false,
      detail: null
    };
  }

  componentDidMount() {
    this.getDetailInfo();
  }

  getDetailInfo() {
    api.get(ApiPath.ADMIN_DETAIL_PATH).then(
      (response) => {
        if (response.status === ResponseCode.OK && response.data.meta.success) {
          this.setState({ detail: response.data.data, loading: false });
        } else {
          notification.error({message: response?.data?.meta?.error_data?.error_msg, description: "" });
          this.setState({loading: false });
        }
      },
      (error) => {
        notification.error({message: AppConst.INTERNAL_SERVER_ERROR, description: error });
        this.setState({loading: false });
      }
    );
  }

  onFinish = () => {
    this.formRef.current.validateFields().then(async (values) => {
      const userId = this.props.match.params.id;
      api.put(ApiPath.ADMIN_UPDATE_PATH, values).then(
        (response) => {
          if (response.status === ResponseCode.OK && response.data.meta.success) {
            if (userId) {
              notification.success({message: 'message.MSG_03', description: "" });
            } else {
              notification.success({message: 'message.MSG_01', description: "" });
            }
            this.setState({isEdit: false, loading: false});
          } else {
            notification.error({message: response?.data?.meta?.error_data?.error_msg || AppConst.INTERNAL_SERVER_ERROR, description: "" });
            this.setState({loading: false});
            return;
          }
        },
        (error) => {
          notification.error({message: AppConst.INTERNAL_SERVER_ERROR, description: error });
          this.setState({loading: false});
          return;
        }
      );
    })
    .catch(errorInfo => {
      console.log('errorInfo', errorInfo);
    });
  }

  formatInput = (event) => {
    const target = event.target;
    this.formRef.current.setFieldsValue({[target?.id]: target?.value.trim()});
  }

  edit = () => {
    this.setState({ isEdit: true});
  }

  save = () => {
    this.onFinish();
  }

  cancel = () => {
    browserHistory.push(AppConst.ROUTE.ORGANIZER_MANAGEMENTS);
    window.location = AppConst.ROUTE.ORGANIZER_MANAGEMENTS;
  }

  render() {
    const { t } = this.props;
    const { detail, isEdit, loading } = this.state;
    if (!detail) return <div></div>;
    return (
      <Spin indicator={antIcon} spinning={loading}>
        <div className="container">
          <h2 className="text-center">{t("organizer_detail.page_title")}</h2>
          <hr></hr>
          <>
            <div className="steps-content">
              <Form
                ref={this.formRef}
                onFinish={this.onFinish}
              >
                <Row className="row-content">
                  <Col span={4}></Col>
                  <Col className="row-title-content" span={4}>
                    <span className="label-style-common">{t('organizer_detail.detail_title.account')}</span>
                  </Col>
                  <Col span={8}>
                    <Input value={detail?.account} defaultValue={detail?.account} disabled={true}/>
                  </Col>
                  <Col span={8}></Col>
                </Row>
                <Row>
                  <Col span={4}></Col>
                  <Col className="row-title-content" span={4}>
                    <span className="label-style-common">{t('organizer_detail.detail_title.password')}</span>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="password"
                      initialValue=""
                    >
                      <Input.Password onBlur={this.formatInput} disabled={!isEdit}/>
                    </Form.Item>
                  </Col>
                  <Col span={8}></Col>
                </Row>
                <Row>
                  <Col span={4}></Col>
                  <Col className="row-title-content" span={4}>
                    <span className="label-style-common">{t('organizer_detail.detail_title.email')}</span>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="email"
                      initialValue={detail?.email}
                      rules={[{ required: true, message: t('organizer_update.create_form.email.validation.required') }]}
                    >
                      <Input onBlur={this.formatInput} disabled={!isEdit}/>
                    </Form.Item>
                  </Col>
                  <Col span={8}></Col>
                </Row>
              </Form>
            </div>
            <div className="steps-action">
              {isEdit && (
                <Button className="style-button button-style-common" onClick={this.save}>
                  {t('organizer_detail.save_btn_label')}
                </Button>
              )}
              {!isEdit && (
                <Button className="style-button button-style-common" onClick={this.edit}>
                  {t('organizer_detail.edit_btn_label')}
                </Button>
              )}
              <Button className="button-style-common" onClick={this.cancel}>
                {t('organizer_detail.go_to_list_btn_label')}
              </Button>
            </div>
          </>
        </div>
      </Spin>
    );
  }
}

const mstp = (state) => ({
  loginReducer: state.loginReducer,
});
const mdtp = (dispatch) => ({});

export default connect(mstp, mdtp)(withTranslation()(AdminDetail));
