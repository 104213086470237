import { ActionTypes } from './actions';

const DEFAULT_STATE = {
  user: [],
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };
    case ActionTypes.PUT_USER_INFORMATION_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.PUT_USER_INFORMATION_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.RESET_TYPE_OF_ACTION:
      return {
        ...state,
        type: '',
      };
    default:
      return state;
  }
};
