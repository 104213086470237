import { put, takeLatest } from 'redux-saga/effects';
import { actions, ActionTypes } from './actions';
import {
  getListRichMenuAPI,
  getRichMenuDetailAPI,
  putRichMenuAPI,
  getURLS3API,
  uploadFileToS3API,
  postRichMenuAPI,
  deleteRichMenuAPI,
  getActiveRichMenuAPI,
} from '../../services/RichMenuRequest';
import { RESPONSE_STATUS, HTTP_RESPONSE } from '../../common/ResponseCode';
import { actions as appActions } from '../Home/actions';

function* getListRichMenu({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield getListRichMenuAPI(params);
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getListRichMenuSuccess(response.data));
    } else {
      yield put(actions.getListRichMenuError(response));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.getListRichMenuError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* getRichMenuDetail({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield getRichMenuDetailAPI(params);
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getRichMenuDetailSuccess(response.data));
    } else {
      yield put(actions.getRichMenuDetailError(response));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.getRichMenuDetailError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* pushFileToS3({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield uploadFileToS3API(params);
    if (response.status === HTTP_RESPONSE.OK) {
      yield put(actions.pushFileToS3Success(response));
    } else {
      yield put(actions.pushFileToS3Error(response));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.pushFileToS3Error(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* getURLUploadToS3({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield getURLS3API(params);
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getURLUploadToS3Success(response));
    } else {
      yield put(actions.getURLUploadToS3Error(response));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.getURLUploadToS3Error(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* putRichMenuDetail({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield putRichMenuAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.putRichMenuDetailSuccess(response.data));
    } else {
      yield put(actions.putRichMenuDetailError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.putRichMenuDetailError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* postRichMenuDetail({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield postRichMenuAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.postRichMenuDetailSuccess(response.data));
    } else {
      yield put(actions.postRichMenuDetailError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.postRichMenuDetailError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* deleteRichMenuDetail({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield deleteRichMenuAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.deleteRichMenuDetailSuccess(response.data));
    } else {
      yield put(actions.deleteRichMenuDetailError(response));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.deleteRichMenuDetailError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* getActiveRichMenu() {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield getActiveRichMenuAPI();
    console.log({ response });
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getActiveRichMenuSuccess(response.data));
    } else {
      yield put(actions.getActiveRichMenuError(response));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.getActiveRichMenuError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

export function* watchRichMenuManagement() {
  yield takeLatest(ActionTypes.GET_RICH_MENU_DETAIL_START, getRichMenuDetail);
  yield takeLatest(ActionTypes.GET_LIST_RICH_MENU_START, getListRichMenu);
  yield takeLatest(ActionTypes.PUSH_FILE_TO_S3_START, pushFileToS3);
  yield takeLatest(ActionTypes.PUT_RICH_MENU_DETAIL_START, putRichMenuDetail);
  yield takeLatest(ActionTypes.GET_URL_UPLOAD_TO_S3_START, getURLUploadToS3);
  yield takeLatest(ActionTypes.POST_RICH_MENU_DETAIL_START, postRichMenuDetail);
  yield takeLatest(ActionTypes.DELETE_RICH_MENU_DETAIL_START, deleteRichMenuDetail);
  yield takeLatest(ActionTypes.GET_ACTIVE_RICH_MENU_START, getActiveRichMenu);
}
