import { put, takeLatest } from 'redux-saga/effects';
import { actions, ActionTypes } from './actions';
import { getDemoApi } from '../../services/AuthRequest';

function* getDemo() {
  try {
    const response = yield getDemoApi();
    if (response) {
      yield put(actions.getDemoSuccess({}));
    } else {
      yield put(actions.getDemoError({}));
    }
  } catch (e) {
    yield put(actions.getDemoError(e));
  }
}

export function* watchHome() {
  yield takeLatest(ActionTypes.GET_DEMO_START, getDemo);
}
