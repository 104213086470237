export const ActionTypes = {
  GET_FILTER_MESSAGE_PACKAGE_START: 'GET_FILTER_MESSAGE_PACKAGE_START',
  GET_FILTER_MESSAGE_PACKAGE_SUCCESS: 'GET_FILTER_MESSAGE_PACKAGE_SUCCESS',
  GET_FILTER_MESSAGE_PACKAGE_ERROR: 'GET_FILTER_MESSAGE_PACKAGE_ERROR',
  POST_NEW_MESSAGE_PACKAGE_START: 'POST_NEW_MESSAGE_PACKAGE_START',
  POST_NEW_MESSAGE_PACKAGE_SUCCESS: 'POST_NEW_MESSAGE_PACKAGE_SUCCESS',
  POST_NEW_MESSAGE_PACKAGE_ERROR: 'POST_NEW_MESSAGE_PACKAGE_ERROR',
  GET_MESSAGE_PACKAGE_DETAIL_START: 'GET_MESSAGE_PACKAGE_DETAIL_START',
  GET_MESSAGE_PACKAGE_DETAIL_SUCCESS: 'GET_MESSAGE_PACKAGE_DETAIL_SUCCESS',
  GET_MESSAGE_PACKAGE_DETAIL_ERROR: 'GET_MESSAGE_PACKAGE_DETAIL_ERROR',
  PUT_MESSAGE_PACKAGE_DETAIL_START: 'PUT_MESSAGE_PACKAGE_DETAIL_START',
  PUT_MESSAGE_PACKAGE_DETAIL_SUCCESS: 'PUT_MESSAGE_PACKAGE_DETAIL_SUCCESS',
  PUT_MESSAGE_PACKAGE_DETAIL_ERROR: 'PUT_MESSAGE_PACKAGE_DETAIL_ERROR',
  POST_NEW_MESSAGE_START: 'POST_NEW_MESSAGE_START',
  POST_NEW_MESSAGE_SUCCESS: 'POST_NEW_MESSAGE_SUCCESS',
  POST_NEW_MESSAGE_ERROR: 'POST_NEW_MESSAGE_ERROR',
  GET_LIST_MESSAGE_START: 'GET_LIST_MESSAGE_START',
  GET_LIST_MESSAGE_SUCCESS: 'GET_LIST_MESSAGE_SUCCESS',
  GET_LIST_MESSAGE_ERROR: 'GET_LIST_MESSAGE_ERROR',
  GET_MESSAGE_DETAIL_START: 'GET_MESSAGE_DETAIL_START',
  GET_MESSAGE_DETAIL_SUCCESS: 'GET_MESSAGE_DETAIL_SUCCESS',
  GET_MESSAGE_DETAIL_ERROR: 'GET_MESSAGE_DETAIL_ERROR',
  PUT_MESSAGE_DETAIL_START: 'PUT_MESSAGE_DETAIL_START',
  PUT_MESSAGE_DETAIL_SUCCESS: 'PUT_MESSAGE_DETAIL_SUCCESS',
  PUT_MESSAGE_DETAIL_ERROR: 'PUT_MESSAGE_DETAIL_ERROR',
  POST_EXECUTE_MESSAGE_PACKAGE_START: 'POST_EXECUTE_MESSAGE_PACKAGE_START',
  POST_EXECUTE_MESSAGE_PACKAGE_SUCCESS: 'POST_EXECUTE_MESSAGE_PACKAGE_SUCCESS',
  POST_EXECUTE_MESSAGE_PACKAGE_ERROR: 'POST_EXECUTE_MESSAGE_PACKAGE_ERROR',
  GET_MESSAGE_PACKAGE_SUBSCRIBER_START: 'GET_MESSAGE_PACKAGE_SUBSCRIBER_START',
  GET_MESSAGE_PACKAGE_SUBSCRIBER_SUCCESS: 'GET_MESSAGE_PACKAGE_SUBSCRIBER_SUCCESS',
  GET_MESSAGE_PACKAGE_SUBSCRIBER_ERROR: 'GET_MESSAGE_PACKAGE_SUBSCRIBER_ERROR',
  DELETE_MESSAGE_PACKAGE_START: 'DELETE_MESSAGE_PACKAGE_START',
  DELETE_MESSAGE_PACKAGE_SUCCESS: 'DELETE_MESSAGE_PACKAGE_SUCCESS',
  DELETE_MESSAGE_PACKAGE_ERROR: 'DELETE_MESSAGE_PACKAGE_ERROR',
  DELETE_A_MESSAGE_START: 'DELETE_A_MESSAGE_START',
  DELETE_A_MESSAGE_SUCCESS: 'DELETE_A_MESSAGE_SUCCESS',
  DELETE_A_MESSAGE_ERROR: 'DELETE_A_MESSAGE_ERROR',
  RESET_TYPE_OF_ACTION: 'RESET_TYPE_OF_ACTION',
};

export const actions = {
  getFilterMessagePackageStart: (params) => ({
    type: ActionTypes.GET_FILTER_MESSAGE_PACKAGE_START,
    params,
  }),
  getFilterMessagePackageSuccess: function (data) {
    return {
      type: ActionTypes.GET_FILTER_MESSAGE_PACKAGE_SUCCESS,
      payload: data,
    };
  },
  getFilterMessagePackageError: function (error) {
    return {
      type: ActionTypes.GET_FILTER_MESSAGE_PACKAGE_ERROR,
      payload: error,
    };
  },
  postNewMessagePackageStart: function (params) {
    return {
      type: ActionTypes.POST_NEW_MESSAGE_PACKAGE_START,
      params,
    };
  },
  postNewMessagePackageSuccess: function (data) {
    return {
      type: ActionTypes.POST_NEW_MESSAGE_PACKAGE_SUCCESS,
      payload: data,
    };
  },
  postNewMessagePackageError: function (error) {
    return {
      type: ActionTypes.POST_NEW_MESSAGE_PACKAGE_ERROR,
      payload: error,
    };
  },
  getMessagePackageDetailStart: (params) => ({
    type: ActionTypes.GET_MESSAGE_PACKAGE_DETAIL_START,
    params,
  }),
  getMessagePackageDetailSuccess: function (data) {
    return {
      type: ActionTypes.GET_MESSAGE_PACKAGE_DETAIL_SUCCESS,
      payload: data,
    };
  },
  getMessagePackageDetailError: function (error) {
    return {
      type: ActionTypes.GET_MESSAGE_PACKAGE_DETAIL_ERROR,
      payload: error,
    };
  },
  putMessagePackageDetailStart: function (params) {
    return {
      type: ActionTypes.PUT_MESSAGE_PACKAGE_DETAIL_START,
      params,
    };
  },
  putMessagePackageDetailSuccess: function (data) {
    return {
      type: ActionTypes.PUT_MESSAGE_PACKAGE_DETAIL_SUCCESS,
      payload: data,
    };
  },
  putMessagePackageDetailError: function (error) {
    return {
      type: ActionTypes.PUT_MESSAGE_PACKAGE_DETAIL_ERROR,
      payload: error,
    };
  },
  postNewMessageStart: function (params) {
    return {
      type: ActionTypes.POST_NEW_MESSAGE_START,
      params,
    };
  },
  postNewMessageSuccess: function (data) {
    return {
      type: ActionTypes.POST_NEW_MESSAGE_SUCCESS,
      payload: data,
    };
  },
  postNewMessageError: function (error) {
    return {
      type: ActionTypes.POST_NEW_MESSAGE_ERROR,
      payload: error,
    };
  },
  getListMessageStart: (params) => ({
    type: ActionTypes.GET_LIST_MESSAGE_START,
    params,
  }),
  getListMessageSuccess: function (data) {
    return {
      type: ActionTypes.GET_LIST_MESSAGE_SUCCESS,
      payload: data,
    };
  },
  getListMessageError: function (error) {
    return {
      type: ActionTypes.GET_LIST_MESSAGE_ERROR,
      payload: error,
    };
  },
  getMessageDetailStart: (params) => ({
    type: ActionTypes.GET_MESSAGE_DETAIL_START,
    params,
  }),
  getMessageDetailSuccess: function (data) {
    return {
      type: ActionTypes.GET_MESSAGE_DETAIL_SUCCESS,
      payload: data,
    };
  },
  getMessageDetailError: function (error) {
    return {
      type: ActionTypes.GET_MESSAGE_DETAIL_ERROR,
      payload: error,
    };
  },
  putMessageDetailStart: (params) => ({
    type: ActionTypes.PUT_MESSAGE_DETAIL_START,
    params,
  }),
  putMessageDetailSuccess: function (data) {
    return {
      type: ActionTypes.PUT_MESSAGE_DETAIL_SUCCESS,
      payload: data,
    };
  },
  putMessageDetailError: function (error) {
    return {
      type: ActionTypes.PUT_MESSAGE_DETAIL_ERROR,
      payload: error,
    };
  },
  postExecuteMessagePackageStart: function (params) {
    return {
      type: ActionTypes.POST_EXECUTE_MESSAGE_PACKAGE_START,
      params,
    };
  },
  postExecuteMessagePackageSuccess: function (data) {
    return {
      type: ActionTypes.POST_EXECUTE_MESSAGE_PACKAGE_SUCCESS,
      payload: data,
    };
  },
  postExecuteMessagePackageError: function (error) {
    return {
      type: ActionTypes.POST_EXECUTE_MESSAGE_PACKAGE_ERROR,
      payload: error,
    };
  },
  getMessagePackageSubscriberStart: (params) => {
    return {
      type: ActionTypes.GET_MESSAGE_PACKAGE_SUBSCRIBER_START,
      params,
    };
  },
  getMessagePackageSubscriberSuccess: function (data) {
    return {
      type: ActionTypes.GET_MESSAGE_PACKAGE_SUBSCRIBER_SUCCESS,
      payload: data,
    };
  },
  getMessagePackageSubscriberError: function (error) {
    return {
      type: ActionTypes.GET_MESSAGE_PACKAGE_SUBSCRIBER_ERROR,
      payload: error,
    };
  },
  deleteMessagePackageStart: (params) => {
    return {
      type: ActionTypes.DELETE_MESSAGE_PACKAGE_START,
      params,
    };
  },
  deleteMessagePackageSuccess: function (data) {
    return {
      type: ActionTypes.DELETE_MESSAGE_PACKAGE_SUCCESS,
      payload: data,
    };
  },
  deleteMessagePackageError: function (error) {
    return {
      type: ActionTypes.DELETE_MESSAGE_PACKAGE_ERROR,
      payload: error,
    };
  },
  deleteAMessageStart: (params) => {
    return {
      type: ActionTypes.DELETE_A_MESSAGE_START,
      params,
    };
  },
  deleteAMessageSuccess: function (data) {
    return {
      type: ActionTypes.DELETE_A_MESSAGE_SUCCESS,
      payload: data,
    };
  },
  deleteAMessageError: function (error) {
    return {
      type: ActionTypes.DELETE_A_MESSAGE_ERROR,
      payload: error,
    };
  },
};
