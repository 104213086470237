import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Button, Col, Input, notification, Row, Tabs} from "antd";
import React, {Component} from 'react';
import io from "socket.io-client";
import SecurityService from "../../services/security_service";
import moment from 'moment';
import $ from 'jquery';
import {api} from "../../services/api";
import {SearchOutlined} from "@ant-design/icons";
import {RESPONSE_STATUS} from "../../common/ResponseCode";
import ModalUploadImage from '../../components/ModalUploadImageResizer';
import UploadVideoModal from '../../components/UploadOneVideoModal';
import {avatar} from '../../utils/index'
import uploadPhotoBtn from '../../resources/assets/upload_photo.png'
import uploadVideoBtn from '../../resources/assets/upload_video.png'
import sendBtn from '../../resources/assets/send_btn.png'

const {TabPane} = Tabs;
const {TextArea} = Input;

class PersonalTalk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: null,
      conversations: [],
      first_conversation: null,
      conversation_history: [],
      current_conversation: null,
      isVisibleModalUploadImage: false,
      isVisibleModalUploadVideo: false,
      load_count: 0,
    };
    this.socket = io(process.env.REACT_APP_SOCKET_URL);
    this.sendLineMessage = this.sendLineMessage.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputClick = this.onInputClick.bind(this);
  }

  onInputClick(event) {
    this.markAsRead(this.state.current_conversation);
    this.setState({});
  }

  onInputChange(event) {
    this.markAsRead(this.state.current_conversation);
    this.clearInput();
    this.setState({
      text: event.target.value,
    });
  }

  isNone(value) {
    return value === undefined || value == null || value.trim() === "";
  }

  isChatNoContent() {
    return this.isNone(this.state.text) && this.isNone(this.state.image_url) && this.isNone(this.state.video_url);
  }

  sendLineMessage() {
    if (this.isChatNoContent()) {
      return;
    }
    const data = {
      subscriber_id: this.state.current_conversation?.subscriber_id,
      text: this.state.text != null ? this.state.text.trim() : null,
      image_url: this.state.image_url,
      image_size: this.state.image_size,
      image_link: this.state.image_link,
      video_url: this.state.video_url,
      video_thumbnail_url: this.state.thumbnail_url
    }
    api.post("/organizer/line-chat/create", data).then((response) => {
      if (response.data.meta.success=== RESPONSE_STATUS.SUCCESS) {
        this.socket.emit("line-chat-conversations");
        this.onGetConversationHistory(this.state.current_conversation, true);
      } else {
        notification.error({message: response.data.meta?.error_data?.error_msg});
      }
      this.clearInput();
    }, (err) => {
      console.log(err)
    })
  }

  handleSendMessage = (e) => {    
    if (e.which === 13) {
      e.preventDefault();

      if (e.shiftKey) {
        this.setState({
          text: this.state.text + '\n',
        });
        return;
      }

      this.sendLineMessage();
    }
  }

  onGetConversationHistory = (conversation, triggerByClick) => {
    if (this.state.current_conversation?.subscriber_id !== conversation.subscriber_id) {
      this.clearInput();
    }
    const load_count = this.state.load_count + 1;
    this.setState({
      current_conversation: conversation,
      load_count: load_count
    });
    this.socket.emit("line-chat-conversation-history", {subscriber_id: conversation.subscriber_id});
    if (triggerByClick) {
      this.markAsRead(conversation);
    }
  }

  markAsRead = (conversation) => {
    const _conversation = this.state.conversations.find(c=>c.subscriber_id === conversation.subscriber_id);
    if (_conversation != null && _conversation.total_unread && _conversation.total_unread > 0) {
      this.state.conversations.find(c=>c.subscriber_id === conversation.subscriber_id).total_unread = 0;
      api.post("/organizer/line-chat/" + _conversation.subscriber_id + "/mark-as-read-all").then();
    }
  }

  toggleModalUploadImage = () => {
    this.setState({
      isVisibleModalUploadImage: !this.state.isVisibleModalUploadImage
    })
  };

  toggleModalUploadVideo = () => {
    this.setState({
      isVisibleModalUploadVideo: !this.state.isVisibleModalUploadVideo,
    })
  }

  handleChangeUploadImageModal = (data) => {
    const { imageUrl, imageSize, link } = data;
    this.clearInput();
    if (imageUrl) {
      this.setState({
        image_url: imageUrl,
      });
    }
    if (imageSize) {
      this.setState({
        image_size: imageSize,
      });
    }
    if (link) {
      this.setState({
        image_link: link,
      });
    }
    
    this.setState({
      text: null,
      video_url: null,
      video_thumbnail_url: null
    });

    setTimeout(() => {
      this.sendLineMessage();
    }, 0);
  };

  handleChangeUploadVideo = (data) => {
    const { videoURL, thumbnailURL } = data;
    this.clearInput();
    this.setState({
      video_url: videoURL,
      thumbnail_url: thumbnailURL,
    });

    setTimeout(() => {
      this.sendLineMessage();
    }, 0);
  }

  componentDidMount() {
    this.socket.emit("auth", {token: SecurityService.getToken()});
    this.socket.on("auth", isSuccess => {
      if (isSuccess) {
        // Load all conversations info
        this.socket.emit("line-chat-conversations");
        this.socket.on("line-chat-conversations", (data) => {
          this.setState({conversations: data});
          if (data.length > 0 && this.state.load_count === 0) {
            this.setState({
              first_conversation: data[0],
            })
            this.onGetConversationHistory(data[0], false);
          }
        });

        // When received messages of conversation, update current conversation history
        this.socket.on("line-chat-conversation-history", (data) => {
          this.setState({conversation_history: data});
        });


        // When received new of conversation, update current conversation history
        this.socket.on("new-line-chat-messages-detail", (data) => {
          this.socket.emit("line-chat-conversations");
          if (data?.subscriber_id === this.state.current_conversation?.subscriber_id) {
            this.onGetConversationHistory(this.state.current_conversation, true);
          }
        });
      }
    })
  }

  scrollToBottom = () => {
    let elementBottom = document.querySelector(".chat-frame")
    if (elementBottom) {
      elementBottom.scrollTop = elementBottom.scrollHeight;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.scrollToBottom();
  }

  onKeywordChange = (event) => {
    this.setState({keyword: event.target.value});
  }

  clearInput = () => {
    this.setState({
      text: null, image_url: null,
      image_size: null,
      image_link: null,
      video_url: null,
      video_thumbnail_url: null
    });
  }

  render() {
    return (
        <div style={{width: "100%", height: "742px"}}>
          <div style={{width: "80%", margin: "auto"}}>
            <h2 style={{color: "#3BCC80"}}><b>個別トーク</b></h2>
            <hr/>
          </div>
          <Row className="personal-talk__sider__list" style={{width: "80%", margin: "auto"}}>
            <Col span={6}>
              <div style={{border: "1px solid #E5E5E5", height: "742px", borderRadius: "10px", overflow: "auto"}}>
                <Row style={{padding: "0px"}}>
                  <Input style={{borderRadius: "3px", margin: "20px"}} onChange={(event) => this.onKeywordChange(event)}
                         prefix={<SearchOutlined/>} placeholder="名前"/>

                  <Row className="list-conversations" style={{marginTop: "30px", width: "100%"}}>
                    {this.state.conversations.filter(c => this.state.keyword ? (c.subscriber_name.toLocaleLowerCase().indexOf(this.state.keyword.trim().toLocaleLowerCase()) >= 0) : true)?.map((conversation, index) =>
                        <Row key={index} className="conversation" id={"conversion" + conversation.subscriber_id} style={{
                          width: "100%",
                          padding: "7px 20px 7px 20px",
                          cursor: "pointer",
                          backgroundColor: conversation.subscriber_id === this.state.current_conversation?.subscriber_id ? "rgb(59 204 128 / 0.27)" : ""
                        }} onClick={() => this.onGetConversationHistory(conversation, true)}>
                          <Col span={5}><img src={avatar(conversation.avatar)}
                                             style={{width: "50px", height: "50px", borderRadius: "25px"}}/></Col>
                          <Col span={15}>
                            <div className="personal-talk__overme_100"><b style={{fontSize: "16px"}}>{conversation.subscriber_name}</b></div>
                            <div className="personal-talk__overme">
                              <span style={{fontSize: "13px", color: "#5E5E5E"}}>
                                {conversation.latest_msg_text && conversation.latest_msg_text}
                                {conversation.latest_msg_image_url && '< a photo >'}
                                {conversation.latest_msg_video_url && '< a video >'}
                              </span>
                            </div>
                          </Col>
                          <Col span={4} className="text-right">
                            <div><span
                                style={{fontSize: "13px"}}>{conversation.latest_msg_time ? moment(new Date(conversation.latest_msg_time)).format('MM/DD') : ""}</span>
                            </div>
                            <div hidden={conversation.total_unread === 0} className="text-center" style={{
                              width: "18px",
                              height: "18px",
                              background: "#3BCC80",
                              borderRadius: "9px",
                              color: "white",
                              fontSize: "12px",
                              marginLeft: "auto"
                            }}><span>{conversation.total_unread}</span></div>
                          </Col>
                        </Row>
                    )}
                  </Row>
                </Row>
              </div>
            </Col>
            <Col span={18} style={{paddingLeft: "20px", paddingRight: "20px"}}>
              <div style={{border: "1px solid #E5E5E5", height: "82px", borderRadius: "10px", padding: "15px"}}>
                <Row style={{width: "80%"}}>
                  <Col span={2}><img src={avatar(this.state.current_conversation?.avatar)}
                                     style={{width: "50px", height: "50px", borderRadius: "25px"}}/></Col>
                  <Col span={22} className="personal-talk__overme_100">
                    <b>{this.state.current_conversation?.subscriber_name}</b>
                    <br/>
                    <span style={{fontSize: "13px", color: "#5E5E5E"}}>{this.state.current_conversation?.status_message}</span>
                  </Col>
                </Row>
              </div>
              <div style={{height: "10px "}}/>
              <div className="chat-frame" style={{
                border: "1px solid #E5E5E5",
                height: "567px",
                borderRadius: "10px",
                padding: "15px 15px 15px 5px",
                overflow: "auto"
              }}>
                {this.state.conversation_history?.map((msg, index) =>
                    <div key={index} style={{
                      padding: "4px",
                      marginRight: msg.type === 'IN' ? "200px" : "unset",
                      marginLeft: msg.type === 'OUT' ? "200px" : "unset",
                      display: "flex",
                      flexDirection: msg.type === 'IN' ? 'inherit' : "row-reverse"
                    }}>
                      <img src={msg.type === 'IN' ? avatar(msg.avatar) : avatar(null)}
                           style={{
                             width: "40px",
                             height: "40px",
                             borderRadius: "20px",
                             marginRight: "10px",
                             marginLeft: "10px"
                           }}/>
                      {msg.text && <span style={{
                        borderRadius: "18px",
                        minHeight: "39px",
                        background: msg.type === 'IN' ? "#F1F1F1" : "#3BCC80",
                        padding: "8px 16px 8px 16px",
                        color: msg.type === 'IN' ? "#5E5E5E" : "#FFFFFF",
                        fontSize: "13px",
                        whiteSpace: "pre",
                      }}>{msg.text}</span>}
                      {msg.image_url && <img src={msg.image_url} style={{maxHeight: "200px"}}/>}
                      {msg.video_url && <video
                        width="400px"
                        height="auto"
                        src={msg.video_url}
                        preload="true"
                        poster={msg.video_thumbnail_url}
                        controls
                      >
                        <object data={msg.video_url}>
                          <embed src={msg.video_url}/>
                        </object>
                      </video>}
                    </div>
                )}
              </div>
              <div style={{height: "10px"}}/>
              <div style={{
                border: "1px solid #E5E5E5",
                height: "73px",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                paddingLeft: "15px",
                width: "100%"
              }}>
                <div style={{display: "flex", width: "95%",}}>
                  <div style={{width: "34px", height: "34px", borderRadius: "5px"}}
                       className="text-center" onClick={this.toggleModalUploadImage}>
                    <img src={uploadPhotoBtn}  style={{width: "34px", height: "34px"}} />
                  </div>
                  <div style={{
                    width: "34px",
                    height: "34px",
                    marginLeft: "10px",
                    borderRadius: "5px"
                  }} className="text-center" onClick={this.toggleModalUploadVideo}>
                    <img src={uploadVideoBtn} style={{width: "34px", height: "34px"}}/>
                  </div>
                  <div style={{width: "100%", marginLeft: "10px"}}>
                    <TextArea placeholder="メッセージを入力してください" style={{borderRadius: "5px", height: "39px"}} maxLength={2000} value={this.state.text} onClick={this.onInputClick}
                           onChange={this.onInputChange} autoSize={{ minRows: 2, maxRows: 2 }} onKeyDown={this.handleSendMessage}/>
                  </div>
                  <Button style={{marginLeft: "10px", border: "none", height: "40px", opacity: this.isChatNoContent() ? "0.5" : 1}} onClick={this.sendLineMessage}>
                    <img src={sendBtn} style={{width: "34px", height: "34px"}}/>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <ModalUploadImage
            isVisible={this.state.isVisibleModalUploadImage}
            toggleModal={this.toggleModalUploadImage}
            handleChangeModal={this.handleChangeUploadImageModal}
            imageURLValue=''
            imageSizeValue='giga'
            imageLinkValue=''
            saveLabel={"送信"}
          />
          <UploadVideoModal
            isModalVisible={this.state.isVisibleModalUploadVideo}
            toggleModal={this.toggleModalUploadVideo}
            handleChangeModal={this.handleChangeUploadVideo}
            videoURLValue={this.state.video_url}
            thumbnailURLValue={this.state.thumbnail_url}
            saveLabel={"送信"}
          />
        </div>
    );
  }
}


const mstp = (state) => ({});
const mdtp = (dispatch) => ({});

export default connect(mstp, mdtp)(withTranslation()(PersonalTalk));
