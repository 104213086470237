import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Modal, Button, Space, Input, notification } from 'antd';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import InputComponent from '../../../components/Input';

export default function ConversionModal({
  t,
  modalTitle,
  isModalVisible,
  toggleModal,
  updateConversionFolder,
  conversionFolder,
  createConversionFolder,
  isCreateFolder = false,
}) {
  const ref = useRef(null);
  const [initialValues, setInitialValues] = useState({
    folderName: '',
  });

  const validationSchema = Yup.object().shape({
    folderName: Yup.string()
      .trim()
      .required(t('message.MSG_02', { fieldName: t('tag_management.modal_input_placeholder') })),
  });

  useEffect(() => {
    if (isModalVisible) {
      if (ref != null && ref.current != null) {
        ref.current.resetForm();
      }
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (conversionFolder?.name) {
      setInitialValues({
        folderName: conversionFolder.name,
      });
    }
  }, [conversionFolder]);

  const onSubmitForm = (data) => {
    if (isCreateFolder) {
      createConversionFolder({
        name: data.folderName.trim(),
      });
    } else {
      updateConversionFolder({ folderID: conversionFolder.id, folderName: data.folderName.trim() });
    }
  };

  return (
    <Modal
      title={modalTitle}
      visible={isModalVisible}
      onCancel={toggleModal}
      closable={false}
      footer={null}
      className="modal-tag"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSubmitForm}
        innerRef={ref}
      >
        {({ submitForm, isValid }) => (
          <Fragment>
            <Field
              name="folderName"
              placeholder={t('tag_management.modal_input_placeholder')}
              maxLength={30}
              component={InputComponent}
            />
            <Space size="middle">
              <Button type="text" className="button button-delete" onClick={toggleModal}>
                {t('common.button_close')}
              </Button>
              <Button type="text" className="button button-save" onClick={submitForm} disabled={!isValid}>
                {t('common.button_save')}
              </Button>
            </Space>
          </Fragment>
        )}
      </Formik>
    </Modal>
  );
}
