import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Upload, Input, InputNumber, notification } from 'antd';
import { AppConst } from '../../../../../common/AppConst';
import './index.css';
import { getFileName } from '../../../../../utils';

function Step3({ form, seminar, isEdit, t, handleUploadVideo, somethingChanged }) {
  const [uploadState, setUploadState] = useState(t('seminar_detail.step1.upload'));

  useEffect(() => {
    form.current.resetFields();
  }, [seminar]);

  const beforeUploadVideo = (file, fileList) => {
    if (file.size / 1024 / 1024 / 1024 > 15) {
      notification.error({ message: 'ビデオ容量は15GB以下です。', description: '' });
      return false;
    }
    form.current.setFieldsValue({ video_url_name: file.name });
  };

  const onChangeVideo = (info) => {
    setUploadState(t('seminar_detail.step1.uploading'));
    handleUploadVideo(false);
    somethingChanged();

    if (info.file.status === 'done') {
      setUploadState(t('seminar_detail.step1.upload'));
      handleUploadVideo(true);
      form.current.setFieldsValue({ video_url: info.file.originFileObj, video_url_name: getFileName(info.file.name) });
    } else if (info.file.status === 'error') {
      console.log('error');
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const uploaderProps = {
    name: 'file',
    accept: '.mp4',
    showUploadList: false,
    beforeUpload: beforeUploadVideo,
    customRequest: dummyRequest,
    onChange: onChangeVideo,
    onError(err) {
      console.log('onError', err);
    },
  };

  const formatInput = (event) => {
    const target = event.target;
    form.current.setFieldsValue({ [target?.id]: target?.value.trim() });
  };

  return (
    <div>
      <Row>
        <Col span={2}></Col>
        <Col className="row-title-content" span={4}>
          <span className="label-style-common">{t('seminar_detail.step4.field1')}</span>
        </Col>
        <Col span={14}>
          <Row>
            <Col className="step1-image-title" span={19}>
              <Form.Item name="presenting_access_url" initialValue={seminar?.presenting_access_url}>
                <Input className="step4-disable-input" disabled={true} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={2}></Col>
        <Col span={4}>
          <span className="label-style-common">{t('seminar_detail.step3.label_seminar_video')}</span>
        </Col>
        <Col span={14}>
          <Row>
            <Col className="step1-image-title" span={19}>
              <Form.Item
                name="video_url_name"
                initialValue={getFileName(seminar?.video_url)}
                rules={[{ required: true, message: t('seminar_detail.step4.field1') + t('common.field_required') }]}
              >
                <Input className="step4-disable-input" disabled={true} />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="video_url" initialValue={seminar?.video_url}>
                <Upload className="full-width" {...uploaderProps}>
                  <Button className="full-width step1-photo" disabled={!isEdit || uploadState === AppConst.UPLOADING}>
                    {uploadState}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={2}></Col>
        <Col className="row-title-content" span={4}>
          <span className="label-style-common">{t('seminar_detail.step4.field2')}</span>
        </Col>
        <Col span={11}>
          <Form.Item
            name="conversion_btn_url"
            initialValue={seminar?.conversion_btn_url}
            rules={[{ required: true, message: t('seminar_detail.step4.field2') + t('common.field_required') }]}
          >
            <Input onBlur={formatInput} onChange={somethingChanged} disabled={!isEdit} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={2}></Col>
        <Col className="row-title-content" span={4}>
          <span className="label-style-common">{t('seminar_detail.step4.field3')}</span>
        </Col>
        <Col span={11}>
          <Form.Item
            name="conversion_btn_name"
            initialValue={seminar?.conversion_btn_name}
            rules={[{ required: true, message: t('seminar_detail.step4.field3') + t('common.field_required') }]}
          >
            <Input onBlur={formatInput} onChange={somethingChanged} disabled={!isEdit} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={2}></Col>
        <Col className="row-title-content" span={4}>
          <span className="label-style-common">{t('seminar_detail.step4.field4')}</span>
        </Col>
        <Col span={5}>
          <Row>
            <Form.Item
              name="conversion_btn_time_display"
              initialValue={seminar?.conversion_btn_time_display}
              rules={[{ required: true, message: t('seminar_detail.step4.field4') + t('common.field_required') }]}
            >
              <InputNumber
                className="message-time-diff-input"
                type="number"
                min={0}
                onChange={somethingChanged}
                disabled={!isEdit}
              />
            </Form.Item>
            <span style={{ position: 'absolute', marginLeft: '4.5vw', lineHeight: '2.25' }}>分前</span>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Step3;
