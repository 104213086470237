import React, { useState, useMemo, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Divider, Row, Col, DatePicker, TimePicker, Space, Switch, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';
import InputComponent from '../../../components/Input';
import TemplateModal from './ModalTemplate';
import UploadImageModal from '../../../components/ModalUploadImage';
import RichMenuItemComponent from '../../../components/RichMenuItem';
import { LIST_TEMPLATE } from '../../../common/AppConst';
import { getRichMenuTemplate, mergeDateAndTimeToTimestamp } from '../../../utils';
import { actions, ActionTypes } from '../actions';
import { actions as appActions } from '../../Home/actions';
import DEFAULT_PREVIEW_IMAGE from '../../../resources/assets/default-preview-image.png';

function CreateRichMenuPage({ createNewRichMenu, pushFileToS3, getURLUploadToS3, fileUrl, type, resetType, error }) {
  const { t } = useTranslation();
  const [isVisibleTemplateModal, setIsVisibleTemplateModal] = useState(false);
  const [isVisibleUploadImageModal, setIsVisibleUploadImageModal] = useState(false);
  const [templateMenu, setTemplateMenu] = useState();
  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const [isSubmitForm, setIsSubmitForm] = useState(false);
  const [uploadFile, setUploadFile] = useState({});
  const formRef = useRef(null);
  const { menuID } = useParams();
  let history = useHistory();
  const [richMenuImageDimension, setRichMenuImageDimension] = useState({
    width: 0,
    height: 0,
  });
  const [initialValues, setInitialValues] = useState({
    title: '',
    start_at: null,
    start_at_time: null,
    end_at: null,
    end_at_time: null,
    label: '',
    default_display_status: false,
    template: -1,
    actions: [],
    image_url: '',
    image_width: 0,
    image_height: 0,
    image_local: '',
  });

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .required(t('message.MSG_02', { fieldName: 'タイトル' })),
    label: Yup.string()
      .trim()
      .required(t('message.MSG_02', { fieldName: 'メニューラベル' })),
    start_at: Yup.string()
      .nullable()
      .required(t('message.MSG_02', { fieldName: '期限' })),
    end_at: Yup.string()
      .nullable()
      .required(t('message.MSG_02', { fieldName: '期限' })),
    template: Yup.number().test('template', t('message.MSG_02', { fieldName: 'テンプレート' }), (value) => value != -1),
    image_url: Yup.string()
      .nullable()
      .when('template', {
        is: (value) => value != -1,
        then: Yup.string().required(t('message.MSG_02', { fieldName: '画像' })),
      }),
    actions: Yup.array().of(
      Yup.object().shape(
        {
          data: Yup.string().when(['type', 'response_action_name'], {
            is: (type, response_action_name) => type === 'TEXT' && !response_action_name,
            then: Yup.string().trim().required(t('message.MSG_17')),
            otherwise: Yup.string().when(['type'], {
              is: (type) => type === 'LINK',
              then: Yup.string()
                .trim()
                .required(t('message.MSG_02', { fieldName: 'リンク' })),
            }),
          }),
          response_action_name: Yup.string().when(['type', 'data'], {
            is: (type, data) => type !== 'NONE' && !data,
            otherwise: Yup.string().notRequired(),
            then: Yup.string().trim().required(''),
          }),
        },
        [['data', 'response_action_name']]
      )
    ),
  });

  const onChangeInput = (setFieldValue, fieldName, inputType) => (e, value) => {
    switch (inputType) {
      case 'input':
        return setFieldValue(fieldName, e.target.value);
      case 'select':
        return setFieldValue(fieldName, e);
      case 'switch':
        return setFieldValue(fieldName, e);
      case 'date':
        return setFieldValue(fieldName, e);
      case 'template':
        return setFieldValue(fieldName, e);
      default:
        return setFieldValue(fieldName, e);
    }
  };

  const onSubmitFormRef = () => {
    const {
      start_at,
      start_at_time,
      end_at,
      end_at_time,
      default_display_status,
      label,
      template,
      title,
      actions,
      image_url,
    } = formRef.current.values;

    const body = {
      ...formRef.current.values,
      start_at: mergeDateAndTimeToTimestamp(start_at, start_at_time),
      end_at: mergeDateAndTimeToTimestamp(end_at, end_at_time),
      default_display_status: default_display_status,
      label: label.trim(),
      template: template.toString(),
      title: title.trim(),
      rich_menu_actions: actions,
      image_url: image_url,
      status: 'DRAFT',
      image_width: richMenuImageDimension.width,
      image_height: richMenuImageDimension.height,
    };
    delete body.start_at_time;
    delete body.end_at_time;
    delete body.actions;
    createNewRichMenu({
      body,
    });
  };

  useEffect(() => {
    if (type === ActionTypes.POST_RICH_MENU_DETAIL_SUCCESS) {
      notification.success({ message: t('message.MSG_01') });
      resetType();
      setIsSubmitForm(false);
      history.push('/organizer/rich-menu/');
    }
    if (type === ActionTypes.PUSH_FILE_TO_S3_SUCCESS) {
      if (!saveAsDraft) {
        formRef.current.submitForm();
      } else {
        onSubmitFormRef();
      }
      resetType();
    }
    if (type === ActionTypes.GET_URL_UPLOAD_TO_S3_SUCCESS) {
      setIsSubmitForm(true);
      formRef.current.setFieldValue('image_url', fileUrl?.data?.first);
      resetType();
    }
  }, [type]);

  useEffect(() => {
    const { width, height } = richMenuImageDimension;
    formRef.current.setFieldValue('image_width', width);
    formRef.current.setFieldValue('image_height', height);
  }, [richMenuImageDimension]);

  useEffect(() => {
    if (error) {
      notification.error({ message: error.error_msg });
    }
  }, [error]);

  const clearDeadlineInput = (setFieldValue) => () => {
    setFieldValue('start_at', null);
    setFieldValue('start_at_time', null);
    setFieldValue('end_at', null);
    setFieldValue('end_at_time', null);
  };

  const handleSubmitForm = (data) => {
    console.log('data', data);
    const body = {
      ...data,
      start_at: mergeDateAndTimeToTimestamp(data.start_at, data.start_at_time),
      end_at: mergeDateAndTimeToTimestamp(data.end_at, data.end_at_time),
      default_display_status: data.default_display_status,
      label: data.label.trim(),
      template: data.template.toString(),
      title: data.title.trim(),
      rich_menu_actions: data.actions,
      image_url: data.image_url,
      status: 'EDITED',
      image_width: richMenuImageDimension.width,
      image_height: richMenuImageDimension.height,
    };
    delete body.start_at_time;
    delete body.end_at_time;
    delete body.actions;
    createNewRichMenu({
      body,
    });
  };

  const getMenuItem = (e) => {
    console.log('data', e.target.value);
  };

  const disabledFromDate = (date) => (current) => {
    return (current && current > moment(date)) || current < moment().subtract(1, 'days');
  };

  const disabledToDate = (date) => (current) => {
    return current && current < moment(date);
  };

  const handleSubmitAsSave = () => {
    setSaveAsDraft(false);
    pushFileToS3({
      uploadURL: fileUrl.data.second,
      file: uploadFile.originFileObj,
    });
  };

  const handleSubmitAsDraft = () => {
    setSaveAsDraft(true);
    if (isSubmitForm) {
      pushFileToS3({
        uploadURL: fileUrl.data.second,
        file: uploadFile.originFileObj,
      });
    } else {
      onSubmitFormRef();
    }
  };

  const toggleTemplateModal = () => setIsVisibleTemplateModal(!isVisibleTemplateModal);
  const toggleUploadImageModal = () => setIsVisibleUploadImageModal(!isVisibleUploadImageModal);

  return (
    <div className="create-rich-menu ant-col-22 ant-col-offset-1">
      <h2 className="title">{t('create_rich_menu.title')}</h2>
      <Divider />
      <Formik
        onSubmit={handleSubmitForm}
        initialValues={initialValues}
        innerRef={formRef}
        validationSchema={validationSchema}
        validateOnBlur={true}
        enableReinitialize
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <h4 className="create-rich-menu__subtitle">{t('create_rich_menu.subtitle_display_settings')}</h4>
            <div className="create-rich-menu__display-settings">
              <Row gutter={16}>
                <Col className="gutter-row" span={4}>
                  <span className="create-rich-menu__display-settings__label-field title-content">
                    {t('create_rich_menu.label_title')}
                  </span>
                </Col>
                <Col className="gutter-row" span={8}>
                  <Field
                    type="text"
                    name="title"
                    maxLength={30}
                    placeholder={t('create_rich_menu.label_title')}
                    component={InputComponent}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-row" span={4}>
                  <span className="create-rich-menu__display-settings__label-field title-content">
                    {t('create_rich_menu.label_deadline')}
                  </span>
                </Col>
                <Row className="create-rich-menu__time">
                  <Col className="gutter-row" span={8}>
                    <Space size="middle">
                      <Field
                        name="start_at"
                        value={props.values.start_at ? moment(props.values.start_at) : null}
                        onChange={onChangeInput(props.setFieldValue, 'start_at', 'date')}
                        component={DatePicker}
                        disabledDate={disabledFromDate(props.values.end_at)}
                        format="YYYY-MM-DD"
                        placeholder="YYYY-MM-DD"
                        inputReadOnly
                      />
                      <Field
                        type="text"
                        name="start_at_time"
                        value={props.values.start_at_time ? moment(props.values.start_at_time) : null}
                        onChange={onChangeInput(props.setFieldValue, 'start_at_time', 'date')}
                        component={TimePicker}
                        format="HH:mm"
                        placeholder="hh:mm"
                        inputReadOnly
                      />
                    </Space>
                    {props.errors.start_at && props.touched.start_at && (
                      <div className="message message-error">{props.errors.start_at}</div>
                    )}
                  </Col>
                  <Col className="gutter-row" span={8}>
                    <Space size="middle">
                      <Field
                        name="end_at"
                        value={props.values.end_at ? moment(props.values.end_at) : null}
                        onChange={onChangeInput(props.setFieldValue, 'end_at', 'date')}
                        disabledDate={disabledToDate(props.values.start_at)}
                        component={DatePicker}
                        format="YYYY-MM-DD"
                        placeholder="YYYY-MM-DD"
                        inputReadOnly
                      />
                      <Field
                        name="end_at_time"
                        value={props.values.end_at_time ? moment(props.values.end_at_time) : null}
                        onChange={onChangeInput(props.setFieldValue, 'end_at_time', 'date')}
                        component={TimePicker}
                        format="HH:mm"
                        placeholder="hh:mm"
                        inputReadOnly
                      />
                      <Button className="button button-clear" onClick={clearDeadlineInput(props.setFieldValue)}>
                        {t('common.clear')}
                      </Button>
                    </Space>
                    {props.errors.end_at && props.touched.end_at && (
                      <div className="message message-error">{props.errors.end_at}</div>
                    )}
                  </Col>
                </Row>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-row" span={4}>
                  <span className="create-rich-menu__display-settings__label-field title-content">
                    {t('create_rich_menu.label_menu_label')}
                  </span>
                </Col>
                <Col className="gutter-row" span={8}>
                  <Field
                    type="text"
                    name="label"
                    maxLength={14}
                    placeholder={t('create_rich_menu.label_menu_label')}
                    component={InputComponent}
                  />
                </Col>
              </Row>
              <div className="create-rich-menu__display-settings__always-show">
                <span className="create-rich-menu__display-settings__always-show__content title-content">
                  {t('create_rich_menu.question_alway_display')}
                </span>
                <Field
                  name="default_display_status"
                  onChange={onChangeInput(props.setFieldValue, 'default_display_status', 'switch')}
                  component={Switch}
                />
              </div>
            </div>
            <Divider />
            <h4 className="create-rich-menu__subtitle">{t('create_rich_menu.subtitle_content_settings')}</h4>
            <Divider />
            <div className="create-rich-menu__settings">
              <Row gutter={16} className="row-setting">
                <Col className="gutter-row" span={6}>
                  <Button
                    type="text"
                    className="button button-template"
                    disabled={props.values.image_url}
                    onClick={toggleTemplateModal}
                  >
                    {t('create_rich_menu.button_select_template')}
                  </Button>
                  {props.errors.template && props.touched.template && (
                    <div className="message message-error">{props.errors.template}</div>
                  )}
                  <div className="create-rich-menu__settings__image-preview">
                    <div
                      className="create-rich-menu__settings__image-preview"
                      style={{
                        backgroundImage: `url(${
                          props.values.image_local ? props.values.image_local : DEFAULT_PREVIEW_IMAGE
                        })`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        minHeight: props.values.image_local ? 'unset' : '200px',
                      }}
                    >
                      <img
                        className="create-rich-menu__settings__image-preview__template"
                        src={props.values.template !== -1 ? getRichMenuTemplate(props.values.template) : ''}
                      />
                    </div>
                  </div>
                  <Button
                    className="button button-close"
                    disabled={props.values.template === -1}
                    onClick={toggleUploadImageModal}
                  >
                    {t('create_rich_menu.button_upload_image')}
                  </Button>
                  <UploadImageModal
                    formikProps={props}
                    handleFieldChange={onChangeInput}
                    toggleModal={toggleUploadImageModal}
                    isVisible={isVisibleUploadImageModal}
                    menuID={menuID}
                    getURLUploadToS3={getURLUploadToS3}
                    setUploadFile={setUploadFile}
                    templateMenu={props.values.template}
                    imageDimension={setRichMenuImageDimension}
                  />
                  {props.errors.image_url && props.touched.image_url && (
                    <div className="message message-error">{props.errors.image_url}</div>
                  )}
                </Col>
                <Col className="gutter-row" span={18}>
                  <span className="title-content">{t('rich_menu_management.template_used_activity')}</span>
                  {props.values.template !== -1 && (
                    <RichMenuItemComponent
                      props={props}
                      getMenuItem={getMenuItem}
                      template={LIST_TEMPLATE[props.values.template]}
                    />
                  )}
                </Col>
              </Row>
            </div>
            <Space size="middle" align="center" className="create-rich-menu__action">
              <Button type="text" className="button button-inactive" href="/organizer/rich-menu">
                {t('common.button_close')}
              </Button>
              <Button type="text" className="button button-close" onClick={handleSubmitAsDraft}>
                {t('create_rich_menu.button_draft')}
              </Button>
              <Button
                type="text"
                className="button button-active"
                onClick={isSubmitForm ? handleSubmitAsSave : props.submitForm}
              >
                {t('create_rich_menu.button_save')}
              </Button>
            </Space>
            <TemplateModal
              isVisible={isVisibleTemplateModal}
              toggleModal={toggleTemplateModal}
              handleChange={onChangeInput(formRef?.current?.setFieldValue, 'template', 'template')}
              setTemplateMenu={setTemplateMenu}
              templateMenu={formRef?.current?.values?.template}
              richMenuImageDimension={richMenuImageDimension}
            />
          </form>
        )}
      </Formik>
    </div>
  );
}

const mapStateToProps = (state) => ({
  richMenu: state.richMenuManagementReducer.richMenu,
  fileUrl: state.richMenuManagementReducer.fileUrl,
  type: state.richMenuManagementReducer.type,
  error: state.richMenuManagementReducer.error,
});

const mapDispatchToProps = (dispatch) => ({
  createNewRichMenu: (payload) => dispatch(actions.postRichMenuDetailStart(payload)),
  updateRichMenu: (data) => dispatch(actions.putRichMenuDetailStart(data)),
  pushFileToS3: (file) => dispatch(actions.pushFileToS3Start(file)),
  getURLUploadToS3: (file) => dispatch(actions.getURLUploadToS3Start(file)),
  resetType: () => dispatch(appActions.resetTypeOfAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateRichMenuPage);
