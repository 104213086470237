import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Divider, Row, Col, Space, Button, Table, notification, Modal, Popconfirm } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { Formik, Field } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import * as Yup from 'yup';
import { actions, ActionTypes } from '../../actions';
import InputComponent from '../../../../components/Input';
import SelectComponent from '../../../../components/Select';
import { actions as appActions } from '../../../Home/actions';
import { PlusCircleOutlined } from '@ant-design/icons';
import { getFileName } from '../../../../utils';

const ModalDetail = ({ isModalVisible, toggleModal, messageData }) => {
  return (
    <Modal title="メッセージの詳細" visible={isModalVisible} closable={false} footer={null} onCancel={toggleModal}>
      <ul className="modal-message-detail">
        {messageData?.type?.text ? <li key={1}>{`${messageData.type.text} `}</li> : ''}
        {messageData?.type?.imageUrl ? <li key={2}>{`${messageData.type.imageUrl}`}</li> : ''}
        {messageData?.type?.videoUrl ? <li key={3}>{`${messageData.type.videoUrl}`}</li> : ''}
      </ul>
      <div className="modal-message-detail__button">
        <Button className="button button-active" onClick={toggleModal}>
          閉じる
        </Button>
      </div>
    </Modal>
  );
};

function UpdateMessagePackagePage({
  getMessagePackageDetail,
  updateMessagePackageDetail,
  messagePackage,
  getListMessage,
  listMessage,
  deleteMessage,
  type,
  resetType,
}) {
  const { t } = useTranslation();
  const { messagePackageID } = useParams();
  const [initialValues, setInitialValues] = useState({
    name: '',
    status: 'USING',
  });
  const [messageData, setMessageData] = useState('');
  const [isVisibleModalMessageDetail, setIsVisibleModalMessageDetail] = useState(false);
  let history = useHistory();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(t('message.MSG_02', { fieldName: 'メッセージパッケージ名' })),
  });

  useEffect(() => {
    getMessagePackageDetail(messagePackageID);
    getListMessage(messagePackageID);
  }, []);

  useEffect(() => {
    if (messageData) console.log('message data', messageData);
  }, [messageData]);

  useEffect(() => {
    if (!_.isEmpty(messagePackage)) {
      const { name, status } = messagePackage;
      setInitialValues({
        name,
        status,
      });
    }
  }, [messagePackage]);

  useEffect(() => {
    switch (type) {
      case ActionTypes.PUT_MESSAGE_PACKAGE_DETAIL_SUCCESS:
        history.push(`/organizer/message-packages`);
        notification.success({ message: t('message.MSG_03') });
        return;
      case ActionTypes.DELETE_A_MESSAGE_SUCCESS:
        notification.success({ message: t('message.MSG_04') });
        getListMessage(messagePackageID);
        resetType();
      default:
        return;
    }
  }, [type]);

  const toggleModalMessageDetail = () => setIsVisibleModalMessageDetail(!isVisibleModalMessageDetail);

  const handleInputChange = (setFieldValue, fieldName, inputType = '') => (e) => {
    if (inputType === 'input') {
      setFieldValue(fieldName, e.target.value);
    } else {
      setFieldValue(fieldName, e);
    }
  };

  const handleFormSubmit = (data) => {
    updateMessagePackageDetail({
      id: messagePackageID,
      body: {
        ...data,
        name: data.name.trim(),
      },
    });
  };

  const goToCreateMessagePage = () => {
    history.push(`/organizer/message-packages/message/${messagePackageID}/create`);
  };

  const validationTime = (time) => {
    return time ? time : '';
  };

  const handleDelete = (messagePackageID, messageID) => () => {
    deleteMessage({
      messagePackageID,
      messageID,
    });
  };

  const columns = [
    {
      title: 'No.',
      dataIndex: 'index',
      key: 'index',
      width: '10%',
    },
    {
      title: t('message_package.edit_message_package_column_type'),
      dataIndex: 'type',
      key: 'type',
      render: (message) => (
        <Fragment>
          <div>{!!message.text && t('message_package.edit_message_package_type_text')}</div>
          <div>{!!message.imageUrl && t('message_package.edit_message_package_type_image')}</div>
          <div>{!!message.videoUrl && t('message_package.edit_message_package_type_video')}</div>
        </Fragment>
      ),
      align: 'center',
      width: '10%',
    },
    {
      className: 'max-2-lines',
      title: t('message_package.edit_message_package_column_content'),
      dataIndex: 'content',
      key: 'content',
      render: (item, { index }) => (
        <ul>
          {item.text && <li key={index}>{`${item.text} `}</li>}
          {item.imageUrl && <li key={index}>{`${getFileName(item.imageUrl)}`}</li>}
          {item.videoUrl && <li key={index}>{`${getFileName(item.videoUrl)}`}</li>}
        </ul>
      ),
      align: 'center',
      width: '30%',
    },
    {
      title: t('message_package.edit_message_package_column_time_to_send'),
      key: 'timeToSend',
      dataIndex: 'timeToSend',
      render: (time) => {
        return time.type === 'RELATIVE' ? (
          <div>
            {time.day ? time.day : 0}日と
            {moment()
              .set({ hour: validationTime(time.hour) })
              .format('HH')}
            時間
            {moment()
              .set({ minute: validationTime(time.minute) })
              .format('mm')}
            分後
          </div>
        ) : (
          <div>
            {time.day ? time.day : 0}日後{moment(time.timeToSend).format('HH:mm')}
          </div>
        );
      },
      align: 'center',
      width: '20%',
    },
    {
      title: t('message_package.edit_message_package_column_people'),
      key: 'people',
      dataIndex: 'people',
      render: (text) => <span>{`${text}人`}</span>,
      align: 'center',
      width: '10%',
    },
    {
      title: t('message_package.edit_message_package_column_action'),
      key: 'id',
      render: ({ key }, record) => (
        <Space size="middle">
          <Button
            type="text"
            className="button button-solid"
            onClick={() => {
              toggleModalMessageDetail();
              setMessageData(record);
            }}
          >
            {t('message_package.button_detail')}
          </Button>
          <Button
            type="text"
            className="button button-solid"
            href={`/organizer/message-packages/message/${messagePackageID}/edit/${key}`}
          >
            {t('message_package.button_edit')}
          </Button>
          <Popconfirm
            placement="topRight"
            title={t('common.confirm_delete_message')}
            onConfirm={handleDelete(messagePackageID, key)}
            okText={t('common.yes')}
            cancelText={t('common.no')}
          >
            <Button type="text" className="button button-inactive">
              {t('message_package.button_delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
      align: 'center',
      width: '20%',
    },
  ];

  const dataSource = listMessage?.map((message, index) => ({
    key: message.id,
    index: index + 1,
    type: message.messageData,
    content: message.messageData,
    timeToSend:
      message.sending_time_type === 'RELATIVE'
        ? {
            type: 'RELATIVE',
            day: message.day_diff,
            hour: message.hour_diff,
            minute: message.minute_diff,
          }
        : {
            type: 'ABSOLUTE',
            day: message.day_diff,
            timeToSend: message.time_to_send,
          },
    people: message.total_people_received,
  }));

  return (
    <div className="update-message-package">
      <div className="ant-col-22 ant-col-offset-1">
        <h2 className="update-message-package__title title">{t('message_package.title_edit_message_package')}</h2>
        <Divider />
        <h4 className="update-message-package__subtitle">
          {t('message_package.edit_message_package_subtitle_configure')}
        </h4>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ values, setFieldValue, submitForm }) => (
            <div className="update-message-package__form">
              <Row gutter={16}>
                <Col className="gutter-row" span={4}>
                  <div className="title-content">{t('message_package.edit_message_package_label_name')}</div>
                </Col>
                <Col className="gutter-row" span={8}>
                  <Field
                    name="name"
                    placeholder={t('message_package.edit_message_package_input_placeholder')}
                    inputLength={60}
                    component={InputComponent}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-row" span={4}>
                  <div className="title-content">{t('message_package.edit_message_package_label_status')}</div>
                </Col>
                <Col className="gutter-row" span={8}>
                  <Field
                    name="status"
                    value={values.status}
                    options={[
                      {
                        key: 'DO_NOT_USE',
                        value: t('message_package.status_not_use'),
                      },
                      {
                        key: 'USING',
                        value: t('message_package.status_in_using'),
                      },
                    ]}
                    onChange={handleInputChange(setFieldValue, 'status')}
                    component={SelectComponent}
                  />
                </Col>
              </Row>
              <h4 className="update-message-package__subtitle">
                {t('message_package.edit_message_package_subtitle_configure')}
              </h4>
              <Button
                type="text"
                className="button button-active"
                onClick={goToCreateMessagePage}
                icon={<PlusCircleOutlined />}
              >
                {t('message_package.message_page_create_title')}
              </Button>
              <Table bordered columns={columns} dataSource={dataSource} pagination={{ pageSize: 4 }} />
              <Divider />
              <Space size="middle">
                <Button type="text" className="button button-inactive" href="/organizer/message-packages">
                  {t('common.button_close')}
                </Button>
                <Button type="text" className="button button-active" onClick={submitForm}>
                  {t('common.button_save')}
                </Button>
              </Space>
            </div>
          )}
        </Formik>
      </div>
      <ModalDetail
        isModalVisible={isVisibleModalMessageDetail}
        toggleModal={toggleModalMessageDetail}
        messageData={messageData}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  messagePackage: state.messagePackageReducer.messagePackage,
  listMessage: state.messagePackageReducer.listMessage,
  type: state.messagePackageReducer.type,
});

const mapDispatchToProps = (dispatch) => ({
  getMessagePackageDetail: (messagePackageID) => dispatch(actions.getMessagePackageDetailStart(messagePackageID)),
  updateMessagePackageDetail: (payload) => dispatch(actions.putMessagePackageDetailStart(payload)),
  getListMessage: (id) => dispatch(actions.getListMessageStart(id)),
  deleteMessage: (payload) => dispatch(actions.deleteAMessageStart(payload)),
  resetType: () => dispatch(appActions.resetTypeOfAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateMessagePackagePage);
