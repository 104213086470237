import { type } from 'jquery';
import { ActionTypes } from './actions';

const DEFAULT_STATE = {
  filterMessagePackage: [],
  messagePackage: {},
  listMessage: [],
  listSubscriber: [],
  type: '',
  error: '',
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_FILTER_MESSAGE_PACKAGE_SUCCESS:
      return {
        ...state,
        filterMessagePackage: action.payload,
      };
    case ActionTypes.POST_NEW_MESSAGE_PACKAGE_SUCCESS:
      return {
        ...state,
        messagePackage: action.payload,
        type: action.type,
      };
    case ActionTypes.POST_NEW_MESSAGE_PACKAGE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.GET_MESSAGE_PACKAGE_DETAIL_SUCCESS:
      return {
        ...state,
        messagePackage: action.payload,
      };
    case ActionTypes.PUT_MESSAGE_PACKAGE_DETAIL_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.PUT_MESSAGE_PACKAGE_DETAIL_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.POST_NEW_MESSAGE_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.POST_NEW_MESSAGE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.GET_LIST_MESSAGE_SUCCESS:
      return {
        ...state,
        listMessage: action.payload,
      };
    case ActionTypes.GET_MESSAGE_DETAIL_SUCCESS:
      return {
        ...state,
        messageDetail: action.payload,
      };
    case ActionTypes.PUT_MESSAGE_DETAIL_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.POST_EXECUTE_MESSAGE_PACKAGE_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.POST_EXECUTE_MESSAGE_PACKAGE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.GET_MESSAGE_PACKAGE_SUBSCRIBER_SUCCESS:
      return {
        ...state,
        listSubscriber: action.payload,
      };
    case ActionTypes.DELETE_MESSAGE_PACKAGE_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.DELETE_MESSAGE_PACKAGE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.DELETE_A_MESSAGE_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.DELETE_A_MESSAGE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.RESET_TYPE_OF_ACTION:
      return {
        ...state,
        type: '',
      };
    default:
      return state;
  }
};
