import React, { useEffect } from 'react';
import { Col, Form, Row, Input } from 'antd';
import './index.css';

const { TextArea } = Input;
function Step6({form, seminar, isEdit, t, somethingChanged}) {
  useEffect(() => {
    form.current.resetFields();
  },
    [seminar]
  );

  const formatInput = (event) => {
    const target = event.target;
    form.current.setFieldsValue({[target?.id]: target?.value.trim()});
  }

  return (
    <div>
      <Row>
        <Col className="row-title-content" span={6}>
          <span className="label-style-common">{t('seminar_detail.step6.field1')}</span>
        </Col>
        <Col span={14}>
          <Form.Item
            name="google_analytics"
            initialValue={seminar?.google_analytics}
          >
            <TextArea placeholder={t('seminar_detail.step6.field1')} onBlur={formatInput} onChange={somethingChanged} rows={8} disabled={!isEdit} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col className="row-title-content" span={6}>
          <span className="label-style-common">{t('seminar_detail.step6.field2')}</span>
        </Col>
        <Col span={14}>
          <Form.Item
            name="vp_header_meta"
            initialValue={seminar?.vp_header_meta}
          >
            <TextArea placeholder={t('seminar_detail.step6.field2')} onBlur={formatInput} onChange={somethingChanged} rows={8} disabled={!isEdit} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col className="row-title-content" span={6}>
          <span className="label-style-common">{t('seminar_detail.step6.field3')}</span>
        </Col>
        <Col span={14}>
          <Form.Item
            name="vp_body_meta"
            initialValue={seminar?.vp_body_meta}
          >
            <TextArea placeholder={t('seminar_detail.step6.field3')} onBlur={formatInput} onChange={somethingChanged} rows={8} disabled={!isEdit} />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}

export default Step6;