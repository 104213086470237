import { ActionTypes } from './actions';

const DEFAULT_STATE = {
  tags: [],
  tagFolders: [],
  filterTags: [],
  tagDetail: {},
  type: '',
  error: '',
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_LIST_TAG_SUCCESS:
      return {
        ...state,
        tags: action.payload,
      };
    case ActionTypes.GET_LIST_TAG_FOLDER_SUCCESS:
      return {
        ...state,
        tagFolders: action.payload,
        type: action.type,
      };
    case ActionTypes.PUT_TAG_INFORMATION_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.GET_FILTER_TAG_SUCCESS:
      return {
        ...state,
        filterTags: action.payload,
      };
    case ActionTypes.PUT_FOLDER_TAG_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.PUT_FOLDER_TAG_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.POST_FOLDER_TAG_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.POST_FOLDER_TAG_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.POST_NEW_TAG_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.POST_NEW_TAG_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.PUT_TAG_DETAIL_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.PUT_TAG_DETAIL_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.GET_TAG_DETAIL_SUCCESS:
      return {
        ...state,
        tagDetail: action.payload,
      };
    case ActionTypes.DELETE_A_TAG_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.DELETE_A_TAG_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.DELETE_TAG_FOLDER_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.DELETE_TAG_FOLDER_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.RESET_TYPE_OF_ACTION:
      return {
        ...state,
        type: '',
      };
    case ActionTypes.RESET_ERROR_MESSAGE:
      return {
        ...state,
        error: '',
      };
    default:
      return state;
  }
};
