import React, { Component } from "react";
import { api } from "../../../services/api";
import { ResponseCode } from "../../../common/ResponseCode";
import { OrganizerValidationConst } from "../../../common/validation-const/organizer_validation_const";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { browserHistory } from "../../../browser-history";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { DatePicker, Spin, Button, notification } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { AppConst } from '../../../common/AppConst';
import './index.css';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class OrganizerCreate extends Component {
  constructor(props) {
    super(props);
    this.values = {
      account: "",
      password: "",
      password_confirmation: "",
      email: "",
      expired_date: ""
    };
    this.state = { errorMsg: "", loading: false };
    this.validateForm = this.createOrganizerValidator.bind(this);
  }

  createOrganizerValidator() {
    const { t } = this.props;
    return Yup.object({
      account: Yup.string()
        .required(t("organizer_create.create_form.account.validation.required"))
        .min(
          OrganizerValidationConst.ACCOUNT_MIN,
          t("organizer_create.create_form.account.validation.min", {
            min: OrganizerValidationConst.ACCOUNT_MIN,
          })
        )
        .max(
          OrganizerValidationConst.ACCOUNT_MAX,
          t("organizer_create.create_form.account.validation.max", {
            max: OrganizerValidationConst.ACCOUNT_MAX,
          })
        ),
      password: Yup.string()
        .required(
          t("organizer_create.create_form.password.validation.required")
        )
        .min(
          OrganizerValidationConst.PWD_MIN,
          t("organizer_create.create_form.password.validation.min", {
            min: OrganizerValidationConst.PWD_MIN,
          })
        ),
      password_confirmation: Yup.string().oneOf(
        [Yup.ref("password"), null],
        t("organizer_create.create_form.password_confirmation.validation.match")
      ).required(t("organizer_create.create_form.password_confirmation.validation.required")),
      email: Yup.string()
        .required(t("organizer_create.create_form.email.validation.required"))
        .matches(
          OrganizerValidationConst.EMAIL_REGEX,
          t("organizer_create.create_form.email.validation.match")
        ),
    });
  }

  handleSubmit = (values) => {
    this.createOrganizer(values);
  };

  disabledDate = (current) => {
    return current && current < moment().startOf('days');
  }

  createOrganizerForm = (t) => (
    <Formik
      initialValues={this.values}
      validationSchema={this.validateForm}
      onSubmit={this.handleSubmit}
    >
      {({ errors, touched, handleSubmit, setFieldValue }) => (
        <>
          <table className="table table-borderless">
            <tbody>
              <tr>
                <th className="align-middle">
                  {t("organizer_create.create_form.account.title")}
                </th>
                <td>
                  <Field
                      className="form-control"
                      id="account"
                      type="account"
                      name="account"
                      maxLength="50"
                      autoComplete="new-password"
                      placeholder={t(
                          "organizer_create.create_form.account.placeholder"
                      )}
                  />
                  <div
                      hidden={!(errors.account && touched.account && errors.account)}
                  >
                    <small className="text-danger error-field">
                      {errors.account && touched.account && errors.account}
                    </small>
                  </div>
                </td>
              </tr>
              <tr>
                <th className="align-middle">
                  {t("organizer_create.create_form.password.title")}
                </th>
                <td>
                  <Field
                      className="form-control"
                      id="password"
                      type="password"
                      name="password"
                      maxLength="20"
                      autoComplete="new-password"
                      placeholder={t(
                          "organizer_create.create_form.password.placeholder"
                      )}
                  />
                  <div
                      hidden={!(errors.password && touched.password && errors.password)}
                  >
                    <small className="text-danger error-field">
                      {errors.password && touched.password && errors.password}
                    </small>
                  </div>
                </td>
              </tr>
              <tr>
                <th className="align-middle">
                  {t("organizer_create.create_form.password_confirmation.title")}
                </th>
                <td>
                  <Field
                      className="form-control"
                      id="password_confirmation"
                      type="password"
                      maxLength="20"
                      name="password_confirmation"
                      autoComplete="new-password"
                      placeholder={t(
                          "organizer_create.create_form.password_confirmation.placeholder"
                      )}
                  />
                  <div
                    className="error-field-password-confirm"
                    hidden={
                      !(
                          errors.password_confirmation &&
                          touched.password_confirmation &&
                          errors.password_confirmation
                      )
                    }
                  >
                    <span className="text-danger error-field password-confirm">
                      {errors.password_confirmation &&
                      touched.password_confirmation &&
                      errors.password_confirmation}
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <th className="align-middle">
                  {t("organizer_create.create_form.email.title")}
                </th>
                <td>
                  <Field
                      className="form-control"
                      id="email"
                      type="email"
                      name="email"
                      maxLength="255"
                      autoComplete="new-password"
                      placeholder={t("organizer_create.create_form.email.placeholder")}
                  />

                  <div hidden={!(errors.email && touched.email && errors.email)}>
                    <small className="text-danger error-field">
                      {errors.email && touched.email && errors.email}
                    </small>
                  </div>
                </td>
              </tr>
              <tr>
                <th className="align-middle">
                  {t("organizer_create.create_form.expired_date.title")}
                </th>
                <td>
                  <DatePicker
                    id="expired_date"
                    className="expired-date"
                    name="expired_date"
                    placeholder=""
                    disabledDate={this.disabledDate}
                    onChange={(date, dateString) => setFieldValue('expired_date', dateString)}
                  />
                  {/* <Field
                      className="form-control"
                      id="expired_date"
                      type="date"
                      name="expired_date"
                      min={moment(new Date()).format("yyyy-MM-DD")}
                      autoComplete="new-password"
                  /> */}

                  <div hidden={!(errors.expired_date && touched.expired_date && errors.expired_date)}>
                    <small className="text-danger error-field">
                      {errors.expired_date && touched.expired_date && errors.expired_date}
                    </small>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div
              hidden={this.state.errorMsg === ""}
              className="alert alert-danger"
              role="alert"
          >
            {this.state.errorMsg}
          </div>
          <hr></hr>
          <div className="text-center">
            <Button className="style-button button-style-common" onClick={handleSubmit}>
              {t("organizer_create.create_form.submit_btn_label")}
            </Button>
            {/* <button
              className="btn btn-success create-organizer-btn"
              onClick={handleSubmit}
            >
              {t("organizer_create.create_form.submit_btn_label")}
            </button> */}
            &nbsp;
            <Button className="button-style-common" onClick={browserHistory.goBack}>
              {t("organizer_create.create_form.cancel_btn_label")}
            </Button>
            {/* <button
              className="btn btn-default cancel-btn"
              onClick={browserHistory.goBack}
            >
              {t("organizer_create.create_form.cancel_btn_label")}
            </button> */}
          </div>
        </>
      )}
    </Formik>
  );

  createOrganizer(params) {
    this.setState({loading: true});
    api.post("/admin/organizers/register", params).then(
      (response) => {
        if (response.status === ResponseCode.OK) {
          if (response.data.meta.success) {
            notification.success({message: AppConst.SUCCESS, description: "" });
            this.setState({loading: false});
            browserHistory.push("/admin/organizers");
            window.location.href = "/admin/organizers";
            return;
          }
          this.setState({
            errorMsg: response.data.meta.error_data.error_msg,
            loading: false
          });
        }
      },
      (error) => {
      }
    );
  }

  render() {
    const { loading } = this.state;
    const { t } = this.props;
    return (
      <Spin indicator={antIcon} spinning={loading}>
        <div className="container">
          <h2 className="text-center">{t("organizer_create.page_title")}</h2>
          <hr></hr>
          <div className="col-md-8 offset-md-2">
            {this.createOrganizerForm(t)}
          </div>
        </div>
      </Spin>
    );
  }
}

const mstp = (state) => ({
  loginReducer: state.loginReducer,
});
const mdtp = (dispatch) => ({});

export default connect(mstp, mdtp)(withTranslation()(OrganizerCreate));
