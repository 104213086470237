import React, { Fragment, useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { Input, Row, Col, Divider, Button, Space, Switch, notification } from 'antd';
import { Field, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { SnippetsOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import _ from 'lodash';
import SelectComponent from '../../../components/Select';
import { actions, ActionTypes } from '../actions';
import { getConversionFolders } from '../../../common/AppConst';
import InputComponent from '../../../components/Input';
import ActionSelectionModal from '../SelectionActionModal';
import { actions as appActions } from '../../Home/actions';

function ConversionFormPage({
  getListConversionFolder,
  folders,
  createScriptURL,
  conversionScript,
  type,
  getConversionDetail,
  conversion,
  createNewConversion,
  updateConversion,
  resetType,
  error,
  resetErrorMessage,
}) {
  const { t } = useTranslation();
  const [isVisibleActionModal, setIsVisibleActionModal] = useState(false);
  const [initialValues, setInitialValues] = useState({
    folderID: null,
    conversionID: '',
    name: '',
    actionID: '',
    actionName: '',
    scriptURL: '',
    runFirstOnce: false,
  });
  const formRef = useRef(null);
  let history = useHistory();
  const { conversionID } = useParams();

  const validationShame = Yup.object().shape({
    folderID: Yup.string()
      .nullable()
      .required(t('message.MSG_02', { fieldName: 'フォルダ' })),
    name: Yup.string().required(t('message.MSG_02', { fieldName: 'コンバージョン名' })),
    actionName: Yup.string().required(t('message.MSG_02', { fieldName: 'アクション' })),
    scriptURL: Yup.string().required(t('message.MSG_02', { fieldName: '埋り込みコード' })),
  });

  const handleFieldChange = (setFieldValue, fieldName, inputType) => (value) => {
    if (inputType === 'select' || inputType === 'switch') {
      setFieldValue(fieldName, value);
    }
  };

  const buildScript = (scriptUrl) => {
    if (!scriptUrl) return '';
    return "<script src='" + scriptUrl + "'></script>";
  };

  const handleSubmit = (data) => {
    console.log('data form', data);

    if (conversionID) {
      updateConversion({
        conversionID,
        body: {
          action_id: data.actionID,
          folder_id: data.folderID,
          name: data.name,
          run_first_once: data.runFirstOnce,
        },
      });
    } else {
      createNewConversion({
        action_id: data.actionID,
        folder_id: data.folderID,
        id: data.conversionID,
        name: data.name,
        run_first_once: data.runFirstOnce,
        script_url: data.scriptURL,
      });
    }
  };

  const toggleActionModal = useCallback(() => {
    setIsVisibleActionModal(!isVisibleActionModal);
  }, [isVisibleActionModal]);

  const copyScriptURL = (value) => {
    navigator.clipboard.writeText(value);
    notification.success({ message: t('message.MSG_20') });
  };

  const onCreateScriptURL = () => {
    createScriptURL();
  };

  useEffect(() => {
    getListConversionFolder();
    if (conversionID) {
      getConversionDetail(conversionID);
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(conversion)) {
      setInitialValues({
        ...initialValues,
        folderID: conversion.conversion_folder_id,
        conversionID: conversion.id,
        name: conversion.name,
        actionID: conversion.action_id,
        actionName: conversion.action_name,
        scriptURL: conversion.scriptUrl,
        runFirstOnce: conversion.run_first_once,
      });
    }
  }, [conversion]);

  useEffect(() => {
    switch (type) {
      case ActionTypes.POST_SCRIPT_URL_SUCCESS:
        const { setFieldValue } = formRef.current;
        setFieldValue('conversionID', conversionScript.new_conversion_id);
        setFieldValue('scriptURL', conversionScript.script_url);
        return;
      case ActionTypes.POST_NEW_CONVERSION_SUCCESS:
        notification.success({ message: t('message.MSG_01') });
        history.push('/organizer/conversions');
        resetType();
        return;
      case ActionTypes.GET_CONVERSION_DETAIL_SUCCESS:
        resetType();
        return;
      case ActionTypes.PUT_CONVERSION_DETAIL_SUCCESS:
        notification.success({ message: t('message.MSG_03') });
        history.push('/organizer/conversions');
        resetType();
        return;
      default:
        resetType();
        return;
    }
  }, [type]);

  useEffect(() => {
    if (error) {
      notification.error({ message: error.error_msg });
      resetErrorMessage();
    }
  }, [error]);

  return (
    <div className="conversion-form">
      <div className="ant-col-22 ant-col-offset-1">
        <h2 className="conversion-form__title title">
          {!conversionID
            ? t('conversion_management.title_create_conversion')
            : t('conversion_management.title_edit_conversion')}
        </h2>
        <Divider />
        <h4 className="conversion-form__subtitle">{t('conversion_management.subtitle_config_conversion')}</h4>
        <Formik
          initialValues={initialValues}
          validationSchema={validationShame}
          enableReinitialize
          onSubmit={handleSubmit}
          innerRef={formRef}
          className="conversion-form"
          validateOnMount={true}
        >
          {({ values, setFieldValue, submitForm, errors, touched, isValid }) => (
            <Fragment>
              <Row gutter={16}>
                <Col className="gutter-row" span={4}>
                  <div className="conversion-form__label">{t('conversion_management.label_folder')}</div>
                </Col>
                <Col className="gutter-row" span={6}>
                  <Field
                    name="folderID"
                    className="conversion-form__input"
                    placeholder={t('conversion_management.placeholder_select_folder')}
                    value={values.folderID}
                    options={getConversionFolders(folders)}
                    onChange={handleFieldChange(setFieldValue, 'folderID', 'select')}
                    component={SelectComponent}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-row" span={4}>
                  <div className="conversion-form__label">{t('conversion_management.label_conversion_name')}</div>
                </Col>
                <Col className="gutter-row" span={6}>
                  <Field
                    name="name"
                    className="conversion-form__name"
                    inputLength={30}
                    placeholder={t('conversion_management.placeholder_conversion_name')}
                    component={InputComponent}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-row" span={4}>
                  <div className="conversion-form__label">{t('conversion_management.label_action')}</div>
                </Col>
                <Col className="gutter-row" span={6}>
                  <div className="conversion-form__action">
                    <Field
                      name="actionName"
                      className="conversion-form__action__input"
                      value={values.actionName}
                      disabled
                      component={InputComponent}
                    />
                    <Button type="text" className="button button-solid" onClick={toggleActionModal}>
                      {t('common.button_choose')}
                    </Button>
                    <ActionSelectionModal
                      formikValues={values}
                      formikSetFieldValue={setFieldValue}
                      isModalVisible={isVisibleActionModal}
                      toggleModal={toggleActionModal}
                    />
                  </div>
                  <div className="conversion-form__action__switch">
                    <span>{t('conversion_management.text_run_only_first_time')}</span>
                    <Field
                      name="runFirstOnce"
                      checked={values.runFirstOnce}
                      onChange={handleFieldChange(setFieldValue, 'runFirstOnce', 'switch')}
                      component={Switch}
                    />
                  </div>
                </Col>
              </Row>
              <Button type="text" className="button button-active" onClick={onCreateScriptURL}>
                {t('conversion_management.button_registration')}
              </Button>
              <Row gutter={16}>
                <Col className="gutter-row" span={4}>
                  <div className="conversion-form__label">{t('conversion_management.label_script_url')}</div>
                </Col>
                <Col className="gutter-row" span={6}>
                  <Input.Search
                    className="conversion-form__input"
                    onSearch={copyScriptURL}
                    value={buildScript(values.scriptURL)}
                    enterButton={<SnippetsOutlined />}
                  />
                  {errors.scriptURL && touched.scriptURL && (
                    <span className="message message-error">{errors.scriptURL}</span>
                  )}
                </Col>
              </Row>
              <Divider />
              <Space size="middle">
                <Button type="text" className="button button-inactive" href="/organizer/conversions">
                  {t('common.button_cancel')}
                </Button>
                <Button disabled={!isValid} type="text" className="button button-active" onClick={submitForm}>
                  {t('common.button_save')}
                </Button>
              </Space>
            </Fragment>
          )}
        </Formik>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  folders: state.conversionManagementReducer.conversionFolders?.folders,
  conversionScript: state.conversionManagementReducer.conversionScript,
  conversion: state.conversionManagementReducer.conversionDetail,
  type: state.conversionManagementReducer.type,
  error: state.conversionManagementReducer.error,
});

const mapDispatchToProps = (dispatch) => ({
  getListConversionFolder: () => dispatch(actions.getListConversionFolderStart()),
  createScriptURL: () => dispatch(actions.postScriptURLStart()),
  createNewConversion: (data) => dispatch(actions.postNewConversionStart(data)),
  getConversionDetail: (conversionID) => dispatch(actions.getConversionDetailStart(conversionID)),
  updateConversion: (data) => dispatch(actions.putConversionDetailStart(data)),
  resetType: () => dispatch(appActions.resetTypeOfAction()),
  resetErrorMessage: () => dispatch(appActions.resetErrorMessage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConversionFormPage);
