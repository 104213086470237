import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import {
  Col,
  Form,
  Row,
  Checkbox,
  Input,
  Table,
  Button,
  Upload,
  Select,
  Radio,
  notification,
  Space,
  InputNumber,
  TimePicker,
  Tooltip,
} from 'antd';
import { AppConst } from '../../../../../common/AppConst';
import Popconfirm from 'antd/es/popconfirm';
import UploadImageModal from '../../../../../components/ModalUploadImageResizer';
import UploadVideoModal from '../../../../../components/UploadOneVideoModal';
import './index.css';
import { PlusCircleOutlined } from '@ant-design/icons';
import { getFileName } from '../../../../../utils';
const { TextArea } = Input;
const { Option } = Select;
const acceptVideo = ['video/mp4'];
const acceptImage = ['image/png', 'image/jpg', 'image/jpeg'];
const messageTypeList = [
  { name: 'セミナー選択後メッセージ', value: AppConst.MESSAGE_TYPE_SELECTED },
  { name: 'セミナー開始日前メッセージ', value: AppConst.MESSAGE_TYPE_BEFORE_BEGIN },
  { name: 'セミナー開始当日メッセージ', value: AppConst.MESSAGE_TYPE_BEGIN },
  { name: 'セミナー開始日後メッセージ', value: AppConst.MESSAGE_TYPE_TARGET },
  { name: '見逃し視聴期間最終日メッセージ', value: AppConst.MESSAGE_TYPE_LAST },
];
const messageTypeTargetList = [
  { name: 'セミナーURLにアクセスしていない人', value: AppConst.MESSAGE_TYPE_NOT_ACCESS },
  { name: 'セミナーURLを見たがコンバージョンボタンをクリックしていない人', value: AppConst.MESSAGE_TYPE_NOT_CLICK },
  { name: 'セミナーURLを見てコンバージョンボタンをクリックした人', value: AppConst.MESSAGE_TYPE_CLICKED },
  { name: '全部', value: AppConst.MESSAGE_TYPE_ALL },
];
const irregularDayOption = [];
for (let i = 0; i < 8; i++) {
  irregularDayOption.push(
    <Option key={i} value={i}>
      {i + ' 日後'}
    </Option>
  );
}
const irregularDayOptionBefore = [];
for (let i = 0; i < 8; i++) {
  irregularDayOptionBefore.push(
    <Option key={i} value={i}>
      {i + ' 日前'}
    </Option>
  );
}
const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

function Step4({
  form,
  isEdit,
  t,
  isEditMessage,
  addNewMessage,
  timeToSend,
  deleteMessage,
  seminarMessages,
  somethingChanged,
  onChangeTimeToSend,
  onChangeMessageType,
  messageType,
  detailMessage,
  changeDetailMessage,
}) {
  const [isUploadMessageText, setIsUploadMessageText] = useState(detailMessage?.message_data?.text);
  const [isUploadMessageImage, setIsUploadMessageImage] = useState(detailMessage?.message_data?.imageUrl);
  const [isUploadMessageVideo, setIsUploadMessageVideo] = useState(detailMessage?.message_data?.videoUrl);
  const [timeDiffType, setTimeDiffType] = useState(detailMessage?.time_diff_unit);
  const [isVisibleModalUploadImage, setIsVisibleModalUploadImage] = useState(false);
  const [isVisibleModalUploadVideo, setIsVisibleModalUploadVideo] = useState(false);

  useEffect(() => {
    console.log({ detailMessage });
    setIsUploadMessageText(detailMessage?.message_data?.text);
    setIsUploadMessageImage(detailMessage?.message_data?.imageUrl);
    setIsUploadMessageVideo(detailMessage?.message_data?.videoUrl);
    setTimeDiffType(detailMessage?.time_diff_unit);
    if (Object.values(detailMessage).length === 0) {
      form.current.resetFields();
    }
  }, [
    detailMessage,
    detailMessage?.message_data?.text,
    detailMessage?.message_data?.imageUrl,
    detailMessage?.message_data?.videoUrl,
    detailMessage?.time_diff_unit,
  ]);

  const getMessageType = (type) => {
    const messageType = messageTypeList.find((item) => {
      if (item.value === type) {
        return item;
      }
      if (
        type === AppConst.MESSAGE_TYPE_CLICKED ||
        type === AppConst.MESSAGE_TYPE_NOT_CLICK ||
        type === AppConst.MESSAGE_TYPE_NOT_ACCESS ||
        type === AppConst.MESSAGE_TYPE_ALL
      ) {
        return item.value === AppConst.MESSAGE_TYPE_TARGET;
      }
      return null;
    });
    if (messageType) {
      return messageType.name;
    }
    return '';
  };

  const getTimeDiffUnit = (record) => {
    const messageType = record?.message_type;
    const timeDiff = record?.time_diff;
    const type =
      messageType === AppConst.MESSAGE_TYPE_BEFORE_BEGIN || messageType === AppConst.MESSAGE_TYPE_BEGIN ? '前' : '後';

    if (timeDiff === 0 && messageType === AppConst.MESSAGE_TYPE_BEGIN) {
      return '開始時';
    }

    if (!timeDiff && timeDiff !== 0) {
      return '';
    }

    if (record.time_to_send) {
      const time_to_send = moment(record.time_to_send).format('HH:mm');
      if (
        timeDiff === 0 &&
        (messageType === AppConst.MESSAGE_TYPE_CLICKED ||
          messageType === AppConst.MESSAGE_TYPE_NOT_CLICK ||
          messageType === AppConst.MESSAGE_TYPE_NOT_ACCESS ||
          messageType === AppConst.MESSAGE_TYPE_ALL)
      ) {
        return '開催当日 ' + time_to_send;
      }
      if (messageType === AppConst.MESSAGE_TYPE_LAST) {
        return time_to_send;
      }
      return `${timeDiff}日${type} ${time_to_send}`;
    }

    const unit = record?.time_diff_unit;
    switch (unit) {
      case AppConst.TIME_DIFF_TYPE.DAY:
        return timeDiff + '日' + type;
      case AppConst.TIME_DIFF_TYPE.HOUR:
        return timeDiff + '時' + type;
      case AppConst.TIME_DIFF_TYPE.MINUTE:
        return timeDiff + '分' + type;
      default:
        return timeDiff;
    }
  };

  const deleteSeminarMessage = (id) => {
    deleteMessage(id);
  };

  const getMessageData = (data) => {
    return <div className="text-center">
      <div>{data.text && t('organizer_detail.detail_title.message_text')}</div>
      <div>{data.imageUrl && t('organizer_detail.detail_title.message_image')}</div>
      <div>{data.videoUrl && t('organizer_detail.detail_title.message_video')}</div>
    </div>;
  };

  const getMessageContent = (record) => {
    const messageContent = [];
    if (record?.message_data?.text) {
      messageContent.push(record?.message_data?.text);
    }
    if (record?.message_data?.imageUrl) {
      messageContent.push(record?.message_data?.imageUrl);
    }
    if (record?.message_data?.videoUrl) {
      messageContent.push(record?.message_data?.videoUrl);
    }

    return messageContent.map((item, index) => (
      <div className="tooltip-message-content" key={index}>
        &bull; &nbsp; {item}
      </div>
    ));
  };

  const toggleModalUploadImage = () => {
    setIsVisibleModalUploadImage(!isVisibleModalUploadImage);
  };

  const toggleModalUploadVideo = () => {
    setIsVisibleModalUploadVideo(!isVisibleModalUploadVideo);
  };

  const handleChangeUploadImageModal = (values) => {
    form.current.setFieldsValue({ message_data_photo_name: getFileName(values.imageUrl) });
    form.current.setFieldsValue({ message_data_photo: values.imageUrl });
    form.current.setFieldsValue({ message_data_photo_size: values.imageSize });
    form.current.setFieldsValue({ message_data_photo_target_url: values.link });
  };

  const handleChangeUploadVideo = (values) => {
    form.current.setFieldsValue({ message_data_video: values.videoURL });
    form.current.setFieldsValue({ message_data_video_name: getFileName(values.videoURL) });
    form.current.setFieldsValue({ message_data_video_thumbnail: values.thumbnailURL });
    form.current.setFieldsValue({ message_data_video_thumbnail_name: getFileName(values.thumbnailURL) });
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      render: (text, record, index) => <div>{index + 1}</div>,
      ellipsis: true,
      align: 'left',
    },
    {
      title: 'タイプ',
      className: 'font-size',
      dataIndex: 'message_type',
      key: 'message_type',
      width: '20%',
      render: (text, record, index) => getMessageType(text),
      ellipsis: true,
      align: 'left',
    },
    {
      title: '内容',
      className: 'font-size',
      dataIndex: 'text',
      key: 'text',
      width: '20%',
      render: (text, record, index) => (
        <div>
          <Tooltip
            color="#50d890"
            overlayClassName="tooltip-message-content"
            className="step4-tooltip"
            placement="bottomLeft"
            title={getMessageContent(record)}
          >
            {getMessageContent(record)}
          </Tooltip>
        </div>
      ),
      ellipsis: true,
      align: 'left',
    },
    {
      title: '送信時点',
      className: 'font-size',
      dataIndex: 'time_to_send',
      key: 'time_to_send',
      width: '20%',
      render: (text, record, index) => (
        <div>
          <Tooltip color="#50d890" className="step4-tooltip" placement="bottom" title={getTimeDiffUnit(record)}>
            {getTimeDiffUnit(record)}
          </Tooltip>
        </div>
      ),
      ellipsis: true,
      align: 'left',
    },
    {
      title: '種類',
      className: 'font-size',
      dataIndex: 'message_data',
      key: 'message_data',
      ellipsis: true,
      width: '15%',
      render: (text, record, index) => (
        <div>
          <Tooltip color="#50d890" className="step4-tooltip" placement="bottom" title={getMessageData(text)}>
            {getMessageData(text)}
          </Tooltip>
        </div>
      ),
      align: 'left',
    },
    {
      title: '',
      className: 'font-size',
      key: 'action',
      width: '20%',
      align: 'center',
      render: (text, record) => (
        <Space size="middle" align="center">
          <Button className="step4-detail-button" onClick={() => changeDetailMessage(record)} type="text">
            {t('seminar_detail.step3.edit')}
          </Button>
          <Popconfirm
            placement="topRight"
            title={t('common.confirm_delete_message')}
            onConfirm={() => deleteSeminarMessage(record.id)}
            okText={t('common.yes')}
            cancelText={t('common.no')}
          >
            <Button className="step4-delete-button" type="text">
              {t('seminar_detail.step3.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const changeMessageType = (value) => {
    // let sending_time_type = null;
    // if (value === AppConst.MESSAGE_TYPE_SELECTED) {
    //   sending_time_type = AppConst.UNDEFINED;
    // }
    // if (value === AppConst.MESSAGE_TYPE_BEGIN) {
    //   sending_time_type = AppConst.SENDING_TIME_TYPE.RELATIVE;
    // }
    // form.current.setFieldsValue({sending_time_type});
    form.current.setFieldsValue({ time_to_send: null });
    form.current.setFieldsValue({ time_diff: null });
    form.current.setFieldsValue({ time_diff_unit: null });
    // const timeToSend = value === AppConst.MESSAGE_TYPE_TARGET ? AppConst.SENDING_TIME_TYPE.RELATIVE : null;
    // onChangeTimeToSend(timeToSend);
    onChangeMessageType(value);
    somethingChanged();
  };

  // const changeTimeToSend = (e) => {
  //   const value = e.target.value;
  //   if (value === AppConst.SENDING_TIME_TYPE.ABSOLUTE) {
  //     form.current.setFieldsValue({time_diff: null});
  //     form.current.setFieldsValue({time_diff_unit: null});
  //     setTimeDiffType(null);
  //   }
  //   if (value === AppConst.SENDING_TIME_TYPE.RELATIVE) {
  //     form.current.setFieldsValue({time_to_send: null});
  //     form.current.setFieldsValue({time_diff_unit: AppConst.TIME_DIFF_TYPE.DAY});
  //     setTimeDiffType(AppConst.TIME_DIFF_TYPE.DAY);
  //   }
  //   onChangeTimeToSend(value);
  // }

  const handleChangeCheckbox = (checked, id) => {
    switch (id) {
      case 'isUploadMessageText':
        if (!checked) {
          form.current.setFieldsValue({ message_data_text: '' });
        }
        setIsUploadMessageText(checked);
        break;
      case 'isUploadMessageImage':
        if (!checked) {
          form.current.setFieldsValue({ message_data_photo: null, message_data_photo_name: '' });
        }
        setIsUploadMessageImage(checked);
        break;
      case 'isUploadMessageVideo':
        if (!checked) {
          form.current.setFieldsValue({
            message_data_video: null,
            message_data_video_name: '',
            message_data_video_thumbnail: null,
            message_data_video_thumbnail_name: '',
          });
        }
        setIsUploadMessageVideo(checked);
        break;
      default:
        break;
    }
  };

  const onhangeCheckbox = (event) => {
    const checked = event.target.checked;
    const id = event.target.id;
    handleChangeCheckbox(checked, id);
    somethingChanged();
  };

  const beforeUploadImage = (file, fileList) => {
    const fileSize = file.size / 1024 / 1024;
    if (!acceptImage.includes(file.type) || fileSize >= 10) {
      notification.error({ message: t('common.image_upload_invalid'), description: '' });
      return;
    }
    form.current.setFieldsValue({ message_data_photo_name: file.name });
    somethingChanged();
  };

  const beforeUploadVideo = (file, fileList) => {
    const fileSize = file.size / 1024 / 1024;
    if (!acceptVideo.includes(file.type) || fileSize >= 10) {
      notification.error({ message: t('common.video_upload_invalid'), description: '' });
      return;
    }
    form.current.setFieldsValue({ message_data_video_name: file.name });
    somethingChanged();
  };

  const beforeUploadThumbnail = (file, fileList) => {
    const fileSize = file.size / 1024 / 1024;
    if (!acceptImage.includes(file.type) || fileSize >= 10) {
      notification.error({ message: t('common.image_upload_invalid'), description: '' });
      return;
    }
    form.current.setFieldsValue({ message_data_video_thumbnail_name: file.name });
    somethingChanged();
  };

  const changeTimeDiff = (e) => {
    form.current.setFieldsValue({ time_diff: null });
    setTimeDiffType(e.target.value);
    somethingChanged();
  };

  // const disabledDate = (current) => {
  //   return current && current < moment().startOf('days');
  // }

  const formatInput = (event) => {
    const target = event.target;
    form.current.setFieldsValue({ [target?.id]: target?.value.trim() });
  };

  return (
    <div>
      <Row>
        <div>
          <Button
            onClick={addNewMessage}
            className="step3-add-new-message button button-active"
            icon={<PlusCircleOutlined />}
          >
            {t('seminar_detail.step3.addNew')}
          </Button>
        </div>
      </Row>
      {isEditMessage ? (
        <div>
          <Row>
            <Col span={2}></Col>
            <Col className="row-title-content" span={4}>
              <span className="label-style-common">{t('seminar_detail.step3.field1')}</span>
            </Col>
            <Col span={18}>
              <Form.Item
                name="message_type"
                initialValue={messageType}
                rules={[{ required: true, message: t('seminar_detail.step3.field1') + t('common.field_required') }]}
              >
                <Select onChange={changeMessageType} style={{ width: '20vw' }} disabled={!isEdit}>
                  {messageTypeList.map((item, index) => (
                    <Option key={index + 1} value={item.value}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={2}></Col>
            <Col className="row-title-content" span={4}>
              <span className="label-style-common">{t('seminar_detail.step3.field2')}</span>
            </Col>
            <Col span={14}>
              <Row>
                <Col span={4}>
                  <Form.Item
                    name="isUploadMessageText"
                    initialValue={detailMessage?.message_data?.text}
                    valuePropName="checked"
                  >
                    <Checkbox onChange={onhangeCheckbox} disabled={!isEdit}>
                      {t('organizer_detail.detail_title.message_text')}
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="isUploadMessageImage"
                    initialValue={detailMessage?.message_data?.imageUrl}
                    valuePropName="checked"
                  >
                    <Checkbox onChange={onhangeCheckbox} disabled={!isEdit}>
                      {t('organizer_detail.detail_title.message_image')}
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="isUploadMessageVideo"
                    initialValue={detailMessage?.message_data?.videoUrl}
                    valuePropName="checked"
                  >
                    <Checkbox onChange={onhangeCheckbox} disabled={!isEdit}>
                      {t('organizer_detail.detail_title.message_video')}
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="message_data_text"
                    rules={[
                      { required: isUploadMessageText, message: t('common.message_text') + t('common.field_required') },
                    ]}
                    initialValue={detailMessage?.message_data?.text}
                  >
                    <TextArea
                      onBlur={formatInput}
                      onChange={somethingChanged}
                      rows={4}
                      maxLength={2000}
                      disabled={!(isUploadMessageText && isEdit)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col className="step1-image-title" span={19}>
                  <Form.Item
                    name="message_data_photo_name"
                    initialValue={getFileName(detailMessage?.message_data?.imageUrl)}
                    rules={[
                      {
                        required: isUploadMessageImage,
                        message: t('common.image_upload') + t('common.field_required'),
                      },
                    ]}
                  >
                    <Input placeholder={t('common.image_upload')} disabled={true} value={getFileName(detailMessage?.message_data?.imageUrl)} />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="message_data_photo"
                    initialValue={detailMessage?.message_data?.imageUrl}
                  >
                    <Button
                      className="full-width step1-photo  button button-active"
                      disabled={!(isUploadMessageImage && isEdit)}
                      onClick={toggleModalUploadImage}
                    >
                      {t('seminar_detail.step1.upload')}
                    </Button>
                  </Form.Item>
                  <Form.Item
                    name="message_data_photo_size"
                    initialValue={
                      detailMessage?.message_data?.imageSize ? detailMessage.message_data.imageSize : 'giga'
                    }
                    hidden
                  ></Form.Item>
                  <Form.Item
                    name="message_data_photo_target_url"
                    initialValue={detailMessage?.message_data?.imageTargetUrl}
                    hidden
                  ></Form.Item>
                </Col>
              </Row>
              <Row>
                <Col className="step1-image-title" span={19}>
                  <Form.Item
                    name="message_data_video_name"
                    initialValue={getFileName(detailMessage?.message_data?.videoUrl)}
                    rules={[
                      {
                        required: isUploadMessageVideo,
                        message: t('common.video_upload') + t('common.field_required'),
                      },
                    ]}
                  >
                    <Input placeholder={t('common.video_upload')} disabled={true} />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item name="message_data_video" initialValue={detailMessage?.message_data?.videoUrl}>
                    <Button
                      className="full-width step1-photo button button-active"
                      disabled={!(isUploadMessageVideo && isEdit)}
                      onClick={toggleModalUploadVideo}
                    >
                      {t('seminar_detail.step1.upload')}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col className="step1-image-title" span={19}>
                  <Form.Item
                    name="message_data_video_thumbnail_name"
                    initialValue={getFileName(detailMessage?.message_data?.videoImageThumbnail)}
                    rules={[
                      {
                        required: isUploadMessageVideo,
                        message: t('common.video_thumbnail_upload') + t('common.field_required'),
                      },
                    ]}
                  >
                    <Input placeholder={t('common.video_thumbnail_upload')} disabled={true} />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="message_data_video_thumbnail"
                    initialValue={detailMessage?.message_data?.videoImageThumbnail}
                  >
                    <Button
                      className="full-width step1-photo button button-active"
                      disabled={!(isUploadMessageVideo && isEdit)}
                      onClick={toggleModalUploadVideo}
                    >
                      {t('seminar_detail.step1.upload')}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ display: messageType === AppConst.MESSAGE_TYPE_TARGET ? 'flex' : 'none' }}>
            <Row className="full-width">
              <Col span={2}></Col>
              <Col className="row-title-content" span={4}>
                <span className="label-style-common">{t('seminar_detail.step3.field4')}</span>
              </Col>
              <Col span={18}>
                <Form.Item
                  name="message_type_last"
                  initialValue={detailMessage?.message_type}
                  rules={[
                    {
                      required: messageType === AppConst.MESSAGE_TYPE_TARGET,
                      message: t('seminar_detail.step3.field4') + t('common.field_required'),
                    },
                  ]}
                >
                  <Radio.Group onChange={somethingChanged} disabled={!isEdit}>
                    {messageTypeTargetList.map((item, index) => (
                      <Radio key={index + 1} style={radioStyle} value={item.value}>
                        {item.name}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            {/* <Row className="full-width">
                <Col span={2}></Col>
                <Col className="row-title-content" span={4}>
                  <span className="label-style-common">{t('seminar_detail.step3.field3')}</span>
                </Col>
                <Col span={14}>
                  <Row style={{display: timeToSend === AppConst.SENDING_TIME_TYPE.RELATIVE ? "flex" : "none" }}>
                    <Col span={24}>
                      <Form.Item
                        name="time_diff_unit"
                        initialValue={detailMessage?.time_diff_unit || timeDiffType}
                        rules={[{ required: messageType === AppConst.MESSAGE_TYPE_TARGET, message: t('seminar_detail.step3.field3') + t('common.field_required') }]}
                      >
                        <Radio.Group disabled={!isEdit} onChange={changeTimeDiff}>
                          <Radio value={AppConst.TIME_DIFF_TYPE.DAY}>{t('seminar_detail.step3.day')}</Radio>
                          <Radio value={AppConst.TIME_DIFF_TYPE.HOUR}>{t('seminar_detail.step3.hour')}</Radio>
                          <Radio value={AppConst.TIME_DIFF_TYPE.MINUTE}>{t('seminar_detail.step3.minute')}</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Row>
                      <Form.Item
                        hidden={timeDiffType !== AppConst.TIME_DIFF_TYPE.DAY}
                        name="time_diff"
                        initialValue={detailMessage?.time_diff}
                        rules={[{ required: timeToSend === AppConst.SENDING_TIME_TYPE.RELATIVE && timeDiffType === AppConst.TIME_DIFF_TYPE.DAY, message: t('seminar_detail.step3.field3') + t('common.field_required') }]}
                      >
                        <Select
                          disabled={!isEdit}
                          style={{ width: 200 }}
                        >
                          {irregularDayOption}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        hidden={timeDiffType !== AppConst.TIME_DIFF_TYPE.HOUR}
                        name="time_diff"
                        initialValue={detailMessage?.time_diff}
                        rules={[{ required: timeToSend === AppConst.SENDING_TIME_TYPE.RELATIVE && timeDiffType === AppConst.TIME_DIFF_TYPE.HOUR, message: t('seminar_detail.step3.field3') + t('common.field_required') }]}
                      >
                        <InputNumber type="number" min={0} className="message-time-diff-input" disabled={!isEdit} />
                      </Form.Item>
                      <span style={{position: 'absolute', marginLeft: '4.5vw', lineHeight: "2.25", display: timeDiffType === AppConst.TIME_DIFF_TYPE.HOUR ? "block" : "none"}}>時後</span>
                      <Form.Item
                        hidden={timeDiffType !== AppConst.TIME_DIFF_TYPE.MINUTE}
                        name="time_diff"
                        initialValue={detailMessage?.time_diff}
                        rules={[{ required: timeToSend === AppConst.SENDING_TIME_TYPE.RELATIVE && timeDiffType === AppConst.TIME_DIFF_TYPE.MINUTE, message: t('seminar_detail.step3.field3') + t('common.field_required') }]}
                      >
                        <InputNumber type="number" min={0} className="message-time-diff-input" disabled={!isEdit} />
                      </Form.Item>
                      <span style={{position: 'absolute', marginLeft: '4.5vw', lineHeight: "2.25", display: timeDiffType === AppConst.TIME_DIFF_TYPE.MINUTE ? "block" : "none"}}>分後</span>
                    </Row>
                  </Row>
                </Col>
              </Row> */}
          </Row>
          <Row
            style={{
              display:
                messageType === AppConst.MESSAGE_TYPE_SELECTED ||
                messageType === AppConst.MESSAGE_TYPE_BEFORE_BEGIN ||
                messageType === AppConst.MESSAGE_TYPE_TARGET
                  ? 'flex'
                  : 'none',
            }}
          >
            <Col span={2}></Col>
            <Col className="row-title-content" span={4}>
              <span className="label-style-common">{t('seminar_detail.step3.field3')}</span>
            </Col>
            <Col span={14}>
              {/* <Row>
                  <Form.Item
                    name="sending_time_type"
                    initialValue={detailMessage?.sending_time_type || timeToSend}
                    rules={[{ required: messageType === AppConst.MESSAGE_TYPE_BEFORE_BEGIN, message: t('seminar_detail.step3.field3') + t('common.field_required') }]}
                  >
                    <Radio.Group disabled={!messageType || messageType === AppConst.MESSAGE_TYPE_SELECTED || !isEdit} onChange={changeTimeToSend}>
                      <Radio value={AppConst.SENDING_TIME_TYPE.ABSOLUTE}>{t('seminar_detail.step2.fixed_day')}</Radio>
                      <Radio value={AppConst.SENDING_TIME_TYPE.RELATIVE}>{t('seminar_detail.step2.irregular_day')}</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Row>
                <Row style={{display: timeToSend === AppConst.SENDING_TIME_TYPE.ABSOLUTE ? "flex" : "none" }}>
                  <Form.Item
                    // hidden={timeToSend !== AppConst.SENDING_TIME_TYPE.ABSOLUTE }
                    name="time_to_send"
                    initialValue={detailMessage?.time_to_send ? moment(detailMessage?.time_to_send) : null}
                    rules={[{ required: timeToSend === AppConst.SENDING_TIME_TYPE.ABSOLUTE && messageType === AppConst.MESSAGE_TYPE_BEFORE_BEGIN, message: t('seminar_detail.step3.field3') + t('common.field_required') }]}
                  >
                    <DatePicker placeholder="" disabledDate={disabledDate} showNow={false} showTime={{format: 'HH:mm'}} format="YYYY-MM-DD HH:mm" className="due-date" disabled={!isEdit || messageType === AppConst.MESSAGE_TYPE_SELECTED}/>
                  </Form.Item>
                </Row> */}
              <Row>
                <Col span={8}>
                  <Form.Item
                    name="time_diff"
                    initialValue={detailMessage?.time_diff}
                    rules={[
                      {
                        required:
                          messageType === AppConst.MESSAGE_TYPE_TARGET ||
                          messageType === AppConst.MESSAGE_TYPE_BEFORE_BEGIN,
                        message: t('seminar_detail.step3.field3') + t('common.field_required'),
                      },
                    ]}
                  >
                    <Select
                      disabled={messageType === AppConst.MESSAGE_TYPE_SELECTED || !isEdit}
                      style={{ width: 200 }}
                      onChange={somethingChanged}
                    >
                      {messageType === AppConst.MESSAGE_TYPE_TARGET ? irregularDayOption : irregularDayOptionBefore}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="time_to_send"
                    initialValue={detailMessage?.time_to_send ? moment(detailMessage?.time_to_send) : null}
                    rules={[
                      {
                        required:
                          messageType === AppConst.MESSAGE_TYPE_TARGET ||
                          messageType === AppConst.MESSAGE_TYPE_BEFORE_BEGIN,
                        message: t('seminar_detail.step3.field3') + t('common.field_required'),
                      },
                    ]}
                  >
                    <TimePicker
                      placeholder=""
                      showNow={false}
                      format="HH:mm"
                      className="step4-timepicker"
                      onChange={somethingChanged}
                      disabled={messageType === AppConst.MESSAGE_TYPE_SELECTED || !isEdit}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ display: messageType === AppConst.MESSAGE_TYPE_BEGIN ? 'flex' : 'none' }}>
            <Col span={2}></Col>
            <Col className="row-title-content" span={4}>
              <span className="label-style-common">{t('seminar_detail.step3.field3')}</span>
            </Col>
            <Col span={14}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="time_diff_unit"
                    initialValue={detailMessage?.time_diff_unit || timeDiffType}
                    rules={[
                      {
                        required: messageType === AppConst.MESSAGE_TYPE_BEGIN,
                        message: t('seminar_detail.step3.field3') + t('common.field_required'),
                      },
                    ]}
                  >
                    <Radio.Group disabled={!isEdit} onChange={changeTimeDiff}>
                      <Radio value={AppConst.TIME_DIFF_TYPE.HOUR}>{t('seminar_detail.step3.hour')}</Radio>
                      <Radio value={AppConst.TIME_DIFF_TYPE.MINUTE}>{t('seminar_detail.step3.minute')}</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Row>
                  <Form.Item
                    hidden={timeDiffType !== AppConst.TIME_DIFF_TYPE.HOUR}
                    name="time_diff"
                    initialValue={detailMessage?.time_diff}
                    rules={[
                      {
                        required:
                          messageType === AppConst.MESSAGE_TYPE_BEGIN && timeDiffType === AppConst.TIME_DIFF_TYPE.HOUR,
                        message: t('seminar_detail.step3.field3') + t('common.field_required'),
                      },
                    ]}
                  >
                    <InputNumber
                      type="number"
                      min={0}
                      className="message-time-diff-input"
                      onChange={somethingChanged}
                      disabled={!isEdit}
                    />
                  </Form.Item>
                  <span
                    style={{
                      position: 'absolute',
                      marginLeft: '4.5vw',
                      lineHeight: '2.25',
                      display: timeDiffType === AppConst.TIME_DIFF_TYPE.HOUR ? 'block' : 'none',
                    }}
                  >
                    時前
                  </span>
                  <Form.Item
                    hidden={timeDiffType !== AppConst.TIME_DIFF_TYPE.MINUTE}
                    name="time_diff"
                    initialValue={detailMessage?.time_diff}
                    rules={[
                      {
                        required:
                          messageType === AppConst.MESSAGE_TYPE_BEGIN &&
                          timeDiffType === AppConst.TIME_DIFF_TYPE.MINUTE,
                        message: t('seminar_detail.step3.field3') + t('common.field_required'),
                      },
                    ]}
                  >
                    <InputNumber
                      type="number"
                      min={0}
                      className="message-time-diff-input"
                      onChange={somethingChanged}
                      disabled={!isEdit}
                    />
                  </Form.Item>
                  <span
                    style={{
                      position: 'absolute',
                      marginLeft: '4.5vw',
                      lineHeight: '2.25',
                      display: timeDiffType === AppConst.TIME_DIFF_TYPE.MINUTE ? 'block' : 'none',
                    }}
                  >
                    分前
                  </span>
                </Row>
              </Row>
            </Col>
          </Row>
          <Row style={{ display: messageType === AppConst.MESSAGE_TYPE_LAST ? 'flex' : 'none' }}>
            <Col span={2}></Col>
            <Col className="row-title-content" span={4}>
              <span className="label-style-common">{t('seminar_detail.step3.field3')}</span>
            </Col>
            <Col span={14}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="time_to_send"
                    initialValue={detailMessage?.time_to_send ? moment(detailMessage?.time_to_send) : null}
                    rules={[
                      {
                        required: messageType === AppConst.MESSAGE_TYPE_LAST,
                        message: t('seminar_detail.step3.field3') + t('common.field_required'),
                      },
                    ]}
                  >
                    <TimePicker
                      placeholder=""
                      showNow={false}
                      format="HH:mm"
                      onChange={somethingChanged}
                      className="step4-timepicker"
                      disabled={!isEdit}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        <Table columns={columns} dataSource={seminarMessages} bordered rowKey="id" />
      )}
      <UploadImageModal
        isVisible={isVisibleModalUploadImage}
        toggleModal={toggleModalUploadImage}
        handleChangeModal={handleChangeUploadImageModal}
        imageURLValue={form.current?.getFieldValue('message_data_photo')}
        imageSizeValue={form.current?.getFieldValue('message_data_photo_size')}
        imageLinkValue={form.current?.getFieldValue('message_data_photo_target_url')}
      />
      <UploadVideoModal
        isModalVisible={isVisibleModalUploadVideo}
        toggleModal={toggleModalUploadVideo}
        handleChangeModal={handleChangeUploadVideo}
        videoURLValue={form.current?.getFieldValue('message_data_video')}
        thumbnailURLValue={form.current?.getFieldValue('message_data_video_thumbnail')}
      />
    </div>
  );
}

export default Step4;
