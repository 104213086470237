import React, { useEffect } from 'react';
import { Field } from 'formik';
import { Tag, Layout, Menu } from 'antd';
import _ from 'lodash';
import { FolderOpenOutlined } from '@ant-design/icons';
import InputComponent from '../../../components/Input';
import { ActionTypes as TagActionTypes } from '../../TagManagement/actions';
import RadioComponent from '../../../components/Radio';
import ErrorMessage from '../../../components/MessageError';

const { Item } = Menu;
const { Content, Sider } = Layout;

function UpdateTagComponent({
  t,
  values,
  setFieldValue,
  tagFolders,
  filterTags,
  initialTags,
  getListTag,
  getFilterTag,
  type,
  indexField,
}) {
  const getItemSelected = ({ key }) => {
    getListTag(tagFolders[key]?.id);
  };

  const removeElement = (setFieldValue, fieldName, listTag, tag) => () => {
    return setFieldValue(
      fieldName,
      listTag.filter((item) => item.id !== tag.id)
    );
  };

  const handleSearchTags = (setFieldValue, fieldName) => (e) => {
    const { value } = e.target;
    setFieldValue(fieldName, value);
    getFilterTag(value);
  };

  const findItem = (items, attribute, value) => {
    return items?.findIndex((item) => item[attribute] === value);
  };

  const onChangeCheckbox = (setFieldValue, fieldName, tag, listTag) => {
    const indexElement = findItem(listTag, 'id', tag.id);
    if (indexElement !== -1) {
      return setFieldValue(
        fieldName,
        listTag.filter((item) => item.id !== tag.id)
      );
    }
    return setFieldValue(fieldName, listTag.concat({ id: tag.id, name: tag.name }));
  };

  useEffect(() => {
    if (type === TagActionTypes.GET_LIST_TAG_FOLDER_SUCCESS) {
      if (tagFolders?.length) {
        getListTag(tagFolders[0]?.id);
      }
    }
  }, [type]);

  return (
    <div className="action-update-tag">
      <Field
        type="radio"
        name={`actions.${indexField}.type`}
        value={values.actions[indexField].type}
        options={[
          { key: 'TAG_ADD', value: t('action_management.option_add_tag') },
          { key: 'TAG_REMOVE', value: t('action_management.option_remove_tag') },
        ]}
        onChange={(e) => setFieldValue(`actions.${indexField}.type`, e.target.value)}
        component={RadioComponent}
      />
      <div className="modal-update-tags__action">
        <label className="modal-update-tags__action__label title-content">
          {t('action_management.label_tag_selection')}
        </label>
        <div className="modal-update-tags__action__input">
          <div className="modal-update-tags__action__input__list-tag">
            {_.compact(values.actions[indexField]?.list_tag).map((item, index) => {
              return (
                <Tag
                  closable
                  onClose={removeElement(
                    setFieldValue,
                    `actions.${indexField}.list_tag`,
                    values.actions[indexField].list_tag,
                    item
                  )}
                  key={index}
                  visible={true}
                >
                  {item.name}
                </Tag>
              );
            })}
          </div>
          <Field
            name="searchTags"
            wrapperClass="modal-update-tags__action__input__search"
            onChange={handleSearchTags(setFieldValue, 'searchTags')}
            component={InputComponent}
          />
        </div>
        <ErrorMessage name={`actions.${indexField}.list_tag`} />
      </div>
      <Layout className="site-layout-background">
        <Sider className="site-layout-background" width={values.searchTags ? 0 : 200}>
          <Menu mode="inline" defaultSelectedKeys={['0']} defaultOpenKeys={['0']} onClick={getItemSelected}>
            {tagFolders?.map(({ id, name }, index) => (
              <Item key={index} icon={<FolderOpenOutlined />}>
                {name}
              </Item>
            ))}
          </Menu>
        </Sider>
        <Content style={{ padding: '8px 24px' }}>
          <div role="group" aria-labelledby="checkbox-group">
            {(values.searchTags ? filterTags : initialTags)?.map((tag, index) => (
              <label htmlFor={tag.name} key={index}>
                <Field
                  id={tag.name}
                  type="checkbox"
                  name={`actions.${indexField}.list_tag`}
                  className="action-update-tag__checkbox"
                  checked={findItem(values.actions[indexField].list_tag, 'id', tag.id) !== -1}
                  onChange={() => {
                    setFieldValue('searchTags', '');
                    onChangeCheckbox(
                      setFieldValue,
                      `actions.${indexField}.list_tag`,
                      tag,
                      values.actions[indexField].list_tag
                    );
                  }}
                />
                {tag.name}
              </label>
            ))}
          </div>
        </Content>
      </Layout>
    </div>
  );
}

export default UpdateTagComponent;
