import React from 'react';
import { Radio } from 'antd';

const { Group } = Radio;

function SelectComponent({ field, form, options = [], wrapperClass = '', idWrapper, labelInput, fieldName, ...props }) {
  return (
    <div style={{ position: 'relative' }} id={idWrapper} className={`select ${wrapperClass}`}>
      {labelInput ? <span className="label-input">{labelInput}</span> : ''}
      <Group name={fieldName} {...props}>
        {options.map((item, index) => (
          <Radio key={index} value={item.key}>
            {item.value}
          </Radio>
        ))}
      </Group>
      {form?.errors[field.name] && form?.touched[field.name] && (
        <span className="required">{form?.errors[field.name]}</span>
      )}
    </div>
  );
}

export default SelectComponent;
