export const ActionTypes = {
  GET_DEMO_START: 'GET_DEMO_START',
  GET_DEMO_SUCCESS: 'GET_DEMO_SUCCESS',
  GET_DEMO_ERROR: 'GET_DEMO_ERROR',
  RESET_TYPE_OF_ACTION: 'RESET_TYPE_OF_ACTION',
  SHOW_LOADING_EVENT: 'SHOW_LOADING_EVENT',
  HIDE_LOADING_EVENT: 'HIDE_LOADING_EVENT',
  RESET_ERROR_MESSAGE: 'RESET_ERROR_MESSAGE',
};

export const actions = {
  getDemoStart: function () {
    return {
      type: ActionTypes.GET_DEMO_START,
    };
  },
  getDemoSuccess: function (success) {
    return {
      type: ActionTypes.GET_DEMO_SUCCESS,
      payload: success,
    };
  },
  getDemoError: function (error) {
    return {
      type: ActionTypes.GET_DEMO_ERROR,
      payload: error,
    };
  },
  resetTypeOfAction: function () {
    return {
      type: ActionTypes.RESET_TYPE_OF_ACTION,
      payload: '',
    };
  },
  showLoadingEvent: () => {
    return {
      type: ActionTypes.SHOW_LOADING_EVENT,
    };
  },
  hideLoadingEvent: () => {
    return {
      type: ActionTypes.HIDE_LOADING_EVENT,
    };
  },
  resetErrorMessage: function () {
    return {
      type: ActionTypes.RESET_ERROR_MESSAGE,
      payload: '',
    };
  },
};
