export class AppConst {
  static DEFAULT_ITEM_PER_PAGE = 20;
  static ERROR_404_PATH = '/error/404';
  static ORGANIZER_ID = 'organizerId';
  static SEMINAR_ID = 'seminarId';
  static SEMINAR_MESSAGE_ID = 'seminarMessageId';
  static DATE_TIME_YYYY_MM_DD_HH_MM_SS = 'yyyy-MM-DD HH:mm:ss';
  static MESSAGE_TYPE_SELECTED = 'SELECTED_MSG';
  static MESSAGE_TYPE_BEFORE_BEGIN = 'BEFORE_BEGIN_MSG';
  static MESSAGE_TYPE_BEGIN = 'BEGIN_MSG';
  static MESSAGE_TYPE_NOT_ACCESS = 'AFTER_END_NOT_ACCESS_URL_MSG';
  static MESSAGE_TYPE_NOT_CLICK = 'AFTER_END_NOT_CLICK_BUTTON_MSG';
  static MESSAGE_TYPE_CLICKED = 'AFTER_END_CLICKED_BUTTON_MSG';
  static MESSAGE_TYPE_TARGET = 'TARGET_MSG';
  static MESSAGE_TYPE_LAST = 'LAST_MSG';
  static MESSAGE_TYPE_ALL = 'AFTER_END_ALL_SUBSCRIBERS_MSG';
  static TIME_DIFF_TYPE = {
    UNDEFINED: 'UNDEFINED',
    DAY: 'DAY',
    HOUR: 'HOUR',
    MINUTE: 'MINUTE',
  };
  static SENDING_TIME_TYPE = {
    UNDEFINED: 'UNDEFINED',
    RELATIVE: 'RELATIVE',
    ABSOLUTE: 'ABSOLUTE',
  };
  static ROUTE = {
    LOGIN: '/login',
    ORGANIZER_MANAGEMENTS: '/admin/organizers',
    ORGANIZER_CREATION: '/admin/organizers/new',
    SEMINAR_MANAGEMENTS: '/organizer/seminars',
    SEMINAR_CREATION: '/organizer/seminars/new',
    ORGANIZER_DETAIL: '/organizer/setting/',
    ADMIN_ORGANIZER_DETAIL: '/admin/organizer/',
    SEMINAR_DETAIL: '/organizer/seminar/',
    ADMIN_SETTING_DETAIL: '/admin/setting',
    SHORTEN_URL: '/s/:code',
  };
  static S3_TYPE_UPLOAD = {
    THANKS_VIDEO: 'THANKS_VIDEO',
    THANKS_VIDEO_THUMBNAIL: 'THANKS_VIDEO_THUMBNAIL',
    THANKS_IMAGE: 'THANKS_IMAGE',
    SEMINAR_PHOTO: 'SEMINAR_PHOTO',
    SEMINAR_VIDEO: 'SEMINAR_VIDEO',
    SEMINAR_VIDEO_THUMBNAIL: 'SEMINAR_VIDEO_THUMBNAIL',
    SEMINAR_DESCRIPTION_PHOTO: 'SEMINAR_DESCRIPTION_PHOTO',
    SEMINAR_DESCRIPTION_VIDEO: 'SEMINAR_DESCRIPTION_VIDEO',
    SEMINAR_DESCRIPTION_VIDEO_THUMBNAIL: 'SEMINAR_DESCRIPTION_VIDEO_THUMBNAIL',
    PRESENTER_PHOTO: 'PRESENTER_PHOTO',
    PRESENTER_PROFILE_PHOTO: 'PRESENTER_PROFILE_PHOTO',
    PRESENTER_PROFIlE_VIDEO: 'PRESENTER_PROFIlE_VIDEO',
    PRESENTER_PROFIlE_VIDEO_THUMBNAIL: 'PRESENTER_PROFIlE_VIDEO_THUMBNAIL',
    SEMINAR_MSG_RESOURCES: 'SEMINAR_MSG_RESOURCES',
  };
  static NOTIFICATION = {
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error',
  };
  static INTERNAL_SERVER_ERROR = 'Internal server error.';
  static SUCCESS = 'Successfully!';
  static UNDEFINED = 'UNDEFINED';
  static UPLOADING = 'Uploading...';
  static WINDOW_INNER_WIDTH = 768;
  static SEMINAR_STATUS = {
    INIT: 'INIT',
    PUBLISH: 'PUBLISH',
    UNPUBLISH: 'UNPUBLISH',
  };
}

export const getGenderOptions = (t) => {
  return [
    { key: 'male', value: t('common.gender_male') },
    { key: 'female', value: t('common.gender_female') },
  ];
};

export const getStatusOptions = (t) => {
  return [
    { key: '', value: t('rich_menu_management.select_all') },
    { key: 'ACTIVATE', value: t('rich_menu_management.select_selected') },
    { key: 'DRAFT', value: t('rich_menu_management.select_draft') },
    { key: 'EDITED', value: t('rich_menu_management.select_edited') },
  ];
};

export const getUserStatus = (t) => {
  return [
    { key: '', value: t('common.select_all') },
    { key: true, value: t('common.active') },
    { key: false, value: t('common.inactive') },
  ];
};

export const getContentMessageTag = (t) => {
  return [
    {
      key: 'text',
      value: t('tag_management.label_text'),
    },
    {
      key: 'image',
      value: t('tag_management.label_image'),
    },
    {
      key: 'video',
      value: t('tag_management.label_video'),
    },
  ];
};

export const getTypeTimeSendMessageTag = (t) => {
  return [
    {
      key: 'UNDEFINED',
      value: t('tag_management.label_send_immediately'),
    },
    {
      key: 'RELATIVE',
      value: t('tag_management.label_delay_transmission'),
    },
    {
      key: 'ABSOLUTE',
      value: t('tag_management.label_specify_the_time'),
    },
  ];
};

export const getTagFolders = (list = []) => {
  if (list?.length) {
    return list.map((item) => ({
      key: item.id,
      value: item.name,
    }));
  }
  return;
};

export const getConversionFolders = (list = []) => {
  if (list?.length) {
    return list.map((item) => ({
      key: item.id,
      value: item.name,
    }));
  }
  return;
};

export const FORMAT_TIME_LONG = 'YYYY-MM-DD HH:mm:ss';
export const FORMAT_TIME_DAY = 'YYYY-MM-DD';
export const FORMAT_TIME_TIME = 'HH:mm:ss';
export const FORMAT_TIME_DAY_HOUR = 'YYYY-MM-DD HH';
export const FORMAT_TIME_DAY_HOUR_MINUTES = 'YYYY-MM-DD HH:mm';

export const getListTemplate = () => {
  return ['A', 'B', 'C', 'D', 'E', 'F'];
};

export const LIST_TEMPLATE = [
  ['A', 'B', 'C', 'D', 'E', 'F'],
  ['A', 'B', 'C', 'D'],
  ['A', 'B', 'C', 'D'],
  ['A', 'B', 'C'],
  ['A', 'B'],
  ['A', 'B'],
  ['A'],
  ['A', 'B', 'C'],
  ['A', 'B'],
  ['A', 'B'],
  ['A', 'B'],
  ['A'],
];

export const getActionOptions = (t) => {
  return [
    { key: 'TEXT', value: t('create_rich_menu.select_action_text') },
    { key: 'LINK', value: t('create_rich_menu.select_action_link') },
    { key: 'NONE', value: t('create_rich_menu.select_action_no_action') },
  ];
};

export const FORMAT_IMAGE = 'JPG, JPEG, PNG';
export const SIZE_IMAGE = '2500 × 1686, 2500 × 843, 1200 × 810, 1200 × 405, 800 × 540, 800 × 270';
export const PAGE_SIZE = 10;

export const convertSeminarStatus = (status) => {
  switch (status) {
    case 'NOT_ACCESS_URL_MSG':
      return '欠席しました';
    case 'NOT_CLICK_BUTTON_MSG':
      return '出席しました（未コンバージョン)';
    case 'CLICKED_BUTTON_MSG':
      return '出席しました（コンバージョン済み）';
    default:
      return 'まだ参加していません';
  }
};

export const IMAGE_SIZE_OPTIONS = [
  { key: 'giga', value: 'Maximum（大）' },
  { key: 'mega', value: 'Medium Big（中大）' },
  { key: 'kilo', value: 'Medium（中）' },
  { key: 'micro', value: 'Medium Small （中小）' },
  { key: 'nano', value: 'Minimum（小）' },
];

export const REGEX_NUMBER = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
export const REGEX_ONLY_NUMBER = /[0-9]/;
export const REGEX_NOT_NUMBER = /[^0-9]/;
export const REGEX_SPECIAL_CHARACTER = /[^a-zA-Z0-9\s]/;
