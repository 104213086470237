import { ActionTypes } from './actions';

const DEFAULT_STATE = {
  conversions: [],
  conversionFolders: [],
  filterConversions: [],
  conversionDetail: {},
  conversionScript: '',
  type: '',
  error: '',
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_LIST_CONVERSION_SUCCESS:
      return {
        ...state,
        conversions: action.payload,
      };
    case ActionTypes.GET_LIST_CONVERSION_FOLDER_SUCCESS:
      return {
        ...state,
        conversionFolders: action.payload,
        type: action.type,
      };
    case ActionTypes.GET_FILTER_CONVERSION_SUCCESS:
      return {
        ...state,
        filterConversions: action.payload,
      };
    case ActionTypes.PUT_FOLDER_CONVERSION_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.PUT_FOLDER_CONVERSION_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.POST_FOLDER_CONVERSION_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.POST_FOLDER_CONVERSION_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.POST_NEW_CONVERSION_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.POST_NEW_CONVERSION_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.PUT_CONVERSION_DETAIL_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.PUT_CONVERSION_DETAIL_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.GET_CONVERSION_DETAIL_SUCCESS:
      return {
        ...state,
        conversionDetail: action.payload,
        type: action.type,
      };
    case ActionTypes.DELETE_A_CONVERSION_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.DELETE_A_CONVERSION_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.DELETE_CONVERSION_FOLDER_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.DELETE_CONVERSION_FOLDER_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.RESET_TYPE_OF_ACTION:
      return {
        ...state,
        type: '',
      };
    case ActionTypes.POST_SCRIPT_URL_SUCCESS:
      return {
        ...state,
        conversionScript: action.payload,
        type: action.type,
      };
    case ActionTypes.POST_SCRIPT_URL_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.RESET_ERROR_MESSAGE:
      return {
        ...state,
        error: '',
      };
    default:
      return state;
  }
};
