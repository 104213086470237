import React, { useEffect } from 'react';

function Textarea({
  field,
  form: { touched, errors },
  wrapperClass,
  labelTitle,
  messageError,
  required,
  actionDispatch,
  inputLength,
  fieldName,
  ...props
}) {
  const onKeyDown = (e) => {
    if (e.keyCode !== 8 && e.target.value.length >= props.maxLength) e.preventDefault();
  };

  useEffect(() => {
    if (!errors[field.name] && actionDispatch) {
      actionDispatch();
    }
  }, [field.value]);

  return (
    <div className={`input ${wrapperClass}`}>
      {labelTitle && (
        <label htmlFor={field.name}>
          {labelTitle} {required && <span className="required">*</span>}
        </label>
      )}
      {props.maxLength ? (
        <div className="input__max--length">
          <textarea name={fieldName} onKeyDown={onKeyDown} {...field} {...props} />
          <span>{`${field.value.length}/${props.maxLength}`}</span>
        </div>
      ) : (
        <textarea name={fieldName} maxLength={inputLength} id={field.name} {...field} {...props} />
      )}
      {errors[field.name] && touched[field.name] && <span className="required">{errors[field.name]}</span>}
      {messageError && <span className="required">{messageError}</span>}
    </div>
  );
}

export default Textarea;
