import { put, takeLatest } from 'redux-saga/effects';
import { actions, ActionTypes } from './actions';
import {
  getListConversionAPI,
  getListConversionFolderAPI,
  putTagInfoAPI,
  getFilterConversionAPI,
  putConversionFolderAPI,
  postConversionFolderAPI,
  postNewConversionAPI,
  putConversionDetailAPI,
  getConversionDetailAPI,
  deleteConversionAPI,
  deleteConversionFolderAPI,
  postScriptURLAPI,
} from '../../services/ConversionRequest';
import { RESPONSE_STATUS } from '../../common/ResponseCode';
import { actions as appActions } from '../Home/actions';

function* getListConversionFolder() {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield getListConversionFolderAPI();
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getListConversionFolderSuccess(response.data));
    } else {
      yield put(actions.getListConversionFolderError(response));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.getListConversionFolderError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* getListConversion({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield getListConversionAPI(params);
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getListConversionSuccess(response?.data));
    } else {
      yield put(actions.getListConversionError(response));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.getListConversionError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* getFilterConversion({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield getFilterConversionAPI(params);
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getFilterConversionSuccess(response?.data));
    } else {
      yield put(actions.getFilterConversionError(response));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.getFilterConversionError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* putConversionFolder({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield putConversionFolderAPI(params);
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.putFolderConversionSuccess(response));
    } else {
      yield put(actions.putFolderConversionError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.putFolderConversionError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* postConversionFolder({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield postConversionFolderAPI(params);
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.postFolderConversionSuccess(response));
    } else {
      yield put(actions.postFolderConversionError(response?.meta?.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.postFolderConversionError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* postNewConversion({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield postNewConversionAPI(params);
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.postNewConversionSuccess(response));
    } else {
      yield put(actions.postNewConversionError(response?.meta?.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.postNewConversionError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* putConversionDetail({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield putConversionDetailAPI(params);
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.putConversionDetailSuccess(response));
    } else {
      yield put(actions.putConversionDetailError(response));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.putConversionDetailError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* getConversionDetail({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield getConversionDetailAPI(params);
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getConversionDetailSuccess(response?.data));
    } else {
      yield put(actions.getConversionDetailError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.getConversionDetailError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* deleteAConversion({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield deleteConversionAPI(params);
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.deleteAConversionSuccess(response?.data));
    } else {
      yield put(actions.deleteAConversionError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.deleteAConversionError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* deleteConversionFolder({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield deleteConversionFolderAPI(params);
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.deleteConversionFolderSuccess(response?.data));
    } else {
      yield put(actions.deleteConversionFolderError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.deleteConversionFolderError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* postScriptURL() {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield postScriptURLAPI();
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.postScriptURLSuccess(response.data));
    } else {
      yield put(actions.postScriptURLError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.postScriptURLError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

export function* watchConversionManagement() {
  yield takeLatest(ActionTypes.GET_LIST_CONVERSION_FOLDER_START, getListConversionFolder);
  yield takeLatest(ActionTypes.GET_LIST_CONVERSION_START, getListConversion);
  yield takeLatest(ActionTypes.GET_FILTER_CONVERSION_START, getFilterConversion);
  yield takeLatest(ActionTypes.PUT_FOLDER_CONVERSION_START, putConversionFolder);
  yield takeLatest(ActionTypes.POST_FOLDER_CONVERSION_START, postConversionFolder);
  yield takeLatest(ActionTypes.POST_NEW_CONVERSION_START, postNewConversion);
  yield takeLatest(ActionTypes.PUT_CONVERSION_DETAIL_START, putConversionDetail);
  yield takeLatest(ActionTypes.GET_CONVERSION_DETAIL_START, getConversionDetail);
  yield takeLatest(ActionTypes.DELETE_A_CONVERSION_START, deleteAConversion);
  yield takeLatest(ActionTypes.DELETE_CONVERSION_FOLDER_START, deleteConversionFolder);
  yield takeLatest(ActionTypes.POST_SCRIPT_URL_START, postScriptURL);
}
