export class ApiPath {
  static ORGANIZERS_FILTER_PATH = "/admin/organizers/filter";
  static ADMIN_ORGANIZER_DETAIL_PATH = "/admin/organizers/";
  static ADMIN_ORGANIZER_UPDATE_ACCOUNT_PATH = "/admin/organizers/organizerId/update/account";
  static ADMIN_ORGANIZER_UPDATE_CHANNEL_PATH = "/admin/organizers/organizerId/update/channel";
  static ADMIN_ORGANIZER_UPDATE_COMPANY_PATH = "/admin/organizers/organizerId/update/company";
  static ADMIN_ORGANIZER_UPDATE_MESSAGE_PATH = "/admin/organizers/organizerId/update/msg";
  static ADMIN_ORGANIZER_UPLOAD_S3_PATH = "/admin/organizers/organizerId/pre-sign-url";
  static ORGANIZER_DETAIL_PATH = "/organizer/";
  static ORGANIZER_UPDATE_ACCOUNT_PATH = "/organizer/organizerId/update/account";
  static ORGANIZER_UPDATE_CHANNEL_PATH = "/organizer/organizerId/update/channel";
  static ORGANIZER_UPDATE_COMPANY_PATH = "/organizer/organizerId/update/company";
  static ORGANIZER_UPDATE_MESSAGE_PATH = "/organizer/organizerId/update/msg";
  static ORGANIZER_UPLOAD_S3_PATH = "/organizer/organizerId/pre-sign-url";
  static SEMINAR_CREATION_PATH = "/organizer/seminars/create";
  static SEMINAR_UPLOAD_S3_PATH = "/organizer/seminars/seminarId/pre-sign-url";
  static SEMINAR_UPDATE_PRESENTER_PATH = "/organizer/seminars/seminarId/update/presenter";
  static SEMINAR_UPDATE_META_PATH = "/organizer/seminars/seminarId/update/meta";
  static SEMINAR_UPDATE_PRESENT_END_PATH = "/organizer/seminars/seminarId/update/present_end";
  static SEMINAR_UPDATE_PRESENT_START_PATH = "/organizer/seminars/seminarId/update/present_start";
  static SEMINAR_UPDATE_PRESENT_TIME_PATH = "/organizer/seminars/seminarId/update/present_time";
  static SEMINAR_UPDATE_SEMENAR_MESSAGE_PATH = "/organizer/seminars/seminarId/update/seminar_message/seminarMessageId";
  static SEMINAR_CREATE_SEMENAR_MESSAGE_PATH = "/organizer/seminars/seminarId/create/seminar_message";
  static SEMINAR_MESSAGE_UPLOAD_S3_PATH = "/organizer/seminars/messages/pre-sign-url";
  static DELETE_SEMINAR_PATH = "/organizer/seminars/seminarId/delete";
  static ADMIN_DETAIL_PATH = "/admin";
  static ADMIN_UPDATE_PATH = "/admin/update"
}
