import { api } from './api';

export const getListActionAPI = (folderId) => {
  return api.getMethod(`/organizer/actions/folder/${folderId}`);
};

export const getListActionFolderAPI = () => {
  return api.getMethod(`/organizer/actions`);
};

export const putTagInfoAPI = ({ userID, body }) => {
  return api.putMethod(`organizer/subscribers/${userID}/update-tag-info`, body);
};

export const getFilterActionsAPI = (keyword) => {
  return api.getMethod(`organizer/actions/filter`, { keyword });
};

export const putFolderActionAPI = ({ folderID, folderName }) => {
  return api.putMethod(`/organizer/actions/folder/${folderID}/update?name=${encodeURIComponent(folderName)}`);
};

export const postFolderActionAPI = (name) => {
  return api.postMethod(`/organizer/actions/folder/create?name=${encodeURIComponent(name)}`);
};

export const postNewActionAPI = (body) => {
  return api.postMethod('/organizer/actions/create', body);
};

export const putActionDetailAPI = ({ actionID, body }) => {
  return api.putMethod(`/organizer/actions/${actionID}/update`, body);
};

export const getActionDetailAPI = (actionID) => {
  return api.getMethod(`/organizer/actions/${actionID}`);
};

export const deleteActionAPI = (actionID) => {
  return api.deleteMethod(`/organizer/actions/${actionID}/delete`);
};

export const postExecuteActionAPI = (body) => {
  return api.postMethod(`/organizer/actions/execute`, body);
};

export const deleteActionFolderAPI = (folderID) => {
  return api.deleteMethod(`/organizer/actions/folder/${folderID}/delete`);
};
