import { put, takeLatest } from 'redux-saga/effects';
import { actions, ActionTypes } from './actions';
import {
  getListTagAPI,
  getLIstTagFolderAPI,
  putTagInfoAPI,
  getFilterTagsAPI,
  putFolderTagAPI,
  postFolderTagAPI,
  postNewTagAPI,
  putTagDetailAPI,
  getTagDetailAPI,
  deleteTagAPI,
  deleteTagFolderAPI,
} from '../../services/TagRequest';
import { RESPONSE_STATUS } from '../../common/ResponseCode';
import { actions as appActions } from '../Home/actions';

function* getListTagFolder() {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield getLIstTagFolderAPI();
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getListTagFolderSuccess(response.data));
    } else {
      yield put(actions.getListTagFolderError(response));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.getListTagFolderError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* getListTag({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield getListTagAPI(params);
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getListTagSuccess(response?.data));
    } else {
      yield put(actions.getListTagError(response));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.getListTagError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* putTagInformation({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield putTagInfoAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.putTagInformationSuccess(response));
    } else {
      yield put(actions.putTagInformationError(response));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.putTagInformationError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* getFilterTag({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield getFilterTagsAPI(params);
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getFilterTagSuccess(response?.data));
    } else {
      yield put(actions.getFilterTagError(response));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.getFilterTagError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* putFolderTag({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield putFolderTagAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.putFolderTagSuccess(response));
    } else {
      yield put(actions.putFolderTagError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.putFolderTagError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* postFolderTag({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield postFolderTagAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.postFolderTagSuccess(response));
    } else {
      yield put(actions.postFolderTagError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.postFolderTagError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* postNewTag({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield postNewTagAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.postNewTagSuccess(response));
    } else {
      yield put(actions.postNewTagError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.postNewTagError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* putTagDetail({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield putTagDetailAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.putTagDetailSuccess(response));
    } else {
      yield put(actions.putTagDetailError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.putTagDetailError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* getTagDetail({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield getTagDetailAPI(params);
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getTagDetailSuccess(response?.data));
    } else {
      yield put(actions.getTagDetailError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.getTagDetailError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* deleteATag({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield deleteTagAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.deleteATagSuccess(response?.data));
    } else {
      yield put(actions.deleteATagError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.deleteATagError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* deleteTagFolder({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield deleteTagFolderAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.deleteTagFolderSuccess(response?.data));
    } else {
      yield put(actions.deleteTagFolderError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.deleteTagFolderError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

export function* watchTagManagement() {
  yield takeLatest(ActionTypes.GET_LIST_TAG_FOLDER_START, getListTagFolder);
  yield takeLatest(ActionTypes.GET_LIST_TAG_START, getListTag);
  yield takeLatest(ActionTypes.PUT_TAG_INFORMATION_START, putTagInformation);
  yield takeLatest(ActionTypes.GET_FILTER_TAG_START, getFilterTag);
  yield takeLatest(ActionTypes.PUT_FOLDER_TAG_START, putFolderTag);
  yield takeLatest(ActionTypes.POST_FOLDER_TAG_START, postFolderTag);
  yield takeLatest(ActionTypes.POST_NEW_TAG_START, postNewTag);
  yield takeLatest(ActionTypes.PUT_TAG_DETAIL_START, putTagDetail);
  yield takeLatest(ActionTypes.GET_TAG_DETAIL_START, getTagDetail);
  yield takeLatest(ActionTypes.DELETE_A_TAG_START, deleteATag);
  yield takeLatest(ActionTypes.DELETE_TAG_FOLDER_START, deleteTagFolder);
}
