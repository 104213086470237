import { api } from './api';

export const getFilterMessagePackageAPI = (keyword = '') => {
  return api.getMethod(`/organizer/message-packages/filter`, { keyword });
};

export const postNewMessagePackageAPI = (body) => {
  return api.postMethod(`/organizer/message-packages/create`, body);
};

export const getMessagePackageDetailAPI = (id) => {
  return api.getMethod(`/organizer/message-packages/${id}`);
};

export const putMessagePackageAPI = ({ id, body }) => {
  return api.putMethod(`/organizer/message-packages/${id}/update`, body);
};

export const postNewMessageAPI = (body) => {
  return api.postMethod(`organizer/messages/create`, body);
};

export const getListMessageAPI = (id) => {
  return api.getMethod(`/organizer/messages/list/${id}`);
};

export const getMessageDetailAPI = ({ messagePackageID, messageID }) => {
  return api.getMethod(`/organizer/messages/${messagePackageID}/${messageID}`);
};

export const putMessageDetailAPI = ({ id, body }) => {
  return api.putMethod(`/organizer/messages/${id}/update`, body);
};

export const postExecuteMessagePackageAPI = (body) => {
  return api.postMethod(`/organizer/message-packages/execute`, body);
};

export const getMessagePackageSubscriberAPI = ({ keyword = '', message_package_id = '' }) => {
  return api.getMethod(`/organizer/message-packages/subscriber-message-package/filter?`, {
    keyword,
    message_package_id,
  });
};

export const deleteMessagePackageAPI = (id) => {
  return api.deleteMethod(`/organizer/message-packages/${id}/delete`);
};

export const deleteMessageAPI = ({ messagePackageID = '', messageID = '' }) => {
  return api.deleteMethod(`/organizer/messages/${messagePackageID}/${messageID}/delete`);
};
