export const ActionTypes = {
  GET_LIST_ACTION_FOLDER_START: 'GET_LIST_ACTION_FOLDER_START',
  GET_LIST_ACTION_FOLDER_SUCCESS: 'GET_LIST_ACTION_FOLDER_SUCCESS',
  GET_LIST_ACTION_FOLDER_ERROR: 'GET_LIST_ACTION_FOLDER_ERROR',
  GET_LIST_ACTION_START: 'GET_LIST_ACTION_START',
  GET_LIST_ACTION_SUCCESS: 'GET_LIST_ACTION_SUCCESS',
  GET_LIST_ACTION_ERROR: 'GET_LIST_ACTION_ERROR',
  GET_FILTER_ACTION_START: 'GET_FILTER_ACTION_START',
  GET_FILTER_ACTION_SUCCESS: 'GET_FILTER_ACTION_SUCCESS',
  GET_FILTER_ACTION_ERROR: 'GET_FILTER_ACTION_ERROR',
  PUT_FOLDER_ACTION_START: 'PUT_FOLDER_ACTION_START',
  PUT_FOLDER_ACTION_SUCCESS: 'PUT_FOLDER_ACTION_SUCCESS',
  PUT_FOLDER_ACTION_ERROR: 'PUT_FOLDER_ACTION_ERROR',
  POST_FOLDER_ACTION_START: 'POST_FOLDER_ACTION_START',
  POST_FOLDER_ACTION_SUCCESS: 'POST_FOLDER_ACTION_SUCCESS',
  POST_FOLDER_ACTION_ERROR: 'POST_FOLDER_ACTION_ERROR',
  POST_NEW_ACTION_START: 'POST_NEW_ACTION_START',
  POST_NEW_ACTION_SUCCESS: 'POST_NEW_ACTION_SUCCESS',
  POST_NEW_ACTION_ERROR: 'POST_NEW_ACTION_ERROR',
  PUT_ACTION_DETAIL_START: 'PUT_ACTION_DETAIL_START',
  PUT_ACTION_DETAIL_SUCCESS: 'PUT_ACTION_DETAIL_SUCCESS',
  PUT_ACTION_DETAIL_ERROR: 'PUT_ACTION_DETAIL_ERROR',
  GET_ACTION_DETAIL_START: 'GET_ACTION_DETAIL_START',
  GET_ACTION_DETAIL_SUCCESS: 'GET_ACTION_DETAIL_SUCCESS',
  GET_ACTION_DETAIL_ERROR: 'GET_ACTION_DETAIL_ERROR',
  DELETE_A_ACTION_START: 'DELETE_A_ACTION_START',
  DELETE_A_ACTION_SUCCESS: 'DELETE_A_ACTION_SUCCESS',
  DELETE_A_ACTION_ERROR: 'DELETE_A_ACTION_ERROR',
  POST_EXECUTE_ACTION_START: 'POST_EXECUTE_ACTION_START',
  POST_EXECUTE_ACTION_SUCCESS: 'POST_EXECUTE_ACTION_SUCCESS',
  POST_EXECUTE_ACTION_ERROR: 'POST_EXECUTE_ACTION_ERROR',
  DELETE_ACTION_FOLDER_START: 'DELETE_ACTION_FOLDER_START',
  DELETE_ACTION_FOLDER_SUCCESS: 'DELETE_ACTION_FOLDER_SUCCESS',
  DELETE_ACTION_FOLDER_ERROR: 'DELETE_ACTION_FOLDER_ERROR',
  RESET_TYPE_OF_ACTION: 'RESET_TYPE_OF_ACTION',
  RESET_ERROR_MESSAGE: 'RESET_ERROR_MESSAGE',
};

export const actions = {
  getListActionFolderStart: function () {
    return {
      type: ActionTypes.GET_LIST_ACTION_FOLDER_START,
    };
  },
  getListActionFolderSuccess: function (data) {
    return {
      type: ActionTypes.GET_LIST_ACTION_FOLDER_SUCCESS,
      payload: data,
    };
  },
  getListActionFolderError: function (error) {
    return {
      type: ActionTypes.GET_LIST_ACTION_FOLDER_ERROR,
      payload: error,
    };
  },
  getListActionStart: function (params) {
    return {
      type: ActionTypes.GET_LIST_ACTION_START,
      params,
    };
  },
  getListActionSuccess: function (data) {
    return {
      type: ActionTypes.GET_LIST_ACTION_SUCCESS,
      payload: data,
    };
  },
  getListActionError: function (error) {
    return {
      type: ActionTypes.GET_LIST_ACTION_ERROR,
      payload: error,
    };
  },
  getFilterActionStart: function (params) {
    return {
      type: ActionTypes.GET_FILTER_ACTION_START,
      params,
    };
  },
  getFilterActionSuccess: function (data) {
    return {
      type: ActionTypes.GET_FILTER_ACTION_SUCCESS,
      payload: data,
    };
  },
  getFilterActionError: function (error) {
    return {
      type: ActionTypes.GET_FILTER_ACTION_ERROR,
      payload: error,
    };
  },
  putFolderActionStart: function (params) {
    return {
      type: ActionTypes.PUT_FOLDER_ACTION_START,
      params,
    };
  },
  putFolderActionSuccess: function (data) {
    return {
      type: ActionTypes.PUT_FOLDER_ACTION_SUCCESS,
      payload: data,
    };
  },
  putFolderActionError: function (error) {
    return {
      type: ActionTypes.PUT_FOLDER_ACTION_ERROR,
      payload: error,
    };
  },
  postFolderActionStart: function (params) {
    return {
      type: ActionTypes.POST_FOLDER_ACTION_START,
      params,
    };
  },
  postFolderActionSuccess: function (data) {
    return {
      type: ActionTypes.POST_FOLDER_ACTION_SUCCESS,
      payload: data,
    };
  },
  postFolderActionError: function (error) {
    return {
      type: ActionTypes.POST_FOLDER_ACTION_ERROR,
      payload: error,
    };
  },
  postNewActionStart: function (params) {
    return {
      type: ActionTypes.POST_NEW_ACTION_START,
      params,
    };
  },
  postNewActionSuccess: function (data) {
    return {
      type: ActionTypes.POST_NEW_ACTION_SUCCESS,
      payload: data,
    };
  },
  postNewActionError: function (error) {
    return {
      type: ActionTypes.POST_NEW_ACTION_ERROR,
      payload: error,
    };
  },
  putActionDetailStart: function (params) {
    return {
      type: ActionTypes.PUT_ACTION_DETAIL_START,
      params,
    };
  },
  putActionDetailSuccess: function (data) {
    return {
      type: ActionTypes.PUT_ACTION_DETAIL_SUCCESS,
      payload: data,
    };
  },
  putActionDetailError: function (error) {
    return {
      type: ActionTypes.PUT_ACTION_DETAIL_ERROR,
      payload: error,
    };
  },
  getActionDetailStart: function (params) {
    return {
      type: ActionTypes.GET_ACTION_DETAIL_START,
      params,
    };
  },
  getActionDetailSuccess: function (data) {
    return {
      type: ActionTypes.GET_ACTION_DETAIL_SUCCESS,
      payload: data,
    };
  },
  getActionDetailError: function (error) {
    return {
      type: ActionTypes.GET_ACTION_DETAIL_ERROR,
      payload: error,
    };
  },
  deleteAActionStart: function (params) {
    return {
      type: ActionTypes.DELETE_A_ACTION_START,
      params,
    };
  },
  deleteAActionSuccess: function (data) {
    return {
      type: ActionTypes.DELETE_A_ACTION_SUCCESS,
      payload: data,
    };
  },
  deleteAActionError: function (error) {
    return {
      type: ActionTypes.DELETE_A_ACTION_ERROR,
      payload: error,
    };
  },
  postExecuteActionStart: function (params) {
    return {
      type: ActionTypes.POST_EXECUTE_ACTION_START,
      params,
    };
  },
  postExecuteActionSuccess: function (data) {
    return {
      type: ActionTypes.POST_EXECUTE_ACTION_SUCCESS,
      payload: data,
    };
  },
  postExecuteActionError: function (error) {
    return {
      type: ActionTypes.POST_EXECUTE_ACTION_ERROR,
      payload: error,
    };
  },
  deleteActionFolderStart: function (params) {
    return {
      type: ActionTypes.DELETE_ACTION_FOLDER_START,
      params,
    };
  },
  deleteActionFolderSuccess: function (data) {
    return {
      type: ActionTypes.DELETE_ACTION_FOLDER_SUCCESS,
      payload: data,
    };
  },
  deleteActionFolderError: function (error) {
    return {
      type: ActionTypes.DELETE_ACTION_FOLDER_ERROR,
      payload: error,
    };
  },
};
