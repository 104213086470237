import { api } from './api';

export const getListTagAPI = (folderId) => {
  return api.getMethod(`/organizer/tags/folder/${folderId}`);
};

export const getLIstTagFolderAPI = () => {
  return api.getMethod(`/organizer/tags`);
};

export const putTagInfoAPI = ({ userID, body }) => {
  return api.putMethod(`organizer/subscribers/${userID}/update-tag-info`, body);
};

export const getFilterTagsAPI = (keyword) => {
  return api.getMethod(`organizer/tags/filter`, { keyword });
};

export const putFolderTagAPI = ({ folderID, folderName }) => {
  return api.putMethod(`/organizer/tags/folder/${folderID}/update?name=${encodeURIComponent(folderName)}`);
};

export const postFolderTagAPI = (name) => {
  return api.postMethod(`/organizer/tags/folder/create?name=${encodeURIComponent(name)}`);
};

export const postNewTagAPI = (body) => {
  return api.postMethod('/organizer/tags/create', body);
};

export const putTagDetailAPI = ({ tagID, body }) => {
  return api.putMethod(`/organizer/tags/${tagID}/update`, body);
};

export const getTagDetailAPI = (tagID) => {
  return api.getMethod(`/organizer/tags/${tagID}`);
};

export const deleteTagAPI = (tagID) => {
  return api.deleteMethod(`/organizer/tags/${tagID}/delete`);
};

export const deleteTagFolderAPI = (folderID) => {
  return api.deleteMethod(`/organizer/tags/folder/${folderID}/delete`);
};
