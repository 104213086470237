import { api } from './api';

export const getUserDetailAPI = (userId) => {
  return api.getMethod(`/organizer/subscribers/${userId}`);
};

export const putUserInformationAPI = ({ userId, body }) => {
  return api.putMethod(`/organizer/subscribers/${userId}/update-additional-info`, body);
};

export const getListTagAPI = (folderId) => {
  return api.getMethod(`/organizer/tags/${folderId}`);
};

export const getLIstTagFolderAPI = () => {
  return api.getMethod(`/organizer/tags`);
};

export const putTagInfoAPI = ({ userID, body }) => {
  return api.putMethod(`organizer/subscribers/${userID}/update-tag-info`, body);
};

export const getFilterTagsAPI = (keyword) => {
  return api.getMethod(`organizer/tags/filter?keyword=${keyword}`);
};
