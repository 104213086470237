import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

function SelectComponent({ field, form, options = [], wrapperClass = '', idWrapper, labelInput, ...props }) {
  return (
    <div style={{ position: 'relative' }} id={idWrapper} className={`select ${wrapperClass}`}>
      {labelInput ? <span className="label-input">{labelInput}</span> : ''}
      <Select {...props}>
        {options.map((item, index) => (
          <Option key={index} value={item.key}>
            {item.value}
          </Option>
        ))}
      </Select>
      {form?.errors[field.name] && form?.touched[field.name] && (
        <div className="required message message-error">{form?.errors[field.name]}</div>
      )}
    </div>
  );
}

export default SelectComponent;
