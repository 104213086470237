/* eslint-disable indent */
import { ActionTypes } from "./actions";

const DEFAULT_STATE = {
  isLoading: false,
  isLoginSuccess: null,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_LOGIN_START:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.GET_LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("role", action.payload.role);
      const organizerId = action.payload.organizerId;
      if (organizerId) {
        localStorage.setItem("organizerId", organizerId);
      }
      return {
        ...state,
        isLoading: false,
        isLoginSuccess: true,
      };
    case ActionTypes.GET_LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        isLoginSuccess: false,
      };
    default:
      return state;
  }
};
