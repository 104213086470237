export const ActionTypes = {
  GET_LOGIN_START: "GET_LOGIN_START",
  GET_LOGIN_SUCCESS: "GET_LOGIN_SUCCESS",
  GET_LOGIN_ERROR: "GET_LOGIN_ERROR",
};

export const actions = {
  getLoginStart: function (params) {
    return {
      type: ActionTypes.GET_LOGIN_START,
      params: params,
    };
  },
  getLoginSuccess: function (token, role, organizerId) {
    return {
      type: ActionTypes.GET_LOGIN_SUCCESS,
      payload: { token, role, organizerId },
    };
  },
  getLoginError: function (error) {
    return {
      type: ActionTypes.GET_LOGIN_ERROR,
      payload: error,
    };
  },
};
