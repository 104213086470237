import { put, takeLatest } from 'redux-saga/effects';
import { actions, ActionTypes } from './actions';
import { getListUserAPI, putUserStatusAPI } from '../../services/ListUserRequest';
import { RESPONSE_STATUS } from '../../common/ResponseCode';
import { actions as appActions } from '../Home/actions';

function* getListUser({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield getListUserAPI(params);
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getListUserSuccess(response));
    } else {
      yield put(actions.getListUserError(response));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.getListUserError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* putUserStatus({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield putUserStatusAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.putUserStatusSuccess(response));
    } else {
      yield put(actions.putUserStatusError(response));
      yield put(appActions.hideLoadingEvent());
    }
  } catch (e) {
    yield put(appActions.hideLoadingEvent());
    yield put(actions.putUserStatusError(e));
  }
}

export function* watchListUser() {
  yield takeLatest(ActionTypes.GET_LIST_USER, getListUser);
  yield takeLatest(ActionTypes.PUT_USER_STATUS, putUserStatus);
}
