export class ResponseCode {
  static OK = 200;
}

export const RESPONSE_STATUS = {
  SUCCESS: true,
  ERROR: false,
};

export const HTTP_RESPONSE = {
  OK: 200,
  ERROR_CODE_401: 401,
};
