import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Button, Col, Row, Tabs, Switch, Space, notification} from "antd";
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CKEditor from 'ckeditor4-react-advanced';

import {api} from "../../services/api";
import {RESPONSE_STATUS} from "../../common/ResponseCode";

const {TabPane} = Tabs;

class SeminarSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enabled_comment: null,
            header: null,
            footer: null,
            // Default value
            df_enabled_comment: null,
            df_header: null,
            df_footer: null,
        };
        this.onHeaderEditorChange = this.onHeaderEditorChange.bind(this);
        this.onFooterEditorChange = this.onFooterEditorChange.bind(this);
        this.onResetForm = this.onResetForm.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
        this.onEnabledCommentChange = this.onEnabledCommentChange.bind(this);
    }


    onEnabledCommentChange(checked) {
        this.setState({enabled_comment: checked})
    }

    onHeaderEditorChange(evt) {
        this.setState({
            header: evt.editor.getData()
        });
    }

    onFooterEditorChange(evt) {
        this.setState({
            footer: evt.editor.getData()
        });
    }

    onResetForm(event) {
        this.setState({
            enabled_comment: this.state.df_enabled_comment,
            footer: this.state.df_footer,
            header: this.state.df_header,
        });
    }

    onSubmitForm() {
        const params = {
            "enabled_comment": this.state.enabled_comment,
            "footer": this.state.footer,
            "header": this.state.header
        }
        api.put("/organizer/seminar-settings/update", params).then(
            (response) => {
                if (response.data.meta.success === RESPONSE_STATUS.SUCCESS) {
                    this.setState({
                        df_enabled_comment: this.state.enabled_comment,
                        df_footer: this.state.footer,
                        df_header: this.state.header,
                    });
                    notification.success({message: "設定が保存されました。"});
                } else {
                    notification.error({message: response.data.meta?.error_data?.error_msg});
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    getDetail() {
        api.get("/organizer/seminar-settings/detail").then(
            (response) => {
                if (response.data.meta.success === RESPONSE_STATUS.SUCCESS) {
                    this.setState({
                        enabled_comment: response.data.data.enabled_comment,
                        footer: response.data.data.footer,
                        header: response.data.data.header,

                        df_enabled_comment: response.data.data.enabled_comment,
                        df_footer: response.data.data.footer,
                        df_header: response.data.data.header
                    });
                } else {
                    notification.error({message: response.data.meta?.error_data?.error_msg});
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    ckEditorConfig = {
        toolbar: [
            ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript'],
            ['TextColor', 'BGColor', 'Font', 'FontSize' ],
            ['Maximize', 'ShowBlocks'],
            ['Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates'],
            ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'],
            ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt'],
            ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-','JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl'],
            ['Link', 'Unlink', 'Anchor'],
            ['Image', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe']
        ]
    };

    componentDidMount() {
        setTimeout(() => this.getDetail(), 500);
    }

    render() {
        return (
            <div style={{width: "85%", margin: "auto"}}>
                <Row>
                    <Col span={24}>
                        <h2 style={{color: "#3BCC80"}}>セミナーページ設定</h2>
                        <hr/>
                        <b>コメント機能</b>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div style={{display: "flex", marginTop: "20px"}}  className="div-enabed-comment">
                            <p style={{marginRight: "20px"}}>セミナーページでコメント機能を利用できます</p>
                            <Switch style={{color: "red"}} checked={this.state.enabled_comment} onChange={this.onEnabledCommentChange} />
                        </div>
                        <br/>
                        <span><b>セミナーページのデザイン</b></span>
                        <Row>
                            <Col span={24}>
                                <Tabs defaultActiveKey="1" className="seminar-setting-tabs"  type="card">
                                    <TabPane tab="ヘッダー" key="1">
                                        <Col span={24}>
                                            <br/>
                                            <p><b>※プレビュー</b></p>
                                            <div style={{height: "250px", border: "1px solid #E5E5E5", overflow: "auto"}}><EditorPreview data={this.state.header}/></div>
                                        </Col>
                                        <Row>
                                            <Col span={24}>
                                                <br/>
                                                <br/>
                                                <p><b>※内容</b></p>
                                                <div>
                                                    <CKEditor
                                                        config={ this.ckEditorConfig }
                                                        data={this.state.header}
                                                        content={this.state.header}
                                                        value={this.state.header}
                                                        onChange={this.onHeaderEditorChange}/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tab="フッター" key="2">
                                        <Col span={24}>
                                            <br/>
                                            <p><b>※プレビュー</b></p>
                                            <div style={{height: "250px", border: "1px solid #E5E5E5", overflow: "auto"}}><EditorPreview data={this.state.footer}/></div>
                                        </Col>
                                        <Row>
                                            <Col span={24}>
                                                <br/>
                                                <br/>
                                                <p><b>※内容</b></p>
                                                <div>
                                                    <CKEditor
                                                        config={ this.ckEditorConfig }
                                                        data={this.state.footer}
                                                        content={this.state.footer}
                                                        value={this.state.footer}
                                                        onChange={this.onFooterEditorChange}/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </Row>

                    </Col>
                </Row>
                <Row span={24} style={{display: "flex", marginTop: "25px"}} className="text-center">
                    <Col span={10}/>
                    <Col>
                        <Button className="button button-close" style={{paddingLeft: "35px", paddingRight: "35px"}} onClick={this.onResetForm}>
                            リセット
                        </Button>
                        <Button style={{marginLeft: "30px", paddingLeft: "45px", paddingRight: "45px"}} className="button button-save" onClick={this.onSubmitForm}>
                            保存
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }
}


class EditorPreview extends Component {
    render() {
        return (
            <div style={{width: "99%"}} className="editor-preview">
                <div dangerouslySetInnerHTML={{__html: this.props.data}}/>
            </div>
        );
    }
}

EditorPreview.defaultProps = {
    data: ''
};

EditorPreview.propTypes = {
    data: PropTypes.string
};


const mstp = (state) => ({});
const mdtp = (dispatch) => ({});

export default connect(mstp, mdtp)(withTranslation()(SeminarSetting));
