export const ActionTypes = {
  PUSH_FILE_TO_S3_STORE_START: 'PUSH_FILE_TO_S3_STORE_START',
  PUSH_FILE_TO_S3_STORE_SUCCESS: 'PUSH_FILE_TO_S3_STORE_SUCCESS',
  PUSH_FILE_TO_S3_STORE_ERROR: 'PUSH_FILE_TO_S3_STORE_ERROR',
  PUSH_THUMBNAIL_IMAGE_TO_S3_START: 'PUSH_THUMBNAIL_IMAGE_TO_S3_START',
  PUSH_THUMBNAIL_IMAGE_TO_S3_SUCCESS: 'PUSH_THUMBNAIL_IMAGE_TO_S3_SUCCESS',
  PUSH_THUMBNAIL_IMAGE_TO_S3_ERROR: 'PUSH_THUMBNAIL_IMAGE_TO_S3_ERROR',
  PUSH_IMAGE_TO_S3_START: 'PUSH_IMAGE_TO_S3_START',
  PUSH_IMAGE_TO_S3_SUCCESS: 'PUSH_IMAGE_TO_S3_SUCCESS',
  PUSH_IMAGE_TO_S3_ERROR: 'PUSH_IMAGE_TO_S3_ERROR',
  RESET_TYPE_OF_ACTION: 'RESET_TYPE_OF_ACTION',
};

export const actions = {
  pushFileToS3Start: function (params) {
    return {
      type: ActionTypes.PUSH_FILE_TO_S3_STORE_START,
      params,
    };
  },
  pushFileToS3Success: function (data) {
    return {
      type: ActionTypes.PUSH_FILE_TO_S3_STORE_SUCCESS,
      payload: data,
    };
  },
  pushFileToS3Error: function (error) {
    return {
      type: ActionTypes.PUSH_FILE_TO_S3_STORE_ERROR,
      payload: error,
    };
  },
  pushThumbnailImageToS3Start: function (params) {
    return {
      type: ActionTypes.PUSH_THUMBNAIL_IMAGE_TO_S3_START,
      params,
    };
  },
  pushThumbnailImageToS3Success: function (data) {
    return {
      type: ActionTypes.PUSH_THUMBNAIL_IMAGE_TO_S3_SUCCESS,
      payload: data,
    };
  },
  pushThumbnailImageToS3Error: function (error) {
    return {
      type: ActionTypes.PUSH_THUMBNAIL_IMAGE_TO_S3_ERROR,
      payload: error,
    };
  },
  pushImageToS3Start: function (params) {
    return {
      type: ActionTypes.PUSH_IMAGE_TO_S3_START,
      params,
    };
  },
  pushImageToS3Success: function (data) {
    return {
      type: ActionTypes.PUSH_IMAGE_TO_S3_SUCCESS,
      payload: data,
    };
  },
  pushImageToS3Error: function (error) {
    return {
      type: ActionTypes.PUSH_IMAGE_TO_S3_ERROR,
      payload: error,
    };
  },
};
