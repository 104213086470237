import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Router, Switch, Route } from 'react-router-dom';
import { ConfigProvider, Empty } from 'antd';
import { browserHistory } from './utils/history';
import './App.css';
import './sass/app.scss';
import DefaultLayout from './pages/layout/default';
import { AppConst } from './common/AppConst';
import routes from './Routes';
import { checkAuth } from './utils/auth';
import SecurityService from './services/security_service';
import Login from './pages/Login';
import LandingPagePage from './pages/LandingPage';
import PresentingPage from './pages/PresentingPage';
import ja_JP from 'antd/lib/locale-provider/ja_JP';
import ShorternUrl from './pages/ShorternUrl';

class App extends Component {
  componentDidMount() {
    const currentRoute = this.getCurrentRoute();
    if (/\/s\//g.test(this.props.location.pathname)) {
      return;
    } else if (!['Login', 'LoginPage', 'LandingPagePage', 'PresentingPage', 'ShortenUrl'].includes(currentRoute.name)) {
      checkAuth();
    }
  }

  getCurrentRoute = () => {
    if (/\/s\//g.test(this.props.location.pathname)) {
      return {
        name: 'ShortenUrl',
      };
    }
    const route = routes.find((item) => {
      return this.props.location.pathname.includes(item.pathActive);
    });
    if (this.props.location.pathname === '/') {
      window.location.replace(this.goToHome());
    }
    return route || {};
  };

  goToHome = () => {
    return SecurityService.isAdmin() ? AppConst.ROUTE.ORGANIZER_MANAGEMENTS : AppConst.ROUTE.SEMINAR_MANAGEMENTS;
  };

  render() {
    const currentRoute = this.getCurrentRoute();
    const { t } = this.props;

    switch (currentRoute.name) {
      case 'LoginPage':
        return (
          <div className="App bg-app">
            <Router history={browserHistory}>
              <Switch>
                <Route path={AppConst.ROUTE.LOGIN} render={(props) => <Login {...props} />} />
              </Switch>
            </Router>
          </div>
        );
      case 'ShortenUrl':
        return (
          <div className="App bg-app">
            <Router history={browserHistory}>
              <Switch>
                <Route path={AppConst.ROUTE.SHORTEN_URL} render={(props) => <ShorternUrl {...props} />} />
              </Switch>
            </Router>
          </div>
        );
      case 'LandingPagePage':
        return (
          <div className="App bg-app">
            <Router history={browserHistory}>
              <Switch>
                <Route path={currentRoute.path} render={(props) => <LandingPagePage {...props} />} />
              </Switch>
            </Router>
          </div>
        );
      case 'PresentingPage':
        return (
          <div className="App bg-app">
            <Router history={browserHistory}>
              <Switch>
                <Route path={currentRoute.path} render={(props) => <PresentingPage {...props} />} />
              </Switch>
            </Router>
          </div>
        );
      default:
        return (
          <ConfigProvider
            locale={ja_JP}
            autoInsertSpaceInButton={false}
            renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('message.MSG_07')} />}
          >
            <div className="App bg-app">
              <Router history={browserHistory}>
                <Switch>
                  <Route path={currentRoute.path} render={(props) => <DefaultLayout {...props} t={this.props.t} />} />
                </Switch>
              </Router>
            </div>
          </ConfigProvider>
        );
    }
  }
}

export default withTranslation()(App);
