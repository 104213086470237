import React from 'react';
import 'antd/dist/antd.css';
import {browserHistory} from "../../../browser-history";
import {Table, Space, Input, Row, notification, Button, Tooltip} from "antd";
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import { api } from "../../../services/api";
import {ResponseCode} from "../../../common/ResponseCode";
import Popconfirm from "antd/es/popconfirm";
import { AppConst } from "../../../common/AppConst";
import './index.css';

export default class OrganizerList extends React.Component {
  constructor(pros) {
    super(pros);
    this.state = this.defaultState;
  }

  defaultState = {
    keyword: "",
    organizers: [],
    filteredInfo: null,
    sortedInfo: null,
    page: 1,
    perPage: 10,
    total: 0,
    orderBy: null,
    orderDirection: null,
  };

  componentDidMount() {
    const { keyword, page, perPage, orderBy, orderDirection } = this.state;
    this.search(keyword, page, perPage, orderBy, orderDirection);
  }


  handleChange = (pagination, filters, sorter) => {
    const keyword = this.state.keyword;
    const page = pagination.current;
    const perPage = pagination.pageSize;
    const orderBy = sorter.column?.sortName;
    const orderDirection = this.getOrderDirection(sorter.order);

    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
      keyword: this.state.keyword,
      page: pagination.current,
      perPage: pagination.pageSize,
      orderBy: orderBy,
      orderDirection: orderDirection,
    });

    this.search(keyword, page, perPage, orderBy, orderDirection);
  };

  search = (keyword, page, perPage, orderBy, orderDirection) => {
    const params = {keyword, perPage, page, orderBy, orderDirection};
    this.setState({
      loading: true,
    });
    api.get("/admin/organizers/filter", params).then(
        (response) => {
          if (response.status === ResponseCode.OK) {
            let seminars = response.data?.data?.elements;
            this.setState({
              loading: false,
              keyword: "",
              organizers: seminars,
              total: response.data?.data?.paging?.total
            });
          } else {
            this.setState({ organizers: [], loading: false, });
          }
        },
        (error) => {
          this.setState({ organizers: [], loading: false, });
        }
    );
  };


  onChangeKeyword = (event) => {
    const newKeyword = event.target.value;
    const newPage = 1;
    this.search(newKeyword, newPage, this.perPage);
    this.setState({keyword: newKeyword, page: newPage});
  };


  deleteOrganizer = (organizerId) => () => {
    api.delete("/admin/organizers/" + organizerId + "/delete").then((response) => {
      if (response.status === ResponseCode.OK) {
        this.setState({
          organizers: this.state.organizers.filter((e) => e.id !== organizerId),
        });
      }
    });
  };

  detailOrganizer = (organizerId) => {
    browserHistory.push(AppConst.ROUTE.ADMIN_ORGANIZER_DETAIL + organizerId);
    window.location = AppConst.ROUTE.ADMIN_ORGANIZER_DETAIL + organizerId;
  }

  updateStatus = (organizerId, status) => () => {
    api.put("/admin/organizers/" + organizerId + "/update-enable-status" + "?status=" + status).then((response) => {
      if (response.status === ResponseCode.OK && response.data.success) {
        notification.success({message: AppConst.SUCCESS, description: "" });
        this.setState({
          organizers: this.state.organizers.map((item) => {
            if (item.id === organizerId) {
              item.enabled = status
            }
            return item;
          }),
        });
        return;
      }
      notification.error({message: response?.data?.meta?.error_data?.error_msg, description: "" });
    });
  };

  getOrderDirection(orderAlias) {
    switch (orderAlias) {
      case "descend":
        return  "DESC";
      case "ascend":
        return "ASC"
      default:
        return null;
    }
  }

  addNew = () => {
    browserHistory.push(AppConst.ROUTE.ORGANIZER_CREATION);
    window.location = AppConst.ROUTE.ORGANIZER_CREATION;
  }

  render() {
    let { sortedInfo, page, perPage } = this.state;
    const { t } = this.props;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'No',
        dataIndex: 'id',
        key: 'id',
        render: (text, record, index) => (<div>{(page - 1)*perPage + (index + 1)}</div>),
        width: '5%',
        ellipsis: true,
        align: "left"
      },
      {
        title: 'アカウント',
        width: '10%',
        dataIndex: 'account',
        key: 'account',
        sortName: "account",
        sortOrder: sortedInfo.columnKey === 'account' && sortedInfo.order,
        render: (text, record) => (
          <div className="step4-tooltip">
            <Tooltip color="#50d890" overlayClassName="tooltip-message-content" placement="bottomLeft" title={text}>
              <div className="tooltip-message-content">{text}</div>
            </Tooltip>
          </div>),
        ellipsis: true,
        align: "left"
      },
      {
        title: 'メール',
        width: '15%',
        dataIndex: 'email',
        key: 'email',
        sortName: "email",
        sortOrder: sortedInfo.columnKey === 'email' && sortedInfo.order,
        render: (text, record) => (
          <div className="step4-tooltip">
            <Tooltip color="#50d890" overlayClassName="tooltip-message-content" placement="bottomLeft" title={text}>
              <div className="tooltip-message-content">{text}</div>
            </Tooltip>
          </div>),
        ellipsis: true,
        align: "left"
      },
      {
        title: '会社名',
        width: '10%',
        dataIndex: 'company_name',
        key: 'company_name',
        sortName: "companyName",
        sortOrder: sortedInfo.columnKey === 'company_name' && sortedInfo.order,
        render: (text, record) => (
          <div className="step4-tooltip">
            <Tooltip color="#50d890" overlayClassName="tooltip-message-content" placement="bottomLeft" title={text}>
              <div className="tooltip-message-content">{text}</div>
            </Tooltip>
          </div>),
        ellipsis: true,
        align: "center",
      },
      {
        title: '住所',
        width: '15%',
        dataIndex: 'company_address',
        key: 'company_address',
        sortName: "companyAddress",
        sortOrder: sortedInfo.columnKey === 'company_address' && sortedInfo.order,
        render: (text, record) => (
          <div className="step4-tooltip">
            <Tooltip color="#50d890" overlayClassName="tooltip-message-content" placement="bottomLeft" title={text}>
              <div className="tooltip-message-content">{text}</div>
            </Tooltip>
          </div>),
        ellipsis: true,
        align: "left"
      },
      {
        title: '電話番号',
        width: '10%',
        dataIndex: 'company_phone_no',
        key: 'company_phone_no',
        sortName: "companyPhoneNo",
        sortOrder: sortedInfo.columnKey === 'company_phone_no' && sortedInfo.order,
        render: (text, record) => (
          <div className="step4-tooltip">
            <Tooltip color="#50d890" overlayClassName="tooltip-message-content" placement="bottomLeft" title={text}>
              <div className="tooltip-message-content">{text}</div>
            </Tooltip>
          </div>),
        ellipsis: true,
        align: "left"
      },
      {
        title: '郵便番号',
        width: '10%',
        dataIndex: 'company_postal_code',
        key: 'company_postal_code',
        render: (text, record) => (
          <div className="step4-tooltip">
            <Tooltip color="#50d890" overlayClassName="tooltip-message-content" className="step4-tooltip" placement="bottomLeft" title={text}>
              <div className="tooltip-message-content">{text}</div>  
            </Tooltip>
          </div>),
        ellipsis: true,
        align: "left"
      },
      {
        title: '様態',
        width: '10%',
        dataIndex: 'enabled',
        key: 'enabled',
        render: (text, record) => (
          <div>
            <Tooltip color="#50d890" overlayClassName="tooltip-message-content" className="step4-tooltip" placement="bottomLeft" title={text ? 'アクティブ' : '停止'}>{text ? 'アクティブ' : '停止'}</Tooltip>
          </div>),
        ellipsis: true,
        align: "left"
      },
      {
        title: '',
        key: 'action',
        width: '20%',
        align: "center",
        render: (text, record) => (
            <div size="middle" align="center">
              <Button type="text" className="organizer-detail" onClick={() => this.detailOrganizer(record.id)}>
                詳細
              </Button>
              {record?.enabled ? (
                <Button type="text" className="organizer-stop" onClick={this.updateStatus(record.id, false)}>停止</Button>
              ) : (
                <Button type="text" className="organizer-stop" onClick={this.updateStatus(record.id, true)}>再開</Button>
              )}
              <Popconfirm
                  placement="topRight"
                  title={t('common.confirm_delete_message')}
                  onConfirm={this.deleteOrganizer(record.id)}
                  okText={t('common.yes')}
                  cancelText={t('common.no')}
              >
                <Button type="text" className="organizer-delete">削除</Button>
              </Popconfirm>
            </div>
        ),
      }
    ];
    return (
        <div className="ant-col-22 ant-col-offset-1">
          <>
            <Row>
              <div className="account-management-title">
                {t('organizers_list.page_title')}
              </div>
            </Row>
            <Row>
              <div className="search-bar">
                <Input className="search-input" placeholder={t('organizers_list.input_search_placeholder')} size="large" onChange={this.onChangeKeyword} suffix={<SearchOutlined />} />
                <Button onClick={this.addNew} className="create-seminar-button" type="text">{t('organizers_list.create_btn_label')}</Button>
              </div>
            </Row>
            <Table columns={columns} dataSource={this.state.organizers} onChange={this.handleChange}
              bordered
              loading={this.state.loading}
              rowKey="id"
              pagination={{ defaultPageSize: this.state.perPage, current: this.state.page, total: this.state.total}} />
          </>
        </div>
    );
  }
}

