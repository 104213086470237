import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table, Radio, Divider, Space, Button, Input, Tag } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { actions } from '../../pages/MessagePackage/actions';
import ErrorMessage from '../MessageError';

function SelectionMessagePackageComponent({
  getFilterMessagePackage,
  filterMessagePackage,
  setFieldValue,
  formikValues,
  indexField,
}) {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState({
    id: '',
    name: '',
  });
  const [searchText, setSearchText] = useState('');

  const columns = [
    {
      title: t('action_management.column_message_package_name'),
      dataIndex: 'name',
      render: (text) => <a>{text}</a>,
    },
    {
      render: (text, record) => (
        <>
          {formikValues.actions[indexField]?.msg_package_id === record.id ? (
            <Fragment>
              {t('common.button_choosing')}{' '}
              <Button
                className="button-choosing"
                icon={<CloseOutlined />}
                onClick={() => {
                  onSetFieldValue();
                }}
              />
            </Fragment>
          ) : (
            <Button
              className="button button-active"
              onClick={() => {
                onSetFieldValue(record.id, record.name);
              }}
            >
              {t('common.button_choose')}
            </Button>
          )}
        </>
      ),
      align: 'center',
    },
  ];

  const data = filterMessagePackage?.map((item, index) => ({
    key: index,
    id: item.id,
    name: item.name,
    emptyData: '',
  }));

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
    renderCell: (checked, record) => {
      return (
        <Space size="middle">
          <div>{record.name}</div>
          <Radio
            onClick={() =>
              setInitialValues({
                id: record.id,
                name: record.name,
              })
            }
            checked={initialValues.id === record.id}
          >
            {initialValues.id === record.id ? 'active' : 'activated'}
          </Radio>
        </Space>
      );
    },
  };

  const onSetFieldValue = (id = '', name = '') => {
    setFieldValue(`actions.${indexField}.msg_package_id`, id);
    setFieldValue(`actions.${indexField}.msg_package_name`, name);
    setSearchText('');
  };

  useEffect(() => {
    getFilterMessagePackage(searchText.trim());
  }, [searchText]);

  return (
    <Fragment>
      <div className="select-message-package__input">
        {formikValues.actions.find((action) => action.type === 'MESSAGE_PACKAGE')?.msg_package_name ? (
          <Tag>{formikValues.actions.find((action) => action.type === 'MESSAGE_PACKAGE').msg_package_name}</Tag>
        ) : (
          ''
        )}
        <Input
          placeholder={t('action_management.placeholder_search_message_package')}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
      <ErrorMessage name={`actions.${indexField}.msg_package_id`} />
      <Divider />

      <Table
        bordered
        columns={columns}
        dataSource={data}
        scroll={{ y: 500 }}
        selectedRowKeys={[1]}
        pagination={false}
        className="select-message-package__table"
      />
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  filterMessagePackage: state.messagePackageReducer.filterMessagePackage,
});

const mapDispatchToProps = (dispatch) => ({
  getFilterMessagePackage: (keyword) => dispatch(actions.getFilterMessagePackageStart(keyword)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectionMessagePackageComponent);
