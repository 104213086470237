import { AppConst } from '../../common/AppConst';
import SecurityService from "../../services/security_service";

export const logout = () => {
  localStorage.removeItem("token");
  window.location.replace(AppConst.ROUTE.LOGIN);
};

export const checkAuth = () => {
  if (!SecurityService.isLoggedIn()) {
    window.location.replace(AppConst.ROUTE.LOGIN);
  }
};
