import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Descriptions, Input, Button, Space, Upload, notification, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { PictureOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { actions, ActionTypes } from '../UploadVideoModal/actions';
import { actions as appActions } from '../../pages/Home/actions';
import SelectComponent from '../Select';
import { IMAGE_SIZE_OPTIONS } from '../../common/AppConst';
import { getFileName, getFileNameWithoutExtension } from '../../utils';
import IconComputerFolder from '../../resources/assets/IconComputerFolder';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};

function ModalUpdateImage({
  isVisible,
  toggleModal,
  imageFileURL,
  uploadImage,
  type,
  resetType,
  handleChangeModal,
  imageURLValue,
  imageSizeValue,
  imageLinkValue,
  saveLabel,
  organizerIdAdmin,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const organizerID = localStorage.getItem('organizerId') || organizerIdAdmin;
  const isAdmin = !localStorage.getItem('organizerId');
  const [initialValues, setInitialValues] = useState({
    imageUrl: '',
    imageSize: '',
    link: '',
  });

  useEffect(() => {
    setInitialValues((prevState) => ({
      ...prevState,
      imageUrl: imageURLValue,
      imageSize: imageSizeValue,
      link: imageLinkValue,
    }));
  }, [isVisible]);

  useEffect(() => {
    switch (type) {
      case ActionTypes.PUSH_IMAGE_TO_S3_SUCCESS:
        setInitialValues({
          ...initialValues,
          imageUrl: imageFileURL.data.file_url,
        });
        resetType();
        return;
      default:
        return;
    }
  }, [type]);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png';
    const isLt1M = file.size / 1024 / 1024 < 1;

    if (!isJpgOrPng) {
      notification.error({ message: t('message.MSG_15') });
    } else if (!isLt1M) {
      notification.error({ message: t('message.MSG_08') });
    }

    return isJpgOrPng && isLt1M;
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      uploadImage({
        isAdmin,
        organizerID,
        file: info.file.originFileObj,
        type: 'IMAGE',
        fileName: getFileNameWithoutExtension(info.file.name),
      });
      getBase64(info.file.originFileObj, (imageUrl) => {
        setLoading(false);
      });
    }
  };

  const handleSelectChange = (value) => {
    setInitialValues({
      ...initialValues,
      imageSize: value,
    });
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setInitialValues({
      ...initialValues,
      link: value.trim(),
    });
  };

  const handleResizeImage = (value) => {
    switch (value) {
      case 'nano':
        return 'image-size-nano';
      case 'micro':
        return 'image-size-micro';
      case 'kilo':
        return 'image-size-kilo';
      case 'mega':
        return 'image-size-mega';
      case 'giga':
        return 'image-size-giga';
      default:
        return '';
    }
  };

  const handleCloseModal = () => {
    setInitialValues({
      imageUrl: imageURLValue,
      imageSize: imageSizeValue,
      link: imageLinkValue,
    });
    toggleModal();
  };

  const handleSubmit = () => {
    handleChangeModal(initialValues);
    setInitialValues({
      imageUrl: '',
      imageSize: '',
      link: '',
    });
    toggleModal();
  };

  return (
    <Modal
      title={t('modal_upload_image.title')}
      className="upload-image-resize-modal"
      width={800}
      visible={isVisible}
      onCancel={toggleModal}
      closable={false}
      footer={null}
    >
      <Row gutter={16}>
        <Col className="gutter-row column-left" span={10}>
          <Descriptions layout="vertical" column={1} colon={false}>
            <Descriptions.Item label={t('modal_upload_image.label_file')} labelStyle={{ minWidth: '100px' }}>
              <Upload
                accept=".jpg, .png, .jpeg"
                name="avatar"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                customRequest={dummyRequest}
              >
                <Input readOnly addonAfter={<IconComputerFolder />} value={getFileName(initialValues.imageUrl)} />
              </Upload>
            </Descriptions.Item>
            <Descriptions.Item label={t('modal_upload_image.label_size')} labelStyle={{ minWidth: '100px' }}>
              <SelectComponent
                value={initialValues.imageSize}
                onChange={handleSelectChange}
                options={IMAGE_SIZE_OPTIONS}
              />
            </Descriptions.Item>
            <Descriptions.Item label={t('modal_upload_image.label_url')} labelStyle={{ minWidth: '100px' }}>
              <Input onChange={handleInputChange} value={initialValues.link} />
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col className="gutter-row column-right" span={14}>
          <div className="column-right__image-preview">
            {initialValues.imageUrl ? (
              <img
                alt="image preview"
                className={`image ${handleResizeImage(initialValues.imageSize)}`}
                src={initialValues.imageUrl}
              />
            ) : (
              <PictureOutlined
                style={{
                  fontSize: '128px',
                  color: '#d3d3d3',
                  margin: 'auto',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
            )}
          </div>
        </Col>
      </Row>
      <Divider />
      <div className="upload-image-modal__action">
        <Space size="middle" align="center">
          <Button className="button button-inactive" onClick={handleCloseModal}>
            {t('common.button_close')}
          </Button>
          <Button className="button button-active" onClick={handleSubmit} disabled={loading}>
            {saveLabel ? saveLabel : t('common.button_save')}
          </Button>
        </Space>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  imageFileURL: state.uploadFileReducer.image,
  type: state.uploadFileReducer.type,
});

const mapDispatchToProps = (dispatch) => ({
  uploadImage: (payload) => dispatch(actions.pushImageToS3Start(payload)),
  resetType: () => dispatch(appActions.resetTypeOfAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalUpdateImage);
