import _ from 'lodash';
import { validateStatus } from '../utils/validateStatus';
import { HTTP_RESPONSE } from '../common/ResponseCode';

const axios = require('axios');

const HEADERS = {
  // "Authorization": "Basic " + base64.encode(Config.AUTH_USER_NAME + ":" + Config.AUTH_PASSWORD),
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
  // "x-auth-token": localStorage.getItem(StorageKey.TOKEN_KEY),
  Authorization: localStorage.getItem('token'),
};

const HEADERS_MULTIPLE_PART = {
  ...HEADERS,
  'Content-Type': 'multipart/form-data; boundary=something',
  Accept: 'application/json',
};

const resetToLogin = () => {
  const promiseList = [];
  promiseList.push(localStorage.removeItem('token'));
  /* Remove console log and add notification*/
  Promise.all(promiseList)
    .then((resolvedList) => {
      console.log('Error unauthorized');
    })
    .catch((error) => {
      console.log('Error unauthorized');
    });
};

const throttledResetToLogin = _.throttle(resetToLogin, 500, {
  leading: false,
  trailing: true,
});

const checkErrorStatus = (response) => {
  if (response?.meta?.code === 0) {
    return response;
  }
  // console.log(response?.meta?.extraInfo, response?.meta?.errorCode);
  return response;
};

const getFullUrl = (url) => {
  if (!url.startsWith('/')) {
    url = '/' + url;
  }
  return `${process.env.REACT_APP_API_URL}` + url;
};

const api = {
  post: (endpoint, params) => {
    return axios.default
      .post(getFullUrl(endpoint), params, {
        headers: HEADERS,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          return response;
        },
        (err) => {
          return err.response || err;
        }
      )
      .catch(
        (response) => {
          return response;
        },
        (err) => {
          return err.response || err;
        }
      );
  },

  postMethod: (endpoint, params) => {
    return axios.default
      .post(getFullUrl(endpoint), params, {
        headers: HEADERS,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          if (response?.status === HTTP_RESPONSE.ERROR_CODE_401) {
            throttledResetToLogin(endpoint, params, response);
            return response?.data;
          }
          return checkErrorStatus(response.data);
        },
        (err) => {
          return err.response || err;
        }
      )
      .catch(
        (response) => {
          return checkErrorStatus(response.data);
        },
        (err) => {
          return err.response || err;
        }
      );
  },

  postMultiplePart: (endpoint, params, config = {}) => {
    return axios.default
      .post(getFullUrl(endpoint), params, {
        headers: HEADERS_MULTIPLE_PART,
        validateStatus: (status) => validateStatus(status),
        ...config,
      })
      .then(
        (response) => {
          return response.data;
        },
        (err) => {
          return err.response || err;
        }
      )
      .catch(
        (response) => {
          return response.data;
        },
        (err) => {
          return err.response || err;
        }
      );
  },

  get: (endpoint, params = {}) => {
    return axios.default
      .get(getFullUrl(endpoint), {
        params: params,
        headers: HEADERS,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          return response;
        },
        (err) => {
          return err.response || err;
        }
      )
      .catch(
        (response) => {
          return response;
        },
        (err) => {
          return err.response || err;
        }
      );
  },

  getMethod: (endpoint, params = {}) => {
    return axios.default
      .get(getFullUrl(endpoint), {
        params: params,
        headers: HEADERS,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          if (response?.status === HTTP_RESPONSE.ERROR_CODE_401) {
            throttledResetToLogin(endpoint, params, response);
            return response?.data;
          }
          return checkErrorStatus(response.data);
        },
        (err) => {
          return err.response || err;
        }
      )
      .catch(
        (response) => {
          return checkErrorStatus(response.data);
        },
        (err) => {
          return err.response || err;
        }
      );
  },

  put: (endpoint, params, config = {}) => {
    return axios.default
      .put(getFullUrl(endpoint), params, {
        headers: HEADERS,
        validateStatus: (status) => validateStatus(status),
        ...config,
      })
      .then(
        (response) => {
          return response;
        },
        (err) => {
          return err.response || err;
        }
      )
      .catch(
        (response) => {
          return response;
        },
        (err) => {
          return err.response || err;
        }
      );
  },

  putMethod: (endpoint, params) => {
    return axios.default
      .put(getFullUrl(endpoint), params, {
        headers: HEADERS,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          if (response?.status === HTTP_RESPONSE.ERROR_CODE_401) {
            throttledResetToLogin(endpoint, params, response);
            return response?.data;
          }
          return checkErrorStatus(response.data);
        },
        (err) => {
          return err.response || err;
        }
      )
      .catch(
        (response) => {
          return checkErrorStatus(response.data);
        },
        (err) => {
          return err.response || err;
        }
      );
  },

  putToS3: (endpoint, file, options) => {
    return axios.default
      .put(endpoint, file, options)
      .then(
        (response) => {
          return response;
        },
        (err) => {
          return err.response || err;
        }
      )
      .catch(
        (response) => {
          return response;
        },
        (err) => {
          return err.response || err;
        }
      );
  },

  delete: (endpoint, params) => {
    return axios.default
      .delete(getFullUrl(endpoint), {
        params: params,
        headers: HEADERS,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          return response;
        },
        (err) => {
          return err.response || err;
        }
      )
      .catch(
        (response) => {
          return response;
        },
        (err) => {
          return err.response || err;
        }
      );
  },

  deleteMethod: (endpoint, params) => {
    return axios.default
      .delete(getFullUrl(endpoint), {
        params: params,
        headers: HEADERS,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          if (response?.status === HTTP_RESPONSE.ERROR_CODE_401) {
            throttledResetToLogin(endpoint, params, response);
            return response?.data;
          }
          return checkErrorStatus(response.data);
        },
        (err) => {
          return err.response || err;
        }
      )
      .catch(
        (response) => {
          return checkErrorStatus(response.data);
        },
        (err) => {
          return err.response || err;
        }
      );
  },
};

export { api };
