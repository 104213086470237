import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Input, notification, Tabs, Upload } from 'antd';
import { CopyOutlined, UploadOutlined } from '@ant-design/icons';
import { api } from '../../../services/api';
import { ResponseCode } from '../../../common/ResponseCode';
import SecurityService from '../../../services/security_service';
import ModalUpdateTag from './ModalUpdateTags';
import { actions } from '../../ListUser/actions';
import { actions as tagActions } from '../../TagManagement/actions';
import { getFileName, getFileNameWithoutExtension } from '../../../utils';
import './index.css';

const { TabPane } = Tabs;

class About extends Component {
  constructor(props) {
    if (!SecurityService.isOrganizer()) {
      window.location.href = '/';
    }
    super(props);
    this.defaultState = {};
    this.state = {
      isVisibleModalTag: false,
      tag_name: [],
    };
    this.loadLpScriptInfo();
    this.loadFn0ScriptInfo();
    this.fn0Submit = this.fn0Submit.bind(this);
    this.lpSubmit = this.lpSubmit.bind(this);
  }

  copyFn0ToClipboard = (domId) => {
    navigator.clipboard
      .writeText(this.buildScript(domId, this.state.get_seminar_script))
      .then(() => notification.success({ message: 'コピーされました。' }));
  };

  copyLpToClipboard = (domId) => {
    navigator.clipboard
      .writeText(this.buildScript(domId, this.state.lp_script))
      .then(() => notification.success({ message: 'コピーされました。' }));
  };

  buildScript(domId, scriptUrl) {
    if (!scriptUrl) return '';
    return "<div id='" + domId + "'><script src='" + scriptUrl + "'></script></div>";
  }

  getFileExtension(file) {
    const fileName = file.name;
    let ext = /(?:\.([^.]+))?$/.exec(fileName)[1];
    if (!ext) {
      ext = file.type.split('/').length === 2 ? file.type.split('/')[1] : '';
    }
    return ext;
  }

  loadLpScriptInfo() {
    api.get('/organizer/' + localStorage.getItem('organizerId') + '/get-lp-script').then((response) => {
      if (response.status === ResponseCode.OK) {
        this.setState({
          lp_script: response.data.data ? response.data.data.script : null,
          lpFileName: response.data.data ? getFileName(response.data.data.btn_image) : null,
        });
      }
    });
  }

  loadFn0ScriptInfo() {
    api.get('/organizer/' + localStorage.getItem('organizerId') + '/get-seminar-list-script').then((response) => {
      if (response.status === ResponseCode.OK) {
        this.setState({
          get_seminar_script: response.data.data ? response.data.data.script : null,
          fn0FileName: response.data.data ? getFileName(response.data.data.btn_image) : null,
          tag_id: response.data.data ? response.data.data.tag_id : '',
          tag_name: response.data.data ? response.data.data.tag_name : '',
        });
      }
    });
  }

  onLpFileChange = (file) => {
    console.log(file.file);
    if (file.file.status === 'done') {
      this.setState({
        lpFile: file.file.originFileObj,
        lpFileName: file.file.name,
      });
      this.setState({
        lp_script: null,
      });
    }
  };

  onFn0Change = (file) => {
    if (file.file.status === 'done') {
      this.setState({
        file: file.file.originFileObj,
        fn0FileName: file.file.name,
      });

      this.setState({
        get_seminar_script: null,
      });
    }
  };

  toggleModalUpdateTags = () => {
    this.setState({
      isVisibleModalTag: !this.state.isVisibleModalTag,
    });
  };

  onSubmitModal = (data) => {
    console.log({ data });
    this.setState({
      tag_id: data.tag_id,
      tag_name: data.tag_name,
    });
    this.toggleModalUpdateTags();
  };

  fn0Submit = () => {
    const params = {
      'file-ext': this.getFileExtension(this.state.file),
      'content-type': this.state.file.type,
      type: 'IMAGE',
      'file-name': getFileNameWithoutExtension(this.state.file.name),
    };
    const options = {
      headers: {
        'Content-Type': this.state.file.type,
      },
    };
    api
      .get('/organizer/' + localStorage.getItem('organizerId') + '/pre-sign-url', params)
      .then((response) => {
        const targetUrl = response.data.data.first;
        const preSignUrl = response.data.data.second;
        api.putToS3(preSignUrl, this.state.file, options).then((res) => {
          api
            .post(
              '/organizer/' +
                localStorage.getItem('organizerId') +
                '/create-seminar-list-script?btn_image=' +
                response.data.data.first +
                `&tag_id=${this.state.tag_id}`
            )
            .then((r) => {
              this.setState({
                get_seminar_script: r.data.data,
              });
            });
        });
      })
      .catch((error) => console.log(error));
  };
  lpSubmit = () => {
    const params = {
      'file-ext': this.getFileExtension(this.state.lpFile),
      'content-type': this.state.lpFile.type,
      type: 'IMAGE',
      'file-name': getFileNameWithoutExtension(this.state.lpFile.name),
    };
    const options = {
      headers: {
        'Content-Type': this.state.lpFile.type,
      },
    };
    api
      .get('/organizer/' + localStorage.getItem('organizerId') + '/pre-sign-url', params)
      .then((response) => {
        const targetUrl = response.data.data.first;
        const preSignUrl = response.data.data.second;
        api.putToS3(preSignUrl, this.state.lpFile, options).then((res) => {
          api
            .post(
              '/organizer/' +
                localStorage.getItem('organizerId') +
                '/create-lp-script?btn_image=' +
                response.data.data.first
            )
            .then((r) => {
              this.setState({
                lp_script: r.data.data,
              });
            });
        });
      })
      .catch((error) => console.log(error));
  };

  componentDidMount() {
    this.props.getListTagFolder();
  }

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({ message: 'JPG、JPEG、またはPNGのファイルを選択して下さい。' });
      return false;
    }
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      notification.error({ message: '1MB以下のファイルを選択してください。' });
      return false;
    }
  };

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  render() {
    const { getListTag, getFilterTags, tagFolders, tags, filterTags } = this.props;
    const {
      lpFileName,
      fn0FileName,
      lpFile,
      lp_script,
      file,
      tag_name,
      isVisibleModalTag,
      get_seminar_script,
    } = this.state;

    return (
      <>
        <div className="container landing-settings-page">
          <h3 className="page-title title">外部ページの埋り込みコード</h3>
          <hr />

          <div>
            <Tabs defaultActiveKey="1">
              <TabPane tab="LP（ランディングページ）" key="1">
                <div>
                  <Form>
                    <Form.Item name={['setting', 'limit']} label="ボタン画像">
                      <Input readOnly value={lpFileName} />
                      <Upload
                        onChange={this.onLpFileChange}
                        showUploadList={false}
                        beforeUpload={(file) => this.beforeUpload(file)}
                        accept=".png, .jpg, .jpeg"
                        customRequest={this.dummyRequest}
                      >
                        <Button className="button button-solid" icon={<UploadOutlined />}>
                          アップロード
                        </Button>
                      </Upload>
                    </Form.Item>
                    <div>
                      <Button
                        disabled={!lpFile}
                        onClick={this.lpSubmit}
                        className={lpFile ? 'button button-solid' : 'button button-clear'}
                      >
                        登録
                      </Button>
                    </div>
                    <br />
                    <div>
                      <Input
                        readOnly={true}
                        value={this.buildScript('lp-script', lp_script)}
                        suffix={
                          <CopyOutlined
                            onClick={() => this.copyLpToClipboard('lp-script')}
                            className="site-form-item-icon"
                          />
                        }
                      />
                    </div>
                  </Form>
                </div>
              </TabPane>
              <TabPane tab="FN0（ファネルゼロ）" key="2">
                <div>
                  <Form>
                    <Form.Item name={['setting', 'limit']} label="ボタン画像">
                      <Input readOnly value={fn0FileName} />
                      <Upload
                        onChange={this.onFn0Change}
                        showUploadList={false}
                        beforeUpload={(file) => this.beforeUpload(file)}
                        accept=".png, .jpg, .jpeg"
                        customRequest={this.dummyRequest}
                      >
                        <Button icon={<UploadOutlined />} className="button button-solid">
                          アップロード
                        </Button>
                      </Upload>
                    </Form.Item>
                    <Form.Item style={{ display: 'flex' }} label="タグ付け設定">
                      <Input readOnly value={tag_name} />
                      <Button className="site-form-item-icon button button-solid" onClick={this.toggleModalUpdateTags}>
                        選択
                      </Button>
                    </Form.Item>
                    <div>
                      <Button
                        disabled={!file}
                        onClick={this.fn0Submit}
                        className={file ? 'button button-solid' : 'button button-clear'}
                      >
                        登録
                      </Button>
                    </div>
                    <br />
                    <div>
                      <Input
                        readOnly={true}
                        value={this.buildScript('fn0-script', get_seminar_script)}
                        suffix={
                          <CopyOutlined
                            onClick={() => this.copyFn0ToClipboard('fn0-script')}
                            className="site-form-item-icon"
                          />
                        }
                      />
                    </div>
                  </Form>
                </div>
              </TabPane>
            </Tabs>
            <ModalUpdateTag
              initialTag={tag_name}
              isVisible={isVisibleModalTag}
              toggleModal={this.toggleModalUpdateTags}
              tagFolders={tagFolders}
              getListTag={getListTag}
              tags={tags}
              onSubmitModal={this.onSubmitModal}
              getFilterTags={getFilterTags}
              filterTags={filterTags}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetail: state.userDetailReducer.user,
  typeOfAction: state.userDetailReducer.type,
  typeOfTagAction: state.tagManagementReducer.type,
  tagFolders: state.tagManagementReducer.tagFolders,
  tags: state.tagManagementReducer.tags,
  filterTags: state.tagManagementReducer.filterTags,
});

const mapDispatchToProps = (dispatch) => ({
  getUserDetail: (userId) => dispatch(actions.getUserDetailStart(userId)),
  updateUserDetail: (payload) => dispatch(actions.putUserInformationStart(payload)),
  getListTag: (folderId) => dispatch(tagActions.getListTagStart(folderId)),
  getListTagFolder: () => dispatch(tagActions.getListTagFolderStart()),
  putTagInformation: (payload) => dispatch(tagActions.putTagInformationStart(payload)),
  getFilterTags: (keyword) => dispatch(tagActions.getFilterTagStart(keyword)),
});

export default connect(mapStateToProps, mapDispatchToProps)(About);
