import React, { Fragment, useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Row, Col, Descriptions, Button, Space, Table, Popconfirm } from 'antd';
import moment from 'moment';
import { Formik, Field } from 'formik';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { FORMAT_TIME_LONG, PAGE_SIZE } from '../../common/AppConst';
import InputComponent from '../../components/Input';
import SelectComponent from '../../components/Select';
import DatePickerComponent from '../../components/DatePickerComponent';
import { getStatusOptions, FORMAT_TIME_DAY_HOUR_MINUTES } from '../../common/AppConst';
import { connect } from 'react-redux';
import { actions, ActionTypes } from './actions';
import { actions as appActions } from '../Home/actions';

function RichMenuManagementPage({
  getListRichMenu,
  listRichMenu,
  resetType,
  type,
  deleteRichMenu,
  getActiveRichMenu,
  activeRichMenu,
}) {
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = useState({
    search: '',
    status: '',
    fromDate: '',
    toDate: '',
  });
  const [currentValues, setCurrentValues] = useState(initialValues);
  const [currentPage, setCurrentPage] = useState(1);
  const prevCurrentValues = usePrevious(currentValues);

  const deleteMenu = (menuID) => () => {
    deleteRichMenu({ menuID });
  };

  const columns = [
    {
      title: t('rich_menu_management.table_column_image'),
      dataIndex: 'image',
      key: 'image',
      render: (imageUrl) => <img src={imageUrl} className="rich-menu__form__image" />,
      width: '10%',
    },
    {
      title: t('rich_menu_management.table_column_title'),
      dataIndex: 'title',
      key: 'title',
      width: '20%',
    },
    {
      title: t('rich_menu_management.table_column_action'),
      dataIndex: 'actions',
      key: 'actions',
      width: '20%',
      render: (items) => (
        <ul>
          {items?.map((item) => (
            <li key={item.id}>
              {(item.type === 'TEXT' && (
                <Fragment>
                  <span>{!!item.data && t('create_rich_menu.select_action_text') + ' - ' + item.data}</span>
                  <span>{!!item.data && !!item.response_action_name && 'と'}</span>
                  <span>{!!item.response_action_name && 'アクション' + ' - ' + item.response_action_name}</span>
                </Fragment>
              )) ||
                (item.type === 'LINK' && <span>{t('create_rich_menu.select_action_link') + ' - ' + item.data}</span>) ||
                (item.type === 'NONE' && t('create_rich_menu.select_action_no_action'))}
            </li>
          ))}
        </ul>
      ),
    },
    {
      title: t('rich_menu_management.table_column_status'),
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      render: (value) => <div>{getStatusOptions(t).find((item) => item.key === value)?.value}</div>,
      align: 'center',
    },
    {
      title: t('rich_menu_management.table_column_time'),
      key: 'time',
      dataIndex: 'time',
      width: '20%',
      render: (text) => (
        <Fragment>
          {text.startAt ? (
            <span>{moment(text.startAt).format(FORMAT_TIME_DAY_HOUR_MINUTES)}</span>
          ) : (
            t('rich_menu_management.text_not_set')
          )}
          {' ~ '}
          {text.endAt ? (
            <span>{moment(text.endAt).format(FORMAT_TIME_DAY_HOUR_MINUTES)}</span>
          ) : (
            t('rich_menu_management.text_not_set')
          )}
        </Fragment>
      ),
      align: 'center',
    },
    {
      title: '',
      key: 'action',
      render: ({ key, status }) => (
        <Space size="middle" direction="vertical">
          <Button
            type="text"
            className="button button-solid"
            href={`/organizer/rich-menu/edit/${key}`}
            disabled={status === 'ACTIVATE'}
          >
            {t('common.edit')}
          </Button>
          <Popconfirm
            placement="topRight"
            title={t('common.confirm_delete_message')}
            onConfirm={deleteMenu(key)}
            okText={t('common.yes')}
            cancelText={t('common.no')}
          >
            <Button type="text" className="button button-inactive">
              {t('rich_menu_management.button_delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
      align: 'center',
      width: '10%',
    },
  ];

  const data = listRichMenu?.elements?.map((item) => ({
    key: item.id,
    image: item.image_url,
    title: item.title,
    actions: item.rich_menu_actions,
    status: item.status,
    time: {
      startAt: item.start_at,
      endAt: item.end_at,
    },
  }));

  const onChangeInput = (setFieldValue, fieldName, inputType) => (e) => {
    if (inputType === 'input') {
      const value = e.target.value;

      setFieldValue(fieldName, value);
      setCurrentValues({
        ...currentValues,
        search: value.trim(),
      });
    } else if (inputType === 'select') {
      setFieldValue(fieldName, e);
      setCurrentValues({
        ...currentValues,
        status: e,
      });
    }
  };

  const onChangeDatePicker = (setFieldValue, fieldName) => (date, dateString) => {
    setFieldValue(fieldName, dateString);
    if (fieldName === 'fromDate') {
      if (!date) {
        setCurrentValues({
          ...currentValues,
          fromDate: '',
        });
      } else {
        setCurrentValues({
          ...currentValues,
          fromDate: moment(date).startOf('day').format(FORMAT_TIME_LONG),
        });
      }
    } else {
      if (!date) {
        setCurrentValues({
          ...currentValues,
          toDate: '',
        });
      } else {
        setCurrentValues({
          ...currentValues,
          toDate: moment(date).endOf('day').format(FORMAT_TIME_LONG),
        });
      }
    }
  };

  const disabledFromDate = (date) => (current) => {
    return current && current > moment(date);
  };

  const disabledToDate = (date) => (current) => {
    return current && current < moment(date);
  };

  const handleFormReset = (resetForm) => () => {
    resetForm();
    setCurrentValues(initialValues);
    getListRichMenu(initialValues);
  };

  const handleSubmit = () => {
    getListRichMenu(currentValues);
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {
    if (!_.isEmpty(currentValues) && JSON.stringify(prevCurrentValues) !== JSON.stringify(currentValues)) {
      getListRichMenu(currentValues);
      setCurrentPage(1);
    }
  }, [currentValues]);

  useEffect(() => {
    getActiveRichMenu();
  }, []);

  useEffect(() => {
    if (type === ActionTypes.PUT_RICH_MENU_DETAIL_SUCCESS) {
      getListRichMenu(initialValues);
      resetType();
    } else if (type === ActionTypes.DELETE_RICH_MENU_DETAIL_SUCCESS) {
      getListRichMenu(initialValues);
      getActiveRichMenu();
      resetType();
    }
  }, [type]);

  return (
    <Fragment>
      <div className="rich-menu">
        <div className="ant-col-22 ant-col-offset-1">
          <h2 className="title">{t('rich_menu_management.title')}</h2>
          <Divider />
          <h4 className="rich-menu__subtitle title-content">{t('rich_menu_management.template_used')}</h4>
          <Row gutter={16} className="rich-menu__template-used">
            {_.isEmpty(activeRichMenu) ? (
              t('rich_menu_management.text_not_rich_menu_activated')
            ) : (
              <Fragment>
                <Col className="gutter-row" span={6} className="rich-menu__template-used__column">
                  <img className="rich-menu__template-used__column__image" src={activeRichMenu?.image_url} />
                </Col>
                <Col className="gutter-row" span={18}>
                  <Descriptions layout="horizontal" column={1} colon={false}>
                    <Descriptions.Item
                      label={t('rich_menu_management.template_used_title')}
                      labelStyle={{ minWidth: '100px' }}
                    >
                      {activeRichMenu?.title}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={t('rich_menu_management.template_used_time')}
                      labelStyle={{ minWidth: '100px' }}
                    >
                      {moment(activeRichMenu?.start_at).format(FORMAT_TIME_DAY_HOUR_MINUTES)}
                      {' - '}
                      {moment(activeRichMenu?.end_at).format(FORMAT_TIME_DAY_HOUR_MINUTES)}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={t('rich_menu_management.template_used_activity')}
                      labelStyle={{ minWidth: '100px' }}
                    >
                      <ul>
                        {activeRichMenu?.rich_menu_actions?.map((item, index) => (
                          <li key={item.id}>
                            {(item.type === 'TEXT' && (
                              <Fragment>
                                <span>
                                  {!!item.data && t('create_rich_menu.select_action_text') + ' - ' + item.data}
                                </span>
                                <span>{!!item.data && !!item.response_action_name && 'と'}</span>
                                <span>
                                  {!!item.response_action_name && 'アクション' + ' - ' + item.response_action_name}
                                </span>
                              </Fragment>
                            )) ||
                              (item.type === 'LINK' && (
                                <span>{t('create_rich_menu.select_action_link') + ' - ' + item.data}</span>
                              )) ||
                              (item.type === 'NONE' && t('create_rich_menu.select_action_no_action'))}
                          </li>
                        ))}
                      </ul>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Fragment>
            )}
          </Row>
          <h4 className="rich-menu__subtitle title-content">{t('rich_menu_management.list_menu_template')}</h4>
          <Button type="text" className="button button-active" href="/organizer/rich-menu/create">
            {t('rich_menu_management.button_create')}
          </Button>
          <Formik onSubmit={handleSubmit} initialValues={initialValues}>
            {({ handleSubmit, handleReset, setFieldValue, values }) => (
              <form className="rich-menu__form" onSubmit={handleSubmit}>
                <Space size="middle">
                  <Field
                    type="text"
                    name="search"
                    wrapperClass="rich-menu__form__search"
                    placeholder={t('rich_menu_management.template_used_title')}
                    onChange={onChangeInput(setFieldValue, 'search', 'input')}
                    icon={<SearchOutlined />}
                    onKeyPress={(e) => (e.which === 13 ? handleSubmit() : e.preventDefault)}
                    component={InputComponent}
                  />
                  <Field
                    name="status"
                    className="rich-menu__form__select-status"
                    value={values.status}
                    options={getStatusOptions(t)}
                    onChange={onChangeInput(setFieldValue, 'status', 'select')}
                    component={SelectComponent}
                  />
                  <Field
                    name="fromDate"
                    className="rich-menu__form__from-date"
                    value={values.fromDate ? moment(values.fromDate) : null}
                    inputReadOnly
                    placeholder="YYYY-MM-DD"
                    disabledDate={disabledFromDate(values.toDate)}
                    onChange={onChangeDatePicker(setFieldValue, 'fromDate')}
                    component={DatePickerComponent}
                  />
                  {'~'}
                  <Field
                    name="status"
                    className="rich-menu__form__to-date"
                    value={values.toDate ? moment(values.toDate) : null}
                    disabledDate={disabledToDate(values.fromDate)}
                    inputReadOnly
                    placeholder="YYYY-MM-DD"
                    onChange={onChangeDatePicker(setFieldValue, 'toDate')}
                    component={DatePickerComponent}
                  />
                  <Button className="button button-active" onClick={handleSubmit}>
                    <SearchOutlined />
                  </Button>
                  <Button className="button button-clear" onClick={handleFormReset(handleReset)}>
                    {t('common.clear')}
                  </Button>
                </Space>
              </form>
            )}
          </Formik>
          <Table
            columns={columns}
            dataSource={data}
            bordered
            pagination={{ pageSize: PAGE_SIZE, current: currentPage, onChange: (page) => setCurrentPage(page) }}
          />
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  listRichMenu: state.richMenuManagementReducer.listRichMenu,
  activeRichMenu: state.richMenuManagementReducer.activeRichMenu,
  type: state.richMenuManagementReducer.type,
});

const mapDispatchToProps = (dispatch) => ({
  getListRichMenu: (payload = '') => dispatch(actions.getListRichMenuStart(payload)),
  deleteRichMenu: (payload) => dispatch(actions.deleteRichMenuDetailStart(payload)),
  getActiveRichMenu: () => dispatch(actions.getActiveRichMenuStart()),
  resetType: () => dispatch(appActions.resetTypeOfAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RichMenuManagementPage);
