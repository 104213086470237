import React,{ useEffect } from 'react';
import moment from 'moment';
import {Select, Col, Form, Row, Radio , DatePicker, TimePicker, InputNumber} from 'antd';
import './index.css';
const { Option } = Select;

const hours = [];
for (let hour = 0; hour < 24; hour++) {
  hours.push(hour);
}
const minutes = [];
for (let minute = 0; minute < 60; minute++) {
  minutes.push(minute);
}
const irregularDayOption = [];
for (let i = 0; i < 8; i++) {
  irregularDayOption.push(<Option key={i} value={i}>{i + ' 日後'}</Option>);
}
const repetitionDayOption = [];
for (let i = 1; i < 4; i++) {
  repetitionDayOption.push(<Option key={i} value={i}>{i + ' 日間'}</Option>);
}

function Step2({form, seminar, isEdit, eventDay, onChangeEventDay, repetitionDay, onchangeRepetitionDay, t, somethingChanged}) {
  useEffect(() => {
    form.current.resetFields();
  },
    [seminar]
  );
  const onChange = (e) => {
    const value = e.target.value;
    if (value === 1) {
      form.current.setFieldsValue({present_after_days: null});
    }
    if (value === 2) {
      form.current.setFieldsValue({present_day: null});
    }
    form.current.setFieldsValue({present_time_1: null});
    form.current.setFieldsValue({present_time_2: null});
    form.current.setFieldsValue({present_time_3: null});
    onChangeEventDay(value);
    somethingChanged();
  }

  // const onchangeRepetition = (value) => {
  //   if (value === 1) {
  //     form.current.setFieldsValue({present_time_2: null});
  //     form.current.setFieldsValue({present_time_3: null});
  //   }
  //   if (value === 2) {
  //     const time1 = form.current.getFieldValue('present_time_1');
  //     form.current.setFieldsValue({present_time_2: time1});
  //     form.current.setFieldsValue({present_time_3: null});
  //   }
  //   if (value === 3) {
  //     const time2 = form.current.getFieldValue('present_time_2');
  //     form.current.setFieldsValue({present_time_3: time2});
  //   }
  //   onchangeRepetitionDay(value);
  // }

  // const onChangePresentDay = (value) => {
  //   const eventDayType = form.current.getFieldValue('present_repetition_days');
  //   if (eventDayType >= 1) {
  //     form.current.setFieldsValue({present_time_1: value});
  //   }

  // }

  // const onChangePresentAfterDays = (value) => {
  //   form.current.setFieldsValue({present_time_1: moment().add(value, "days")});
  //   const eventDayType = form.current.getFieldValue('present_repetition_days');
  //   console.log('eventDayType', eventDayType);
  //   if (eventDayType >= 2) {
  //     form.current.setFieldsValue({present_time_2: moment().add(value, "days")});
  //   }
  //   if (eventDayType >= 3) {
  //     form.current.setFieldsValue({present_time_3: moment().add(value, "days")});
  //   }
  // }

  const disabledDate = (current) => {
    return current && current < moment().startOf('days');
  }

  const disabledHours2 = () => {
    let time1 = form.current.getFieldValue('present_time_1');
    if (!time1) {
      return []
    }
    return hours.filter(item => item < moment(time1).hours());
  }

  const disabledMinutes2 = (selectedHour) => {
    let time1 = form.current.getFieldValue('present_time_1');
    if (!time1) {
      time1 = moment().startOf('minutes');
    }
    const hours = moment(time1).hours();
    if (selectedHour === hours) {
      return minutes.filter(item => item < moment(time1).minutes());
    }
    if (selectedHour <= 0) {
      return minutes;
    }
    return [];
  }

  const disabledHours3 = () => {
    let time2 = form.current.getFieldValue('present_time_2');
    if (!time2) {
      return [];
    }
    return hours.filter(item => item < moment(time2).hours());
  }

  const disabledMinutes3 = (selectedHour) => {
    let time2 = form.current.getFieldValue('present_time_2');
    if (!time2) {
      time2 = moment().startOf('minutes');
    }
    const hours = moment(time2).hours();
    if (selectedHour === hours) {
      return minutes.filter(item => item < moment(time2).minutes());
    }
    if (selectedHour <= 0) {
      return minutes;
    }
    return [];
  }

  const changeTimePresent = (value, type) => {
    const time1 = form.current.getFieldValue("present_time_1");
    if (time1 && type !== 1) {
      form.current.setFieldsValue({present_time_1: moment().hour(time1.hour()).minute(time1.minute())});
    }

    if (value && type === 1) {
      form.current.setFieldsValue({present_time_1: moment().hour(value?.hour()).minute(value?.minute())});
    }

    const time2 = form.current.getFieldValue("present_time_2");
    if (time2 && type !== 2) {
      form.current.setFieldsValue({present_time_2: moment().hour(time2.hour()).minute(time2.minute())});
    }

    if (value && type === 2) {
      form.current.setFieldsValue({present_time_2: moment().hour(value?.hour()).minute(value?.minute())});
    }

    const time3 = form.current.getFieldValue("present_time_3");
    if (time3 && type !== 3) {
      form.current.setFieldsValue({present_time_3: moment().hour(time3.hour()).minute(time3.minute())});
    }
  
    if (value && type === 3) {
      form.current.setFieldsValue({present_time_3: moment().hour(value?.hour()).minute(value?.minute())});
    }
    somethingChanged();
  }

  return (
    <div>
      <Row>
        <Col span={2}></Col>
        <Col className="row-title-content" span={4}>
          <span className="label-style-common">{t('seminar_detail.step2.event_day')}</span>
        </Col>
        <Col span={14}>
          <Row>
            <Form.Item
              name="event_day"
              initialValue={(seminar?.present_days || !seminar) ? 1 : (seminar?.present_after_days !== null ? 2 : null)}
              rules={[{ required: true, message: t('seminar_detail.step2.event_day') + t('common.field_required') }]}
            >
              <Radio.Group disabled={!isEdit} onChange={onChange}>
                <Radio value={1}>{t('seminar_detail.step2.fixed_day')}</Radio>
                <Radio value={2}>{t('seminar_detail.step2.irregular_day')}</Radio>
              </Radio.Group>
            </Form.Item>
          </Row>
          <Row>
            <Form.Item
              hidden={eventDay !== 1 }
              name="present_day"
              initialValue={seminar?.present_days ? moment(seminar?.present_days) : null}
              rules={[{ required: eventDay === 1, message: t('seminar_detail.step2.event_day') + t('common.field_required') }]}
            >
              <DatePicker placeholder="" disabledDate={disabledDate} onChange={somethingChanged} className="due-date" disabled={!isEdit}/>
            </Form.Item>
            <Form.Item
              hidden={eventDay !== 2}
              name="present_after_days"
              initialValue={seminar?.present_after_days}
              rules={[{ required: eventDay === 2, message: t('seminar_detail.step2.event_day') + t('common.field_required') }]}
            >
              <Select style={{ width: 200 }} onChange={somethingChanged} disabled={!isEdit}>
                {irregularDayOption}
              </Select>
            </Form.Item>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={2}></Col>
        <Col className="row-title-content" span={4}>
          <span className="label-style-common">{t('seminar_detail.step2.present_repetition_days')}</span>
        </Col>
        <Col span={14}>
          <Form.Item
            name="present_repetition_days"
            initialValue={seminar?.present_repetition_days || repetitionDay}
            rules={[{ required: true, message: t('seminar_detail.step2.present_repetition_days') + t('common.field_required') }]}
          >
            <Select style={{ width: 200 }} onChange={somethingChanged} disabled={!isEdit}>
              {repetitionDayOption}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={2}></Col>
        <Col className="row-title-content" span={4}>
          <span className="label-style-common">{t('seminar_detail.step2.open_time_1')}</span>
        </Col>
        <Col span={14}>
          <Form.Item
            name="present_time_1"
            initialValue={seminar?.present_time_1 ? moment(seminar?.present_time_1) : null}
            rules={[{ required: true, message: t('seminar_detail.step2.open_time_1') + t('common.field_required') }]}
          >
            <TimePicker placeholder="" showNow={false} format="HH:mm" className="due-date" onChange={(value) => changeTimePresent(value, 1)} disabled={!isEdit}/>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={2}></Col>
        <Col className="row-title-content" span={4}>
          <span className="label-style-common">{t('seminar_detail.step2.open_time_2')}</span>
        </Col>
        <Col span={14}>
          <Form.Item
            name="present_time_2"
            initialValue={seminar?.present_time_2 ? moment(seminar?.present_time_2) : null}
            rules={[{ required: false, message: t('seminar_detail.step2.open_time_2') + t('common.field_required') }]}
          >
            <TimePicker placeholder="" showNow={false}  disabledHours={disabledHours2} disabledMinutes={disabledMinutes2} onChange={(value) => changeTimePresent(value, 2)} format="HH:mm" className="due-date" disabled={!isEdit}/>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={2}></Col>
        <Col className="row-title-content" span={4}>
          <span className="label-style-common">{t('seminar_detail.step2.open_time_3')}</span>
        </Col>
        <Col span={14}>
          <Form.Item
            name="present_time_3"
            initialValue={seminar?.present_time_3 ? moment(seminar?.present_time_3) : null}
            rules={[{ required: false, message: t('seminar_detail.step2.present_time_3') + t('common.field_required') }]}
          >
            <TimePicker placeholder="" showNow={false}  disabledHours={disabledHours3} disabledMinutes={disabledMinutes3} onChange={(value) => changeTimePresent(value, 3)} format="HH:mm" className="due-date" disabled={!isEdit}/>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={2}></Col>
        <Col className="row-title-content" span={4}>
          <span className="label-style-common">{t('seminar_detail.step5.field3')}</span>
        </Col>
        <Col span={4}>
          <Row>
            <Form.Item
              name="expiration_period"
              initialValue={seminar?.expiration_period}
            >
              <InputNumber className="message-time-diff-input" type="number" min={0} onChange={somethingChanged} disabled={!isEdit} />
            </Form.Item>
            <span style={{lineHeight: "2.25"}}>日</span></Row>
        </Col>
      </Row>
    </div>
  );
}

export default Step2;