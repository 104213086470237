import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Input } from 'antd';
import { actions } from './actions';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: '',
      password: '',
    };
  }

  onClickLogin = (event) => {
    event.preventDefault();
    const { account, password } = this.state;
    const params = { account, password };
    this.props.getLoginStart(params);
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div className="text-center">
        <div className="row login-header">
          <div className="container col-sm-4"></div>
          <div id="login-div" className="container col-sm-auto">
            <img alt="logo" src={require('../../resources/assets/logo.png')} className="login-logo" />
            <br></br>
            <br></br>
            <div className="form-login-container">
              <div className="sign-in-title">サインイン</div>
              <div className="sign-in-wellcome">L Everにようこそ</div>
              <div
                hidden={
                  this.props.loginReducer.isLoginSuccess == null || this.props.loginReducer.isLoginSuccess === true
                }
                className="alert alert-danger"
                role="alert"
              >
                {t('login.error_message')}
              </div>
              <form className="form-login" action="" method="POST">
                <div className="form-group">
                  <Input
                    onChange={this.handleChange}
                    name="account"
                    type="text"
                    className="form-login-input"
                    id="account"
                    required={true}
                    placeholder={t('login.login_form.account.placeholder')}
                  />
                </div>

                <div className="form-group">
                  <Input
                    onChange={this.handleChange}
                    name="password"
                    type="password"
                    required={true}
                    className="form-login-input"
                    id="password"
                    placeholder={t('login.login_form.password.placeholder')}
                  />
                </div>
                <button type="submit" className="btn btn-primary button-login" onClick={this.onClickLogin}>
                  ログイン
                </button>
              </form>
            </div>
          </div>
          <div className="container col-sm-4"></div>
        </div>
      </div>
    );
  }
}

const mstp = (state) => ({
  loginReducer: state.loginReducer,
});
const mdtp = (dispatch) => ({
  getLoginStart: (params) => dispatch(actions.getLoginStart(params)),
});

export default connect(mstp, mdtp)(withTranslation()(Login));
