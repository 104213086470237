export default class SecurityService {
    static ROLES = {
        ROLE_ADMIN: 'ADMIN',
        ROLE_ORGANIZER: 'ORGANIZER',
    };


    static hasAnyRoles(...roles) {
        if (SecurityService.isLoggedIn()) {
            const currentRole = SecurityService.getRole().toString();
            for (let index in roles) {
                const role = roles[index].toString();
                if (currentRole === role.toString()) return true;
            }
        }
        return false;
    }

    static isAdmin() {
        return SecurityService.isLoggedIn() && SecurityService.getRole().toString() === this.ROLES.ROLE_ADMIN.toString();
    }


    static isOrganizer() {
        return SecurityService.isLoggedIn() && SecurityService.getRole().toString() === this.ROLES.ROLE_ORGANIZER.toString();
    }

    static isLoggedIn() {
        return SecurityService.getRole() && SecurityService.getToken();
    }

    static logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        localStorage.removeItem("organizerId");
    };

    static getToken = () => {
        return localStorage.getItem("token");
    };

    static getRole = () => {
        return localStorage.getItem("role");
    };
}
