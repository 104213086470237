import { ActionTypes } from './actions';

const DEFAULT_STATE = {
  actions: [],
  actionFolders: [],
  filterActions: [],
  actionDetail: {},
  type: '',
  error: '',
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_LIST_ACTION_SUCCESS:
      return {
        ...state,
        actions: action.payload,
      };
    case ActionTypes.GET_LIST_ACTION_FOLDER_SUCCESS:
      return {
        ...state,
        actionFolders: action.payload,
        type: action.type,
      };
    case ActionTypes.PUT_TAG_INFORMATION_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.GET_FILTER_ACTION_SUCCESS:
      return {
        ...state,
        filterActions: action.payload,
      };
    case ActionTypes.PUT_FOLDER_ACTION_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.PUT_FOLDER_ACTION_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.POST_FOLDER_ACTION_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.POST_FOLDER_ACTION_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.POST_NEW_ACTION_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.POST_NEW_ACTION_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.PUT_ACTION_DETAIL_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.PUT_ACTION_DETAIL_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.GET_ACTION_DETAIL_SUCCESS:
      return {
        ...state,
        actionDetail: action.payload,
      };
    case ActionTypes.DELETE_A_ACTION_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.DELETE_A_ACTION_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.RESET_TYPE_OF_ACTION:
      return {
        ...state,
        type: '',
      };
    case ActionTypes.POST_EXECUTE_ACTION_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.POST_EXECUTE_ACTION_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.DELETE_ACTION_FOLDER_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.DELETE_ACTION_FOLDER_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.RESET_ERROR_MESSAGE:
      return {
        ...state,
        error: '',
      };
    default:
      return state;
  }
};
