import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Table, Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { actions } from '../actions';
import { FORMAT_TIME_LONG } from '../../../common/AppConst';

function ListSubscriberModal({ isModalVisible, toggleModal, getListSubscriber, listSubscriber, messagePackageID }) {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (isModalVisible) {
      getListSubscriber({ keyword: searchText, message_package_id: messagePackageID });
    }
  }, [searchText, isModalVisible]);

  useEffect(() => {
    if (isModalVisible) {
      setSearchText('');
    }
  }, [isModalVisible]);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchText(value.trim());
  };

  const dataSource = listSubscriber?.map((user, index) => ({
    key: index,
    index: index + 1,
    name: user.display_name,
    status: user.status,
    updated_at: moment(user.updated_at).format(FORMAT_TIME_LONG),
  }));

  const columns = [
    {
      title: 'No',
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: t('message_package.column_name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('message_package.column_status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (status) =>
        status === 'IN_PROGRESS' ? (
          <span className="text-pizazz">{t('message_package.status_in_progress')}</span>
        ) : (
          <span className="text-shamrock">{t('message_package.status_done')}</span>
        ),
    },
    {
      title: t('message_package.column_created_at'),
      dataIndex: 'updated_at',
      key: 'updated_at',
      align: 'center',
    },
  ];

  return (
    <Modal
      title={t('message_package.modal_subscriber_title')}
      visible={isModalVisible}
      onCancel={toggleModal}
      footer={null}
      closable={false}
      className="list-subscriber-modal"
      destroyOnClose={true}
    >
      <Input prefix={<SearchOutlined />} placeholder={t('message_package.column_name')} onChange={handleSearch} />
      <Table dataSource={dataSource} columns={columns} bordered />
      <Button type="text" onClick={toggleModal} className="button button-inactive">
        {t('message_package.text_close')}
      </Button>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  listSubscriber: state.messagePackageReducer.listSubscriber,
});

const mapDispatchToProps = (dispatch) => ({
  getListSubscriber: (payload) => dispatch(actions.getMessagePackageSubscriberStart(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListSubscriberModal);
