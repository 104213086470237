import React from 'react';
import { Spin } from 'antd';

function EventLoading({ isLoading, className, dataText = 'Loading', ...props }) {
  return (
    <React.Fragment>
      {isLoading && (
        <div className={`event-loading ${className}`}>
          <Spin data-text={dataText}></Spin>
        </div>
      )}
    </React.Fragment>
  );
}

export default EventLoading;
