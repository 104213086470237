import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table, Radio, Divider, Space, Button, Input, Tag } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { actions } from '../../../MessagePackage/actions';

function SelectionMessagePackageComponent({
  getFilterMessagePackage,
  filterMessagePackage,
  onChangeMessagePackage,
  values,
  isEdit,
  height = 500,
}) {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState({
    id: '',
    name: '',
  });
  const [searchText, setSearchText] = useState('');

  const columns = [
    {
      title: t('action_management.column_message_package_name'),
      dataIndex: 'name',
      render: (text) => <a>{text}</a>,
    },
    {
      render: (text, record) => (
        <>
          {initialValues.id === record.id ? (
            <Fragment>
              {t('common.button_choosing')}{' '}
              <Button
                className="button-choosing"
                icon={<CloseOutlined />}
                onClick={() => {
                  onSetFieldValue();
                }}
                disabled={!isEdit}
              />
            </Fragment>
          ) : (
            <Button
              className="button button-active"
              onClick={() => {
                onSetFieldValue(record.id, record.name);
              }}
              disabled={!isEdit}
            >
              {t('common.button_choose')}
            </Button>
          )}
        </>
      ),
      align: 'center',
    },
  ];

  const data = filterMessagePackage?.map((item, index) => ({
    key: index,
    id: item.id,
    name: item.name,
    emptyData: '',
  }));

  const onSetFieldValue = (id = '', name = '') => {
    onChangeMessagePackage({ id, name });
  };

  useEffect(() => {
    getFilterMessagePackage(searchText);
  }, [searchText]);

  useEffect(() => {
    setInitialValues({ ...values });
  }, [values]);

  return (
    <Fragment>
      <div className="select-message-package__input">
        {initialValues.name ? <Tag>{initialValues.name}</Tag> : ''}
        <Input onChange={(e) => setSearchText(e.target.value.trim())} disabled={!isEdit} />
      </div>
      <Divider />

      <Table
        bordered
        columns={columns}
        dataSource={data}
        scroll={{ y: height }}
        selectedRowKeys={[1]}
        pagination={false}
        className="select-message-package__table"
      />
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  filterMessagePackage: state.messagePackageReducer.filterMessagePackage,
});

const mapDispatchToProps = (dispatch) => ({
  getFilterMessagePackage: (keyword) => dispatch(actions.getFilterMessagePackageStart(keyword)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectionMessagePackageComponent);
