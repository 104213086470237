import * as React from 'react';

function IconMute(props) {
  return (
    <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.063.418L.418 2.063 5.505 7.15l-.339.35H.5v7h4.666L11 20.333v-7.688l4.876 4.876a7.828 7.828 0 01-2.543 1.295v2.404a10.43 10.43 0 004.212-2.042l2.391 2.392 1.645-1.645L2.063.418zm6.603 14.28l-2.531-2.532H2.833V9.833h3.302L7.16 8.806l1.505 1.505v4.387zM19.166 11c0 .956-.175 1.878-.478 2.73l1.785 1.785A10.425 10.425 0 0021.5 11c0-4.994-3.489-9.17-8.167-10.232v2.403A8.173 8.173 0 0119.166 11zM11 1.666L8.806 3.86 11 6.053V1.666zM16.25 11a5.25 5.25 0 00-2.917-4.702v2.088l2.893 2.894c.012-.094.024-.187.024-.28z"
        fill="#fff"
      />
    </svg>
  );
}

export default IconMute;
