import React, { useState } from 'react';
import { Modal, Row, Col, Descriptions, Input, Button, Space, Upload, notification, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { PictureOutlined } from '@ant-design/icons';
import { Field } from 'formik';
import { connect } from 'react-redux';
import { FORMAT_IMAGE } from '../../common/AppConst';
import { checkDimension, getFileName } from '../../utils';
import IconComputerFolder from '../../resources/assets/IconComputerFolder';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};

function ModalUpdateImage({
  formikProps,
  isVisible,
  toggleModal,
  handleFieldChange,
  menuID,
  getURLUploadToS3,
  setUploadFile,
  templateMenu,
  imageDimension,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(false);
  const [file, setFile] = useState('');

  async function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({ message: 'JPG、JPEG、またはPNGのファイルを選択して下さい。' });
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      notification.error({ message: '1MB以下のファイルを選択してください。' });
      return false;
    }

    const dimensionsOfImage = await checkDimension(file);
    const isValidBigImage = dimensionsOfImage.width === 2500 && dimensionsOfImage.height === 1686;
    const isValidSmallImage = dimensionsOfImage.width === 2500 && dimensionsOfImage.height === 843;

    if (templateMenu === -1) {
      if (!isValidBigImage && !isValidSmallImage) {
        notification.error({ message: 'The Image must be exactly dimension!' });
      }
    } else if ([0, 1, 2, 3, 4, 5, 6].indexOf(templateMenu) !== -1) {
      if (!isValidBigImage) {
        notification.error({ message: t('message.MSG_13') });
        return false;
      }
    } else if (!isValidSmallImage) {
      notification.error({ message: t('message.MSG_14') });
      return false;
    }

    imageDimension(dimensionsOfImage);
    return isJpgOrPng && isLt2M && (isValidBigImage || isValidSmallImage);
  }

  const handleChange = async (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }

    const isValid = await beforeUpload(info.file);
    if (!isValid) {
      setLoading(false);
      return false;
    } else if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        setImageUrl(imageUrl);
        setLoading(false);
        setFile(info.file);
        setUploadFile(info.file);
      });
    }
  };

  const handleCloseModal = () => {
    imageDimension({
      width: 0,
      height: 0,
    });
    if (formikProps.values.image_local) {
      toggleModal();
      setFile('');
    } else {
      handleFieldChange(formikProps.setFieldValue, 'image_local', 'file')('');
      toggleModal();
    }
  };

  const handleSubmit = () => {
    getURLUploadToS3({ menuID, file });
    handleFieldChange(formikProps.setFieldValue, 'image_local', 'file')(imageUrl);
    // imageMenu(imageUrl);
    toggleModal();
  };

  return (
    <Modal
      title={t('modal_upload_image.title')}
      className="upload-image-modal"
      width={800}
      visible={isVisible}
      onCancel={toggleModal}
      closable={false}
      footer={null}
    >
      <Row gutter={16}>
        <Col className="gutter-row column-left" span={10}>
          <Descriptions layout="vertical" column={1} colon={false}>
            <Descriptions.Item label={t('modal_upload_image.label_image_file')} labelStyle={{ minWidth: '100px' }}>
              <Upload
                name="avatar"
                className="avatar-uploader"
                accept=".png, .jpg, .jpeg"
                showUploadList={false}
                onChange={handleChange}
                customRequest={dummyRequest}
              >
                <Field
                  name="image_url"
                  value={file ? file.name : getFileName(formikProps.values.image_url)}
                  addonAfter={<IconComputerFolder />}
                  readOnly
                  component={Input}
                />
              </Upload>
            </Descriptions.Item>
            <Descriptions.Item label={t('modal_upload_image.label_format')} labelStyle={{ minWidth: '100px' }}>
              {FORMAT_IMAGE}
            </Descriptions.Item>
            <Descriptions.Item label={t('modal_upload_image.label_size_file')} labelStyle={{ minWidth: '100px' }}>
              1MB{t('modal_upload_image.text_limit_size_image')}
            </Descriptions.Item>
            <Descriptions.Item label={t('modal_upload_image.label_size_image')} labelStyle={{ minWidth: '100px' }}>
              {[0, 1, 2, 3, 4, 5, 6].indexOf(templateMenu) !== -1 ? <span>2500×1686</span> : <span>2500×843</span>}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col className="gutter-row column-right" span={14}>
          <div className="column-right__image-preview">
            {file || formikProps.values.image_url ? (
              <img alt="image preview" id="image-validate" src={file ? imageUrl : formikProps.values.image_url} />
            ) : (
              <PictureOutlined
                style={{
                  fontSize: '128px',
                  color: '#d3d3d3',
                  margin: 'auto',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
            )}
          </div>
        </Col>
      </Row>
      <Divider />
      <div className="upload-image-modal__action">
        <Space size="middle" align="center">
          <Button className="button button-inactive" onClick={handleCloseModal}>
            {t('common.button_close')}
          </Button>
          <Button className="button button-active" onClick={handleSubmit} disabled={!file || loading}>
            {t('common.button_save')}
          </Button>
        </Space>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  fileUpload: state.richMenuManagementReducer.file,
  type: state.richMenuManagementReducer.type,
});

export default connect(mapStateToProps, null)(ModalUpdateImage);
