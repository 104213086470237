import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import routes from '../../Routes';
import { Switch, Route } from 'react-router-dom';
import moment from 'moment';
import { AppConst } from '../../common/AppConst';
import { Layout, Menu } from 'antd';
import './index.css';
import {
  ShopOutlined,
  ProjectOutlined,
  LogoutOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  MenuOutlined,
  InsertRowBelowOutlined,
  TagsOutlined,
  ScheduleOutlined,
  ProfileOutlined,
  PieChartOutlined,
  BorderlessTableOutlined,
  ContainerOutlined,
  MessageOutlined,
  WechatOutlined,
} from '@ant-design/icons';
import SecurityService from '../../services/security_service';
import LoadingComponent from '../../components/Loading';

const { Header, Sider, Content } = Layout;

moment.updateLocale(moment.locale(), { invalidDate: '' });

class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }

  onCollapse = () => {
    const collapsed = !this.state.collapsed;
    this.setState({ collapsed });
  };

  getTargetUrl = () => {
    const organizerId = localStorage.getItem('organizerId');
    return SecurityService.isAdmin()
      ? AppConst.ROUTE.ADMIN_SETTING_DETAIL
      : AppConst.ROUTE.ORGANIZER_DETAIL + organizerId;
  };

  getParams = (pathname) => {
    return pathname && pathname.split('/')[2];
  };

  render() {
    const { isLoading } = this.props;

    return (
      <div>
        <Layout>
          <LoadingComponent isLoading={isLoading} />
          <Header>
            <div>
              <MenuOutlined style={{ fontSize: '25px' }} onClick={this.onCollapse} />
              <img alt="logo" src={require('../../resources/assets/logo.png')} className="logo" />
            </div>
          </Header>
          <Layout>
            <Sider width="250" trigger={null} collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
              <Menu
                onClick={this.handleClick}
                defaultSelectedKeys={[`${this.getParams(this.props.location.pathname)}`]}
                mode="inline"
                className="menu--main"
              >
                <Menu.Item key="organizers" hidden={!SecurityService.hasAnyRoles(SecurityService.ROLES.ROLE_ADMIN)}>
                  <a href="/admin/organizers">
                    <span>
                      <ShopOutlined />
                      <span>アカウント管理</span>
                    </span>
                  </a>
                </Menu.Item>
                <Menu.Item key="seminars" hidden={!SecurityService.hasAnyRoles(SecurityService.ROLES.ROLE_ORGANIZER)}>
                  <a href="/organizer/seminars">
                    <span>
                      <ProjectOutlined />
                      <span>セミナー管理</span>
                    </span>
                  </a>
                </Menu.Item>
                <Menu.Item key="setting">
                  <a href={this.getTargetUrl()}>
                    <span>
                      <SettingOutlined />
                      <span>ユーザー情報</span>
                    </span>
                  </a>
                </Menu.Item>
                <Menu.Item key="users" hidden={!SecurityService.hasAnyRoles(SecurityService.ROLES.ROLE_ORGANIZER)}>
                  <a href="/organizer/users">
                    <span>
                      <UnorderedListOutlined />
                      <span>友達管理</span>
                    </span>
                  </a>
                </Menu.Item>
                <Menu.Item key="rich-menu" hidden={!SecurityService.hasAnyRoles(SecurityService.ROLES.ROLE_ORGANIZER)}>
                  <a href="/organizer/rich-menu">
                    <span>
                      <InsertRowBelowOutlined />
                      <span>リッチメニュー管理</span>
                    </span>
                  </a>
                </Menu.Item>
                <Menu.Item
                  key="seminar-settings"
                  hidden={!SecurityService.hasAnyRoles(SecurityService.ROLES.ROLE_ORGANIZER)}
                >
                  <a href="/organizer/seminar-settings">
                    <span>
                      <ProfileOutlined />
                      <span>セミナーページ設定</span>
                    </span>
                  </a>
                </Menu.Item>
                <Menu.Item
                  key="personal-talk"
                  hidden={!SecurityService.hasAnyRoles(SecurityService.ROLES.ROLE_ORGANIZER)}
                >
                  <a href="/organizer/personal-talk">
                    <span>
                      <WechatOutlined />
                      <span>個別トーク</span>
                    </span>
                  </a>
                </Menu.Item>
                <Menu.Item key="talk-list" hidden={!SecurityService.hasAnyRoles(SecurityService.ROLES.ROLE_ORGANIZER)}>
                  <a href="/organizer/talk-list">
                    <span>
                      <MessageOutlined />
                      <span>トーク一覧</span>
                    </span>
                  </a>
                </Menu.Item>
                <Menu.Item key="tags" hidden={!SecurityService.hasAnyRoles(SecurityService.ROLES.ROLE_ORGANIZER)}>
                  <a href="/organizer/tags">
                    <span>
                      <TagsOutlined />
                      <span>タグ管理</span>
                    </span>
                  </a>
                </Menu.Item>
                <Menu.Item key="actions" hidden={!SecurityService.hasAnyRoles(SecurityService.ROLES.ROLE_ORGANIZER)}>
                  <a href="/organizer/actions">
                    <span>
                      <ScheduleOutlined />
                      <span>アクション管理</span>
                    </span>
                  </a>
                </Menu.Item>
                <Menu.Item
                  key="conversions"
                  hidden={!SecurityService.hasAnyRoles(SecurityService.ROLES.ROLE_ORGANIZER)}
                >
                  <a href="/organizer/conversions">
                    <span>
                      <PieChartOutlined />
                      <span>コンバージョン管理</span>
                    </span>
                  </a>
                </Menu.Item>
                <Menu.Item
                  key="message-packages"
                  hidden={!SecurityService.hasAnyRoles(SecurityService.ROLES.ROLE_ORGANIZER)}
                >
                  <a href="/organizer/message-packages">
                    <span>
                      <ContainerOutlined />
                      <span>メッセージパッケージ管理</span>
                    </span>
                  </a>
                </Menu.Item>
                <Menu.Item key="settings" hidden={!SecurityService.hasAnyRoles(SecurityService.ROLES.ROLE_ORGANIZER)}>
                  <a href="/organizer/settings/landing-page">
                    <span>
                      <BorderlessTableOutlined />
                      <span>埋込コード</span>
                    </span>
                  </a>
                </Menu.Item>
                <Menu.Item key="logout">
                  <a href="" onClick={SecurityService.logout}>
                    <span>
                      <LogoutOutlined />
                      <span>ログアウト</span>
                    </span>
                  </a>
                </Menu.Item>
              </Menu>
            </Sider>
            <Content>
              <div className="content">
                <Switch>
                  {routes?.map((route, index) => {
                    return (
                      <Route
                        exact={route.exact}
                        path={route.path}
                        render={(props) => <route.component {...props} t={this.props.t} />}
                        key={index}
                      />
                    );
                  })}
                </Switch>
              </div>
            </Content>
          </Layout>
          {/*<Footer>Footer</Footer>*/}
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.homeReducer.isLoading,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
