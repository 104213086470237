import { api } from './api';
import { HTTP_RESPONSE } from '../common/ResponseCode';
import { getFileExtension, getFileNameWithoutExtension } from '../utils';

export const getListRichMenuAPI = ({
  search = '',
  status = '',
  fromDate = '',
  toDate = '',
  orderBy = '',
  orderDirection = '',
  page = '',
  perPage = 10,
}) => {
  return api.getMethod(`/organizer/richmenus/filter`, {
    title: search,
    status,
    startAt: fromDate,
    endAt: toDate,
    orderBy,
    orderDirection,
    page,
    perPage,
  });
};

export const getRichMenuDetailAPI = (menuID) => {
  return api.getMethod(`/organizer/richmenus/${menuID}`);
};

export const getURLS3API = ({ file, menuID = null }) => {
  return api.getMethod(`/organizer/richmenus/pre-sign-url`, {
    ['content-type']: file.type,
    ['file-ext']: getFileExtension(file.type),
    ['rich-menu']: menuID,
    type: null,
    ['file-name']: getFileNameWithoutExtension(file.name),
  });
};

export const uploadFileToS3API = ({ uploadURL, file }) => {
  const options = {
    headers: {
      'Content-Type': file.type,
    },
  };
  return api.putToS3(uploadURL, file, options);
};

export const pushFileToS3API = ({ menuID, file }) => {
  return getURLS3API({ file, menuID }).then(
    (response) => {
      console.log('response', response);
      const options = {
        params: {
          Key: file.name,
          ContentType: file.type,
        },
        headers: {
          'Content-Type': file.type,
        },
      };
      if (response.meta.success) {
        return api.putToS3(response.data.second, file, options).then(
          (responseS3) => {
            if (responseS3.status === HTTP_RESPONSE.OK) {
              return {
                status: true,
                data: {
                  file_url: response.data.first,
                  file,
                },
              };
            } else {
              console.log('error', responseS3);
            }
          },
          (error) => console.log('error put file to s3', error)
        );
      } else {
        console.log('response', response);
      }
    },
    (error) => {
      console.log('error', error);
    }
  );
};

export const putRichMenuAPI = ({ menuID, body }) => {
  return api.putMethod(`organizer/richmenus/${menuID}/update`, body);
};

export const postRichMenuAPI = ({ body }) => {
  return api.postMethod(`organizer/richmenus/create`, body);
};

export const deleteRichMenuAPI = ({ menuID }) => {
  return api.deleteMethod(`organizer/richmenus/${menuID}/delete`);
};

export const getActiveRichMenuAPI = () => {
  return api.getMethod(`/organizer/richmenus/rich-menu-active`);
};
