import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Upload, Button, notification, Modal, Row, Col, Space, Divider, Input } from 'antd';
import { SyncOutlined, CheckCircleTwoTone, FolderOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { actions, ActionTypes } from '../UploadVideoModal/actions';
import { actions as appActions } from '../../pages/Home/actions';
import { getFileName, getFileNameWithoutExtension } from '../../utils';
import IconComputerFolder from '../../resources/assets/IconComputerFolder';

function UploadVideoModal({
  isModalVisible,
  toggleModal,
  uploadFile,
  type,
  resetType,
  file,
  uploadThumbnail,
  handleChangeModal,
  fileThumbnailURL,
  videoURLValue,
  thumbnailURLValue,
  saveLabel,
  organizerIdAdmin,
}) {
  const [uploadStatus, setUploadStatus] = useState(false);
  const [uploadThumbnailStatus, setUploadThumbnailStatus] = useState(false);
  const { t } = useTranslation();
  const organizerID = localStorage.getItem('organizerId') || organizerIdAdmin;
  const isAdmin = !localStorage.getItem('organizerId');
  const [initialValues, setInitialValues] = useState({
    videoURL: '',
    thumbnailURL: '',
  });

  const beforeUpload = (file) => {
    if (file.type !== 'video/mp4' && file.type !== 'video/webm') {
      notification.error({ message: t('message.MSG_16') });
      return false;
    }
    if (file.size / 1024 / 1024 > 10) {
      notification.error({ message: t('message.MSG_09') });
      return false;
    }
    return true;
  };

  const onChange = (info) => {
    if (info.file.status === 'done') {
      uploadFile({
        isAdmin,
        organizerID,
        file: info.file.originFileObj,
        type: 'VIDEO',
        fileName: getFileNameWithoutExtension(info.file.name),
      });
      setUploadStatus('pending');
    }
  };

  const beforeUploadThumbnailImage = (file) => {
    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      notification.error({ message: t('message.MSG_15') });
      return false;
    }
    if (file.size / 1024 / 1024 > 1) {
      notification.error({ message: t('message.MSG_08') });
      return false;
    }
    return true;
  };

  const onChangeThumbnailImage = (info) => {
    if (info.file.status === 'done') {
      uploadThumbnail({
        isAdmin,
        organizerID,
        file: info.file.originFileObj,
        type: 'VIDEO_THUMBNAIL',
        fileName: getFileNameWithoutExtension(info.file.name),
      });
      setUploadThumbnailStatus('pending');
    }
  };

  useEffect(() => {
    setInitialValues({
      videoURL: videoURLValue,
      thumbnailURL: thumbnailURLValue,
    });
  }, [videoURLValue, thumbnailURLValue]);

  useEffect(() => {
    switch (type) {
      case ActionTypes.PUSH_FILE_TO_S3_STORE_SUCCESS:
        setInitialValues((prevProps) => ({
          ...prevProps,
          videoURL: file.data.file_url,
        }));
        setUploadStatus('success');
        resetType();
        return;
      case ActionTypes.PUSH_THUMBNAIL_IMAGE_TO_S3_SUCCESS:
        setInitialValues((prevProps) => ({
          ...prevProps,
          thumbnailURL: fileThumbnailURL.data.file_url,
        }));
        setUploadThumbnailStatus('success');
        resetType();
        return;
      default:
        return;
    }
  }, [type]);

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const handleSubmit = () => {
    handleChangeModal(initialValues);
    setUploadStatus(false);
    setUploadThumbnailStatus(false);
    toggleModal();
  };

  const handleCloseModal = () => {
    setUploadStatus(false);
    setUploadThumbnailStatus(false);
    setInitialValues({
      videoURL: videoURLValue,
      thumbnailURL: thumbnailURLValue,
    });
    toggleModal();
  };

  return (
    <Modal
      title={t('modal_upload_video.title')}
      visible={isModalVisible}
      onCancel={toggleModal}
      width={700}
      footer={null}
      closable={false}
      className="upload-video-modal"
    >
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <div className="upload-video-modal__label">{t('modal_upload_image.label_file')}</div>
          <Upload
            beforeUpload={beforeUpload}
            accept=".mp4"
            onChange={onChange}
            customRequest={dummyRequest}
            showUploadList={false}
          >
            <Input readOnly suffix={<IconComputerFolder />} value={getFileName(initialValues.videoURL)} />
          </Upload>
          {uploadStatus === 'pending' && <SyncOutlined spin />}
          {uploadStatus === 'success' && <CheckCircleTwoTone twoToneColor="#52c41a" />}
          <div className="upload-video-modal__label">{t('modal_upload_video.label_thumbnail')}</div>
          {initialValues.videoURL ? (
            <Upload
              beforeUpload={beforeUploadThumbnailImage}
              accept=".jpg, .png, .jpeg"
              onChange={onChangeThumbnailImage}
              customRequest={dummyRequest}
              showUploadList={false}
            >
              <Input readOnly suffix={<IconComputerFolder />} value={getFileName(initialValues.thumbnailURL)} />
            </Upload>
          ) : (
            <Input suffix={<IconComputerFolder />} disabled={true} />
          )}
          {uploadThumbnailStatus === 'pending' && <SyncOutlined spin />}
          {uploadThumbnailStatus === 'success' && <CheckCircleTwoTone twoToneColor="#52c41a" />}
        </Col>
        <Col className="gutter-row column-video" span={12}>
          <Fragment>
            {initialValues.videoURL && (
              <video
                width="100%"
                height="auto"
                src={initialValues.videoURL}
                preload="true"
                poster={initialValues.thumbnailURL}
                controls
              >
                <object data={initialValues.videoURL}>
                  <embed src={initialValues.videoURL} />
                </object>
              </video>
            )}
          </Fragment>
        </Col>
      </Row>
      <Divider />
      <Space size="middle">
        <Button type="text" className="button button-inactive" onClick={handleCloseModal}>
          {t('common.button_cancel')}
        </Button>
        <Button
          type="text"
          className="button button-save"
          onClick={handleSubmit}
          disabled={!initialValues.thumbnailURL}
        >
          {saveLabel ? saveLabel : t('common.button_save')}
        </Button>
      </Space>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  type: state.uploadFileReducer.type,
  file: state.uploadFileReducer.file,
  fileThumbnailURL: state.uploadFileReducer.thumbnailURL,
});

const mapDispatchToProps = (dispatch) => ({
  uploadFile: (payload) => dispatch(actions.pushFileToS3Start(payload)),
  uploadThumbnail: (payload) => dispatch(actions.pushThumbnailImageToS3Start(payload)),
  resetType: () => dispatch(appActions.resetTypeOfAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadVideoModal);
