import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Modal, Button, Space, notification } from 'antd';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import InputComponent from '../../../components/Input';

export default function TagModal({
  t,
  modalTitle,
  isModalVisible,
  toggleModal,
  updateFolderAction,
  folderAction,
  createFolderAction,
  isCreateFolder = false,
}) {
  const [initialValues, setInitialValues] = useState({
    folderName: '',
  });
  const formRef = useRef(null);

  useEffect(() => {
    if (isModalVisible) {
      formRef.current.resetForm();
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (folderAction?.name) {
      setInitialValues({
        folderName: folderAction.name,
      });
    }
  }, [folderAction]);

  const validationSchema = Yup.object().shape({
    folderName: Yup.string()
      .trim()
      .required(t('message.MSG_02', { fieldName: t('tag_management.modal_input_placeholder') })),
  });

  const onChangeInput = (setFieldValue, fieldName) => (e) => {
    const { value } = e.target;
    setFieldValue(fieldName, value);
  };

  const onSubmitForm = (data) => {
    if (isCreateFolder) {
      createFolderAction(data.folderName.trim());
    } else {
      updateFolderAction({ folderID: folderAction.id, folderName: data.folderName.trim() });
    }
  };

  return (
    <Modal
      title={modalTitle}
      visible={isModalVisible}
      onCancel={toggleModal}
      closable={false}
      footer={null}
      className="modal-tag"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSubmitForm}
        innerRef={formRef}
      >
        {({ submitForm, setFieldValue, values, isValid }) => (
          <Fragment>
            <Field
              name="folderName"
              placeholder={t('tag_management.modal_input_placeholder')}
              onChange={onChangeInput(setFieldValue, 'folderName')}
              value={values.folderName}
              maxLength={30}
              component={InputComponent}
            />
            <Space size="middle">
              <Button type="text" className="button button-delete" onClick={toggleModal}>
                {t('common.button_close')}
              </Button>
              <Button type="text" className="button button-save" onClick={submitForm} disabled={!isValid}>
                {t('common.edit')}
              </Button>
            </Space>
          </Fragment>
        )}
      </Formik>
    </Modal>
  );
}
