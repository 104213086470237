import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import IMAGE_EXAMPLE_1 from '../../resources/assets/image1.png';
import IMAGE_EXAMPLE_2 from '../../resources/assets/image2.png';
import IMAGE_EXAMPLE_3 from '../../resources/assets/image3.png';
import IMAGE_EXAMPLE_4 from '../../resources/assets/image4.png';

function MessageContentComponent({ isModalVisible, toggleModal, values }) {
  const { t } = useTranslation();
  return (
    <Modal
      title={t('section_message.tooltip_text')}
      width={700}
      visible={isModalVisible}
      onCancel={toggleModal}
      footer={null}
      className="modal-message-example"
    >
      <ul>
        <li>テキストメッセージのみの場合</li>
        <img src={IMAGE_EXAMPLE_1} alt="image example 1" />
        <li>画像メッセージのみの場合</li>
        <img src={IMAGE_EXAMPLE_2} alt="image example 2" />
        <li>テキストメッセージと画像メッセージの場合</li>
        <img src={IMAGE_EXAMPLE_3} alt="image example 3" />
        <li>動画メッセージの場合</li>
        <div>LINEシステムでは動画メッセージが区別して送ります。</div>
        <div>動画メッセージとテキストメッセージまたは画像メッセージを送れば</div>
        <div>二つメッセージの以下のようなメッセージを送ります。</div>
        <img src={IMAGE_EXAMPLE_4} alt="image example 4" />
      </ul>
    </Modal>
  );
}

export default MessageContentComponent;
