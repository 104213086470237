import React, { useEffect, useState, useRef } from 'react';
import { Modal, Button, Layout, Menu, Divider, Tag, Empty } from 'antd';
import { Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { FolderOpenOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import _ from 'lodash';
import Input from '../../../components/Input';

const { Item } = Menu;
const { Content, Sider } = Layout;

export default function UpdateTagsModal({
  isVisible,
  toggleModal,
  tagFolders,
  getListTag,
  tags,
  putTagInformation,
  initialTags,
  getFilterTags,
  filterTags,
}) {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState({
    searchTags: '',
    tagsSelected: [],
  });
  const { userID } = useParams();
  const formRef = useRef(null);

  useEffect(() => {
    getFilterTags('');
  }, []);

  useEffect(() => {
    if (isVisible) {
      formRef.current.resetForm();
    }
  }, [isVisible]);

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      tagsSelected: initialTags,
    });
  }, [initialTags]);

  useEffect(() => {
    if (tagFolders?.length) {
      getListTag(tagFolders[0]?.id);
    }
  }, [tagFolders]);

  const getItemSelected = ({ item, key }) => {
    getListTag(tagFolders[key]?.id);
  };

  const handleSearchTags = (setFieldValue, fieldName) => (e) => {
    const { value } = e.target;
    setFieldValue(fieldName, value);
    getFilterTags(value.trim());
  };

  const removeElement = (setFieldValue, fieldName, item, values) => () => {
    const tags = values.tagsSelected.filter((tag) => tag !== item);
    setFieldValue(fieldName, tags);
  };

  const handleSubmitForm = (data) => {
    const tagsSelected = _.compact(data.tagsSelected);
    const listTag = tagsSelected.map((tag) => {
      return filterTags?.find((item) => tag.toLowerCase() === item.name.toLowerCase())?.id;
    });
    putTagInformation({
      userID,
      body: { tags: listTag },
    });
  };

  return (
    <Modal
      title={t('list_user.title_modal_update_tags')}
      width={800}
      visible={isVisible}
      onCancel={toggleModal}
      footer={null}
      closable={null}
      className="modal-update-tags"
    >
      <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmitForm} innerRef={formRef}>
        {({ handleSubmit, submitForm, values, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            {console.log('values', values)}
            <div className="modal-update-tags__action">
              <label className="modal-update-tags__action__label title-content">
                {t('list_user.label_attack_tags')}
              </label>
              <div className="modal-update-tags__action__input">
                <div className="modal-update-tags__action__input__list-tag">
                  {_.compact(values.tagsSelected).map((item, index) => {
                    return (
                      <Tag
                        closable
                        onClose={removeElement(setFieldValue, 'tagsSelected', item, values)}
                        key={index}
                        visible={true}
                      >
                        {item}
                      </Tag>
                    );
                  })}
                </div>
                <Field
                  name="searchTags"
                  wrapperClass="modal-update-tags__action__input__search"
                  onChange={handleSearchTags(setFieldValue, 'searchTags')}
                  component={Input}
                />
              </div>
            </div>
            <Layout className="site-layout-background">
              <Sider className="site-layout-background" width={values.searchTags ? 0 : 200}>
                <Menu mode="inline" defaultSelectedKeys={['0']} defaultOpenKeys={['0']} onClick={getItemSelected}>
                  {tagFolders?.map(({ id, name }, index) => (
                    <Item key={index} icon={<FolderOpenOutlined />}>
                      {name}
                    </Item>
                  ))}
                </Menu>
              </Sider>
              {filterTags && tags ? (
                <Content style={{ padding: '0 24px' }}>
                  <div role="group" aria-labelledby="checkbox-group">
                    {(values.searchTags ? filterTags : tags)?.map((tag, index) => (
                      <label htmlFor={tag.name} key={index}>
                        <Field
                          id={tag.name}
                          type="checkbox"
                          name="tagsSelected"
                          value={tag.name}
                          onClick={() => setFieldValue('searchTags', '')}
                        />
                        {tag.name}
                      </label>
                    ))}
                  </div>
                </Content>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('message.MSG_07')} />
              )}
            </Layout>
            <Divider />
            <div className="modal-update-tags__button">
              <Button className="button button-close" onClick={toggleModal}>
                {t('common.button_close')}
              </Button>
              <Button className="button button-active" type="text" onClick={submitForm}>
                {t('common.button_save')}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
}
