import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Input } from 'antd';
import './index.css';

function Step5({form, seminar, isEdit, t, somethingChanged}) {
  const [promotionName, setPromotionName] = useState(null);
  const [promotionUrl, setPromotionUrl] = useState(null);
  useEffect(() => {
    form.current.resetFields();
  },
    [seminar]
  );

  const formatInput = (event) => {
    const target = event.target;
    const value = target?.value.trim();
    form.current.setFieldsValue({[target?.id]: value});
  }

  const validateInput = (target, value) => {
    if (target?.id === "promotion_name") {
      if (!value) {
        setPromotionUrl(false);
        return;
      }
      setPromotionUrl(value);
    }
    if (target?.id === "promotion_url") {
      if (!value) {
        setPromotionName(false);
        return;
      }
      setPromotionName(value);
    }
  }

  const onChangeInput = (event) => {
    const target = event.target;
    const value = target?.value.trim();
    validateInput(target, value);
    somethingChanged();
  }

  return (
    <div>
      <Row>
        <Col span={2}></Col>
        <Col className="row-title-content" span={4}>
          <span className="label-style-common">{t('seminar_detail.step5.field1')}</span>
        </Col>
        <Col span={11}>
          <Form.Item
            name="promotion_name"
            initialValue={seminar?.promotion_name}
            rules={[{ required: promotionName, message: t('seminar_detail.step5.field1') + t('common.field_required') }]}
          >
            <Input onBlur={formatInput} onChange={onChangeInput} disabled={!isEdit} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={2}></Col>
        <Col className="row-title-content" span={4}>
          <span className="label-style-common">{t('seminar_detail.step5.field2')}</span>
        </Col>
        <Col span={11}>
          <Form.Item
            name="promotion_url"
            initialValue={seminar?.promotion_url}
            rules={[{ required: promotionUrl, message: t('seminar_detail.step5.field2') + t('common.field_required') }]}
          >
            <Input onBlur={formatInput} onChange={onChangeInput} disabled={!isEdit} />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}

export default Step5;