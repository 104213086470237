export const ActionTypes = {
  GET_LIST_USER: 'GET_LIST_USER',
  GET_LIST_USER_SUCCESS: 'GET_LIST_USER_SUCCESS',
  GET_LIST_USER_ERROR: 'GET_LIST_USER_ERROR',
  PUT_USER_STATUS: 'PUT_USER_STATUS',
  PUT_USER_STATUS_SUCCESS: 'PUT_USER_STATUS_SUCCESS',
  PUT_USER_STATUS_ERROR: 'PUT_USER_STATUS_ERROR',
  RESET_TYPE_OF_ACTION: 'RESET_TYPE_OF_ACTION',
};

export const actions = {
  getListUserStart: function (params) {
    return {
      type: ActionTypes.GET_LIST_USER,
      params,
    };
  },
  getListUserSuccess: function (data) {
    return {
      type: ActionTypes.GET_LIST_USER_SUCCESS,
      payload: data,
    };
  },
  getListUserError: function (error) {
    return {
      type: ActionTypes.GET_LIST_USER_ERROR,
      payload: error,
    };
  },
  putUserStatusStart: function (params) {
    return {
      type: ActionTypes.PUT_USER_STATUS,
      params,
    };
  },
  putUserStatusSuccess: function (data) {
    return {
      type: ActionTypes.PUT_USER_STATUS_SUCCESS,
      payload: data,
    };
  },
  putUserStatusError: function (error) {
    return {
      type: ActionTypes.PUT_USER_STATUS_ERROR,
      payload: error,
    };
  },
};
