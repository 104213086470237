import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Upload, Button, notification, Modal, Row, Col, Space, Divider, Input } from 'antd';
import { SyncOutlined, CheckCircleTwoTone, FolderOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { actions, ActionTypes } from './actions';
import { actions as appActions } from '../../pages/Home/actions';
import { getFileName, getFileNameWithoutExtension } from '../../utils';
import IconComputerFolder from '../../resources/assets/IconComputerFolder';

function UploadVideoModal({
  isModalVisible,
  toggleModal,
  uploadFile,
  type,
  resetType,
  file,
  uploadThumbnail,
  fileThumbnailURL,
  fieldNameVideoURL,
  fieldNameThumbnailURL,
  setFieldValue,
  formikValues,
  indexField,
}) {
  const [uploadStatus, setUploadStatus] = useState(false);
  const [uploadThumbnailStatus, setUploadThumbnailStatus] = useState(false);
  const { t } = useTranslation();
  const organizerID = localStorage.getItem('organizerId');

  const beforeUpload = (file) => {
    if (file.type !== 'video/mp4' && file.type !== 'video/webm') {
      notification.error({ message: t('message.MSG_16') });
      return false;
    }
    if (file.size > 10 * 1024 * 1024) {
      notification.error({ message: t('message.MSG_09') });
      return false;
    }
    return true;
  };

  const onChange = (info) => {
    if (info.file.status === 'done') {
      uploadFile({
        organizerID,
        file: info.file.originFileObj,
        type: 'VIDEO',
        fileName: getFileNameWithoutExtension(info.file.name),
      });
      setUploadStatus('pending');
    }
  };

  const beforeUploadThumbnailImage = (file) => {
    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      notification.error({ message: t('message.MSG_15') });
      return false;
    }
    if (file.size > 1024 * 1024) {
      notification.error({ message: t('message.MSG_08') });
      return false;
    }
    return true;
  };

  const onChangeThumbnailImage = (info) => {
    if (info.file.status === 'done') {
      uploadThumbnail({
        organizerID,
        file: info.file.originFileObj,
        type: 'VIDEO_THUMBNAIL',
        fileName: getFileNameWithoutExtension(info.file.name),
      });
      setUploadThumbnailStatus('pending');
    }
  };

  useEffect(() => {
    switch (type) {
      case ActionTypes.PUSH_FILE_TO_S3_STORE_SUCCESS:
        setFieldValue(`actions.${indexField}.video_url_temp`, file.data.file_url);
        setUploadStatus('success');
        resetType();
        return;
      case ActionTypes.PUSH_THUMBNAIL_IMAGE_TO_S3_SUCCESS:
        setFieldValue(`actions.${indexField}.video_thumbnail_url_temp`, fileThumbnailURL.data.file_url);
        setUploadThumbnailStatus('success');
        resetType();
        return;
      default:
        return;
    }
  }, [type]);

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const handleSubmit = () => {
    if (uploadStatus === 'success') {
      setFieldValue(fieldNameVideoURL, formikValues.actions[indexField].video_url_temp);
    }
    if (uploadThumbnailStatus === 'success') {
      setFieldValue(fieldNameThumbnailURL, formikValues.actions[indexField].video_thumbnail_url_temp);
    }
    setUploadStatus(false);
    setUploadThumbnailStatus(false);
    toggleModal();
  };

  const handleCloseModal = () => {
    setFieldValue(`actions.${indexField}.video_url_temp`, formikValues.actions[indexField].video_url);
    setFieldValue(
      `actions.${indexField}.video_thumbnail_url_temp`,
      formikValues.actions[indexField].video_thumbnail_url
    );
    setUploadStatus(false);
    setUploadThumbnailStatus(false);
    toggleModal();
  };

  return (
    <Modal
      title={t('modal_upload_video.title')}
      visible={isModalVisible}
      onCancel={toggleModal}
      width={700}
      footer={null}
      closable={false}
      className="upload-video-modal"
    >
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <div className="upload-video-modal__label">{t('modal_upload_image.label_file')}</div>
          <Upload
            accept=".mp4, .webm"
            beforeUpload={beforeUpload}
            onChange={onChange}
            customRequest={dummyRequest}
            showUploadList={false}
          >
            <Input
              readOnly
              addonAfter={<IconComputerFolder />}
              value={getFileName(formikValues.actions[indexField]?.video_url_temp)}
            />
          </Upload>
          {uploadStatus === 'pending' && <SyncOutlined spin />}
          {uploadStatus === 'success' && <CheckCircleTwoTone twoToneColor="#52c41a" />}
          <div className="upload-video-modal__label">{t('modal_upload_video.label_thumbnail')}</div>
          {formikValues.actions[indexField]?.video_url_temp ? (
            <Upload
              accept=".png, .jpg, .jpeg"
              beforeUpload={beforeUploadThumbnailImage}
              onChange={onChangeThumbnailImage}
              customRequest={dummyRequest}
              showUploadList={false}
            >
              <Input
                readOnly
                addonAfter={<IconComputerFolder />}
                value={getFileName(formikValues.actions[indexField]?.video_thumbnail_url_temp)}
              />
            </Upload>
          ) : (
            <Input addonAfter={<IconComputerFolder />} disabled={true} />
          )}
          {uploadThumbnailStatus === 'pending' && <SyncOutlined spin />}
          {uploadThumbnailStatus === 'success' && <CheckCircleTwoTone twoToneColor="#52c41a" />}
        </Col>
        <Col className="gutter-row column-video" span={12}>
          {formikValues?.actions[indexField]?.video_url_temp && (
            <Fragment>
              <video
                width="100%"
                height="auto"
                src={formikValues.actions[indexField]?.video_url_temp}
                preload="true"
                poster={formikValues.actions[indexField]?.video_thumbnail_url_temp}
                controls
              >
                <object data={formikValues.actions[indexField]?.video_url_temp}>
                  <embed src={formikValues.actions[indexField]?.video_url_temp} />
                </object>
              </video>
            </Fragment>
          )}
        </Col>
      </Row>
      <Divider />
      <Space size="middle">
        <Button type="text" className="button button-inactive" onClick={handleCloseModal}>
          {t('common.button_cancel')}
        </Button>
        <Button
          type="text"
          className="button button-active"
          onClick={handleSubmit}
          disabled={!formikValues.actions[indexField]?.video_thumbnail_url_temp}
        >
          {t('common.button_save')}
        </Button>
      </Space>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  type: state.uploadFileReducer.type,
  file: state.uploadFileReducer.file,
  fileThumbnailURL: state.uploadFileReducer.thumbnailURL,
});

const mapDispatchToProps = (dispatch) => ({
  uploadFile: (payload) => dispatch(actions.pushFileToS3Start(payload)),
  uploadThumbnail: (payload) => dispatch(actions.pushThumbnailImageToS3Start(payload)),
  resetType: () => dispatch(appActions.resetTypeOfAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadVideoModal);
