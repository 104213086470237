import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { Divider, Input, Button, Table, Tag, Space, notification, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { actions, ActionTypes } from './actions';
import ExecuteMessagePackageModal from './ExecuteMessagePackage';
import { actions as appActions } from '../Home/actions';
import ListSubscriberModal from './ListSubscriberModal';

function MessagePackagePage({ getListMessagePackage, filterMessagePackage, type, resetType, deleteMessagePackage }) {
  const { t } = useTranslation();
  const [filterText, setFilterText] = useState('');
  const [isExecuteMessagePackageModalVisible, setIsExecuteMessagePackageModalVisible] = useState(false);
  const [isListSubscriberModalVisible, setIsListSubscriberModalVisible] = useState(false);
  const [messagePackageID, setMessagePackageID] = useState('');
  const prevFilterText = usePrevious(filterText);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {
    if (filterText !== prevFilterText) {
      getListMessagePackage(filterText.trim());
    }
  }, [filterText]);

  useEffect(() => {
    switch (type) {
      case ActionTypes.POST_EXECUTE_MESSAGE_PACKAGE_SUCCESS:
        toggleExecuteMessagePackageModal();
        notification.success({ message: t('message.MSG_01') });
        resetType();
        return;
      case ActionTypes.DELETE_MESSAGE_PACKAGE_SUCCESS:
        getListMessagePackage(filterText);
        notification.success({ message: t('message.MSG_04') });
        resetType();
      default:
        return;
    }
  }, [type]);

  const handleSearch = (e) => {
    setFilterText(e.target.value);
  };

  const handleDelete = (messagePackageID) => () => {
    deleteMessagePackage(messagePackageID);
  };

  const toggleExecuteMessagePackageModal = useCallback(() => {
    setIsExecuteMessagePackageModalVisible(!isExecuteMessagePackageModalVisible);
  }, [isExecuteMessagePackageModalVisible]);

  const toggleListSubscriberModal = useCallback(() => {
    setIsListSubscriberModalVisible(!isListSubscriberModalVisible);
  }, [isListSubscriberModalVisible]);

  const columns = [
    {
      title: 'No',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: '10%',
    },
    {
      title: t('message_package.label_column_name'),
      dataIndex: 'name',
      key: 'name',
      render: (text) => <a>{text}</a>,
      width: '40%',
      ellipsis: true,
    },
    {
      title: t('message_package.label_column_type'),
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: '10%',
    },
    {
      title: t('message_package.label_column_status'),
      dataIndex: 'status',
      key: 'status',
      render: (text) =>
        text === 'USING' ? (
          <Tag color="green">{t('message_package.status_in_using')}</Tag>
        ) : (
          <Tag color="orange">{t('message_package.status_not_use')}</Tag>
        ),
      align: 'center',
      width: '10%',
    },
    {
      title: t('message_package.label_column_people'),
      key: 'people',
      dataIndex: 'people',
      render: (text, { id }) =>
        !text > 0 ? (
          0
        ) : (
          <Space size="small">
            {text}{' '}
            <Button
              size="small"
              onClick={() => {
                console.log('id', id);
                setMessagePackageID(id);
                toggleListSubscriberModal();
              }}
            >
              {t('message_package.button_display')}
            </Button>
          </Space>
        ),
      align: 'center',
      width: '10%',
    },
    {
      title: t('message_package.label_column_action'),
      key: 'action',
      render: (text, record) => (
        <Space size="middle" wrap>
          <Button
            type="text"
            className="button button-solid"
            disabled={record.status !== 'USING'}
            onClick={() => {
              toggleExecuteMessagePackageModal();
              setMessagePackageID(record.id);
            }}
          >
            {t('message_package.button_delivery')}
          </Button>
          <Button type="text" className="button button-solid" href={`/organizer/message-packages/edit/${record.id}`}>
            {t('common.edit')}
          </Button>
          <Popconfirm
            placement="topRight"
            title={t('common.confirm_delete_message')}
            onConfirm={handleDelete(record.id)}
            okText={t('common.yes')}
            cancelText={t('common.no')}
          >
            <Button type="text" className="button button-inactive">
              {t('common.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
      align: 'center',
      width: '20%',
    },
  ];

  const data = filterMessagePackage?.map((item, index) => ({
    key: item.id,
    id: item.id,
    index: index + 1,
    name: item.name,
    status: item.status,
    type: item.type === 'ABSOLUTE' ? t('message_package.type_absolute') : t('message_package.type_relative'),
    people: item.total_people_delivered,
  }));

  const memoizedExecuteMessagePackageModal = useMemo(
    () => (
      <ExecuteMessagePackageModal
        message_package_id={messagePackageID}
        isModalVisible={isExecuteMessagePackageModalVisible}
        toggleModal={toggleExecuteMessagePackageModal}
      />
    ),
    [isExecuteMessagePackageModalVisible]
  );

  const memoizedListSubscriberModal = useMemo(
    () => (
      <ListSubscriberModal
        messagePackageID={messagePackageID}
        isModalVisible={isListSubscriberModalVisible}
        toggleModal={toggleListSubscriberModal}
      />
    ),
    [isListSubscriberModalVisible, messagePackageID]
  );

  return (
    <div className="message-package">
      <div className="ant-col-22 ant-col-offset-1">
        <h2 className="action-management__title title">{t('message_package.title')}</h2>
        <Divider />
        <div className="message-package__top">
          <Button
            className="button button-active"
            href="/organizer/message-packages/create"
            icon={<PlusCircleOutlined />}
          >
            {t('message_package.button_register')}
          </Button>
          <Input
            placeholder={t('message_package.placeholder_search')}
            suffix={<SearchOutlined />}
            className="message-package__top__input"
            onChange={handleSearch}
            value={filterText}
          />
        </div>
        <Table
          bordered
          columns={columns}
          dataSource={data}
          className="message-package__table"
          pagination={{ pageSize: 10 }}
        />
        {memoizedExecuteMessagePackageModal}
        {memoizedListSubscriberModal}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  filterMessagePackage: state.messagePackageReducer.filterMessagePackage,
  type: state.messagePackageReducer.type,
});

const mapDispatchToProps = (dispatch) => ({
  getListMessagePackage: (keyword) => dispatch(actions.getFilterMessagePackageStart(keyword)),
  deleteMessagePackage: (messagePackageID) => dispatch(actions.deleteMessagePackageStart(messagePackageID)),
  resetType: () => dispatch(appActions.resetTypeOfAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessagePackagePage);
