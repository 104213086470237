import { put, takeLatest } from 'redux-saga/effects';
import { actions, ActionTypes } from './actions';
import {
  getFilterMessagePackageAPI,
  postNewMessagePackageAPI,
  getMessagePackageDetailAPI,
  putMessagePackageAPI,
  postNewMessageAPI,
  getListMessageAPI,
  getMessageDetailAPI,
  putMessageDetailAPI,
  postExecuteMessagePackageAPI,
  getMessagePackageSubscriberAPI,
  deleteMessagePackageAPI,
  deleteMessageAPI,
} from '../../services/MessagePackageRequest';
import { RESPONSE_STATUS } from '../../common/ResponseCode';
import { actions as appActions } from '../Home/actions';

function* getFilterMessagePackage({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield getFilterMessagePackageAPI(params);
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getFilterMessagePackageSuccess(response.data));
    } else {
      yield put(actions.getFilterMessagePackageError(response));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.getFilterMessagePackageError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* getMessagePackageDetail({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield getMessagePackageDetailAPI(params);
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getMessagePackageDetailSuccess(response.data));
    } else {
      yield put(actions.getMessagePackageDetailError(response));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.getMessagePackageDetailError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* postNewMessagePackage({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield postNewMessagePackageAPI(params);
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.postNewMessagePackageSuccess(response.data));
    } else {
      yield put(actions.postNewMessagePackageError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.postNewMessagePackageError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* putMessagePackageDetail({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield putMessagePackageAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.putMessagePackageDetailSuccess(response));
    } else {
      yield put(actions.putMessagePackageDetailError(response));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.putMessagePackageDetailError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* postNewMessage({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield postNewMessageAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.postNewMessageSuccess(response.data));
    } else {
      yield put(actions.postNewMessageError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.postNewMessageError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* getListMessage({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield getListMessageAPI(params);
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getListMessageSuccess(response.data));
    } else {
      yield put(actions.getListMessageError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.getListMessageError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* getMessageDetail({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield getMessageDetailAPI(params);
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getMessageDetailSuccess(response.data));
    } else {
      yield put(actions.getMessageDetailError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.getMessageDetailError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* putMessageDetail({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield putMessageDetailAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.putMessageDetailSuccess(response.data));
    } else {
      yield put(actions.putMessageDetailError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.putMessageDetailError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* postExecuteMessagePackage({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield postExecuteMessagePackageAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.postExecuteMessagePackageSuccess(response.data));
    } else {
      yield put(actions.postExecuteMessagePackageError(response.meta.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.postExecuteMessagePackageError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* getMessagePackageSubscriber({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield getMessagePackageSubscriberAPI(params);
    if (response.meta.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getMessagePackageSubscriberSuccess(response.data));
    } else {
      yield put(actions.getMessagePackageSubscriberError(response));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.getMessagePackageSubscriberError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* deleteMessagePackage({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield deleteMessagePackageAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.deleteMessagePackageSuccess(response.data));
    } else {
      yield put(actions.deleteMessagePackageError(response.data.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.deleteMessagePackageError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

function* deleteMessage({ params }) {
  try {
    yield put(appActions.showLoadingEvent());
    const response = yield deleteMessageAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.deleteAMessageSuccess(response.data));
    } else {
      yield put(actions.deleteAMessageError(response.data.error_data));
    }
    yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.deleteAMessageError(e));
    yield put(appActions.hideLoadingEvent());
  }
}

export function* watchMessagePackage() {
  yield takeLatest(ActionTypes.GET_FILTER_MESSAGE_PACKAGE_START, getFilterMessagePackage);
  yield takeLatest(ActionTypes.POST_NEW_MESSAGE_PACKAGE_START, postNewMessagePackage);
  yield takeLatest(ActionTypes.GET_MESSAGE_PACKAGE_DETAIL_START, getMessagePackageDetail);
  yield takeLatest(ActionTypes.PUT_MESSAGE_PACKAGE_DETAIL_START, putMessagePackageDetail);
  yield takeLatest(ActionTypes.POST_NEW_MESSAGE_START, postNewMessage);
  yield takeLatest(ActionTypes.GET_LIST_MESSAGE_START, getListMessage);
  yield takeLatest(ActionTypes.GET_MESSAGE_DETAIL_START, getMessageDetail);
  yield takeLatest(ActionTypes.PUT_MESSAGE_DETAIL_START, putMessageDetail);
  yield takeLatest(ActionTypes.POST_EXECUTE_MESSAGE_PACKAGE_START, postExecuteMessagePackage);
  yield takeLatest(ActionTypes.GET_MESSAGE_PACKAGE_SUBSCRIBER_START, getMessagePackageSubscriber);
  yield takeLatest(ActionTypes.DELETE_MESSAGE_PACKAGE_START, deleteMessagePackage);
  yield takeLatest(ActionTypes.DELETE_A_MESSAGE_START, deleteMessage);
}
