import { combineReducers } from 'redux';

import homeReducer from '../pages/Home/reducer';
import loginReducer from '../pages/Login/reducer';
import listUserReducer from '../pages/ListUser/reducer';
import userDetailReducer from '../pages/ListUser/User/reducer';
import tagManagementReducer from '../pages/TagManagement/reducer';
import richMenuManagementReducer from '../pages/RichMenuManagement/reducer';
import actionManagementReducer from '../pages/ActionManagement/reducer';
import conversionManagementReducer from '../pages/Conversion/reducer';
import messagePackageReducer from '../pages/MessagePackage/reducer';
import uploadFileReducer from '../components/UploadVideoModal/reducer';

export default combineReducers({
  homeReducer,
  loginReducer,
  listUserReducer,
  userDetailReducer,
  tagManagementReducer,
  richMenuManagementReducer,
  actionManagementReducer,
  conversionManagementReducer,
  messagePackageReducer,
  uploadFileReducer,
});
