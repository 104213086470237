import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Button, Col, DatePicker, Input, Modal, notification, Row, Select, Table, Tabs, Tag} from "antd";
import React, {Component} from 'react';
import {api} from "../../services/api";
import moment from "moment";
import {Option} from "antd/es/mentions";
import {SearchOutlined} from "@ant-design/icons";
import ReactPlayer from "react-player";
import {RESPONSE_STATUS} from "../../common/ResponseCode";

const {TextArea} = Input;
const {TabPane} = Tabs;

class TalkList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      loading: false,
      visible: false,
      filter: this.getDefaultFilter(),
      totalPage: 0,
    }
    this.loadTalkList = this.loadTalkList.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.loadTalkList();
  }

  getDefaultFilter() {
    return {
      keyword: null,
      label: null,
      from: null,
      to: null,
      page: 1,
      perPage: 10,
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    const page = pagination.current;
    const perPage = pagination.pageSize;
    const filter = this.state.filter;
    filter.page = page;
    filter.perPage = perPage;
    this.setState({
      filter: filter
    });

    this.loadTalkList();
  };

  resetFilter = () => {
    this.setState({
      filter: this.getDefaultFilter()
    });
    setTimeout(() => this.loadTalkList(), 0);
  }

  loadTalkList() {
    this.setState({
      loading: true,
    });
    const params = {
      keyword: this.state.filter?.keyword ? this.state.filter?.keyword.trim() : null,
      label: this.state.filter?.label,
      from: this.state.filter?.from,
      to: this.state.filter?.to,
      page: this.state.filter?.page,
      perPage: this.state.filter?.perPage,
    }
    api.get("/organizer/line-chat/filter", params).then((response) => {
      if (response.data.meta.success === RESPONSE_STATUS.SUCCESS) {
        this.setState({
          messages: response.data.data.elements,
          totalPage: response.data?.data?.paging?.total,
          loading: false,
        });
      } else {
        notification.error({message: response.data.meta?.error_data?.error_msg});
        this.setState({
          messages: [],
          totalPage: 0,
          loading: false,
        });
      }
    }).catch((err) => {
      console.log(err)
    });
  }

  showModal = (record) => {
    const cloned = Object.assign({}, record);
    this.setState({
      activeRecord: cloned,
      visible: true,
    });
  };
  showModalMemo = (value, event, record) => {
    const cloned = Object.assign({}, record);
    cloned.label = value;
    this.setState({
      activeRecord: cloned,
      visibleMemo: true,
    });
  };


  handleCancel = () => {
    this.setState({visible: false, activeRecord: null});
  };

  handleMemoCancel = () => {
    this.setState({visibleMemo: false, activeRecord: null});
  };

  labels = [
    {key: "UNREAD", value: "未読", color: '#e23d3d'},
    {key: "READ", value: "既読", color: '#16844c'},
    {key: "REQUIRE_RESPONSE", value: "要対応", color: '#ffa500'},
    {key: "UN_NECESSARY_SUPPORT", value: "不要対応", color: '#808080'},
    {key: "COMPLETED", value: "対応完了", color: '#0000ff'},
  ];

  fullLabels = [
    {key: null, value: "すべて", color: ''},
    {key: "UNREAD", value: "未読", color: '#e23d3d'},
    {key: "READ", value: "既読", color: '#16844c'},
    {key: "REQUIRE_RESPONSE", value: "要対応", color: '#ffa500'},
    {key: "UN_NECESSARY_SUPPORT", value: "不要対応", color: '#808080'},
    {key: "COMPLETED", value: "対応完了", color: '#0000ff'},
  ];

  columns = [
    {
      title: '名前',
      dataIndex: 'subscriber_name',
      key: 'subscriber_name',
      width: "15%",
      ellipsis: true,
    },
    {
      title: '送信日時',
      dataIndex: 'created_at',
      key: 'created_at',
      width: "15%",
      render: created_at => moment(created_at).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: '内容',
      dataIndex: 'text',
      key: 'text',
      width: "25%",
      ellipsis: true,
      render: (text, record, index) => {
        if (text) return text;
        if (record.image_url) return <img src={record.image_url} style={{height: "30px"}}/>
        if (record.video_thumbnail_url) return <img src={record.video_thumbnail_url} style={{height: "30px"}}/>
        if (record.video_url) return <span style={{color: "red"}}><i>This is a video</i></span>
      },
    },
    {
      title: 'スタータス',
      dataIndex: 'label',
      key: 'label',
      width: "15%",
      render: (label, record, index) =>
          <Select style={{width: "100%"}} onChange={(value, event) => this.showModalMemo(value, event, record)}
                  value={label}>
            {this.labels.map(l =>
                <Option value={l.key}>
                  <Tag color={l.color} key={l.key}>{l.value}</Tag>
                </Option>)}
          </Select>,
    },
    {
      title: 'メモ',
      dataIndex: 'memo',
      key: 'memo',
      width: "20%",
      ellipsis: true,
    },
    {
      title: '',
      render: (text, record, index) => <Button type="default" className="button button-solid" onClick={() => this.showModal(record)}>詳細</Button>,
      width: '10%',
    },
  ];

  onKeyWordChange = (event) => {
    const filter = this.state.filter;
    filter.keyword = event.target.value;
    filter.page = 1;
    this.setState({
      filter: filter
    });

    setTimeout(() => this.loadTalkList(), 0);
  }
  onLabelChange = (value, event) => {
    const filter = this.state.filter;
    filter.label = value;
    filter.page = 1;
    this.setState({
      filter: filter
    });

    setTimeout(() => this.loadTalkList(), 0);
  }

  onRowLabelChange(value, event) {
    const activeRecord = this.state.activeRecord;
    activeRecord.label = value;
    this.setState({
      activeRecord: activeRecord
    });
  }

  onRowMemoChange(event) {
    const activeRecord = this.state.activeRecord;
    activeRecord.memo = event.target.value;
    this.setState({
      activeRecord: activeRecord
    });
  }

  updateMsg() {
    this.state.activeRecord.label = this.state.activeRecord.label != null ? this.state.activeRecord.label.trim() : null;
    this.state.activeRecord.memo = this.state.activeRecord.memo != null ? this.state.activeRecord.memo.trim() : null;
    console.log(JSON.stringify(this.state.activeRecord));
    const params = {
      label: this.state.activeRecord.label,
      memo: this.state.activeRecord.memo
    }
    api.put("/organizer/line-chat/" + this.state.activeRecord.id + "/update", params).then((response) => {
      if (response.data.meta.success === RESPONSE_STATUS.SUCCESS) {
        const changedRecord = this.state.messages.find(e => e.id === this.state.activeRecord.id);
        changedRecord.memo = params.memo
        changedRecord.label = params.label
        notification.success({message: "設定が保存されました。"});
        this.handleCancel();
        this.handleMemoCancel();
      } else {
        notification.error({message: response.data.meta?.error_data?.error_msg});
      }
    });
  }

  onStartDateChange = (date, dateString) => {
    const filter = this.state.filter;
    filter.from = moment(date).startOf("day").toISOString();
    filter.page = 1;
    this.setState({
      filter: filter
    });
    setTimeout(() => this.loadTalkList(), 0);
  }
  onEndDateChange = (date, dateString) => {
    const filter = this.state.filter;
    filter.to = moment(date).endOf("day").toISOString();
    filter.page = 1;
    this.setState({
      filter: filter
    });
    setTimeout(() => this.loadTalkList(), 0);
  }

  render() {
    return (
        <div id="talk-list">
          <div className="ant-col-22 ant-col-offset-1">
          <Row>
            <span style={{fontWeight: "700", fontSize: "32px", color: "#3BCC80"}}>トーク一覧</span>
          </Row>
          <hr/>
          <br/>

          <Row>
            <Col span={6} style={{paddingRight: "20px"}}>
              <Input suffix={<SearchOutlined/>} placeholder="名前" value={this.state.filter.keyword}
                     onChange={this.onKeyWordChange}/>
            </Col>
            <Col span={3} style={{paddingRight: "20px"}}>
              <Select style={{width: "100%"}} value={this.state.filter.label}
                      onChange={(value, event) => this.onLabelChange(value, event)}>
                {this.fullLabels.map(l =>
                    <Option value={l.key}>
                      {l.value}
                    </Option>)}
              </Select>
            </Col>
            <Col span={2} style={{paddingRight: "5px"}}>
              <DatePicker disabledDate={d => (this.state.filter?.to && !d.isBefore(this.state.filter?.to))}  placeholder="YYYY-MM-DD" inputReadOnly value={this.state.filter.from ? moment(this.state.filter.from) : null}  onChange={(date, dateString) => this.onStartDateChange(date, dateString)}/>
            </Col>
            <Col style={{marginTop: "4px", marginRight: "5px", marginLeft: "5px", width: "8px", color: "#E5E5E5"}}><span>-</span></Col>
            <Col span={2} style={{paddingRight: "10px"}}>
              <DatePicker disabledDate={d => (this.state.filter?.from && !d.isAfter(this.state.filter?.from))} placeholder="YYYY-MM-DD"  inputReadOnly value={this.state.filter.to ? moment(this.state.filter.to) : null} onChange={(date, dateString) => this.onEndDateChange(date, dateString)}/>
            </Col>
            <Col style={{paddingRight: "5px"}}>
              <Button style={{color: "white", background: "#3BCC80", width: "116px"}} onClick={this.loadTalkList}>
                <SearchOutlined style={{color: "white"}}/>
              </Button>
            </Col>
            <Col>
              <Button type="link" onClick={this.resetFilter}>
                <span style={{textDecorationLine: "underline", color: "#FF5349"}}>クリア</span>
              </Button>
            </Col>
          </Row>
          <br/>
          <Table locale={{ emptyText: ' ダータがありません。' }}  bordered columns={this.columns} dataSource={this.state.messages} onChange={this.handleTableChange}
                 loading={this.state.loading}
                 rowKey="id"
                 pagination={{
                   defaultPageSize: this.state.filter?.perPage,
                   current: this.state.filter?.page,
                   total: this.state.totalPage,
                   showSizeChanger: false
                 }}
          />
          <Row>
            <Modal
                visible={this.state.visible}
                title="メッセージの詳細"
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                className={"talk-list-modal"}
                width={"35%"}
                footer={[]}
            >
              <div style={{
                padding: "20px",
                border: "1px solid #E5E5E5",
                width: "100%",
                height: "400px",
                background: "#FBFBFB"
              }}>
                <div style={{
                  background: "white",
                  width: "100%",
                  height: "75%",
                  border: "1px solid #E5E5E5",
                  padding: "20px",
                  overflow: "auto"
                }}>
                  <span hidden={!this.state.activeRecord?.text}>{this.state.activeRecord?.text}</span>
                  <img height={"100%"} hidden={!this.state.activeRecord?.image_url}
                       src={this.state.activeRecord?.image_url} alt=''/>

                  <ReactPlayer
                      id="video"
                      hidden={!this.state.activeRecord?.video_url}
                      url={this.state.activeRecord?.video_url}
                      loop={false}
                      muted={false}
                      playing={true}
                      playsinline={true}
                      volume={1}
                      width="100%"
                      height="100%"
                      controls={true}
                  />
                </div>
                <div style={{height: "10px"}}></div>
                <div style={{width: "100%", height: "20%"}}>
                  <Row style={{height: "100%"}}>
                    <Col span={17} style={{background: "white", border: "1px solid #E5E5E5"}}>
                      <div>
                        <TextArea maxLength={255} value={this.state.activeRecord?.memo} placeholder="メモを入力してください"
                                  onChange={(event) => this.onRowMemoChange(event)}
                                  autoSize={{minRows: 3, maxRows: 3}}
                        />
                      </div>
                    </Col>
                    <Col span={7}>
                      <div style={{paddingLeft: "12px"}}>
                        <Select style={{width: "100%"}} value={this.state.activeRecord?.label}
                                onChange={(value, event) => this.onRowLabelChange(value, event)}>
                          {this.labels.map(l =>
                              <Option value={l.key}>
                                {l.value}
                              </Option>)}
                        </Select>
                      </div>
                      <div style={{height: "10px"}}></div>
                      <div style={{paddingLeft: "12px"}}>
                        <Button style={{background: "#3BCC80", width: "100%", color: "white"}}
                                onClick={() => this.updateMsg()}>保 存</Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Modal>
          </Row>
          <Row>
            <Modal
                visible={this.state.visibleMemo}
                title="メモ"
                onCancel={() => {
                }}
                className={"talk-list-modal-2"}
                width={"25%"}
                footer={[]}
            >
              <div>
                <TextArea  maxLength={255}  value={this.state.activeRecord?.memo} placeholder="メモを入力してください"
                          onChange={(event) => this.onRowMemoChange(event)}
                          autoSize={{minRows: 3, maxRows: 3}}
                />
              </div>
              <div style={{height: "10px"}}></div>
              <div className="text-center">
                <Button style={{background: "#3BCC80", color: "white", padding: "2px 25px 2px 25px"}}
                        onClick={() => this.updateMsg()}>保 存</Button>
              </div>
            </Modal>
          </Row>
          </div>
        </div>
    );
  }
}


const mstp = (state) => ({});
const mdtp = (dispatch) => ({});

export default connect(mstp, mdtp)(withTranslation()(TalkList));
