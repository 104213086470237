import React, { Fragment, useRef, useEffect, useState } from 'react';
import { Modal, Button, Divider, Row, Col, Space } from 'antd';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import InputComponent from '../../../components/Input';
import SelectComponent from '../../../components/Select';
import DatePickerComponent from '../../../components/DatePickerComponent';
import { FORMAT_TIME_DAY, getGenderOptions, REGEX_NOT_NUMBER, REGEX_NUMBER } from '../../../common/AppConst';
import { validationInput } from '../../../utils';

export default function ModalEditUser({ isVisible, toggleModal, initialUserValue = {}, userID, updateUserDetail }) {
  const { t } = useTranslation();
  const formRef = useRef(null);
  const [initialValues, setInitialValues] = useState({});

  const validationSchema = Yup.object().shape({
    username: Yup.string().nullable().trim(),
    dateOfBirth: Yup.string().nullable().trim(),
    email: Yup.string().nullable().email(t('message.MSG_18')),
    phoneNumber: Yup.number()
      .nullable()
      .test('phoneNumber', t('message.MSG_19'), (value) => !value || REGEX_NUMBER.test(value)),
  });

  useEffect(() => {
    if (isVisible) {
      formRef.current.resetForm();
    }
  }, [isVisible]);

  useEffect(() => {
    const { address, dateOfBirth, email, gender, phoneNumber, username } = initialUserValue;
    setInitialValues({
      address: address ? address : '',
      dateOfBirth: dateOfBirth,
      email: email ? email : '',
      gender: gender ? gender : '',
      phoneNumber: phoneNumber ? phoneNumber : '',
      username: username ? username : '',
    });
  }, [initialUserValue]);

  const handleSubmitForm = (data) => {
    updateUserDetail({
      userId: userID,
      body: {
        name: data.username?.trim(),
        address: data.address?.trim(),
        date_of_birth: Number.parseInt(moment(data.dateOfBirth).format('x')),
        email: data.email,
        gender: data.gender,
        phone_no: data.phoneNumber,
      },
    });
  };

  const handleChange = (setFieldValue, fieldName, inputType = 'text') => (e) => {
    if (fieldName === 'phoneNumber') {
      const value = validationInput(e.target.value, REGEX_NOT_NUMBER);
      setFieldValue(fieldName, value);
    } else if (fieldName === 'email') {
      setFieldValue(fieldName, e.target.value.trim());
    } else if (inputType === 'text') {
      setFieldValue(fieldName, e.target.value);
    } else {
      setFieldValue(fieldName, e);
    }
  };

  return (
    <Modal
      className="modal-edit-user"
      title={t('list_user.modal_edit_user_title')}
      visible={isVisible}
      onCancel={toggleModal}
      footer={null}
      closable={false}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        innerRef={formRef}
        onSubmit={handleSubmitForm}
        enableReinitialize
      >
        {({ setFieldValue, handleSubmit, values }) => (
          <Fragment>
            <form onSubmit={handleSubmit}>
              <Row gutter={16}>
                <Col className="gutter-row label-row" span={6}>
                  <span>{t('list_user.label_username')}</span>
                </Col>
                <Col className="gutter-row" span={18}>
                  <Field name="username" inputLength={255} component={InputComponent} />
                </Col>
                <Col className="gutter-row label-row" span={6}>
                  <span>{t('list_user.label_user_date_of_bird')}</span>
                </Col>
                <Col className="gutter-row" span={18}>
                  <Field
                    name="dateOfBirth"
                    placeholder="YYYY-MM-DD"
                    inputReadOnly
                    format={FORMAT_TIME_DAY}
                    value={values?.dateOfBirth ? moment(values.dateOfBirth) : null}
                    onChange={handleChange(setFieldValue, 'dateOfBirth', 'date')}
                    component={DatePickerComponent}
                  />
                </Col>
                <Col className="gutter-row label-row" span={6}>
                  <span>{t('list_user.label_user_gender')}</span>
                </Col>
                <Col className="gutter-row" span={18}>
                  <Field
                    name="gender"
                    options={getGenderOptions(t)}
                    value={
                      !values.gender
                        ? values.gender
                        : values.gender === 'male'
                        ? t('common.gender_male')
                        : t('common.gender_female')
                    }
                    onChange={handleChange(setFieldValue, 'gender', 'select')}
                    component={SelectComponent}
                  />
                </Col>
                <Col className="gutter-row label-row" span={6}>
                  <span>{t('list_user.label_user_email')}</span>
                </Col>
                <Col className="gutter-row" span={18}>
                  <Field
                    name="email"
                    fieldname="email"
                    value={values.email}
                    inputLength={255}
                    onChange={handleChange(setFieldValue, 'email')}
                    component={InputComponent}
                  />
                </Col>
                <Col className="gutter-row label-row" span={6}>
                  <span>{t('list_user.label_user_phone_number')}</span>
                </Col>
                <Col className="gutter-row" span={18}>
                  <Field
                    name="phoneNumber"
                    fieldname="phoneNumber"
                    value={values.phoneNumber?.trim()}
                    onChange={handleChange(setFieldValue, 'phoneNumber')}
                    inputLength={20}
                    component={InputComponent}
                  />
                </Col>
                <Col className="gutter-row label-row" span={6}>
                  <span>{t('list_user.label_user_address')}</span>
                </Col>
                <Col className="gutter-row" span={18}>
                  <Field
                    name="address"
                    fieldname="address"
                    value={values.address}
                    onChange={handleChange(setFieldValue, 'address')}
                    inputLength={255}
                    component={InputComponent}
                  />
                </Col>
              </Row>
              <Divider />
              <Space size="middle" className="modal-edit-user__action">
                <Button className="button button-inactive" type="text" onClick={toggleModal}>
                  {t('common.button_close')}
                </Button>
                <Button className="button button-active" type="text" onClick={handleSubmit}>
                  {t('common.button_save')}
                </Button>
              </Space>
            </form>
          </Fragment>
        )}
      </Formik>
    </Modal>
  );
}
