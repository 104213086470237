import React, { useState, useEffect, Fragment, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { Layout, Menu, Divider, Table, Space, Button, notification, Input, Popconfirm, Tooltip } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { FolderOpenOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { actions, ActionTypes } from './actions';
import { FORMAT_TIME_DAY } from '../../common/AppConst';
import TagModal from './Modal';
import { actions as appActions } from '../../pages/Home/actions';

const { Item } = Menu;
const { Content, Sider } = Layout;

function TagManagementPage({
  getTagFolder,
  tagFolders,
  getListTag,
  tags,
  putFolderTag,
  error,
  type,
  createFolderTag,
  resetType,
  filterTags,
  getFilterTags,
  deleteATag,
  deleteTagFolder,
  resetErrorMessage,
}) {
  const { t } = useTranslation();
  const [isCreateTagModalVisible, setIsCreateTagModalVisible] = useState(false);
  const [isUpdateTagModalVisible, setIsUpdateTagModalVisible] = useState(false);
  const [folderTag, setFolderTag] = useState({});
  const [searchTag, setSearchTag] = useState('');
  const [activeItem, setActiveItem] = useState(0);

  useEffect(() => {
    getTagFolder();
  }, []);

  useEffect(() => {
    if (error) {
      notification.error({ message: error.error_msg });
      resetErrorMessage();
    }
  }, [error]);

  useEffect(() => {
    switch (type) {
      case ActionTypes.GET_LIST_TAG_FOLDER_SUCCESS:
        if (tagFolders?.length) {
          getListTag(tagFolders[activeItem]?.id);
          setFolderTag(tagFolders[activeItem]);
        }
        resetType();
        return;
      case ActionTypes.PUT_FOLDER_TAG_SUCCESS:
        notification.success({ message: t('message.MSG_03') });
        getListTag(folderTag.id);
        getTagFolder();
        toggleUpdateTagModal();
        resetType();
        return;
      case ActionTypes.POST_FOLDER_TAG_SUCCESS:
        notification.success({ message: t('message.MSG_01') });
        getTagFolder();
        toggleCreateTagModal();
        resetType();
        return;
      case ActionTypes.DELETE_A_TAG_SUCCESS:
        getListTag(folderTag.id);
        if (searchTag) {
          getFilterTags(searchTag);
        }
        resetType();
        return;
      case ActionTypes.DELETE_TAG_FOLDER_SUCCESS:
        getTagFolder();
        resetType();
        return;
      default:
        resetType();
        return;
    }
  }, [type]);

  const toggleCreateTagModal = useCallback(() => setIsCreateTagModalVisible(!isCreateTagModalVisible), [
    isCreateTagModalVisible,
  ]);
  const toggleUpdateTagModal = useCallback(() => setIsUpdateTagModalVisible(!isUpdateTagModalVisible), [
    isUpdateTagModalVisible,
  ]);

  const getListTagByFolder = ({ key }) => {
    getListTag(tagFolders[key]?.id);
    setFolderTag(tagFolders[key]);
    setActiveItem(key);
  };

  const editFolderTag = (folder) => (e) => {
    e.stopPropagation();
    toggleUpdateTagModal();
    setFolderTag(folder);
  };

  const deleteFolderTag = (e) => {
    e.stopPropagation();
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTag(value.trim());
    getFilterTags(value.trim());
  };

  const columns = [
    {
      title: t('tag_management.label_column_tag_name'),
      dataIndex: 'name',
      key: 'name',
      width: '25%',
    },
    {
      title: t('tag_management.label_column_number_of_members'),
      dataIndex: 'people',
      key: 'people',
      width: '25%',
      render: (text, { name, people }) => (
        <Fragment>
          <Space size="middle">
            <span>{text}</span>
            {!people ? null : (
              <Button type="text" className="button button-clear" href={`/organizer/users/tag=${name}`}>
                {t('tag_management.button_see_more')}
              </Button>
            )}
          </Space>
        </Fragment>
      ),
    },
    {
      title: t('tag_management.label_column_created_date'),
      dataIndex: 'created_date',
      key: 'created_date',
      width: '25%',
      align: 'center',
    },
    {
      title: '',
      key: 'action',
      render: (text, { tagID }) => (
        <Space size="middle">
          <Button type="text" className="button button-solid" href={`/organizer/tags/${tagID}`}>
            {t('common.edit')}
          </Button>
          <Popconfirm
            placement="topRight"
            title={t('common.confirm_delete_message')}
            onConfirm={() => deleteATag(tagID)}
            okText={t('common.yes')}
            cancelText={t('common.no')}
          >
            <Button type="text" className="button button-delete">
              {t('common.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
      width: '25%',
      align: 'right',
    },
  ];

  const data = (searchTag ? filterTags : tags)?.map((tag, index) => ({
    key: index,
    tagID: tag.id,
    name: tag.name,
    people: tag.member_attached_total,
    created_date: moment(tag.created_at).format(FORMAT_TIME_DAY),
  }));

  const memoizedCreateTagModal = useMemo(
    () => (
      <TagModal
        isModalVisible={isCreateTagModalVisible}
        toggleModal={toggleCreateTagModal}
        t={t}
        modalTitle={t('tag_management.modal_title_create_tag')}
        createFolderTag={createFolderTag}
        isCreateFolder={true}
      />
    ),
    [isCreateTagModalVisible]
  );

  const memoizedUpdateTagModal = useMemo(
    () => (
      <TagModal
        isModalVisible={isUpdateTagModalVisible}
        toggleModal={toggleUpdateTagModal}
        t={t}
        modalTitle={t('tag_management.modal_title_update_tag')}
        updateFolderTag={putFolderTag}
        folderTag={folderTag}
      />
    ),
    [isUpdateTagModalVisible]
  );

  return (
    <div className="tag-management">
      <div className="ant-col-22 ant-col-offset-1">
        <h2 className="tag-management__title title">{t('tag_management.title')}</h2>
        <Divider />
        <Layout>
          <Sider width={searchTag ? 0 : 400} className="site-layout-background tag-management__sider">
            <Button className="button button-active" onClick={toggleCreateTagModal} icon={<PlusCircleOutlined />}>
              {t('tag_management.button_new_folder')}
            </Button>
            <Menu
              className="tag-management__sider__list"
              mode="inline"
              defaultSelectedKeys={[`${activeItem}`]}
              style={{ borderRight: 0 }}
            >
              {tagFolders?.map((folder, index) => (
                <Item
                  key={index}
                  icon={<FolderOpenOutlined />}
                  onClick={getListTagByFolder}
                  className="tag-management__sider__list__item"
                >
                  <Tooltip title={folder.name}>
                    <span className="folder-name">{folder.name}</span>
                  </Tooltip>
                  <Space size="middle">
                    <Button type="text" className="button button-solid" onClick={editFolderTag(folder)}>
                      {t('common.edit')}
                    </Button>
                    <Popconfirm
                      placement="topRight"
                      title={t('common.confirm_delete_message')}
                      onConfirm={() => deleteTagFolder(folder.id)}
                      okText={t('common.yes')}
                      cancelText={t('common.no')}
                    >
                      <Button type="text" className="button button-inactive" onClick={deleteFolderTag}>
                        {t('common.delete')}
                      </Button>
                    </Popconfirm>
                  </Space>
                </Item>
              ))}
            </Menu>
          </Sider>
          <Content
            className="site-layout-background tag-management__content"
            style={{
              padding: 24,
              margin: 0,
            }}
          >
            <div className="tag-management__content__action">
              <Button className="button button-active" href="/organizer/tags/create" icon={<PlusCircleOutlined />}>
                {t('tag_management.button_new_tag')}
              </Button>
              <Input
                className="tag-management__content__action__input"
                suffix={<SearchOutlined />}
                onChange={handleSearch}
                placeholder={t('tag_management.placeholder_search')}
              />
            </div>
            <Table
              columns={columns}
              dataSource={data}
              pagination={{ pageSize: 10, showSizeChanger: false }}
              className="tag-management__content__table"
            />
          </Content>
        </Layout>
      </div>
      {memoizedCreateTagModal}
      {memoizedUpdateTagModal}
    </div>
  );
}

const mapStateToProps = (state) => ({
  tagFolders: state.tagManagementReducer.tagFolders,
  tags: state.tagManagementReducer.tags,
  type: state.tagManagementReducer.type,
  error: state.tagManagementReducer.error,
  filterTags: state.tagManagementReducer.filterTags,
});

const mapDispatchToProps = (dispatch) => ({
  getTagFolder: () => dispatch(actions.getListTagFolderStart()),
  getListTag: (folderId) => dispatch(actions.getListTagStart(folderId)),
  putFolderTag: (payload) => dispatch(actions.putFolderTagStart(payload)),
  createFolderTag: (payload) => dispatch(actions.postFolderTagStart(payload)),
  getFilterTags: (keyword) => dispatch(actions.getFilterTagStart(keyword)),
  deleteATag: (tagID) => dispatch(actions.deleteATagStart(tagID)),
  deleteTagFolder: (folderID) => dispatch(actions.deleteTagFolderStart(folderID)),
  resetType: () => dispatch(appActions.resetTypeOfAction()),
  resetErrorMessage: () => dispatch(appActions.resetErrorMessage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagManagementPage);
