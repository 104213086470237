import React, { useEffect, useState, useRef } from 'react';
import { Modal, Button, Layout, Menu, Divider, Tag, Empty } from 'antd';
import { Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { FolderOpenOutlined } from '@ant-design/icons';
import _ from 'lodash';
import Input from '../../../../components/Input';
import { removeItemOnce } from '../../../../utils';

const { Item } = Menu;
const { Content, Sider } = Layout;

export default function UpdateTagsModal({
  isVisible,
  toggleModal,
  tagFolders,
  getListTag,
  tags,
  initialTag,
  getFilterTags,
  filterTags,
  onSubmitModal,
}) {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState({
    searchTags: '',
    tagSelected: [],
  });
  const formRef = useRef(null);

  useEffect(() => {
    if (isVisible) {
      formRef.current.resetForm();
    }
  }, [isVisible]);

  useEffect(() => {
    getFilterTags('');
  }, []);

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      tagSelected: initialTag,
    });
  }, [initialTag]);

  useEffect(() => {
    if (tagFolders?.length) {
      getListTag(tagFolders[0]?.id);
    }
  }, [tagFolders]);

  const getItemSelected = ({ item, key }) => {
    getListTag(tagFolders[key]?.id);
  };

  const handleSearchTags = (setFieldValue, fieldName) => (e) => {
    const { value } = e.target;
    setFieldValue(fieldName, value);
    getFilterTags(value);
  };

  const removeElement = (setFieldValue, fieldName) => () => {
    setFieldValue(fieldName, '');
  };

  const handleSubmitForm = (data) => {
    const listTag = filterTags?.find((item) => data.tagSelected.toLowerCase() === item.name.toLowerCase())?.id;
    onSubmitModal({ tag_id: listTag, tag_name: data.tagSelected });
  };

  return (
    <Modal
      title={t('list_user.title_modal_update_tags')}
      width={800}
      visible={isVisible}
      onCancel={toggleModal}
      footer={null}
      closable={null}
      className="modal-update-tags"
    >
      <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmitForm} innerRef={formRef}>
        {({ handleSubmit, submitForm, values, setFieldValue, resetForm }) => (
          <form onSubmit={handleSubmit}>
            <div className="modal-update-tags__action">
              <label className="modal-update-tags__action__label title-content">タグ選択</label>
              <div className="modal-update-tags__action__input">
                <div className="modal-update-tags__action__input__list-tag">
                  {values.tagSelected && (
                    <Tag closable onClose={removeElement(setFieldValue, 'tagSelected')} visible={true}>
                      {values.tagSelected}
                    </Tag>
                  )}
                </div>
                <Field
                  name="searchTags"
                  wrapperClass="modal-update-tags__action__input__search"
                  onChange={handleSearchTags(setFieldValue, 'searchTags')}
                  component={Input}
                />
              </div>
            </div>
            <Layout className="site-layout-background">
              <Sider className="site-layout-background" width={values.searchTags ? 0 : 200}>
                <Menu mode="inline" defaultSelectedKeys={['0']} defaultOpenKeys={['0']} onClick={getItemSelected}>
                  {tagFolders?.map(({ id, name }, index) => (
                    <Item key={index} icon={<FolderOpenOutlined />}>
                      {name}
                    </Item>
                  ))}
                </Menu>
              </Sider>
              {filterTags && tags ? (
                <Content style={{ padding: '0 24px' }}>
                  <div role="group" aria-labelledby="checkbox-group">
                    {(values.searchTags ? filterTags : tags)?.map((tag, index) => (
                      <label htmlFor={tag.name} key={index}>
                        <Field
                          id={tag.name}
                          type="radio"
                          name="tagSelected"
                          value={tag.name}
                          checked={tag.name === values.tagSelected}
                          onClick={() => setFieldValue('searchTags', '')}
                        />
                        {tag.name}
                      </label>
                    ))}
                  </div>
                </Content>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('message.MSG_07')} />
              )}
            </Layout>
            <Divider />
            <div className="modal-update-tags__button">
              <Button className="button button-close" onClick={toggleModal}>
                {t('common.button_close')}
              </Button>
              <Button className="button button-active" type="text" onClick={submitForm}>
                {t('common.button_save')}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
}
