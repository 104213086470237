import React, {Component} from "react";
import {Col, List, Radio, Row} from "antd";
import {api} from "../../services/api";
import {ResponseCode} from "../../common/ResponseCode";
import moment from "moment";
import "./index.css"

export default class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizer: []
    }
      const url = new URL(window.location.href);
      this.limit = url.searchParams.get("limit");
  }

  componentDidMount() {
    const organizer_id = this.props.match.params.organizer_id;
    this.getSeminarList(organizer_id);

    const script = document.createElement("script");
    script.src = "https://d.line-scdn.net/r/web/social-plugin/js/thirdparty/loader.min.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  presentTimeLabel(seminar) {
      let label = seminar.present_time_1 ? moment(seminar.present_time_1).format("HH:mm") : "";
      label += seminar.present_time_2 ? (" | " + moment(seminar.present_time_2).format("HH:mm")) : "";
      label += seminar.present_time_3 ? (" | " + moment(seminar.present_time_3).format("HH:mm")) : "";
      if (label && label.length > 0) {
        if (seminar?.present_day) {
          const start = moment(seminar?.present_day).format("MM月DD日");
          const end = moment(seminar?.present_day).add(seminar?.present_repetition_days, "days").format("MM月DD日");
          return start + "～" + end +"（" + label + "）";
        }
        if (seminar?.present_after_days || seminar?.present_after_days === 0) {
          const start = moment().add(seminar?.present_after_days, "days").format("MM月DD日");
          const end = moment().add(seminar?.present_after_days + seminar?.present_repetition_days, "days").format("MM月DD日");
          return start + "～" + end +"（" + label + "）";
        }
      }
      return "";
  }

  getSeminarList(organizerId) {
    api.get("/landing/organizer/" + organizerId, {limit: this.limit}).then(
        (response) => {
          if (response.status === ResponseCode.OK) {
            let organizer = response.data?.data;
            this.setState({
                organizer: organizer,
            });
          } else {
          }
        }
    );
  };

  render() {
    return (
      <>
          <div>
              <div id="list-header" style={{display: "inline-flex", width: "100%"}}>
                  <img id="calendar-icon" width={40} height={40} src={"https://cdn.zeplin.io/5f10122ff2c6c18dbf25821a/assets/e783af48-0c27-4965-bd15-2393e3c5636e.svg"} />
                  <p id="title">セミナー予定表</p>
              </div>
              <div id="list-content" style={{width: "100%"}}>
                  {this.state.organizer?.seminars?.map((seminar, index) => (
                      <div key={index} className="item" >
                          <div style={{width: "40%", alignSelf: "center"}}>{seminar.title}</div>
                          <div style={{width: "60%", textAlign: "right", color: "#7d7c7c", alignSelf: "center"}}>{this.presentTimeLabel(seminar)}</div>
                      </div>
                  ))}
              </div>
              <div>
                  <Row align={"middle"} justify="center">
                      <div className="line-it-button" data-lang="en" data-type="friend" data-count="true" data-lineid={this.state.organizer.channel_user_id}></div>
                  </Row>
              </div>
          </div>
      </>
    );
  }
}