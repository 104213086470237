import React, { useState, useEffect } from 'react';
import { Modal, Divider, Button, Space, Radio, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import ImageTemplate1 from '../../../../resources/assets/rich-menu-1.png';
import ImageTemplate2 from '../../../../resources/assets/rich-menu-2.png';
import ImageTemplate3 from '../../../../resources/assets/rich-menu-3.png';
import ImageTemplate4 from '../../../../resources/assets/rich-menu-4.png';
import ImageTemplate5 from '../../../../resources/assets/rich-menu-5.png';
import ImageTemplate6 from '../../../../resources/assets/rich-menu-6.png';
import ImageTemplate7 from '../../../../resources/assets/rich-menu-12.png';
import ImageTemplate8 from '../../../../resources/assets/rich-menu-7.png';
import ImageTemplate9 from '../../../../resources/assets/rich-menu-8.png';
import ImageTemplate10 from '../../../../resources/assets/rich-menu-9.png';
import ImageTemplate11 from '../../../../resources/assets/rich-menu-10.png';
import ImageTemplate12 from '../../../../resources/assets/rich-menu-11.png';

export default function TemplateModal({ isVisible, toggleModal, handleChange, setTemplateMenu, templateMenu }) {
  const { t } = useTranslation();
  const [template, setTemplate] = useState(-1);

  const handleSelectChange = (value) => {
    setTemplate(value.target.value);
  };

  const handleToggleModal = () => {
    toggleModal();
    setTemplateMenu(templateMenu);
  };

  const onSubmit = () => {
    toggleModal();
    handleChange(template);
    setTemplateMenu(template);
  };

  return (
    <Modal
      title={t('create_rich_menu.template_modal_title')}
      className="modal-template"
      visible={isVisible}
      onCancel={toggleModal}
      closable={false}
      footer={null}
    >
      <Radio.Group buttonStyle="solid" defaultValue={templateMenu} optionType="button" onChange={handleSelectChange}>
        <Row gutter={16}>
          <Col className="gutter-row" span={6}>
            <Radio.Button value={0}>
              <img src={ImageTemplate1} />
            </Radio.Button>
          </Col>
          <Col className="gutter-row" span={6}>
            <Radio.Button value={1}>
              <img src={ImageTemplate2} />
            </Radio.Button>
          </Col>
          <Col className="gutter-row" span={6}>
            <Radio.Button value={2}>
              <img src={ImageTemplate3} />
            </Radio.Button>
          </Col>
          <Col className="gutter-row" span={6}>
            <Radio.Button value={3}>
              <img src={ImageTemplate4} />
            </Radio.Button>
          </Col>
          <Col className="gutter-row" span={6}>
            <Radio.Button value={4}>
              <img src={ImageTemplate5} />
            </Radio.Button>
          </Col>
          <Col className="gutter-row" span={6}>
            <Radio.Button value={5}>
              <img src={ImageTemplate6} />
            </Radio.Button>
          </Col>
          <Col className="gutter-row" span={6}>
            <Radio.Button value={6}>
              <img src={ImageTemplate7} />
            </Radio.Button>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={6}>
            <Radio.Button value={7}>
              <img src={ImageTemplate8} />
            </Radio.Button>
          </Col>
          <Col className="gutter-row" span={6}>
            <Radio.Button value={8}>
              <img src={ImageTemplate9} />
            </Radio.Button>
          </Col>
          <Col className="gutter-row" span={6}>
            <Radio.Button value={9}>
              <img src={ImageTemplate10} />
            </Radio.Button>
          </Col>
          <Col className="gutter-row" span={6}>
            <Radio.Button value={10}>
              <img src={ImageTemplate11} />
            </Radio.Button>
          </Col>
          <Col className="gutter-row" span={6}>
            <Radio.Button value={11}>
              <img src={ImageTemplate12} />
            </Radio.Button>
          </Col>
        </Row>
      </Radio.Group>
      <Divider />
      <Space className="modal-template__action" size="middle">
        <Button type="text" className="button button-delete" onClick={handleToggleModal}>
          {t('common.button_close')}
        </Button>
        <Button type="text" className="button button-save" onClick={onSubmit}>
          {t('common.button_save')}
        </Button>
      </Space>
    </Modal>
  );
}
