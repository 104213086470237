import { put, takeLatest } from 'redux-saga/effects';
import { actions, ActionTypes } from './actions';
import { uploadFileAPI } from '../../services/FileUploadRequest';
import { RESPONSE_STATUS } from '../../common/ResponseCode';

function* pushFileToS3({ params }) {
  try {
    const response = yield uploadFileAPI(params);
    if (response.status === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.pushFileToS3Success(response));
    } else {
      yield put(actions.pushFileToS3Error(response));
    }
  } catch (e) {
    yield put(actions.pushFileToS3Error(e));
  }
}

function* pushThumbnailImageToS3({ params }) {
  try {
    const response = yield uploadFileAPI(params);
    if (response.status === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.pushThumbnailImageToS3Success(response));
    } else {
      yield put(actions.pushThumbnailImageToS3Error(response));
    }
  } catch (e) {
    yield put(actions.pushThumbnailImageToS3Error(e));
  }
}

function* pushImageToS3({ params }) {
  try {
    const response = yield uploadFileAPI(params);
    console.log({ response });
    if (response.status === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.pushImageToS3Success(response));
    } else {
      yield put(actions.pushImageToS3Error(response));
    }
  } catch (e) {
    yield put(actions.pushImageToS3Error(e));
  }
}

export function* UploadFileWatch() {
  yield takeLatest(ActionTypes.PUSH_FILE_TO_S3_STORE_START, pushFileToS3);
  yield takeLatest(ActionTypes.PUSH_THUMBNAIL_IMAGE_TO_S3_START, pushThumbnailImageToS3);
  yield takeLatest(ActionTypes.PUSH_IMAGE_TO_S3_START, pushImageToS3);
}
