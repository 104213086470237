import { put, takeLatest } from 'redux-saga/effects';
import { actions, ActionTypes } from './actions';
import { getLoginApi } from '../../services/AuthRequest';
import { browserHistory } from '../../browser-history';
import { ResponseCode } from '../../common/ResponseCode';
import SecurityService from '../../services/security_service';
import { AppConst } from '../../common/AppConst';

const getTargetUrl = () => {
  return SecurityService.isAdmin() ? AppConst.ROUTE.ORGANIZER_MANAGEMENTS : AppConst.ROUTE.SEMINAR_MANAGEMENTS;
};

function* getLogin(params) {
  try {
    const loginData = {
      account: params.params.account,
      password: params.params.password,
    };
    const response = yield getLoginApi(loginData);
    if (response.status === ResponseCode.OK) {
      const token = response.data.data.token;
      const role = response.data.data.role;
      const organizerId = response.data.data.id;
      yield put(actions.getLoginSuccess(token, role, organizerId));
      const path = getTargetUrl();
      browserHistory.push(path);
      window.location.href = path;
    } else {
      yield put(actions.getLoginError(response));
    }
  } catch (e) {
    yield put(actions.getLoginError(e));
  }
}

export function* watchLogin() {
  yield takeLatest(ActionTypes.GET_LOGIN_START, getLogin);
}
