import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Upload, Checkbox, Input, notification } from 'antd';
import './index.css';

const { TextArea } = Input;
const acceptVideo = ['video/mp4'];
const acceptImage = ['image/png', 'image/jpg', 'image/jpeg'];
function Step1({ form, seminar, isEdit, t, somethingChanged }) {
  // const [isUploadPresenterProfileText, setIsUploadPresenterProfileText] = useState(seminar?.presenter_profile);
  // const [isUploadPresenterProfileImage, setIsUploadPresenterProfileImage] = useState(seminar?.presenter_profile_photo);
  // const [isUploadPresenterProfileVideo, setIsUploadPresenterProfileVideo] = useState(seminar?.presenter_profile_video_url);
  // const [isUploadDescriptionText, setIsUploadDescriptionText] = useState(seminar?.description);
  // const [isUploadDescriptionImage, setIsUploadDescriptionImage] = useState(seminar?.description_photo);
  // const [isUploadDescriptionVideo, setIsUploadDescriptionVideo] = useState(seminar?.description_video_url);
  const [previewPresenterPhoto, setPreviewPresenterPhoto] = useState(seminar?.presenter_photo);
  const [previewPhoto, setPreviewPhoto] = useState(seminar?.photo);

  useEffect(() => {
    form.current.resetFields();
  }, [seminar]);

  // const handleChangeCheckbox = (checked, id) => {
  //   switch (id) {
  //     case 'isUploadPresenterProfileText':
  //       if (!checked) {
  //         form.current.setFieldsValue({presenter_profile_text: ""});
  //       }
  //       setIsUploadPresenterProfileText(checked);
  //       break;
  //     case 'isUploadPresenterProfileImage':
  //       if (!checked) {
  //         form.current.setFieldsValue({presenter_profile_photo_name: null, presenter_profile_photo_url: null});
  //       }
  //       setIsUploadPresenterProfileImage(checked);
  //       break;
  //     case 'isUploadPresenterProfileVideo':
  //       if (!checked) {
  //         form.current.setFieldsValue({presenter_profile_video_name: null, presenter_profile_video_url: null, presenter_profile_thumbnail_name: null, presenter_profile_thumbnail_url: null});
  //       }
  //       setIsUploadPresenterProfileVideo(checked);
  //       break;
  //     case 'isUploadDescriptionText':
  //       if (!checked) {
  //         form.current.setFieldsValue({description: ""});
  //       }
  //       setIsUploadDescriptionText(checked);
  //       break;
  //     case 'isUploadDescriptionImage':
  //       if (!checked) {
  //         form.current.setFieldsValue({description_photo_name: null, description_photo_url: null});
  //       }
  //       setIsUploadDescriptionImage(checked);
  //       break;
  //     case 'isUploadDescriptionVideo':
  //       if (!checked) {
  //         form.current.setFieldsValue({description_video_name: null, description_video_url: null, description_thumbnail_name: null, description_thumbnail_url: null});
  //       }
  //       setIsUploadDescriptionVideo(checked);
  //       break;
  //     default:
  //       break;
  //   }
  // }

  // const onhangeCheckbox = (event) => {
  //   const checked = event.target.checked;
  //   const id = event.target.id;
  //   handleChangeCheckbox(checked, id);
  // }

  // const beforeUploadPresenterVideo = (file, fileList) => {
  //   const fileSize = file.size/1024/1024;
  //   if (!acceptVideo.includes(file.type) || fileSize >= 10) {
  //     notification.error({message: t('common.video_upload_invalid'), description: "" });
  //     return;
  //   }
  //   form.current.setFieldsValue({presenter_profile_video_name: file.name});
  // }

  // const beforeUploadPresenterImage = (file, fileList) => {
  //   const fileSize = file.size/1024/1024;
  //   if (!acceptImage.includes(file.type) || fileSize >= 10) {
  //     notification.error({message: t('common.image_upload_invalid'), description: "" });
  //     return;
  //   }
  //   form.current.setFieldsValue({presenter_profile_photo_name: file.name});
  // }

  // const beforeUploadPresenterThumbnail = (file, fileList) => {
  //   const fileSize = file.size/1024/1024;
  //   if (!acceptImage.includes(file.type) || fileSize >= 10) {
  //     notification.error({message: t('common.image_upload_invalid'), description: "" });
  //     return;
  //   }
  //   form.current.setFieldsValue({presenter_profile_thumbnail_name: file.name});
  // }

  // const beforeUploadDescriptionVideo = (file, fileList) => {
  //   const fileSize = file.size/1024/1024;
  //   if (!acceptVideo.includes(file.type) || fileSize >= 10) {
  //     notification.error({message: t('common.video_upload_invalid'), description: "" });
  //     return;
  //   }
  //   form.current.setFieldsValue({description_video_name: file.name});
  // }

  // const beforeUploadDescriptionImage = (file, fileList) => {
  //   const fileSize = file.size/1024/1024;
  //   if (!acceptImage.includes(file.type) || fileSize >= 10) {
  //     notification.error({message: t('common.image_upload_invalid'), description: "" });
  //     return;
  //   }
  //   form.current.setFieldsValue({description_photo_name: file.name});
  // }

  // const beforeUploadDescriptionThumbnail = (file, fileList) => {
  //   const fileSize = file.size/1024/1024;
  //   if (!acceptImage.includes(file.type) || fileSize >= 10) {
  //     notification.error({message: t('common.image_upload_invalid'), description: "" });
  //     return;
  //   }
  //   form.current.setFieldsValue({description_thumbnail_name: file.name});
  // }

  const beforeUploadPhoto = (file, fileList) => {
    const fileSize = file.size / 1024 / 1024;
    if (!acceptImage.includes(file.type) || fileSize >= 10) {
      notification.error({ message: t('common.image_upload_invalid'), description: '' });
      return;
    }
    const objectURL = URL.createObjectURL(file);
    setPreviewPhoto(objectURL);
    somethingChanged();
  };

  const beforeUploadPresenterPhoto = (file, fileList) => {
    const fileSize = file.size / 1024 / 1024;
    if (!acceptImage.includes(file.type) || fileSize >= 10) {
      notification.error({ message: t('common.image_upload_invalid'), description: '' });
      return;
    }
    const objectURL = URL.createObjectURL(file);
    setPreviewPresenterPhoto(objectURL);
    somethingChanged();
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const formatInput = (event) => {
    const target = event.target;
    form.current.setFieldsValue({ [target?.id]: target?.value.trim() });
  };

  return (
    <div className="step1-content">
      <Row>
        <Col span={18}>
          <Row>
            <Col className="row-title-content" span={6}>
              <span className="label-style-common">{t('seminar_detail.step1.title')}</span>
            </Col>
            <Col span={18}>
              <Form.Item
                name="title"
                initialValue={seminar?.title}
                rules={[{ required: true, message: t('seminar_detail.step1.title') + t('common.field_required') }]}
              >
                <Input onBlur={formatInput} onChange={somethingChanged} disabled={!isEdit} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col className="row-title-content" span={6}>
              <span className="label-style-common">{t('seminar_detail.step1.presenter')}</span>
            </Col>
            <Col span={18}>
              <Form.Item
                name="presenter"
                initialValue={seminar?.presenter}
                rules={[{ required: true, message: t('seminar_detail.step1.presenter') + t('common.field_required') }]}
              >
                <Input onBlur={formatInput} onChange={somethingChanged} disabled={!isEdit} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col className="row-title-content" span={6}>
              <span className="label-style-common">{t('seminar_detail.step1.presenter_profile_text')}</span>
            </Col>
            <Col span={18}>
              {/* <Row>
                <Col span={4}>
                  <Form.Item
                    name="isUploadPresenterProfileText"
                    initialValue={seminar?.presenter_profile}
                    valuePropName="checked"
                  >
                    <Checkbox onChange={onhangeCheckbox} disabled={!isEdit}>{t('organizer_detail.detail_title.message_text')}</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="isUploadPresenterProfileImage"
                    initialValue={seminar?.presenter_profile_photo}
                    valuePropName="checked"
                  >
                    <Checkbox onChange={onhangeCheckbox} disabled={!isEdit}>{t('organizer_detail.detail_title.message_image')}</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="isUploadPresenterProfileVideo"
                    initialValue={seminar?.presenter_profile_video_url}
                    valuePropName="checked"
                  >
                    <Checkbox onChange={onhangeCheckbox}  disabled={!isEdit}>{t('organizer_detail.detail_title.message_video')}</Checkbox>
                  </Form.Item>
                </Col>
              </Row> */}
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="presenter_profile_text"
                    initialValue={seminar?.presenter_profile}
                    rules={[
                      {
                        required: true,
                        message: t('seminar_detail.step1.presenter_profile_text') + t('common.field_required'),
                      },
                    ]}
                  >
                    <TextArea onBlur={formatInput} onChange={somethingChanged} rows={6} disabled={!isEdit} />
                  </Form.Item>
                </Col>
              </Row>
              {/* <Row>
                <Col className="step1-image-title" span={19}>
                  <Form.Item
                    name="presenter_profile_photo_name"
                    initialValue={seminar?.presenter_profile_photo}
                    rules={[{ required: isUploadPresenterProfileImage, message: t('common.image_upload') + t('common.field_required') }]}
                  >
                    <Input placeholder={t('common.image_upload')} disabled={true}/>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="presenter_profile_photo_url"
                    initialValue={seminar?.presenter_profile_photo}
                  >
                    <Upload
                      className="full-width"
                      name='file'
                      accept=".png,.jpeg,.jpg"
                      showUploadList={false}
                      beforeUpload={beforeUploadPresenterImage}
                    >
                      <Button className="full-width step1-photo" disabled={!(isUploadPresenterProfileImage && isEdit)}>
                        {t('seminar_detail.step1.upload')}
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
              <Col className="step1-image-title" span={19}>
                  <Form.Item
                    name="presenter_profile_video_name"
                    initialValue={seminar?.presenter_profile_video_url}
                    rules={[{ required: isUploadPresenterProfileVideo, message: t('common.video_upload') + t('common.field_required') }]}
                  >
                    <Input placeholder={t('common.video_upload')} disabled={true}/>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="presenter_profile_video_url"
                    initialValue={seminar?.presenter_profile_video_url}
                  >
                    <Upload
                      className="full-width"
                      name='file'
                      accept=".mp4"
                      showUploadList={false}
                      beforeUpload={beforeUploadPresenterVideo}
                    >
                      <Button className="full-width step1-photo" disabled={!(isUploadPresenterProfileVideo && isEdit)}>
                        {t('seminar_detail.step1.upload')}
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row> */}
              {/* <Row>
                <Col className="step1-image-title" span={19}>
                  <Form.Item
                    name="presenter_profile_thumbnail_name"
                    initialValue={seminar?.presenter_profile_thumbnail_url}
                    rules={[{ required: isUploadPresenterProfileVideo, message: t('common.video_thumbnail_upload') + t('common.field_required') }]}
                  >
                    <Input placeholder={t('common.video_thumbnail_upload')} disabled={true}/>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="presenter_profile_thumbnail_url"
                    initialValue={seminar?.presenter_profile_thumbnail_url}
                  >
                    <Upload
                      className="full-width"
                      name='file'
                      accept=".png,.jpeg,.jpg"
                      showUploadList={false}
                      beforeUpload={beforeUploadPresenterThumbnail}
                    >
                      <Button className="full-width step1-photo" disabled={!(isUploadPresenterProfileVideo && isEdit)}>
                        {t('seminar_detail.step1.upload')}
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row> */}
            </Col>
          </Row>
          <Row>
            <Col className="row-title-content" span={6}>
              <span className="label-style-common">{t('seminar_detail.step1.description')}</span>
            </Col>
            <Col span={18}>
              {/* <Row>
                <Col span={4}>
                  <Form.Item
                    name="isUploadDescriptionText"
                    initialValue={seminar?.description}
                    valuePropName="checked"
                  >
                    <Checkbox onChange={onhangeCheckbox} disabled={!isEdit}>{t('organizer_detail.detail_title.message_text')}</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="isUploadDescriptionImage"
                    initialValue={seminar?.description_photo}
                    valuePropName="checked"
                  >
                    <Checkbox onChange={onhangeCheckbox} disabled={!isEdit}>{t('organizer_detail.detail_title.message_image')}</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="isUploadDescriptionVideo"
                    initialValue={seminar?.description_video_url}
                    valuePropName="checked"
                  >
                    <Checkbox onChange={onhangeCheckbox}  disabled={!isEdit}>{t('organizer_detail.detail_title.message_video')}</Checkbox>
                  </Form.Item>
                </Col>
              </Row> */}
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="description"
                    initialValue={seminar?.description}
                    rules={[
                      { required: true, message: t('seminar_detail.step1.description') + t('common.field_required') },
                    ]}
                  >
                    <TextArea onBlur={formatInput} onChange={somethingChanged} rows={6} disabled={!isEdit} />
                  </Form.Item>
                </Col>
              </Row>
              {/* <Row>
                <Col className="step1-image-title" span={19}>
                  <Form.Item
                    name="description_photo_name"
                    initialValue={seminar?.description_photo}
                    rules={[{ required: isUploadDescriptionImage, message: t('common.image_upload') + t('common.field_required') }]}
                  >
                    <Input placeholder={t('common.image_upload')} disabled={true}/>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="description_photo_url"
                    initialValue={seminar?.description_photo}
                  >
                    <Upload
                      className="full-width"
                      name='file'
                      accept=".png,.jpeg,.jpg"
                      showUploadList={false}
                      beforeUpload={beforeUploadDescriptionImage}
                    >
                      <Button className="full-width step1-photo" disabled={!(isUploadDescriptionImage && isEdit)}>
                        {t('seminar_detail.step1.upload')}
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col className="step1-image-title" span={19}>
                  <Form.Item
                    name="description_video_name"
                    initialValue={seminar?.description_video_url}
                    rules={[{ required: isUploadDescriptionVideo, message: t('common.video_upload') + t('common.field_required') }]}
                  >
                    <Input placeholder={t('common.video_upload')} disabled={true}/>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="description_video_url"
                    initialValue={seminar?.description_video_url}
                  >
                    <Upload
                      className="full-width"
                      name='file'
                      accept=".mp4"
                      showUploadList={false}
                      beforeUpload={beforeUploadDescriptionVideo}
                    >
                      <Button className="full-width step1-photo" disabled={!(isUploadDescriptionVideo && isEdit)}>
                        {t('seminar_detail.step1.upload')}
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row> */}
              {/* <Row>
                <Col className="step1-image-title" span={19}>
                  <Form.Item
                    name="description_thumbnail_name"
                    initialValue={seminar?.description_thumbnail_url}
                    rules={[{ required: isUploadDescriptionVideo, message: t('common.video_thumbnail_upload') + t('common.field_required') }]}
                  >
                    <Input placeholder={t('common.video_thumbnail_upload')} disabled={true}/>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="description_thumbnail_url"
                    initialValue={seminar?.description_thumbnail_url}
                  >
                    <Upload
                      className="full-width"
                      name='file'
                      accept=".png,.jpeg,.jpg"
                      showUploadList={false}
                      beforeUpload={beforeUploadDescriptionThumbnail}
                    >
                      <Button className="full-width step1-photo" disabled={!(isUploadDescriptionVideo && isEdit)}>
                        {t('seminar_detail.step1.upload')}
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row> */}
            </Col>
          </Row>
        </Col>
        <Col span={1}></Col>
        <Col span={5}>
          <Row style={{ width: '100%', height: '15vh' }}>
            <img alt="" width="100%" height="100%" src={previewPresenterPhoto} className="image-preview"></img>
            <Form.Item
              className="full-width"
              name="presenter_photo"
              initialValue={seminar?.presenter_photo}
              rules={[
                { required: true, message: t('seminar_detail.step1.presenter_photo') + t('common.field_required') },
              ]}
            >
              <Upload
                className="full-width"
                name="file"
                accept=".png,.jpeg,.jpg"
                showUploadList={false}
                beforeUpload={beforeUploadPresenterPhoto}
                customRequest={dummyRequest}
              >
                <Button className="full-width step1-photo" disabled={!isEdit}>
                  {t('seminar_detail.step1.presenter_photo')}
                </Button>
              </Upload>
            </Form.Item>
          </Row>
          <Row>&nbsp;</Row>
          <Row>&nbsp;</Row>
          <Row>&nbsp;</Row>
          <Row>&nbsp;</Row>
          {/* <Row>&nbsp;</Row>
          <Row>&nbsp;</Row>
          <Row>&nbsp;</Row>
          <Row>&nbsp;</Row>
          <Row>&nbsp;</Row>
          <Row>&nbsp;</Row>
          <Row>&nbsp;</Row>
          <Row>&nbsp;</Row>
          <Row>&nbsp;</Row>
          <Row>&nbsp;</Row>
          <Row>&nbsp;</Row>
          <Row>&nbsp;</Row> */}
          <Row style={{ width: '100%', height: '15vh' }}>
            <img alt="" width="100%" height="100%" src={previewPhoto} className="image-preview"></img>
            <Form.Item
              className="full-width"
              name="photo"
              initialValue={seminar?.photo}
              rules={[{ required: true, message: t('seminar_detail.step1.photo') + t('common.field_required') }]}
            >
              <Upload
                className="full-width"
                name="file"
                accept=".png,.jpeg,.jpg"
                showUploadList={false}
                beforeUpload={beforeUploadPhoto}
                customRequest={dummyRequest}
              >
                <Button className="full-width step1-photo" disabled={!isEdit}>
                  {t('seminar_detail.step1.photo')}
                </Button>
              </Upload>
            </Form.Item>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Step1;
