import React, { useState, useEffect } from 'react';
import { Modal, Divider, Menu, Layout, Button, Table, Tag, Radio, Tooltip, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { FolderOpenOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import _ from 'lodash';
import { actions } from '../../../TagManagement/actions';

const { Item } = Menu;
const { Content, Sider } = Layout;

function TagSelectionModal({
  isModalVisible,
  toggleModal,
  actionFolders,
  actions,
  getListAction,
  getActionFolder,
  formikSetFieldValue,
  formikValues,
  getFilterTags,
  filterActions,
}) {
  const { t } = useTranslation();
  const [searchAction, setSearchAction] = useState();
  const [initialValues, setInitialValues] = useState({
    id: '',
    name: '',
  });

  useEffect(() => {
    getActionFolder();
  }, []);

  useEffect(() => {
    const { tag_id, tag_name } = formikValues;

    setInitialValues({
      id: tag_id,
      name: tag_name,
    });
  }, [isModalVisible]);

  useEffect(() => {
    if (actionFolders && actionFolders?.length) {
      getListAction(actionFolders[0]?.id);
    }
  }, [actionFolders]);

  const onSearchAction = (e) => {
    const { value } = e.target;
    getFilterTags(value.trim());
    setSearchAction(value.trim());
  };

  const handleSubmit = () => {
    const { id, name } = initialValues;
    formikSetFieldValue('tag_id', id);
    formikSetFieldValue('tag_name', name);
    toggleModal();
  };

  const setTagNone = () => {
    setInitialValues({
      id: '',
      name: '',
    });
  };

  const rowSelection = {
    renderCell: (checked, record) => {
      const { id } = initialValues;
      return (
        <Radio
          value={record.actionID}
          onChange={() => {
            setInitialValues({
              id: record.actionID,
              name: record.name,
            });
          }}
          checked={record.actionID === id}
        />
      );
    },
  };

  const columns = [
    {
      title: t('action_management.label_column_action_name'),
      dataIndex: 'name',
      key: 'name',
    },
  ];

  const data = (searchAction ? filterActions : actions)?.map((action, index) => {
    return {
      key: index,
      actionID: action.id,
      name: action.name,
    };
  });

  return (
    <Modal
      title={t('message_package.modal_select_tag_title')}
      visible={isModalVisible}
      onCancel={toggleModal}
      closable={false}
      width={700}
      footer={null}
      className="modal-action"
    >
      <>
        <div>
          <label className="modal-update-action__label title-content">{t('message_package.text_conditions')}</label>
          <Radio.Group
            onChange={(e) => formikSetFieldValue('tag_condition', e.target.value)}
            value={formikValues.tag_condition}
          >
            <Radio value={'ACCEPT'}>{t('message_package.option_accept_tag')}</Radio>
            <Radio value={'IGNORE'}>{t('message_package.option_ignore_tag')}</Radio>
          </Radio.Group>
        </div>
        <div className="modal-update-action">
          <label className="modal-update-action__label title-content">
            {t('action_management.label_tag_selection')}
          </label>
          <div className="modal-update-action__input">
            {!!initialValues.name && (
              <Tag closable onClose={() => setInitialValues({ id: '', name: '' })} visible={true}>
                {initialValues.name}
              </Tag>
            )}
            <input
              placeholder={t('action_management.placeholder_search_selection_tag_modal')}
              onChange={onSearchAction}
            />
          </div>
        </div>
        <Layout className="site-layout-background" style={{ padding: '24px 0' }}>
          <Sider className="site-layout-background" width={searchAction ? 0 : 200}>
            <Menu mode="inline" defaultSelectedKeys={['0']}>
              {actionFolders?.map((folder, index) => (
                <Item
                  key={index}
                  icon={<FolderOpenOutlined />}
                  onClick={({ key }) => getListAction(actionFolders[key]?.id)}
                  className="action-management__sider__list__item"
                >
                  <Tooltip title={folder.name}>
                    <span>{folder.name}</span>
                  </Tooltip>
                </Item>
              ))}
            </Menu>
          </Sider>
          <Content>
            <Radio
              value={''}
              onChange={setTagNone}
              checked={!initialValues.id}
              className="modal-update-action__tag-none"
            >
              {t('message_package.option_not_select_tag')}
            </Radio>
            <Table
              rowSelection={{
                type: 'radio',
                ...rowSelection,
              }}
              columns={columns}
              dataSource={data}
              showHeader={false}
              pagination={false}
            />
          </Content>
        </Layout>
        <Divider />
        <Space className="modal-update-tags__button" size="middle">
          <Button className="button button-inactive" onClick={toggleModal}>
            {t('common.button_close')}
          </Button>
          <Button className="button button-active" onClick={handleSubmit}>
            {t('common.button_save')}
          </Button>
        </Space>
      </>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  actionFolders: state.tagManagementReducer.tagFolders,
  actions: state.tagManagementReducer.tags,
  filterActions: state.tagManagementReducer.filterTags,
});

const mapDispatchToProps = (dispatch) => ({
  getActionFolder: () => dispatch(actions.getListTagFolderStart()),
  getListAction: (folderId) => dispatch(actions.getListTagStart(folderId)),
  getFilterTags: (keyword) => dispatch(actions.getFilterTagStart(keyword)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagSelectionModal);
