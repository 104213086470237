import React, { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Divider, Table, Tag, Button, Space, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import SelectComponent from '../../components/Select';
import { FORMAT_TIME_TIME, PAGE_SIZE, FORMAT_TIME_DAY, getUserStatus } from '../../common/AppConst';
import { actions, ActionTypes } from '../ListUser/actions';
import { actions as appActions } from '../Home/actions';

function ListUserPage({ getListUser, putUserStatus, listUser, typeOfAction, resetTypeOfAction }) {
  const { t } = useTranslation();
  const { tag } = useParams();
  const searchInput = useRef(null);
  const [filter, setFilter] = useState({
    keyword: '',
    status: '',
  });
  const [currentPage, setCurrentPage] = useState(1);

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: '10%',
    },
    {
      title: t('list_user.column_name'),
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      ellipsis: true,
    },
    {
      title: t('list_user.column_status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (status, record) => (
        <Fragment>
          {status ? (
            <span className="active">{t('common.active')}</span>
          ) : (
            <span className="inactive">{t('common.inactive')}</span>
          )}
        </Fragment>
      ),
      width: '10%',
    },
    {
      title: t('list_user.column_seminar'),
      dataIndex: 'seminar',
      key: 'seminar',
      render: (seminar) => (
        <Fragment>
          <h4>{seminar.name}</h4>
          <div>{seminar.author}</div>
          <div>
            {`${moment(seminar.date).format(FORMAT_TIME_DAY)} ${moment(seminar.time1).format(
              FORMAT_TIME_TIME
            )} ${moment(seminar.time2).format(FORMAT_TIME_TIME)} ${moment(seminar.time3).format(FORMAT_TIME_TIME)}`}
          </div>
        </Fragment>
      ),
      align: 'center',
      width: '40%',
    },
    {
      title: t('list_user.column_tag'),
      dataIndex: 'tag',
      key: 'tag',
      render: (tags) => (
        <Fragment>
          {tags?.map(
            (tag, index) =>
              !!tag &&
              index < 4 && (
                <Tag key={index} className={tag.length > 20 ? 'text-ellipsis-width' : ''}>
                  {tag}
                </Tag>
              )
          )}
          {tags.length > 4 && <Tag>...</Tag>}
        </Fragment>
      ),
      width: '20%',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle" direction="vertical">
          {record.status ? (
            <Button
              className="button button-inactive"
              type="text"
              onClick={updateUserStatus(record.userId, !record.status)}
            >
              {t('common.inactive')}
            </Button>
          ) : (
            <Button
              className="button button-active"
              type="text"
              onClick={updateUserStatus(record.userId, !record.status)}
            >
              {t('common.active')}
            </Button>
          )}
          <Button className="button button-solid" href={`/organizer/users/${record.userId}`}>
            {t('list_user.button_details')}
          </Button>
        </Space>
      ),
      align: 'center',
      width: '20%',
    },
  ];

  const dataSource = listUser?.data?.elements.map((user, index) => ({
    no: index + 1,
    userId: user.line_user_id,
    name: user.display_name,
    status: user.status,
    seminar: {
      name: user.latest_attendance_seminar_name,
      author: user.latest_attendance_seminar_presenter,
      date: moment(user.latest_attendance_seminar_day),
      time1: moment(user.latest_attendance_seminar_time1),
      time2: moment(user.latest_attendance_seminar_time2),
      time3: moment(user.latest_attendance_seminar_time3),
    },
    tag: user.tag,
  }));

  const handleTableChange = (pagination) => {
    console.log('handleTableChange', pagination);
  };

  const onChangeInput = (fieldName) => (e) => {
    if (fieldName === 'searchName') {
      setFilter({
        ...filter,
        keyword: e.target.value.trim(),
      });
    } else if (fieldName === 'selectStatus') {
      setFilter({
        ...filter,
        status: e,
      });
    }
  };

  useEffect(() => {
    if (typeOfAction === ActionTypes.PUT_USER_STATUS_SUCCESS) {
      getListUser(filter);
      resetTypeOfAction();
    }
  }, [typeOfAction]);

  useEffect(() => {
    getListUser(filter);
    setCurrentPage(1);
  }, [filter]);

  useEffect(() => {
    if (tag) {
      setFilter({
        ...filter,
        keyword: tag,
      });
    }
  }, [tag]);

  const updateUserStatus = (userId, status) => () => {
    putUserStatus({
      userId,
      status,
    });
  };

  return (
    <div className="list-user">
      <div className="ant-col-22 ant-col-offset-1">
        <h2 className="title">{t('list_user.title_list_user')}</h2>
        <Divider />
        <div className="list-user__filter">
          <Input
            className="list-user__filter__name icon"
            placeholder={t('list_user.label_name_tags')}
            size="large"
            onChange={onChangeInput('searchName')}
            suffix={<SearchOutlined />}
            defaultValue={tag}
          />
          <SelectComponent
            value={filter.status}
            options={getUserStatus(t)}
            onChange={onChangeInput('selectStatus')}
            className="list-user__filter__status"
          />
        </div>
        <Table
          rowKey="no"
          bordered
          dataSource={dataSource}
          columns={columns}
          onChange={handleTableChange}
          pagination={{
            pageSize: PAGE_SIZE,
            current: currentPage,
            onChange: (page) => setCurrentPage(page),
          }}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  listUser: state.listUserReducer.listUser,
  statusUpdate: state.listUserReducer.statusUpdate,
  typeOfAction: state.listUserReducer.type,
});

const mapDispatchToProps = (dispatch) => ({
  getListUser: (params) => dispatch(actions.getListUserStart(params)),
  putUserStatus: (payload) => dispatch(actions.putUserStatusStart(payload)),
  resetTypeOfAction: () => dispatch(appActions.resetTypeOfAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListUserPage);
