import { ActionTypes } from './actions';

const DEFAULT_STATE = {
  listRichMenu: [],
  richMenu: {},
  activeRichMenu: {},
  type: '',
  error: '',
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_LIST_RICH_MENU_SUCCESS:
      return {
        ...state,
        listRichMenu: action.payload,
      };
    case ActionTypes.GET_RICH_MENU_DETAIL_SUCCESS:
      return {
        ...state,
        richMenu: action.payload,
      };
    case ActionTypes.PUSH_FILE_TO_S3_SUCCESS:
      return {
        ...state,
        file: action.payload,
        type: action.type,
      };
    case ActionTypes.PUSH_FILE_TO_S3_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.GET_URL_UPLOAD_TO_S3_SUCCESS:
      return {
        ...state,
        fileUrl: action.payload,
        type: action.type,
      };
    case ActionTypes.GET_URL_UPLOAD_TO_S3_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.PUT_RICH_MENU_DETAIL_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.PUT_RICH_MENU_DETAIL_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.POST_RICH_MENU_DETAIL_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.POST_RICH_MENU_DETAIL_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.DELETE_RICH_MENU_DETAIL_SUCCESS:
      return {
        ...state,
        type: action.type,
      };
    case ActionTypes.GET_ACTIVE_RICH_MENU_SUCCESS:
      return {
        ...state,
        activeRichMenu: action.payload,
      };
    case ActionTypes.RESET_TYPE_OF_ACTION:
      return {
        ...state,
        type: '',
      };
    default:
      return state;
  }
};
